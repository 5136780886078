import React, { useState } from 'react'
import { useHistory } from 'react-router'
import Logos from "../../assets/images/eliments.svg";
import Logo from "../../assets/images/Logo-final.png";
import rocketLogo from "../../assets/images/rocket.png";
import { NavLink } from 'react-router-dom';


export const OrganisationSignupSuccessPage = () => {
    const history = useHistory();

    return (
        <div>
            <section className="mb-4 mt-4 px-3 px-md-0">
                <div className="bg-white border border-gray rounded-16 p-3 p-lg-5 container signup-block">
                    <div className="mb-4 d-flex align-items-center">
                        <div>
                            <img src={Logo} alt="" className="login-logo" /></div>
                        <h2 className="login-hd mb-0 fw-light ms-3 ps-3 border-start border-dark">Institution <br />Sign up </h2>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-md-4 mb-4 mb-md-0">
                            <div className="signup-quotblock  pe-lg-5">
                                <h3 className="signup-quot fw-lighter"> Education is<br /> not the learning of<br />facts but the<br />training of the<br /> mind to think</h3>
                                <img src={rocketLogo} className="rocket-img" alt="" />
                            </div>
                        </div>
                        <div className="col-md-8 ps-lg-5">
                            <h3 className="text-blue fw-bold mb-0 f24"> Congratulations!</h3>
                            <h3 className="text-blue fw-normal mb-2 f24">Your Sign up process is</h3>
                            <h2 className="text-green success-text">Successful</h2>
                            <p className="f12 mb-0 fw-light"> We've created your account! Thank you for signing up, you will shortly receive a mail with your login credentials.</p>
                            <p className="f18 fw-light">Welcome aboard, we are excited to serve you!</p>
                            <NavLink className="d-inline-block forgot-color cursor-pointer mt-2" to={'/'}>Login here</NavLink>
                        </div>
                    </div>
                </div>
                <div className="login-elements text-center">
                    <img src={Logos} alt="" className="login-elements-icons" />
                </div>
            </section>
            <footer className="text-center f12 text-dgray mb-3">
                <div className="container">
                    &copy; 2023-24  <a href="http://iamsamarth.com" className="text-dgray text-decoration-none">iamsamarth.com</a>. All Rights Reserved | <a href="http://iamsamarth.com/privacy-policy/" className="text-dgray text-decoration-none">Terms and Conditions</a> |
                    <a href="http://iamsamarth.com/privacy-policy/" className="text-dgray text-decoration-none ms-1">Privacy Policy</a>
                </div>
            </footer>
        </div>
    )
}