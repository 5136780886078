import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Link, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { GET_INDIVIDUAL_BY_USER_CODE, GET_USER_USER_CODE } from '../../../../app/service/users.service';
import { MNSProgressView } from '../mns/dashboard';
import SUCCESSIMG from '../../../../institute-dashboard-assets/images/success-img.svg'
import { FETCH_PARIKSHA_USER_QUESTION_INFO } from '../../../../app/service/pariksha-module-questions';
import { GET_MODULES_BY_USER_CODES } from '../../../../app/service/module.service';
import { CREATE_PAYMENT, CREATE_PAYMENT_DETAILS_SCHOOL_MODULE_CODE } from '../../../../app/service/payment-service';
import { CREATE_MNS_USER_ASSIGN } from '../../../../app/service/mns-service';
import { CREATE_CCAVENUE_PAYMENT } from '../../../../app/service/ccavenue.service';
import { UI_BASE_URL } from '../../../../config/constant';
import { ADD_UPDATE_SUBSCRIPTION_PRICE } from '../../../../app/service/subscription.service';


export const ParikshaLevelSuccessData = () => {
    const [userInfo, setUserInfo] = useState<any>({});
    let { moduleUUID, userCode, schoolCode, testNo } = useParams<{ moduleUUID: string, userCode: string, schoolCode: string, testNo: string }>();
    const [loading, setLoading] = useState(false);
    const [testInfo, setTestInfo] = useState<any>({});
    const [moduleData, setModuleData] = useState<any>({});
    const [paymentInfo, setPaymentInfo] = useState<any>({});
    const [trialStatus, setTrialStatus] = useState<boolean>(false);
    const [canShowPaymentPopup, setCanShowPaymentPopup] = useState<boolean>(false);
    const [type, setType] = useState<any>('');
    const history = useHistory();
    let selectUuids = JSON.parse(localStorage.getItem('userSelectedSubjectInfo') || '{}');
    // const resultInfo = localStorage.getItem("resultInfo");
    const testData: any = JSON.parse(localStorage.getItem("result-test-data") || '{}');

    useEffect(() => {
        const splitLocation: any = window.location.href.split("?");
        const queryString = splitLocation[1];
        if (queryString) {
            const match = queryString.match(/status=([^&]*)/);
            if (match) {
                const statusValue = match[1];
                const studentInfo: any = JSON.parse(localStorage.getItem('cc_student_info') || '{}');
                const studentPaymentInfo = JSON.parse(localStorage.getItem('cc_user_payment_info') || '{}');
                const txnId = localStorage.getItem("cc_transaction_id");
                const moduleInfo = JSON.parse(localStorage.getItem('cc_module_info') || '{}');
                if (txnId && statusValue === "success") {
                    updateSubscription(txnId, "", studentPaymentInfo, statusValue, studentInfo, moduleInfo);
                } else {
                    removeItemsFromeLocalStorage();
                }
            } else {
            }
        }
        getUserDetails();
        getModuleData();
        let testInfo: any = localStorage.getItem("pariksha_test_info");
        const type = localStorage.getItem("pariksha_type");
        setTestInfo(JSON.parse(testInfo));
        setType(type);
        // getParikshaInfo();

    }, []);

    function getUserDetails() {
        setLoading(true);
        GET_USER_USER_CODE(userCode).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res?.message);
                setLoading(false);
            } else {
                if (res) {
                    const data = res?.rows[0];
                    localStorage.setItem("cc_student_info", JSON.stringify(data));
                    setUserInfo(data);
                    if (data?.account_type === "TRIAL" && data?.trial_start_dt && data?.trial_end_dt) {
                        const trialStartDate = new Date(data?.trial_start_dt);
                        const trialEndDate = new Date(data?.trial_end_dt);
                        const currentDate = new Date();
                        const endEqualsCurrent = currentDate?.toDateString() === trialEndDate?.toDateString();
                        if (currentDate >= trialStartDate && (endEqualsCurrent || currentDate <= trialEndDate) && data?.modules) {
                            const modulesArray = data?.modules?.split(',').map((module: any) => module.trim());
                            let matchFound = modulesArray.includes("ABHYAAS");
                            setTrialStatus(matchFound);
                        }
                    }
                }
                setLoading(false);
            }
        });
    }
    function getModuleData() {
        GET_MODULES_BY_USER_CODES(userCode).then((res: any) => {

            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                } else {
                    const data = res.filter((el: any) => el.module_code === 'ABHYAAS');
                    setModuleData(data[0]);
                }
            }
        });
    }




    function onTopicwiseReport() {
        localStorage.setItem("testUuid", "0");
        history.push(`/student-dashboard/list/pariksha/ability-result/${moduleUUID}/${userCode}/${schoolCode}`);
        // history.push(`/student-dashboard/list/pariksha/progress-view/${moduleUUID}/${userCode}/${selectUuids?.school_code}/${selectUuids?.board_uuid}/${selectUuids?.medium_uuid}/${selectUuids?.standard_uuid}/${selectUuids?.subject_uuid}`);

    }
    const viewResult = () => {
        history.push(`/student-dashboard/list/pariksha/result-explanation/${moduleUUID}/${userCode}/${schoolCode}`);
    }
    const viewTestAnalysis = () => {
        localStorage.setItem("testUuid", "0");
        history.push(`/student-dashboard/list/pariksha/test-analysis/${moduleUUID}/${userCode}/${schoolCode}`);
    }
    const onClickCancel = () => {
        setCanShowPaymentPopup(false);
    }
    const viewAbyhyaasModule = () => {
        if (moduleData?.amount_status === "COMPLETED" || trialStatus) {
            history.push(`/student-dashboard/list/abhyas/progress-view/${moduleData?.uuid}/${userCode}/${schoolCode}/${selectUuids?.board_uuid}/${selectUuids?.medium_uuid}/${selectUuids?.standard_uuid}/${selectUuids?.subject_uuid}`)

        } else {
            if (moduleData?.uuid) {
                getPaymentDetailsOfUser();
            }
        }
    }
    function getPaymentDetailsOfUser() {
        const payLoad = {
            "institute_school_code": schoolCode,
            "standard": "",
            "division": "",
            "students": [{ user_code: `${userCode}` }],
            "module_uuid": moduleData?.uuid
        }
        CREATE_PAYMENT_DETAILS_SCHOOL_MODULE_CODE(payLoad).then((res: any) => {
            if (res.status === "fail") {
                toast.error(res?.message);
            } else {
                if (res) {
                    let data = res?.students;
                    if (data?.length > 0) {
                        setPaymentInfo(data[0]);
                        localStorage.setItem("cc_user_payment_info", JSON.stringify(data[0]));
                        setCanShowPaymentPopup(true);
                    }
                }
            }
        });

    }
    const handlePayment = () => {
        createPayment(moduleData, paymentInfo);
        localStorage.setItem("cc_module_info", JSON.stringify(moduleData));
    }
    function createPayment(moduleInfo: any, paymentInfo: any) {
        const payload = {
            school_code: schoolCode,
            user_ids: paymentInfo?.user_code,
            sub_amount: paymentInfo?.total_amount
        }
        if (parseInt(paymentInfo?.total_amount) > 0) {
            CREATE_PAYMENT(payload).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res.message);
                    } else {
                        onOpenPaymentInfo(payload.sub_amount, res.txn_id, moduleInfo, paymentInfo);
                    }
                }
            });
        } else {
            getAssignModule(paymentInfo, moduleInfo, userInfo);
        }
    }
    function onOpenPaymentInfo(amount: any, txn_id: string, moduleInfo: any, paymentInfo: any) {
        localStorage.setItem("cc_user_payment_info", JSON.stringify(paymentInfo));
        localStorage.setItem("cc_transaction_id", txn_id)
        createCcavenuePayment(amount, txn_id);
    }
    const createCcavenuePayment = (amount: any, txn_id: string) => {
        let payLoad = {
            amount: amount,
            currency: "INR",
            transaction_uuid: txn_id,
            redirect_url: `${UI_BASE_URL}/student-dashboard/list/pariksha/success-page/${moduleUUID}/${userCode}/${schoolCode}/${testNo}`,
            cancel_url: `${UI_BASE_URL}/student-dashboard/list/pariksha/success-page/${moduleUUID}/${userCode}/${schoolCode}/${testNo}`,
        }
        CREATE_CCAVENUE_PAYMENT(payLoad).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res?.error);
            } else {
                initiatePayment(res);

            }
        });
    }
    const initiatePayment = async (paymentData: any) => {
        window.location.href = paymentData.url;
    };
    function updateSubscription(transactionId: any, transactionData: any, paymentInfo: any, paymentStatus: any, studentInfo?: any, moduleInfo?: any) {
        if (paymentInfo?.subscription_payment_status === 'pending') {
            const payload = {
                data: [
                    {
                        "uuid": "",
                        "school_code": studentInfo?.institute_school_code,
                        "user_code": paymentInfo?.user_code,
                        "transaction_id": transactionId,
                        "amount": parseInt(paymentInfo?.subscription_price),
                        // "transaction_details": JSON.stringify(transactionData),
                        "transaction_details": "",
                        "payment_status": "completed"
                    }
                ]
            }
            if (paymentStatus === "success") {
                ADD_UPDATE_SUBSCRIPTION_PRICE(payload).then((res: any) => {
                    if (res.status === 'fail') {
                        toast.error(res?.message);
                    } else {
                        getAssignModule(paymentInfo, moduleInfo, studentInfo);
                        toast.success('Subscription Added Successfully');
                    }
                });
            }
        } else {
            getAssignModule(paymentInfo, moduleInfo, studentInfo);
        }
    }

    function getAssignModule(paymentInfo: any, moduleData: any, studentInfo?: any) {
        const user_code = [];
        user_code.push(paymentInfo?.user_code);
        const payload = {
            module_uuid: moduleData.uuid ? moduleData.uuid : '',
            payment_status: "COMPLETED",
            school_code: studentInfo?.institute_school_code,
            target_std: moduleData.target_std,
            total_amount_paid: paymentInfo?.module_price,
            user_codes: user_code
        }
        CREATE_MNS_USER_ASSIGN(payload).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
            } else {
                toast.success("Successfully assigned  module!");
                getModuleData()
                removeItemsFromeLocalStorage();
            }
        });
    }

    const removeItemsFromeLocalStorage = () => {
        const keysToRemove = ['cc_student_info', 'cc_user_payment_info', 'cc_transaction_id', 'cc_module_info'];
        keysToRemove.forEach((key: any) => {
            localStorage.removeItem(key);
        });
    };
    return (
        <div>
            <div
                className={`modal fade ${canShowPaymentPopup ? "show" : "false"}`}
                style={{ display: canShowPaymentPopup ? "block" : "none" }}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog pariksha-model-dialog" role="document">
                    <div className="modal-content pariksha-model-content">
                        <div className="modal-body  pariksha-model-info">
                            <div className='pariksha-model-border ba bas-dashed'>
                                <div className=' mt-4 justify-content-center'>
                                    <h6 className='blue_text f16'>You do not have to access to <br />ABHYAAS initiative to solve worksheets </h6>
                                </div>
                                <p className='text-center blue_text fw-normal'>You need to pay  <i className="fa fa-rupee pt-3"></i> {paymentInfo?.total_amount}.00  to access <br /> ABHYAAS initiative </p>
                                <div className='start-test mt-4 justify-content-center'>
                                    <h6>Do you want to continue ? </h6>
                                </div>
                                <div className='mt-4'>
                                    <div className='mt-3 d-flex' style={{ justifyContent: 'center' }}>
                                        <button className='btn test-btn' onClick={handlePayment}>Pay Now</button>
                                        <button className='btn test-btn' onClick={onClickCancel}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb pb-2 ps-0 pt-0 border-bottom">
                            <li className="breadcrumb-item "><Link to="/student-dashboard/list"><span className='pe-2'>Dashboard</span></Link></li> /
                            <li className="breadcrumb-item "><Link to={`/student-dashboard/list/pariksha/test-initiative/${moduleUUID}/${userCode}/${schoolCode}`}><span className='pe-2'>Pariksha</span></Link></li> /
                            <li className="breadcrumb-item "><Link to={`/student-dashboard/list/pariksha/test-level-status/${moduleUUID}/${userCode}/${schoolCode}`}><span>Start Pariksha</span></Link></li> /
                            {/* <li className="breadcrumb-item "><Link to={`/student-dashboard/list/pariksha/progress-view/${moduleUUID}/${userCode}/${selectUuids?.school_code}/${selectUuids?.board_uuid}/${selectUuids?.medium_uuid}/${selectUuids?.standard_uuid}/${selectUuids?.subject_uuid}`}><span>{testInfo?.test_name ? testInfo?.test_name : testInfo?.topic_name}</span></Link></li> / */}
                            <li className="breadcrumb-item "><span>{testInfo?.test_name}</span></li> /
                            <li className="breadcrumb-item "><span> Submission</span></li>
                        </ol>
                    </nav>
                    <div className="row mt-1">
                        <div className='col-lg-6 col-12 text-center success-block mt-5 border-right border-secondary '>
                            <div>
                                <div>
                                    <img src={SUCCESSIMG} alt="" />
                                    <h3 className="mt-2 active fw-normal">{userInfo?.name?.charAt(0).toUpperCase() + userInfo?.name?.slice(1).toLowerCase()}</h3>
                                    <h4 className="f20 mt-1">
                                        You have <span className={` fw-bold f22 ${testData?.result?.result === "fail" ? "text-inprogess" : "text-completed"} `}>{testData?.result?.result === "fail" ? "Failed" : "Passed"}</span> the
                                    </h4>
                                </div>
                                <h5 className='text-completed mt-1 '>{testInfo?.test_name}</h5>
                                {testInfo?.exam_type === "CHAPTER_WISE_TEST" && <p className='mb-0 f18 fw-bold'> ({testData?.topic_wise_result[0]?.chapter_name})</p>}
                                <p className='mt-2 f20 fw-bold'>Score : {testData?.result?.correct}/{testData?.result?.total_questions} ({testData?.result?.percentage?.toFixed()}%)</p>
                            </div>
                            <div className=' mt-2 '>
                                {/* <p className="f22 fw-light mt-5 m-0">Please Check Your</p> */}
                                <button className='bg_org px-3 py-2 border-0 text-white rounded-3' onClick={() => { onTopicwiseReport() }}>
                                    Test Summary
                                </button>
                                <button className='bg_org px-3 py-2 border-0 text-white rounded-3 ms-2' onClick={viewTestAnalysis}>
                                    Test Analysis
                                </button>
                            </div>
                            <button className='ab_btn mt-2' onClick={viewResult}> View Questions and Answers with Explanation</button>

                        </div>
                        <div className="col-lg-6 col-12 mt-5 login-fileds ">
                            <div className='row justify-content-center'>
                                <div className='col-lg-10  col-12'>
                                    <div className='text-center'>
                                        <div className='mx-lg-5 px-lg-4'>
                                            <h4 className={`text-inprogess mt-2 ${testData?.result?.result === "fail" && "mb-5"}`}> Top<span className='und_line'>ics assinged for prac</span>tice</h4>
                                            {testData?.result?.result !== "fail" && <p className='mt-3 f14'>(Even through you have passed the test, there are some topics that needs to be practiced)</p>}
                                        </div>
                                        <div className="mt-3 custom-scroll table-responsive mx-md-4">
                                            <table className="table ability-table mb-0">
                                                <thead>
                                                    <tr className='ability-topic-header'>
                                                        <th className='text-start'>Topic Name</th>
                                                        <th className='text-center'>Worksheets</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {testData?.topic_wise_result?.map((item: any, i: number) => (
                                                        <tr>
                                                            <td className={` text-start ${item?.worksheets == 0 && "text-completed"}`}>{item?.topic_name}</td>
                                                            <td className='text-black text-center'>{item?.worksheets}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        <button className='ab_btn mt-5' onClick={viewAbyhyaasModule}>GO FOR ABHYAAS</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}