import React from "react";

const EventSignupPageError = () => {
    return (
        <div className="vh-100 d-flex justify-content-center align-items-center" >
            <div >
                <h1 className="text-danger text-center" style={{ fontSize: "55px" }}>404 Error</h1>
                <h5 className="text-center">This page doesn't exist.</h5>
            </div>
        </div>

    )
}
export default EventSignupPageError;