import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";


const ParikshaResultExplanation = () => {
    const [loading, setLoading] = useState<boolean>(false);
    let { moduleUUID, userCode, schoolCode } = useParams<{ moduleUUID: string, userCode: string, schoolCode: string }>();
    let selectUuids = JSON.parse(localStorage.getItem('userSelectedSubjectInfo') || '{}');
    const testInfo: any = JSON.parse(localStorage.getItem("pariksha_test_info") || '{}');
    const testDataInfo: any = JSON.parse(localStorage.getItem("result-test-data") || '{}');
    const [testData, setTestData] = useState<any>([]);
    const history = useHistory();

    useEffect(() => {
        let preData = testDataInfo?.student_result?.filter((item: any, index: number) => {
            item.isSelected = false;
            return item;
        })
        setTestData(preData);
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 500);

    }, []);

    const getDomElements = (htmlString: any, tag: string) => {
        const parsedContent = new DOMParser().parseFromString(htmlString, 'text/html');
        const pElement = parsedContent.querySelector('p');
        const imgElement: any = parsedContent.querySelector('img');
        return <p className="mb-0">{parsedContent ? parsedContent.textContent : ''}</p>
        if (tag === "p") {
            // return <p className="mb-0">{pElement ? pElement.textContent : ''}</p>
        }
        if (tag === "img") {
            if (imgElement) {
                return imgElement ? imgElement.getAttribute('src') : ''
            } else {
                return "";
            }
        }

        return "";
    }
    const handleBack = () => {
        history.push(`/student-dashboard/list/pariksha/test-initiative/${moduleUUID}/${userCode}/${schoolCode}`);

        // history.push(`/student-dashboard/list/pariksha/success-page/${moduleUUID}/${userCode}/${schoolCode}/${testInfo.test_no}`)
    }
    const getDomOption = (htmlString: any, tag: string) => {
        const parsedContent = new DOMParser().parseFromString(htmlString, 'text/html');
        const pElement = parsedContent.querySelector('p');
        const imgElement: any = parsedContent.querySelector('img');
        if (tag === "p") {
            return <span className="mb-0">{pElement ? pElement.textContent : ''}</span>
        }
        if (tag === "img") {
            if (imgElement) {
                return imgElement ? imgElement.getAttribute('src') : ''
            } else {
                return "";
            }

        }
        return "";
    }
    const getOptions = (data: any, option: string) => {
        const preData = data.find((ele: any) => (ele?.option === option));
        if (preData?.value) {
            return <div className="mb_0 img_ck" dangerouslySetInnerHTML={{ __html: preData?.value }} />
            // return getDomOption(preData?.value, "p")
        }
    }
    const onClickExplanation = (item: any, index: number) => {
        let items = [...testData];
        items[index]['isSelected'] = !item.isSelected;
        setTestData([...items]);
    }
    function onViewQuestion(name: any) {
        return { __html: name };
    }
    return (
        <div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb pb-2 ps-0 pt-0 border-bottom">
                            <li className="breadcrumb-item "><Link to="/student-dashboard/list"><span className='pe-2'>Dashboard</span></Link></li> /
                            <li className="breadcrumb-item "><Link to={`/student-dashboard/list/pariksha/test-initiative/${moduleUUID}/${userCode}/${schoolCode}`}><span className='pe-2'>Pariksha</span></Link></li> /
                            <li className="breadcrumb-item "><Link to={`/student-dashboard/list/pariksha/test-level-status/${moduleUUID}/${userCode}/${schoolCode}`}><span>Start Pariksha</span></Link></li> /
                            {/* <li className="breadcrumb-item "><Link to={`/student-dashboard/list/pariksha/progress-view/${moduleUUID}/${userCode}/${selectUuids?.school_code}/${selectUuids?.board_uuid}/${selectUuids?.medium_uuid}/${selectUuids?.standard_uuid}/${selectUuids?.subject_uuid}`}><span>{testInfo?.test_name}</span></Link></li> / */}
                            <li className="breadcrumb-item "><span className="active">{testInfo?.exam_type?.split('_')
                                .map((word: any) => word?.charAt(0).toUpperCase() + word?.slice(1).toLowerCase())
                                .join(' ')}</span></li> /
                            <li className="breadcrumb-item "><Link to={`/student-dashboard/list/pariksha/success-page/${moduleUUID}/${userCode}/${schoolCode}/${testInfo.test_no}`}><span>Submission</span></Link></li> /
                            <li className="breadcrumb-item "><span>Question-Answers-Explanation</span></li>
                        </ol>
                    </nav>
                    <div className="row">
                        <div className='text-end me-5 mb-1'>
                            <button className="btn btn-primary btn-sm px-5 py-1 rounded-12 cursor-pointer" onClick={handleBack}>Back</button>
                        </div>
                        <h5 className="fw-normal mb-2 ms-1">{testInfo?.exam_type?.split('_')
                            .map((word: any) => word?.charAt(0).toUpperCase() + word?.slice(1).toLowerCase())
                            .join(' ')}   {(testInfo?.exam_type === "CHAPTER_WISE_TEST") && <span>({testDataInfo?.topic_wise_result[0]?.chapter_name})</span>}</h5>
                        <h5 className="q_text_color mb-2 ms-1">Questions and Answers with Explanation</h5>
                        <div className="col-md-11 col-12 ex_border px-4 py-3">
                            <div className="row">
                                <div className="col-md-11 col-12">
                                    {testData?.map((question: any, i: number) => (
                                        <div className="mb-5" key={i}>
                                            <div className="d-flex mb-2">
                                                <h6 className="pt-1">{question?.id}. </h6>
                                                <div className='fw-bold ms-2 mb_0 img_ck' dangerouslySetInnerHTML={onViewQuestion(question?.question)}></div>

                                                {/* <div>
                                                    {getDomElements(question?.question, "p") && <h6 className="ms-1">{getDomElements(question?.question, "p")}</h6>}
                                                    {getDomElements(question?.question, "img") && <div className="ms-1">
                                                        <img className="img_mathjx" src={getDomElements(question.question, "img")} alt="" />
                                                    </div>}
                                                </div> */}
                                            </div>
                                            <div className="ms-md-4 ms-0">
                                                <div className={`d-flex justify-content-between ${question?.result === "wrong" ? "ex_pink" : "ex_blu"}  px-2 pt-1 rounded-3`}>
                                                    <div className={`d-flex  f14 `}><p className="mb-0 ">Your Answer : </p> <div className="d-flex fw-bold ms-2"><p className="mb-0">{question?.answer} {question?.answer && <span className="fw-bold">)</span>}</p> {question?.answer && <p className="ms-2 mb-0">{getOptions(question?.options, question?.answer)}</p>}</div></div>
                                                    <p className="mb-1  fw-bold">{question?.result === "wrong" ? <span className="text-inprogess"> &#10006;</span> : <span className="text-completed tick_mark"> </span>}</p>
                                                </div>
                                                <div className="d-flex mt-1 mb-3 px-2 py-1 ex_blu  rounded-3 f14 "><p className="mb-0 ">Correct Answer : </p> <div className="d-flex fw-bold ms-2"><p className="mb-0">{question?.correct_answer} )</p> {question?.correct_answer && <p className="ms-2 mb-0">{getOptions(question?.options, question?.correct_answer)}</p>}</div></div>
                                                {question?.explanation && <p className="active f14 fw-bold mb-2 cursor-pointer position-relative " onClick={() => onClickExplanation(question, i)}><u>Explanation</u>  : <i className={`ms-2 f22 position-absolute  ${question.isSelected ? "fa fa-angle-down" : "fa fa-angle-right"}  `}></i></p>}
                                                {question.isSelected &&
                                                    <div className='f14 mb-2 mb-0 text-break mb_0 img_ck' dangerouslySetInnerHTML={onViewQuestion(question?.explanation)}></div>
                                                }
                                            </div>
                                        </div>
                                    ))}
                                    {testData?.length == 0 && <p className='ms-3 f16 my-2'>No Data Available</p>}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>}

        </div>
    )
}
export default ParikshaResultExplanation;