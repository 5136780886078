import React, { useEffect, useRef, useState } from "react";
import appConfig from "../../config/constant";
import REPORTBGIMG from '../../assets/images/math-report-bg.png';
import GIRLICON from '../../assets/images/math-girl-icon.png';
import BOYICON from '../../assets/images/math-boy-icon.png';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

interface Props {
    reportInfo: any;
}

const MathsReportData: React.FC<Props> = ({ reportInfo }) => {

    const convertDecimalToTime = (value: any): string => {
        const totalSeconds = Math.round(value * 60); // Convert decimal to total seconds
        const minutes = Math.floor(totalSeconds / 60); // Extract minutes
        const seconds = totalSeconds % 60;
        return `${minutes}${seconds > 0 ? `.${seconds < 10 ? `0${seconds}` : seconds}` : ""}`;
    };
    const options: Highcharts.Options = {
        chart: {
            backgroundColor: "transparent",
            type: 'pie',
            height: 180, // Adjust the height of the chart
            width: 330,
        },
        title: {
            text: ''
        },
        plotOptions: {
            pie: {
                innerSize: '74%', // Setting innerSize to create the donut chart
                depth: 45,
                size: '80%',
                borderWidth: 0,
                borderRadius: 0,
                dataLabels: {
                    enabled: true,
                    useHTML: true,
                    style: {
                        color: 'black', // Set label color
                        fontSize: '12px', // Set font size for labels
                        fontWeight: 'normal' // Set font weight to normal for labels
                    },
                    formatter: function () {
                        return `<div style="color:${this.point.color}; font-weight:"normal">${this.point.name}</div>`; // Custom label format with color
                    },
                    connectorWidth: 0, // Remove connector lines
                    connectorPadding: 0, // Remove connector lines
                    distance: 8,// Reduce gap between labels and chart
                },

            }
        },
        tooltip: {
            formatter: function () {
                return `<b>${this.point.name}</b>: ${this.point.y}`; // Custom tooltip format without "Series" prefix
            }
        },
        series: [{
            type: 'pie',
            data: [
                {
                    name: "Recommended Time", y: Number(reportInfo?.recomended_time) ? Number(reportInfo?.recomended_time) : 0, color: "#0F70CE"
                },

                {
                    name: "Time Utilised", y: Number(reportInfo?.total_time) ? Number(convertDecimalToTime(reportInfo?.total_time)) : 0, color: "#F54412"
                },

            ]
        }],
        credits: {
            enabled: false // Remove Highcharts watermark
        }

    };
    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        const options: any = { month: 'long', day: 'numeric', year: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    };

    return (

        <div className="mx-lg-5  px-3">
            <div className="position-relative mb-3">
                <i className="report_ias_icon position-absolute" style={{ left: "22px", top: "13px" }}></i>
                <img className="report_bg_img" src={REPORTBGIMG} alt="" />
                <h3 className=" pf_txt" >Performance Report</h3>
                <div className="position-absolute d-flex" style={{ left: "22px", top: "170px" }}>
                    {reportInfo?.profile_img ?
                        <img className="report_logo_icon " src={`${appConfig.FILE_UPLOAD_URL}${reportInfo?.profile_img.charAt(0) === "/" ? reportInfo?.profile_img.substring(1) : reportInfo?.profile_img}`} />
                        :
                        <img src={reportInfo?.gender === "female" ? GIRLICON : BOYICON} alt="" style={{ width: "9%" }} />
                    }
                    <h6 className="mb-0 ms-1 mt-3">{reportInfo?.name?.charAt(0)?.toUpperCase() + reportInfo?.name?.slice(1)}</h6>
                </div>
                <h6 className="f18 position-absolute" style={{ bottom: "17px", right: "50px" }}>Class : {reportInfo?.standard}</h6>
            </div>
            <div className="text-center mb-2">
                <span className="maths_initiative_btn">Maths Initiative</span>
            </div>
            <p className="f14 mb-0">( {formatDate(reportInfo?.current_week_from)} - {formatDate(reportInfo?.current_week_to)} )</p>
            <p className="f14 mb-1">Attendance and Usage ( in minutes )</p>
            {reportInfo?.attendance?.length > 0 && <div className="report_time_dsply ms-2 ms-lg-3" >
                {reportInfo?.attendance?.map((data: any, i: number) =>
                    <div className="text-center" key={i}>
                        <p className="mb-0">{data?.day_name?.substring(0, 3)}</p>
                        <span className={`${data?.time_spent > 0 ? "maths_week_btn_time" : "maths_week_btn"}`}>{data?.time_spent > 0 ? `${convertDecimalToTime(data?.time_spent)} min` : "No Login"}</span>
                    </div>)}
            </div>}
            {/* ////// */}
            <div className="tm_blck">
                <div>
                    <div className="d-flex justify-content-between">
                        <div className="maths_time_card px-3 py-2 text-center mt-4">
                            <p className="mb-0 f18">Total Time Utilised</p>
                            <p className="mb-0 f12 fw-bold">{reportInfo?.total_time && convertDecimalToTime(reportInfo?.total_time)} mins / Week</p>
                        </div>
                        <div className="maths_time_card px-3 py-2 text-center mt-4 ms-md-3 ms-lg-5 ms-2">
                            <p className="mb-0 f18">Recommended Time</p>
                            <p className="mb-0 f12 fw-bold">{reportInfo?.recomended_time && convertDecimalToTime(reportInfo?.recomended_time)} mins / Week</p>
                        </div>
                    </div>
                    <p className="mb-0 f14 text-center mt-2">{reportInfo?.overview_message?.charAt(0)?.toUpperCase() + reportInfo?.overview_message?.slice(1)}</p>
                </div>
                <div className=" ">
                    <div className="position-relative">
                        <div >
                            <HighchartsReact highcharts={Highcharts} options={options} />
                        </div>
                        <div className="position-absolute" style={{ right: "27px", top: "35px" }}>
                            <p className="mb-0 f18 text-primary fw-bold">{reportInfo?.percentage}%</p>
                            <h6 className="f18">Overall <br />Accuracy</h6>
                        </div>
                    </div>
                    <p className="f14 mb-0 position-relative text-center " style={{ bottom: "15px" }}>Your child has answered correctly {reportInfo?.percentage}% of the time.</p>

                </div>

            </div>


            {/* ////// */}

            <div className="ms-md-3">
                {/* <div className="d-flex justify-content-between mt-4 pt-2 ms-2 ms-lg-4 ">
                    <div>
                        <div className="d-lg-flex d-block ms-3">
                            <div className="maths_time_card px-3 py-2 text-center">
                                <p className="mb-0 f18">Total Time Utilised</p>
                                <p className="mb-0 f12 fw-bold">{reportInfo?.total_time && convertDecimalToTime(reportInfo?.total_time)} mins / Week</p>
                            </div>
                            <div className="maths_time_card px-3 py-2 text-center ms-5">
                                <p className="mb-0 f18">Recommended Time</p>
                                <p className="mb-0 f12 fw-bold">{reportInfo?.recomended_time && convertDecimalToTime(reportInfo?.recomended_time)} mins / Week</p>
                            </div>
                        </div>
                        <p className="mb-0 f14 text-center mt-2">{reportInfo?.overview_message?.charAt(0)?.toUpperCase() + reportInfo?.overview_message?.slice(1)}</p>
                    </div>
                    <div>
                        <div className="position-relative chart_content">
                            <div className="position-absolute math-report-chart-container report_donut_chart">
                                <HighchartsReact highcharts={Highcharts} options={options} />
                            </div>
                            <div className="position-absolute" style={{ right: "47px", top: "15px" }}>
                                <p className="mb-0 f18 text-primary fw-bold">{reportInfo?.percentage}%</p>
                                <h6 className="f18">Overall <br />Accuracy</h6>
                            </div>
                        </div>
                        <p className="f14 mb-0 position-relative text-center chart_content" style={{ top: "141px", right: "32px" }}>Your child has answered correctly {reportInfo?.percentage}% of the time.</p>
                    </div>
                </div> */}
                <div className="ms-lg-2 mt-2">
                    <span className="maths_recom_btn ">Recommendation</span>
                    <ul className="math_list mt-2 ms-lg-3">
                        <li>We encourage <span className="fw-bold">{reportInfo?.name?.charAt(0)?.toUpperCase() + reportInfo?.name?.slice(1)}</span>  to practice Math lesson regularly on IAS app</li>
                        <li> {reportInfo?.name?.charAt(0)?.toUpperCase() + reportInfo?.name?.slice(1)}'s total app usage was<span className="fw-bold"> {reportInfo?.total_time && convertDecimalToTime(reportInfo?.total_time)} minutes</span> for this week</li>
                        <li>{reportInfo?.name?.charAt(0)?.toUpperCase() + reportInfo?.name?.slice(1)} should spend atleast <span className="fw-bold">{reportInfo?.recomended_time && convertDecimalToTime(reportInfo?.recomended_time)} minutes every week </span> on IAS app, to achieve proficiency in Maths</li>
                    </ul>
                </div>
            </div>
        </div>
    )

}
export default MathsReportData;