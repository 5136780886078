import axios from 'axios';
import appConfig from '../../config/constant';

const API = axios.create({
    baseURL: appConfig.BASE_URL
});


API.interceptors.request.use(function (config: any) {

    // const token = localStorage.getItem('token');
    // config.headers['Content-Type'] = 'application/json';
    // config.headers.Authorization = token ? `Bearer ${token}` : '';
    // return config;
    const token = localStorage.getItem('token');
    config.headers['Content-Type'] = 'application/json';
    if (config?.url?.includes('event/users')) {
        config.headers.Authorization = '20240601';
    } else {
        config.headers.Authorization = token ? `Bearer ${token}` : '';
    }
    return config;
});

API.interceptors.response.use(
    (response) => {
        if (response.data.data) {
            return response.data.data;
        }
        else {
            return response.data ? response.data : {};
        }
    }, (error) => {
        if (error.response.status === 401 && error?.response?.data?.message !== 'invalid user credentials') {
            window.location.href = '/';
        }
        return error.response && error.response.data ? error.response.data : {};
    }
);


export default API;