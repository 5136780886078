import React, { useEffect, useRef, useState } from 'react'
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom'
import { UsersListItem } from '../../../app/model/users/users-list';
import { DELETE_INSTITUTE, GET_INSTITUTE_Filter, GET_INSTITUTE_PAGE } from '../../../app/service/institute.service';
import { LOOK_UP_DATA } from '../../../app/service/lookup.service';
import { DataTable } from '../../../components/data-table'
import { ModuleDataGridCols } from './data-grid-cols';
import { ToastContainer, toast } from 'react-toastify';
import FormBuilder from '../../../components/form-builder';
import moment from 'moment';

export const InstituteList = () => {
    const [usersData, setUsersData] = useState<UsersListItem[]>([]);
    const [pageArray, setPageNumbers] = useState([]);
    const [activePage, setActivePage] = useState<any>();
    const [cityData, setCityData] = useState<any>([]);
    const [statesData, setStatesData] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [searchData, setSearchData] = useState<any>({});
    const instituteForm = useRef<any>({});
    const [districtData, setDistrictData] = useState<any>([]);
    const [preparedSearchData, setPreparedSearchData] = useState<any>({});
    const [accessNew, setAccessNew] = useState<any>([]);
    const search = useLocation().search;
    const currentPageNumber: any = new URLSearchParams(search).get('page');
    const [accessObjectsList, setAccessObjectsList] = useState<any>([]);
    const history = useHistory();
    const filteredData = [
        { name: 'Email ID', value: 'email_id' },
        { name: 'Institution Code', value: 'organization_code' },
        { name: 'Date of Registration', value: 'date_of_registration' },
        { name: 'Taluka', value: 'taluka' },
        { name: 'Contact Number', value: 'contact_number' },
        { name: 'Pan Number', value: 'pan_number' },
        { name: 'Address', value: 'address' },
        { name: 'Website Url', value: 'website_url' },
        { name: 'City', value: 'city_village' },
        { name: 'Registration Number', value: 'registration_number' },
        { name: 'Pincode', value: 'pin_code' }
    ];


    useEffect(() => {
        LOOK_UP_DATA().then((res: any) => {
            if (res) {
                setStatesData(res.states);
            }
        });
        getInstitutes();
        const accessObjectsData: any = localStorage.getItem("access_objects_data");
        setAccessObjectsList(JSON.parse(accessObjectsData));
        handleInstitutesCols(JSON.parse(accessObjectsData));

    }, []);

    function getInstitutes() {
        const activePageNumber = currentPageNumber > 0 ? (currentPageNumber - 1) : 0;
        setLoading(true);
        GET_INSTITUTE_PAGE(activePageNumber, {}).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    setActivePage(currentPageNumber ? currentPageNumber : 1);
                    const data = res.rows;
                    data.forEach((element: any) => {
                        element.created_at = element.created_at.split("T")[0];
                        element.date_of_registration = element.date_of_registration ? moment(element.date_of_registration).format('DD-MM-yyyy') : '';
                    });
                    setUsersData(data);
                    const pageNumbers = Math.ceil(res.count / 10);
                    const pageArray: any = [];
                    for (let i = 1; i <= pageNumbers; i++) {
                        pageArray.push(i);
                    }
                    setPageNumbers(pageArray);
                    setLoading(false);
                }
            }
        });
    }

    const onPageChange = (data: any) => {
        if (data) {
            history.push(`/dashboard/institute/list?page=${data}`);
        }
        setActivePage(data);
        const pageNumber = data - 1;
        setLoading(true);
        GET_INSTITUTE_PAGE(pageNumber, preparedSearchData ? preparedSearchData : {}).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    const data = res.rows;
                    data.forEach((element: any) => {
                        element.date_of_registration = moment(element.date_of_registration).format('DD-MM-yyyy');
                    });
                    setUsersData(data);
                    setLoading(false);
                }
            }
        });

    }

    const onEditInstitute = (data: any) => {
        history.push(`/dashboard/institute/form/${data.id}`);
    }

    const onDeleteInstitute = (data: any) => {
        setLoading(true);
        const id = data.id;
        DELETE_INSTITUTE(id).then(res => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                setLoading(false);
                toast.success("Institutes Deleted Successfully");
                getInstitutes();
            }
        });
    }

    const handleStateChange = (e: any) => {
        const preparedData = statesData.filter((el: any) => el.state === e.target.value);
        setDistrictData(preparedData[0].districts);
    }

    const handleDistrictChange = (e: any) => {
    }

    const handleInput = (data: any) => {
        setSearchData(data);
    };

    function handleSearch() {
        const data = { ...searchData.value };
        if (data.searchFilter === 'organization_code') {
            data.organization_code = data.search_name;
        } else if (data.searchFilter === 'taluka') {
            data.taluka = data.search_name;
        } else if (data.searchFilter === 'contact_number') {
            data.contact_number = data.search_name;
        } else if (data.searchFilter === 'email_id') {
            data.email_id = data.search_name;
        } else if (data.searchFilter === 'date_of_registration') {
            const date_of_registration = data.search_name;
            if (data.search_name) {
                const preparedDate = date_of_registration.split('-');
                const date = preparedDate[0];
                const month = preparedDate[1];
                const year = preparedDate[2];
                data.date_of_registration = year + '-' + month + '-' + date + 'T00:00:00Z';
            }
        } else if (data.searchFilter === 'pan_number') {
            data.pan_number = data.search_name;
        } else if (data.searchFilter === 'address') {
            data.address = data.search_name;
        } else if (data.searchFilter === 'registration_number') {
            data.registration_number = data.search_name;
        } else if (data.searchFilter === 'website_url') {
            data.website_url = data.search_name;
        } else if (data.searchFilter === 'city_village') {
            data.city_village = data.search_name;
        } else if (data.searchFilter === 'pin_code') {
            data.pin_code = data.search_name;
        }
        delete data.search_name;
        delete data.searchFilter;
        if (data) {
            setLoading(true);
            for (const key of Object.keys(data)) {
                if (data[key] === "") {
                    delete data[key];
                }
            }
            setPreparedSearchData(data);
            GET_INSTITUTE_Filter(data).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    history.push(`/dashboard/institute/list?page=${1}`);
                    setActivePage(1);
                    const data = res.rows;
                    setUsersData(data);
                    const pageNumbers = Math.ceil(res.count / 10);
                    const pageArray: any = [];
                    for (let i = 1; i <= pageNumbers; i++) {
                        pageArray.push(i);
                    }
                    setPageNumbers(pageArray);
                    setLoading(false);
                }
            });
        }
    }

    function getPreapareDate(data: any) {
        if (data) {
            const preparedDate = data.split('-');
            const date = preparedDate[0];
            const month = preparedDate[1];
            const year = preparedDate[2];
            return year + '-' + month + '-' + date + 'T00:00:00Z';
        }
    }

    function handleRefresh() {
        const data = searchData
        data.value = {};
        setSearchData({ ...data });
        setPreparedSearchData({});
        getInstitutes();
        instituteForm.current.reset();
    }

    const handleFilterChange = (e: any) => {
        const data = { ...searchData.value };
        setSearchData(data);
    }

    const handleInstitutesCols = (accessObjectsList: any) => {
        const preparedData = accessObjectsList?.filter((el: any) => el.object_code === "INSTITUTES");
        setAccessNew(preparedData);
        if (preparedData?.length > 0) {
            for (let i = 0; i < preparedData[0].child.length; i++) {
                for (let j = 0; j < ModuleDataGridCols.length; j++) {
                    if (preparedData[0].child[i].object_code === "INSTITUTES-EDIT" && preparedData[0].child[i].p_read === 1) {
                        if (ModuleDataGridCols[j].title == "Actions") {
                            ModuleDataGridCols[j].isEdit = true;
                            ModuleDataGridCols[j].canShowColumn = true;

                        }

                    }
                    if (preparedData[0].child[i].object_code === "INSTITUTES-DELETE" && preparedData[0].child[i].p_read === 1) {
                        if (ModuleDataGridCols[j].title == "Actions") {
                            ModuleDataGridCols[j].isDelete = true;
                            ModuleDataGridCols[j].canShowColumn = true;

                        }

                    }


                }
            }
        }

    }
    function onHandleNew() {
        if (accessNew.length > 0) {
            for (let i = 0; i < accessNew[0].child.length; i++) {
                if (accessNew[0].child[i].object_code === "INSTITUTES-NEW" && accessNew[0].child[i].p_read === 1) {
                    return true;
                }
            }
        }
    }


    return (
        <div>
            <div className="border-top border-primary py-3">
                <div className="row">
                    <div className="col-md-10">
                        <h2>Institutes</h2>
                    </div>
                    {onHandleNew() && <div className="border-primary py-3 text-end col-md-2">
                        <Link to="new" className="btn btn-primary btn-sm px-5 rounded-12">New</Link>
                    </div>}
                </div>
                <div className="row" style={{ marginBottom: '15px' }} >
                    <div className="col-md-10">
                        <FormBuilder onUpdate={handleInput}>
                            <form ref={instituteForm}>
                                <div className="row">
                                    <div className="col-md-3" style={{ display: 'flex' }}>
                                        <input type="text" className="form-control ft-14" placeholder="Search name" name="name_of_organization" />
                                    </div>
                                    <div className="col-md-3" style={{ display: 'flex' }}>
                                        <select name="state" className="form-select ft-14" onChange={(e) => { handleStateChange(e) }}>
                                            <option value="">Select state</option>
                                            {statesData.map((name: any, i: number) => (
                                                <option key={i} value={name.state}>
                                                    {name.state}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-md-3" style={{ display: 'flex' }}>
                                        <select name="district" className="form-select ft-14" onChange={(e) => { handleDistrictChange(e) }}>
                                            <option value="">Select district</option>
                                            {districtData.map((name: any, i: number) => (
                                                <option key={i} value={name}>
                                                    {name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-md-3" style={{ display: 'flex' }}>
                                        <input type="text" className="form-control ft-14" placeholder="Search location" name="area" />
                                    </div>
                                    <div className="col-md-3 mt-2">
                                        <input type="text" className="form-control ft-14" placeholder="Custom search" name="search_name" />
                                    </div>
                                    <div className="col-md-3 mt-2">
                                        <select name="searchFilter" className="form-select ft-14" onChange={(e) => { handleFilterChange(e) }}>
                                            <option value="">Select filter</option>
                                            {filteredData.map((name: any, i: number) => (
                                                <option key={i} value={name.value}>
                                                    {name.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </form>
                        </FormBuilder>
                    </div>
                    <div className="col-md-2 mt-5">
                        <button className="btn btn-primary px-3 cursor-pointer" type="button" onClick={() => handleSearch()}>Search</button>&nbsp;
                        <button className="btn clear-btn cursor-pointer" type="button" onClick={() => handleRefresh()}>
                            Clear
                        </button>
                    </div>
                </div>
                {loading &&
                    <div className="text-center p-5">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                }
                {!loading && <DataTable TableCols={ModuleDataGridCols} tableData={usersData}
                    editInfo={onEditInstitute} deleteInfo={onDeleteInstitute}
                    pageNumber={onPageChange} activePageNumber={activePage} pageNumbers={pageArray}></DataTable>}
            </div>
        </div>
    )
}
