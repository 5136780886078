import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Highcharts3D from 'highcharts/highcharts-3d'; // Import Highcharts 3D module

// Initialize Highcharts 3D module
Highcharts3D(Highcharts);

interface PieChartProps {
    title: string;
    data: { name: string; y: number; color: string }[];
}

const ReportAccuracyPieChart: React.FC<PieChartProps> = (props) => {

    const options: Highcharts.Options = {
        chart: {
            type: 'pie',
            marginTop: -25,
            height: 220,
            backgroundColor: "transparent",
            options3d: {
                enabled: true,
                alpha: 65,
                beta: 0
            },
            events: {
                load() {
                    const chart: any = this;
                    const centerX = chart.series[0].center[0];
                    const rad = chart.series[0].center[2] / 3;

                    Highcharts.each(chart.series[0].data, function (p: any) {
                        const angle = p.angle;
                        const x = centerX + rad * Math.cos(angle);
                        p?.dataLabel?.attr({
                            x: x,
                            'text-anchor': 'center',
                        });
                    });
                },
                redraw() {
                    const chart: any = this;
                    const centerX = chart.series[0].center[0];
                    const rad = chart.series[0].center[2] / 3;

                    Highcharts.each(chart.series[0].data, function (p: any) {
                        const angle = p.angle;
                        const x = centerX + rad * Math.cos(angle);
                        p?.dataLabel?.attr({
                            x: x,
                            'text-anchor': 'center',
                        });
                    });
                },
            },
        },
        title: {
            text: props?.title,
            align: 'center',
            style: {
                color: 'black',
                fontSize: '16px',
                fontWeight: 'normal',
            },
        },
        plotOptions: {
            pie: {
                depth: 31,
                size: '100%',
                // allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        return `<div >${this?.y?.toFixed(0)}%</div>`;
                    },
                    distance: -14,
                    color: "white",
                    style: {
                        fontSize: '15px',
                        fontWeight: 'normal',
                    },
                },
                point: {
                    events: {
                        legendItemClick: function () {
                            return false; // <== returning false will cancel the default action
                        }
                    }
                },
            }
        },
        tooltip: {
            formatter: function () {
                return `<b>${this.point.name}</b>: ${this.point.y}%`;
            },
        },
        series: [
            {
                type: 'pie',
                data: props?.data.map((item, index) => ({
                    name: item.name,
                    y: item.y,
                    color: item.color,
                    dataLabels: { enabled: index === 0 },
                })),
            },
        ],
        credits: {
            enabled: false,
        },
    };

    return (
        <div style={{ width: "285px" }}>
            <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
    )
};

export default ReportAccuracyPieChart;
