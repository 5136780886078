import React, { useEffect, useState } from "react";
import { GET_USER_USER_CODE } from "../../../app/service/users.service";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import appConfig from "../../../config/constant";
import STUDENTIMG from '../../../institute-dashboard-assets/images/student-img.svg'

const ParentViewChildrenProfile = () => {
    const [studentInfo, setStudentInfo] = useState<any>({});
    const [loading, setLoading] = useState(false);
    let { userCode } = useParams<{ userCode: string }>();
    const studentCode = userCode;

    useEffect(() => {
        getStudentDetails();
    }, []);
    function getStudentDetails() {
        setLoading(true);
        GET_USER_USER_CODE(studentCode).then((response: any) => {
            if (response.status === 'fail') {
                toast.error(response.message);
                setLoading(false);
            } else {
                const data = response.rows[0];
                if (data.date_of_birth) {
                    const date_of_birth: any = new Date(data.date_of_birth);
                    // setStartDate(date_of_birth);
                }
                setStudentInfo(data);
                setLoading(false);

            }
        });
    }

    const getTimeFormate = (dateTimeString: any) => {
        const formattedDate = new Date(dateTimeString).toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
        return formattedDate;
    }
    return (
        <div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb pb-2 ps-0 pt-0 border-bottom">
                            <li className="breadcrumb-item "><Link to="/parent-dashboard/view"><span className='pe-2'>Dashboard</span></Link></li> /
                            <li className="breadcrumb-item "><span>{studentInfo?.name?.charAt(0).toUpperCase() + studentInfo?.name?.slice(1).toLowerCase()} Profile</span></li>
                        </ol>
                    </nav>
                    <div className='ps-2  mt-4  ms-lg-5'>
                        <div className="row">
                            <div className="col-12   mb-4">
                                <div className="d-md-flex sc-profile-details">
                                    <div className="sc-profile-img py-0">
                                        <img className="pr-img_logo"
                                            src={studentInfo?.profile_img ? `${appConfig.FILE_UPLOAD_URL}${studentInfo?.profile_img.charAt(0) === "/" ? studentInfo?.profile_img.substring(1) : studentInfo?.profile_img}` : STUDENTIMG}
                                        />
                                    </div>
                                    <div className="sc-profile-text ps-md-4">
                                        <h2 className="mt-0 mb-2">{studentInfo?.name?.charAt(0).toUpperCase() + studentInfo?.name?.slice(1).toLowerCase()}</h2>
                                        <p className="m-0 sc-profile-contact">
                                            <span>{studentInfo?.mobile_number}</span> {studentInfo?.email_id && <span> | </span>} <span>{studentInfo?.email_id} </span>
                                        </p>
                                        <p className="m-0 sc-profile-addrs">
                                            <span>{studentInfo?.state}</span> {studentInfo?.district && <span> | </span>} <span>{studentInfo?.district}</span>{studentInfo?.city_village && <span> | </span>}
                                            <span>{studentInfo?.city_village}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6   mb-4">
                                <label className="f12 text-mgray">Name</label>
                                <p className="f18 mb-0">{studentInfo?.name}</p>
                            </div>
                            <div className="col-md-4 col-sm-6   mb-4">
                                <label className="f12 text-mgray">User ID</label>
                                <p className="f18 mb-0">{studentInfo?.user_name}</p>
                            </div>
                            <div className="col-md-4 col-sm-6  mb-4">
                                <label className="f12 text-mgray">Class</label>
                                <p className="f18 mb-0">{studentInfo?.standard}<sup>th</sup></p>
                            </div>
                            <div className="col-md-4 col-sm-6  mb-4">
                                <label className="f12 text-mgray">Division</label>
                                <p className="f18 mb-0">{studentInfo?.division}</p>
                            </div>
                            <div className="col-md-4 col-sm-6  mb-4">
                                <label className="f12 text-mgray">Date of Birth</label>
                                <p className="f18 mb-0">{getTimeFormate(studentInfo?.date_of_birth)}</p>
                            </div>

                            <div className="col-md-4 col-sm-6  mb-4">
                                <label className="f12 text-mgray">Gender</label>
                                <p className="f18 mb-0">{studentInfo?.gender}</p>
                            </div>

                            <div className="col-md-4 col-sm-6  mb-4">
                                <label className="f12 text-mgray">Mobile Number</label>
                                <p className="f18 mb-0">{studentInfo?.mobile_number}</p>
                            </div>
                            <div className="col-md-4 col-sm-6  mb-4">
                                <label className="f12 text-mgray">WhatsApp Number</label>
                                <p className="f18 mb-0">{studentInfo?.whatsapp_number}</p>
                            </div>
                            <div className="col-md-4 col-sm-6  mb-4">
                                <label className="f12 text-mgray">Email ID</label>
                                <p className="f18 mb-0">{studentInfo?.email_id}</p>
                            </div>
                            <div className="col-md-4 col-sm-6  mb-4">
                                <label className="f12 text-mgray">Blood Group</label>
                                <p className="f18 mb-0">{studentInfo?.blood_group}</p>
                            </div>
                            <div className="col-md-4 col-sm-6  mb-4">
                                <label className="f12 text-mgray">Date of Registration</label>
                                <p className="f18 mb-0">{getTimeFormate(studentInfo?.date_of_registration)}</p>
                            </div>
                            <div className="col-md-4 col-sm-6  mb-4">
                                <label className="f12 text-mgray">Address</label>
                                <p className="f18 mb-0">{studentInfo?.address}</p>
                            </div>
                            <div className="col-md-4 col-sm-6  mb-4">
                                <label className="f12 text-mgray">State</label>
                                <p className="f18 mb-0">{studentInfo?.state}</p>
                            </div>
                            <div className="col-md-4 col-sm-6  mb-4">
                                <label className="f12 text-mgray">District</label>
                                <p className="f18 mb-0">{studentInfo?.district}</p>
                            </div>
                            <div className="col-md-4 col-sm-6  mb-4">
                                <label className="f12 text-mgray">City/Village</label>
                                <p className="f18 mb-0">{studentInfo?.city_village}</p>
                            </div>
                            <div className="col-md-4 col-sm-6  mb-4">
                                <label className="f12 text-mgray">Pincode</label>
                                <p className="f18 mb-0">{studentInfo?.pin_code}</p>
                            </div>

                        </div>
                    </div>
                </div>}
        </div>
    )
}
export default ParentViewChildrenProfile;