import React, { useEffect, useRef, useState } from "react";
import FormBuilder from "../form-builder";
import { GET_SCHOOL_BY_ID, GET_STANDARD_DIVISION_BY_SCHOOL_CODE } from "../../app/service/school.service";
import { toast } from "react-toastify";
import { GET_MODULES_BY_SCHOOL_ID } from "../../app/service/module.service";
import { GET_STUDENTS_BY_STANDARD_DIVISION } from "../../app/service/users.service";
import CustomMultiSelector from "../multi-selector/custom-selector";
import { USERS_ACCOUNT_TYPE } from "../../app/service/users-account-type";

interface FormProps {
    setLoading: any;
    schoolId: any;
    schoolsCode: any;
    assignTrial: () => void;
}
const FreeTrialSchoolWise: React.FC<FormProps> = (props: FormProps) => {
    const [assignUserData, setAssignUserData] = useState<any>({});
    const [studentsSelected, setStudentsSelected] = useState<any>([]);
    const [schoolInfo, setSchoolInfo] = useState<any>({});
    const [standardList, setStandardList] = useState<any>([]);
    const [divisionList, setDivisionList] = useState<any>([]);
    const [studentList, setStudentList] = useState<any[]>([]);
    const [moduleList, setModuleList] = useState<any[]>([]);
    const [moduleSelected, setModuleSelected] = useState<any>([]);
    const [optionStudentsLoading, setOptionStudentsLoading] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [trailStartDate, setTrailStartDate] = useState<any>();
    const [trailEndDate, setTrailEndDate] = useState<any>();
    const assignUserForm = useRef<any>({});

    useEffect(() => {
        getStandardDivisionBySchool();
        getSchoolInfo();
    }, [])
    const getSchoolInfo = () => {
        // props.setLoading(true);
        GET_SCHOOL_BY_ID(props?.schoolId).then((res: any) => {
            if (res.status === "fail") {
                toast.error(res.message);
                props.setLoading(false);
            } else {
                if (res) {
                    const data = res.rows[0];
                    setSchoolInfo(data);
                    // breadcumInfo[1].value = data?.name_of_school;
                    getStudentsByStandardDivision({});
                    getModulesList();
                }
                props.setLoading(false);
            }
        });
    };

    const getStandardDivisionBySchool = () => {
        GET_STANDARD_DIVISION_BY_SCHOOL_CODE(props?.schoolsCode).then((res: any) => {
            if (res.status === "fail") {
                toast.error(res?.err);
                props.setLoading(false);
            } else {
                if (res) {
                    let data = res;
                    if (Array.isArray(data)) {
                        const { classes, sections } = data.reduce(removeDuplicatesList, { classes: [], sections: [] });
                        sections?.sort((a: string, b: string) => a > b ? 1 : -1);
                        setStandardList(classes);
                        setDivisionList(sections);

                    }
                }
            }
        });
    }
    function getModulesList() {
        GET_MODULES_BY_SCHOOL_ID(props?.schoolsCode).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
            } else {
                if (res) {
                    const data = res.rows;
                    const preData = data.filter((el: any) => el.module_code !== "CAS-PARENT");
                    const preparedData = [];
                    for (let i = 0; i < preData?.length; i++) {
                        preparedData.push({
                            label: preData[i].module_code,
                            value: preData[i].module_code
                        })
                    }
                    setModuleList(preparedData);
                }
            }
        });

    }
    const removeDuplicatesList = (accumulator: any, currentValue: any) => {
        if (!accumulator.classes.includes(currentValue.class)) {
            accumulator.classes.push(currentValue.class);
        }
        if (!accumulator.sections.includes(currentValue.section)) {
            accumulator.sections.push(currentValue.section);
        }
        return accumulator;
    };
    const handleInput = (data: any) => {
        setAssignUserData({ ...data.value });

    }
    const handleStandardChange = (e: any) => {
        const data = { ...assignUserData };
        data.standard = e.target.value;
        let preparedData = {
            standard: data.standard,
            division: data.division,
        }
        getStudentsByStandardDivision(preparedData);


    }
    const handleDivisionChange = (e: any) => {
        const data = { ...assignUserData };
        data.division = e.target.value;
        let preparedData = {
            standard: data.standard,
            division: data.division,
        }

        getStudentsByStandardDivision(preparedData);


    }
    function getStudentsByStandardDivision(data: any) {

        for (const key of Object.keys(data)) {
            if (data[key] === "") {
                delete data[key];
            }
        }
        setOptionStudentsLoading(true);
        setStudentList([]);
        setStudentsSelected([]);
        GET_STUDENTS_BY_STANDARD_DIVISION(props?.schoolsCode, data).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setOptionStudentsLoading(false);
                } else {
                    const data = res.rows;
                    const preparedData = [];
                    if (data.length > 0) {
                        for (let k = 0; k < data.length; k++) {
                            preparedData.push({
                                label: data[k].name,
                                value: data[k].user_code
                            });
                        }
                        setStudentList(preparedData);
                    } else {
                        setStudentList([]);
                    }
                    setOptionStudentsLoading(false);
                }
            }
        });
    }

    function handleRefresh() {
        setStudentsSelected([]);
        setStudentList([]);
        setModuleSelected([]);
        setIsFormSubmitted(false);
        getStudentsByStandardDivision({});
        setAssignUserData({});
        assignUserForm.current.reset();
    }
    const getStartDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        // const month: any = today.getMonth() + 1;
        // const day: any = today.getDate();
        return `${year}-${month}-${day}`;

    };
    const getEndDate = () => {
        const today = new Date(trailStartDate);
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    function disabledFutureDatesExpire() {
        var dtToday = trailStartDate ? new Date(trailStartDate) : new Date();
        var month: any = dtToday.getMonth() + 1;
        var day: any = dtToday.getDate();
        var year = dtToday.getFullYear();
        if (month < 10)
            month = '0' + month.toString();
        if (day < 10)
            day = day.toString();
        return year + '-' + month + '-' + day;
    }
    const handleAccountChange = (e: any) => {
        const data = { ...assignUserData };
        data.account_type = e.target.value;
        if (e.target.value === "TRIAL") {
            // onSelectStartDate(moment().format("YYYY-MM-DD"))
        }

    }
    function onSelectStartDate(e: any) {
        setTrailStartDate(e);
        let isExpired = e > trailEndDate;
        if (trailEndDate && isExpired) {
            setTrailEndDate("");
        }
        disabledFutureDatesExpire();
        // setTrailEndDate(moment(e).add(1, 'month').format("YYYY-MM-DD"));

    }
    const assignTrial = () => {
        setIsFormSubmitted(true);
        const assignData = { ...assignUserData }
        const usersCodes = studentsSelected?.map((item: any) => `${item.value}`);
        // let usersCodes = studentsSelected?.map((item: any) => item.value).join(',');
        let moduleCodes = moduleSelected?.map((item: any) => item.value).join(',');
        let payLoad = {
            institute_school_code: props?.schoolsCode,
            standard: assignData?.standard ? assignData?.standard : '',
            division: assignData?.division ? assignData?.division : '',
            users: usersCodes,
            account_type: assignData?.account_type,
            trial_start_dt: assignData?.account_type === "TRIAL" ? trailStartDate : '',
            trial_end_dt: assignData?.account_type === "TRIAL" ? trailEndDate : '',
            modules: assignData?.account_type === "TRIAL" ? moduleCodes : ''
        }

        if (assignData?.account_type === "PAID" || (assignData?.account_type === "TRIAL" && moduleSelected?.length > 0 && trailStartDate && trailEndDate)) {
            USERS_ACCOUNT_TYPE(payLoad).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                    } else {
                        toast.success(res?.message);
                        props?.assignTrial();
                    }
                }
            })
        }
    }
    return (
        <div>
            <FormBuilder onUpdate={handleInput}>
                <form ref={assignUserForm}>
                    <div className="row custom-form mt-4 mx-2">
                        <div className="col-md-4 ">
                            <div className="mb-4">
                                <label className="form-label mb-0">School Name</label>
                                <input className="form-control form-control-lg f16 " type="text" name="school_name" defaultValue={schoolInfo?.name_of_school} placeholder="Please enter module name" disabled style={{ backgroundColor: 'hsl(0,0%,96.5%)' }} />
                            </div>
                        </div>
                        <div className="col-md-4 ">
                            <div className="mb-4">
                                <label className="form-label mb-0 mt-1">Standard</label>
                                <select className="form-select" name="standard" onChange={(e) => { handleStandardChange(e) }}>
                                    <option value="">Select Standard</option>
                                    {standardList?.map((item: any, i: number) => (
                                        <option value={item} key={i}>{item}</option>
                                    ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="mb-4 ">
                                <label className="form-label mb-0 mt-1">Division</label>
                                <select className="form-select" name="division" onChange={(e) => { handleDivisionChange(e) }}>
                                    <option value="">Select Division</option>
                                    {divisionList?.map((item: any, i: number) => (
                                        <option value={item} key={i}>{item}</option>
                                    ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="mb-4 mt-2 position-relative">
                                <label className="form-label mb-2">Students</label>
                                {optionStudentsLoading &&
                                    <div className=" mt-1 pt-1 position-absolute " style={{ right: "8px", zIndex: "9999" }}>
                                        <div className="spinner-border ms-5 ms-md-0" role="status" style={{ height: "23px", width: "23px" }} >
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>}
                                <CustomMultiSelector
                                    options={studentList}
                                    value={studentsSelected}
                                    onChange={setStudentsSelected}
                                    labelledBy="Select students"
                                    customAllSelectedText="All students are selected"
                                />
                            </div>
                        </div>
                        <div className='col-md-4 col-12'>
                            <div className='mb-4'>
                                <label className="form-label mb-0 mt-2">Account Type</label>
                                <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                <select className="form-select form-select-lg mb-3 f16" name="account_type" value={assignUserData?.account_type} onChange={(e) => { handleAccountChange(e) }}>
                                    <option value="">Select account type</option>
                                    <option value="TRIAL">Trial</option>
                                    <option value="PAID">Paid</option>
                                </select>
                                {(isFormSubmitted && !assignUserData?.account_type) && <p className="text-danger">Please select account type</p>}
                            </div>
                        </div>
                        {(assignUserData?.account_type === "TRIAL" || assignUserData?.account_type === "TRIAL") && <>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-2 f16 "> Modules</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <CustomMultiSelector
                                        options={moduleList}
                                        value={moduleSelected}
                                        onChange={setModuleSelected}
                                        labelledBy="Select modules"
                                        customAllSelectedText="All modules are selected"
                                    />
                                    {(isFormSubmitted && moduleSelected?.length == 0 && assignUserData?.account_type === "TRIAL") && <p className="text-danger mt-2 mb-0">Please select module</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-2">Trial Start date
                                    </label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '5px' }}>*</span>
                                    <input type="date" className='form-control' name="trial_start_dt" value={trailStartDate} min={getStartDate()} onChange={(e: any) => onSelectStartDate(e.target.value)} onKeyDown={(event) => {
                                        event.preventDefault();
                                    }} />
                                    {(isFormSubmitted && !trailStartDate) && <p className="text-danger">Please select start date</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-2">Trial End date
                                    </label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '5px' }}>*</span>
                                    <input type="date" className='form-control' name="trial_end_dt" disabled={!trailStartDate} value={trailEndDate} min={getEndDate()} onChange={(e: any) => setTrailEndDate(e.target.value)} onKeyDown={(event) => {
                                        event.preventDefault();
                                    }} />
                                    {(isFormSubmitted && !trailEndDate) && <p className="text-danger">Please select end date</p>}
                                </div>
                            </div> </>}
                        <div className="col-12">
                            <div className="form-footer border-top border-primary py-3 text-end mt-4 me-3">
                                <button className="btn clear-btn cursor-pointer mt-2 me-4" type="button" onClick={() => handleRefresh()}>
                                    Clear Selection
                                </button>
                                <button className="btn btn-primary cursor-pointer mt-2" type="button" onClick={assignTrial}>
                                    Assign
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </FormBuilder>

        </div>
    )
}
export default FreeTrialSchoolWise;