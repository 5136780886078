import React, { useEffect, useState } from "react";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
interface Props {
    dataValues: any[];
    labelThree?: any;
    chartTitle: string;

    subTitle: string;
}
const ReportColoumnChart: React.FC<Props> = (props) => {
    const options = {
        chart: {
            type: 'column',
            backgroundColor: "transparent",
            height: 250,
            margin: [50, 50, 100, 50]
        },
        title: {
            text: props?.chartTitle,
            align: 'center',
            style: {
                color: 'black',
                fontSize: '16px',
                fontWeight: 'normal'
            },
        },
        xAxis: {
            lineWidth: 0,
            categories: [props?.subTitle],
            style: {
                color: 'black',
                fontSize: '10px',
                fontWeight: 'normal'
            },
        },
        yAxis: {
            title: {
                text: ''
            },
            tickPixelInterval: 50,
            gridLineColor: "#D9D9D9",
            gridLineWidth: 1,
        },
        legend: {
            symbolWidth: 8,
            symbolHeight: 8,
            symbolRadius: 0,
            symbolPadding: 5,
            y: -1,
            itemStyle: {
                cursor: 'default', // Change cursor to default to indicate non-interactivity
            },
        },
        plotOptions: {
            column: {
                size: '41%',
                pointPadding: 0.2,
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    style: {
                        color: 'black',
                        fontSize: '12px',
                        fontWeight: 'normal'
                    },
                },
                events: {
                    legendItemClick: function () {
                        return false;
                    }
                }
            },
            allowPointSelect: false,
        },
        series: props?.dataValues,
        credits: {
            enabled: false
        }
    };

    return (
        <div style={{ width: "290px" }}>
            <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
    )
}

export default ReportColoumnChart;
