import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { NavLink, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import { ParentDashBoardRoutes } from "../../routes/parent-dashboard/parent-dashboard";
import MOLECULERFORMULA from "../../institute-dashboard-assets/images/molecular-formula.png";
import MATHEMATICS from "../../institute-dashboard-assets/images/mathematics (1).png";
import HEATINGFLASK from "../../institute-dashboard-assets/images/heating-flask.png";
import BOTANYEXPERIMENT from "../../institute-dashboard-assets/images/botany-experiment.png";
import MATHTEST from "../../institute-dashboard-assets/images/maths-test.png"
import MATHHOMEWORK from "../../institute-dashboard-assets/images/maths-homework.png"
import { GET_PARENT_BY_CHILDRENS } from "../../app/service/users.service";
import { GET_MODULES_BY_USER_CODES } from "../../app/service/module.service";

function ParentDashboardView() {
    const [canShowPopup, setCanShowPopup] = useState(false);
    const history = useHistory();
    const userName = localStorage.getItem("user_name");
    const userRole = localStorage.getItem("user_role");
    const [toggleIcon, setToggleIcon] = useState<any>("nav_toggler");
    const [header, setHeader] = useState<any>("header");
    const [body, setBody] = useState<any>("inside-bg");
    const [nav, setNav] = useState<string>("l-navbar");
    const [icon, setIcon] = useState<string>("bx bx-menus");
    const [logotTooltip, setLogoutTooltip] = useState<boolean>(false);
    const userCode = localStorage.getItem("user_code");
    const [studentList, setStudentList] = useState<any>([]);
    const location = useLocation();
    const pathname = location.pathname;
    const splitLocation = pathname.split("/");

    useEffect(() => {
        getChilderens();

    }, []);
    const navToggle = (): void => {
        toggleIcon === "nav_toggler" ? setToggleIcon("nav_toggler toggle") : setToggleIcon("nav_toggler");
        body === "inside-bg" ? setBody("body-pd") : setBody("inside-bg");
        header === "header" ? setHeader("header body-pd") : setHeader("header");
        nav === "l-navbar" ? setNav("l-navbar show") : setNav("l-navbar");
        icon === "bx bx-menus" ? setIcon("bx bx-x") : setIcon("bx bx-menus");

    }

    const getChilderens = () => {
        GET_PARENT_BY_CHILDRENS(userCode, 0).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                } else {
                    const data = res.rows;
                    data.forEach((element: any) => {
                        if (element.type === 'individual') {
                            element.name_of_school = element?.indv_school_details?.school_name;
                        }
                        element.isSelect = false;
                        element.module_list = getModuleByUserCode(element?.user_code);
                    });
                    setStudentList(data);
                }
            }
        })
    }
    const getModuleByUserCode = (userCode: any) => {
        let moduleList: any = [];
        GET_MODULES_BY_USER_CODES(userCode).then((res: any) => {
            if (res) {
                if (res.status === "fail") {
                    toast.error(res.message);
                } else {
                    let data = res;
                    if (Array.isArray(data)) {
                        // let prepared = data?.filter((el: any) => el?.module_code !== 'CAS-PARENT');
                        let opted = data.filter(
                            (el: any) => el.amount_status === "COMPLETED" && el?.module_code !== 'CAS-PARENT'
                        );
                        moduleList.push(opted);
                    }

                }
            }
        });
        return moduleList
    }
    const handleclose = () => {
        setCanShowPopup(false);
    };

    const handleSubmit = () => {
        setCanShowPopup(false);
        history.push("/home");
        localStorage.clear();
    };

    function onClickHome() {
        setCanShowPopup(true);
    }
    const onSelectUser = (item: any, index: number) => {
        // let data = [...studentList];
        let data = studentList?.map((student: any, i: number) => {
            // Set isSelect to false for all students except the selected one
            return {
                ...student,
                isSelect: i === index ? !item?.isSelect : false
            };
        });
        setStudentList([...data]);

    }
    const getModuleUrl = (Userdata: any, moduelData: any) => {
        switch (moduelData?.module_code) {
            case "MATHS":
                return `/parent-dashboard/view/maths/reports/student/${Userdata?.user_code}`;
            case "ABHYAAS":
                return `/parent-dashboard/view/abhyaas/initiative/${moduelData?.module_uuid}/${Userdata?.user_code}/${Userdata?.institute_school_code}`;
            case "PARIKSHA":
                return `/parent-dashboard/view/pariksha/initiative/${moduelData?.module_uuid}/${Userdata?.user_code}/${Userdata?.institute_school_code}`;
            default:
                return "";
        }

    }
    const getModuleLog = (moduleCode: string) => {
        switch (moduleCode) {
            case "CAS":
                return "far fa-file-alt";
            case "MATHS":
                return "fa fa-book";
            case "ABHYAAS":
                return "bi bi-book";
            // return "fa fa-fw fa-leanpub";
            case "PARIKSHA":
                return "fa fa-fw fa-building-o";
            default:
                return "";

        }
    }
    const getActiveLink = (moduleCode: string) => {
        let moduleName = moduleCode?.toLowerCase();
        const locationKey = splitLocation[3];
        if (moduleName === locationKey) {
            return "active";
        }

    }
    return (
        <div>
            <body className={body}>
                <div
                    className={`modal fade ${canShowPopup ? "show" : "false"}`}
                    style={{ display: canShowPopup ? "block" : "none" }}
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    Are You Sure Want To Logout
                                </h5>
                                <button
                                    type="button"
                                    className="close popup-close cursor-pointer"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={handleclose}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-primary cursor-pointer"
                                    onClick={handleSubmit}
                                >
                                    Yes
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-secondary cursor-pointer"
                                    data-dismiss="modal"
                                    onClick={handleclose}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <header className={header} id="header">
                    <div className="header_toggle" onClick={navToggle}> <i className={icon} id="header-toggle"></i> </div>
                    <span className="nav_logo mb-0 d-lg-none"><i className="nav_logo-icon"></i></span>
                    <div className="me-md-auto ms-md-3 d-none d-lg-block"> <span className="f18 fw-light">Parent Dashboard</span> </div>
                    <div className="position-relative">
                        <div className="d-none d-lg-flex align-items-center ">
                            <span className="text-end user-name pe-3">
                                {userName}
                                <br />
                                <small>{userRole}</small>
                            </span>
                            <a className="mx-3 logout-icon cursor-pointer" onMouseEnter={() => setLogoutTooltip(true)}
                                onMouseLeave={() => setLogoutTooltip(false)}
                                onClick={() => {
                                    onClickHome();
                                }}>Sign out</a>
                        </div>
                        {logotTooltip && <p className="mb-0 position-absolute bg-white  border border-secondary px-1 f14 rounded-2 " style={{ right: "5px" }}>Logout</p>}
                    </div>
                </header>
                <div className={nav} id="nav-bar">
                    <nav className="navinstitute">
                        <div> <a className="nav_logo mb-3"> <i className="nav_logo-icon"></i> </a>
                            <div className={`nw_nav srl_bar  ${nav !== "l-navbar" && "show"} `}>
                                <div className="nav_list">
                                    <NavLink to='/parent-dashboard/view' className="nav_link"> <i className="dashboard-icon nav-icon"></i><span className="nav_name">Dashboard</span></NavLink>
                                    {studentList?.map((data: any, index: number) => (
                                        <>
                                            <p className={`nav_link mb-0 ${data?.isSelect && "active "} cursor-pointer`} key={index} onClick={() => onSelectUser(data, index)}><i className="profile-icon nav-icon"></i><span className="nav_name">{data?.name}</span></p>
                                            {data?.isSelect && data?.module_list[0]?.map((item: any, i: number) => (
                                                <>
                                                    <NavLink to={`${getModuleUrl(data, item)}`} className={`nav_link f14 ${nav === "l-navbar" && "ms-4"} py-1 ${getActiveLink(item?.module_code)}`} key={i}><i className={`${getModuleLog(item?.module_code)}`}></i><span className="nav_name">{item?.module_code}</span></NavLink>
                                                    {/* {data?.module_list[0]?.length - 1 == i && <span className={`nav_link f14 ${nav === "l-navbar" && "ms-4"} py-1`}><i className="profile-icon nav-icon"></i>Profile</span>} */}
                                                </>
                                            ))}
                                            {data?.isSelect && <NavLink to={`/parent-dashboard/profile/children/${data?.user_code}`} className={`nav_link f14 ${nav === "l-navbar" && "ms-4"} py-1`}><i className="profile-icon nav-icon"></i><span className="nav_name">Profile</span></NavLink>}

                                        </>
                                    ))}
                                </div>
                                <div className="d-lg-none nav_link ps-3 py-2" onClick={() => { onClickHome(); }}>
                                    <i className="bx bx-log-out nav-icon "></i>
                                    <span className="nav_name  ">SignOut</span>
                                </div>
                                <div className={`mt-4 moleculer ${nav !== "l-navbar" && "show"}`}>
                                    <img className="side_bar_icons" src={MOLECULERFORMULA} alt="" />
                                </div>
                                <div className={`mathematics ${nav !== "l-navbar" && "show"}`}>
                                    <img className="side_bar_icons my-2" src={MATHEMATICS} alt="" />
                                </div>
                                <div className={`moleculer ${nav !== "l-navbar" && "show"}`}>
                                    <img className="side_bar_icons  my-2" src={HEATINGFLASK} alt="" />
                                </div>
                                <div className={`mathematics ${nav !== "l-navbar" && "show"}`}>
                                    <img className="side_bar_icons  my-2" src={BOTANYEXPERIMENT} alt="" />
                                </div>
                                <div className={`moleculer ${nav !== "l-navbar" && "show"}`}>
                                    <img className="side_bar_icons  my-2" src={MATHTEST} alt="" />
                                </div>
                                <div className={`mathematics ${nav !== "l-navbar" && "show"}`}>
                                    <img className="side_bar_icons  my-2" src={MATHHOMEWORK} alt="" />
                                </div>
                            </div>

                        </div>
                        {/* <div className="nav_link d-lg-none mb-4" onClick={() => { onClickHome(); }}>
                            <i className="bx bx-log-out nav-icon"></i>
                            <span className="nav_name ms-2 ">SignOut</span>
                        </div> */}
                    </nav>
                </div>
                <div className="content-body">
                    <div className="container-fluid">
                        <div className="main-title ">

                            <ParentDashBoardRoutes />
                        </div>
                    </div>
                </div>
            </body>
        </div>
    );
}
export default ParentDashboardView;
