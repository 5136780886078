import React, { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { CREATE_SCHOOL_USER, UPLOAD_LOGO } from '../../../../../app/service/school.service';
import { GET_USER_BY_ID, UPDATE_USER } from '../../../../../app/service/users.service';
import FormBuilder from '../../../../../components/form-builder';
import { FormControlError, FormField, FormValidators } from '../../../../../components/form-builder/model/form-field';
import { FormValidator, GetControlIsValid, GetEmailControlIsValid, GetMobileControlIsValid } from '../../../../../components/form-builder/validations';
import moment from 'moment';
import appConfig from '../../../../../config/constant';
import errorMessages from '../../../../../components/error-messages';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DateComponent } from '../../../../../components/date';
import ProfileImageCrop from '../../../../../components/image-crop/crop';

export const ParentForm = () => {
  const [parentsData, setParentsData] = useState<any>({});
  const [formData, setFormData] = useState<any>({});
  const [validationErrors, setValidationErrors] = useState<FormControlError[]>([]);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [currentParentsData, setCurrentParentsData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [parentLogo, setParentLogo] = useState<any>();
  const [imageLoader, setImageLoader] = useState(false);
  const [mobileNumber, setMobileNumber] = useState(false);
  const [whatsappNumber, setWhatsappNumber] = useState(false);
  const [parentInfo, setParentInfo] = useState<any>({});
  const [canShowPopup, setCanShowPopup] = useState(false);
  const [min, setMin] = useState<any>(moment().subtract(60, "years").format("YYYY-MM-DD"));
  const [max, setMax] = useState<any>(moment().subtract(18, "years").format("YYYY-MM-DD"));
  const [checkedStatus, setCheckedStatus] = useState<any>();
  const [email, setEmail] = useState(false);
  const [canCheckMobileNumber, setCanCheckMobileNumber] = useState(false);
  const [canShowWhatsAppError, setCanShowWhatsAppError] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [isDateError, setIsDateError] = useState<any>({});
  const [checkedUserMechanismStatus, setCheckedUserMechanismStatus] = useState<any>();
  let { id, schoolCode, studentId, userId } = useParams<{ id: string, schoolCode: string, studentId: string, userId: string }>();
  const schoolId = parseInt(id);
  const schoolsCode = schoolCode;
  const usersId: number = parseInt(userId);
  const studentsCode = studentId;
  const [nameError, setNameError] = useState('');
  const history = useHistory();
  const formValidations = [
    new FormField('name', [FormValidators.REQUIRED]),
    new FormField('gender', [FormValidators.REQUIRED]),
    new FormField('date_of_birth', []),
    new FormField('blood_group', []),
    new FormField('city_village', []),
    new FormField('email_id', [FormValidators.REQUIRED]),
    new FormField('mobile_number', [FormValidators.REQUIRED]),
    new FormField('whatsapp_number', []),
  ];
  const userRole = localStorage.getItem("user_role");


  useEffect(() => {
    if (usersId > 0) {
      setLoading(true);
      GET_USER_BY_ID(usersId).then((res: any) => {
        if (res.status === 'fail') {
          toast.error(res.message);
          setLoading(false);
        } else {
          setLoading(false);
          const data = res.rows[0];
          if (data.date_of_birth) {
            const date_of_birth: any = new Date(data.date_of_birth);
            setStartDate(date_of_birth);
          }
          if (data.mobile_number === data.whatsapp_number) {
            data.checked = true;
          }
          if (data.access) {
            setCheckedUserMechanismStatus(data.access);
          }
          setParentLogo(data.profile_img);
          setCurrentParentsData(data);
        }
      });
    }
  }, []);

  const handleInput = (data: any) => {
    setParentsData(data);
    const errors: any = FormValidator(formValidations, data.value);
    setValidationErrors(errors);
  };

  const handleSubmit = () => {
    setIsFormSubmitted(true);
    const data = { ...parentsData.value };
    const errors: FormControlError[] = FormValidator(formValidations, data);
    setValidationErrors(errors);
    data.type = "school";
    data.user_role = "PARENT";
    data.institute_school_code = schoolsCode;
    data.student_code = studentsCode;
    data.profile_img = parentLogo;
    data.cas_report_view = 1;
    data.date_of_birth = startDate ? moment(startDate).format('YYYY-MM-DD') : null;
    delete data.sameMobileNumber;
    delete data.file;
    delete data.checked;
    delete data[''];
    delete data.day;
    delete data.month;
    delete data.year;
    if (errors.length < 1 && !email && !mobileNumber && onChangeName(data.name)) {
      data.whatsapp_number = data.whatsapp_number ? data.whatsapp_number : data.mobile_number;
      if (data.date_of_birth !== null) {
        CREATE_SCHOOL_USER(data).then((res: any) => {
          if (res.status === 'fail') {
            toast.error(res.message);
            setLoading(false);
            setCurrentParentsData(data);
          } else {
            toast.success("Parent Added Successfully");
            if (userRole === "CHAIRMAN" || userRole === "GENERAL_SECRETARY" || userRole === "INSTITUTE_ADMIN") {
              history.push(`/institute-dashboard/school/student/info/${schoolId}/parentlist/${schoolsCode}/${studentsCode}`);

            } else {
              history.push(`/dashboard/school/student/info/${schoolId}/parentlist/${schoolsCode}/${studentsCode}`);
            }
            setParentInfo(res);
            setLoading(false);
            setCanShowPopup(true);
          }
        });
      }
    }
    else {

      if (!nameError && !onChangeName(data.name)) {
        setNameError('Please enter name');
      }
    }
  }

  const getInputValid = (control: string) => {
    const value = GetControlIsValid(validationErrors, control);
    return value;
  }

  const getEmailValid = (control: string) => {
    const data = { ...parentsData.value };
    const value = GetEmailControlIsValid(validationErrors, control, data);
    return value;
  }


  const getMobileValid = (control: string) => {
    const data = { ...parentsData.value };
    const value = GetMobileControlIsValid(validationErrors, control, data);
    return value;
  }

  function updateParent() {
    const data = { ...currentParentsData, ...parentsData.value };
    const errors: FormControlError[] = FormValidator(formValidations, data);
    setValidationErrors(errors);
    data.type = "school";
    data.user_role = "PARENT";
    data.institute_school_code = schoolsCode;
    data.id = usersId;
    data.student_code = studentsCode;
    data.profile_img = parentLogo;
    data.cas_report_view = 1;
    data.date_of_birth = startDate ? moment(startDate).format('YYYY-MM-DD') : null;
    delete data.sameMobileNumber;
    delete data.file;
    delete data.checked;
    delete data[''];
    delete data.user_code;
    delete data.teaching_standards;
    delete data.teaching_subjects;
    delete data.address2;
    delete data.status;
    delete data.guardian;
    delete data.occupation;
    delete data.post_in;
    delete data.active;
    //delete data.cas_report_view;
    delete data.indv_school_details;
    delete data.created_at;
    delete data.updated_at;
    delete data.role_name;
    delete data.user_name;
    delete data.short_name;
    delete data.name_of_school;
    delete data.name_of_organization;
    delete data.display_name;
    //delete data.profile_img;
    delete data.address;
    delete data.state;
    delete data.taluka;
    delete data.pin_code;
    delete data.district;
    delete data.standard;
    delete data.division;
    delete data.date_of_registration;
    delete data.day;
    delete data.month;
    delete data.year;
    delete data.access;
    data.whatsapp_number = currentParentsData.whatsapp_number;

    if (errors.length < 1 && !email && !mobileNumber && onChangeName(data.name)) {
      data.access = checkedUserMechanismStatus;
      UPDATE_USER(data).then((res: any) => {
        if (res.status === 'fail') {
          toast.error(res.message);
          setLoading(false);
        } else {
          toast.success("Parent Updated Successfully");
          setLoading(false);
          if (userRole === "CHAIRMAN" || userRole === "GENERAL_SECRETARY" || userRole === "INSTITUTE_ADMIN") {
            history.push(`/institute-dashboard/school/student/info/${schoolId}/parentlist/${schoolsCode}/${studentsCode}`);

          } else {
            history.push(`/dashboard/school/student/info/${schoolId}/parentlist/${schoolsCode}/${studentsCode}`);
          }
        }
      });
    } else {

      if (!nameError && !onChangeName(data.name)) {
        setNameError('Please enter name');
      }
    }
  }

  const handleGender = (e: any) => {
    const data = { ...parentsData.value };
    data.gender = e.target.value;
    if (currentParentsData) {
      currentParentsData.gender = e.target.value;
    }
  }

  function handleUploadLogo(e: any, type: string) {
    if (e.target.files && e.target.files[0]) {
      const fileType = e.target.files[0].name.split('.').pop()
      if (fileType == "jpeg" || fileType == "jpg") {
        const formData = new FormData();
        formData.append('file', e.target.files[0], e.target.files[0].name);
        uploadLogo(formData, type);
      } else {
        toast.error("Please select image file only");
      }
    }
  }

  function uploadLogo(formdata: any, type: string) {
    setImageLoader(true);
    UPLOAD_LOGO(formdata).then((res: any) => {
      if (res.status === 'fail') {
        toast.error(res.message);
        setImageLoader(false);
      } else {
        setParentLogo(res.url);
        setImageLoader(false);
      }
    });
  }

  const handleBloodGroupChange = (e: any) => {
    const data = { ...parentsData.value };
    data.blood_group = e.target.value;
    if (currentParentsData) {
      currentParentsData.blood_group = e.target.value;
    }
  }

  const handleMobileChange = (e: any) => {
    const data = { ...parentsData.value };
    const re = /(6|7|8|9)\d{9}/;

    if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length === 10) {
      data.mobile_number = e.target.value;
      setMobileNumber(false);
    } else {
      data.mobile_number = e.target.value;
      setMobileNumber(true);
    }

    if (currentParentsData) {
      currentParentsData.mobile_number = e.target.value.replace(/\D+/g, '');
    }

    if (currentParentsData.checked) {
      currentParentsData.whatsapp_number = e.target.value.replace(/\D+/g, '');
    }
  }

  const handleWhatsappChange = (e: any) => {
    const data = { ...parentsData.value };
    const re = /(6|7|8|9)\d{9}/;

    if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length === 10) {
      data.whatsapp_number = e.target.value;
      setWhatsappNumber(false);
      setCanShowWhatsAppError(false);
    } else {
      data.whatsapp_number = e.target.value;
      setWhatsappNumber(true);
    }

    if (currentParentsData) {
      currentParentsData.whatsapp_number = e.target.value.replace(/\D+/g, '');
    }
  }

  const handleclose = () => {
    setCanShowPopup(false);
    history.push(`/dashboard/school/student/info/${schoolId}/parentlist/${schoolsCode}/${studentsCode}`);
  }

  const handleSave = () => {
    setCanShowPopup(false);
    history.push(`/dashboard/school/student/info/${schoolId}/parentlist/${schoolsCode}/${studentsCode}`);
  }

  const handleReportStatusChange = (e: any) => {
    // const data = { ...usersData.value };
    const status = e.target.checked === true ? 1 : 0;
    setCheckedStatus(status);

    if (currentParentsData) {
      const status = e.target.checked === true ? 1 : 0;
      currentParentsData.cas_report_view = e.target.checked === true ? 1 : 0;
      setCheckedStatus(status);

    }
  }

  const handleEmailChange = (e: any) => {
    const data = { ...parentsData.value };
    const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/;

    if (e.target.value === '' || re.test(e.target.value)) {
      data.email_id = e.target.value;
      setEmail(false);
    } else {
      data.email_id = e.target.value;
      setEmail(true);
    }

    if (currentParentsData) {
      currentParentsData.email_id = e.target.value;
    }
  }

  const handleMobileNumberChange = (e: any) => {
    const data = { ...parentsData.value };
    if (e.target.checked) {
      if (data.whatsapp_number) {
        data.whatsapp_number = data.mobile_number;
        currentParentsData.whatsapp_number = data.mobile_number;
        setCanCheckMobileNumber(true);
        setCanShowWhatsAppError(false);
      }
      if (data.mobile_number || currentParentsData.mobile_number) {
        setCanCheckMobileNumber(true);
        setCanShowWhatsAppError(false);
        currentParentsData.whatsapp_number = currentParentsData.mobile_number;
        setCurrentParentsData(currentParentsData);
      }
      currentParentsData.checked = true;
    } else {
      setCanCheckMobileNumber(false);
      currentParentsData.checked = false;
    }
  }

  const onChange = (dates: any) => {
    setStartDate(null);
    if (dates.error) {
      setIsDateError(dates);
    } else {
      setStartDate(dates.date);
    }
  };

  function addMonths(theDate: any, years: any) {
    return new Date(theDate.getTime() - years * 24 * 60 * 60 * 1000);
  }

  function subMonths(theDate: any, years: any) {
    return new Date(theDate.getTime() - years * 24 * 60 * 60 * 1000);
  }

  const onChangeName = (event: any) => {
    setNameError('');
    const reg = /^[A-Za-z]+(?:[ _-][A-Za-z]+)*$/;
    if (event) {
      if (!reg.test(event.trim())) {
        setNameError('Enter only alphabets');
      } else {
        return true;
      }
    } else {
      setNameError('Please enter name');
    }
  }

  const handleUserMechanism = (e: any) => {
    if (currentParentsData) {
      const status = e.target.checked === true ? "ENABLED" : "DISABLED";
      currentParentsData.access = status;
      setCheckedUserMechanismStatus(status);
    }
  }

  return (
    <div>
      <div className={`modal fade ${canShowPopup ? 'show' : 'false'}`} style={{ display: (canShowPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Parent Login Details</h5>
              <button type="button" className="close popup-close cursor-pointer" data-dismiss="modal" aria-label="Close" onClick={handleclose}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div>Login ID: {parentInfo.user_name}</div>
              <div>Password: {parentInfo.password}</div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleclose}>Close</button>
            </div>
          </div>
        </div>
      </div>
      {loading &&
        <div className="text-center p-5">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      }
      {!loading && <div>
        <FormBuilder onUpdate={handleInput}>
          <form>
            <div style={{ borderBottom: 'solid 1px black', marginTop: '15px', paddingLeft: '10px' }} className="mb-4">
              <h5 className="form-label mb-2 d-block">Personal Details</h5>
            </div>
            <div className="row custom-form">
              <div className="col-md-4">
                <div className="mb-4">
                  <label className="form-label mb-0"> Mrs./ Mr
                    <span style={{ paddingLeft: '5px' }}>Name</span></label>
                  <span style={{ color: 'red', fontSize: '22px', paddingLeft: '5px' }}>*</span>
                  <input className="form-control form-control-lg" type="text" name="name" onChange={(e) => onChangeName(e.target.value)} defaultValue={currentParentsData.name} placeholder="Please enter name" />
                  {nameError && <p className="text-danger">{nameError}</p>}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-4">
                  <label className="form-label mb-4">Gender</label>
                  <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                  <br />
                  <input type="radio" name="gender" value="male" checked={currentParentsData?.gender === "male"} onChange={(e) => { handleGender(e) }} />
                  <span style={{ margin: '0px 12px' }}>Male</span>
                  <input type="radio" name="gender" value="female" checked={currentParentsData?.gender === "female"} onChange={(e) => { handleGender(e) }} />
                  <span style={{ margin: '0px 12px' }}>Female</span>

                  <input className="mb-0" type="radio" value="transgender" name="gender" checked={currentParentsData?.gender === "transgender"} onChange={(e) => { handleGender(e) }} />
                  <span style={{ paddingRight: '15px', paddingLeft: '15px' }}>Other</span>

                  {isFormSubmitted && !getInputValid('gender') && <p className="text-danger">Please select gender</p>}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-4">
                  <label className="form-label mb-0">Date of Birth</label>
                  <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                  {currentParentsData.date_of_birth &&
                    <DateComponent onDate={onChange} yearDiff={18} defaultData={currentParentsData.date_of_birth}></DateComponent>}
                  {!currentParentsData.date_of_birth && <DateComponent onDate={onChange} yearDiff={18}></DateComponent>}
                  {isFormSubmitted && !startDate && !isDateError?.error && <p className="text-danger">Please select date</p>}
                  {isDateError?.error && !startDate && <p className="text-danger">{isDateError?.error}</p>}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-4">
                  <label className="form-label mb-0">Blood Group</label>
                  <select name="blood_group" value={currentParentsData.blood_group} onChange={(e) => { handleBloodGroupChange(e) }} className="form-select form-select-lg mb-3">
                    <option value="">Select blood group</option>
                    <option value="O+">O+</option>
                    <option value="O-">O-</option>
                    <option value="A-">A-</option>
                    <option value="A+">A+</option>
                    <option value="B-">B-</option>
                    <option value="B+">B+</option>
                    <option value="AB-">AB-</option>
                    <option value="AB+">AB+</option>
                  </select>
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="mb-4 d-flex justify-content-center">
                  <ProfileImageCrop uploadLogo={uploadLogo} profileImg={parentLogo} imageLoader={imageLoader} profileImageDelete={setParentLogo} />
                </div>
              </div>
              {usersId > 0 && <div className="col-md-4">
                <div className="mb-4">
                  <label className="form-label">User Access</label><br />
                  <label className="switch">
                    <input type="checkbox" name="access" value={currentParentsData?.access} checked={currentParentsData?.access === "ENABLED"} onChange={(e) => { handleUserMechanism(e) }} />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>}
              <div style={{ borderBottom: 'solid 1px black' }} className="mb-4">
                <h5 className="form-label mb-2 d-block">Contact Details</h5>
              </div>
              <div className="col-md-4">
                <div className="mb-4">
                  <label className="form-label mb-0">Email ID</label>
                  <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                  <input className="form-control form-control-lg" type="text" name="email_id" value={currentParentsData.email_id} onChange={(e) => { handleEmailChange(e) }} placeholder="Please enter email" />
                  {email && <p className="text-danger">{errorMessages.email}</p>}
                  {isFormSubmitted && !getInputValid('email_id') && <p className="text-danger">Please enter email</p>}
                </div>
              </div>

              <div className="col-md-4">
                <div className="mb-4">
                  <label className="form-label mb-0">Mobile Number</label>
                  <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                  <input className="form-control form-control-lg" type="text" name="mobile_number" maxLength={10} onChange={(e) => { handleMobileChange(e) }} value={currentParentsData.mobile_number} placeholder="Please enter mobile number" />
                  {mobileNumber && <p className="text-danger">{errorMessages.mobile_number}</p>}
                  {isFormSubmitted && !getInputValid('mobile_number') && <p className="text-danger">Please enter mobile number</p>}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-4">
                  <label className="form-label mb-0">WhatsApp Number</label>
                  {/* <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span> */}
                  (  <input type="checkbox" name="sameMobileNumber" checked={currentParentsData.checked} onChange={(e) => { handleMobileNumberChange(e) }} style={{ paddingLeft: '10px' }} />
                  <span style={{ paddingLeft: '10px' }}>Same as Mobile Number</span> )
                  <input className="form-control form-control-lg" disabled={currentParentsData.checked} type="text" name="whatsapp_number" maxLength={10} onChange={(e) => { handleWhatsappChange(e) }} value={currentParentsData.whatsapp_number} placeholder="Please enter whatsapp number" />
                  {(whatsappNumber) && <p className="text-danger">{errorMessages.whatsapp_number}</p>}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-4">
                  <label className="form-label mb-0">City/Village (Optional)</label>
                  <input className="form-control form-control-lg" type="text" name="city_village" defaultValue={currentParentsData.city_village} placeholder="Please enter city/village" />
                </div>
              </div>

            </div>
          </form>
        </FormBuilder>
        <div className="form-footer border-top border-primary py-3 text-end mt-4">
          {usersId === 0 && <a className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer" onClick={handleSubmit}>Create</a>}
          {usersId > 0 && <a className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer" onClick={() => updateParent()}>Update</a>}
          {userRole !== "CHAIRMAN" && userRole !== "GENERAL_SECRETARY" && userRole !== "INSTITUTE_ADMIN" && <Link className="text-dark text-decoration-none ms-2 cursor-pointer" to={`/dashboard/school/student/info/${id}/parentlist/${schoolsCode}/${studentsCode}`}>Cancel</Link>}

          {(userRole === "CHAIRMAN" || userRole === "GENERAL_SECRETARY" || userRole === "INSTITUTE_ADMIN") && <Link className="text-dark text-decoration-none ms-2 cursor-pointer" to={`/institute-dashboard/school/student/info/${id}/parentlist/${schoolsCode}/${studentsCode}`}>Cancel</Link>}
        </div>
      </div>}
    </div>
  )
}