import React, { useState } from 'react';
import { MultiSelect } from 'react-multi-select-component';

interface OptionType {
    value: string;
    label: string;
}

interface MultiSelectProps {
    options: OptionType[];
    value: OptionType[];
    onChange: (selected: OptionType[]) => void;
    labelledBy: string;
    customAllSelectedText: string;
}

const CustomMultiSelector: React.FC<MultiSelectProps> = ({
    options,
    value,
    onChange,
    labelledBy,
    customAllSelectedText,
}) => {
    const [selected, setSelected] = useState<OptionType[]>(value);

    const handleChange = (selected: OptionType[]) => {
        setSelected(selected);
        onChange(selected);
    };

    const getDisplayValue = () => {
        if ((selected?.length === options.length) && selected.length > 0) {
            return customAllSelectedText;
        } else if (selected.length > 0) {
            return selected.map(option => option.label).join(', ');
        } else {
            return labelledBy;
        }
    };

    return (
        <div>
            <MultiSelect
                options={options}
                value={selected}
                onChange={handleChange}
                labelledBy={labelledBy}
                hasSelectAll={true}
                overrideStrings={{
                    selectSomeItems: getDisplayValue(),
                    allItemsAreSelected: customAllSelectedText,
                }}
            />
        </div>
    );
};

export default CustomMultiSelector;
