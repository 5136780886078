import React, { useEffect, useState } from 'react'
import FormBuilder from '../../../components/form-builder'
import { FormValidator, GetControlIsValid } from '../../../components/form-builder/validations';
import { FormControlError, FormField, FormValidators } from '../../../components/form-builder/model/form-field';
import { Link, useHistory, useParams } from 'react-router-dom';
import { CREATE_MSB_USER, GET_MSB_USER_BY_ID, UPDATE_MSB_USER } from '../../../app/service/users.service';
import { PopupData } from '../../../redux/app-utility/model/popup-data';
import { AppUtilityActions } from '../../../redux/app-utility/action';
import { connect } from 'react-redux';
import { LOOK_UP_DATA } from '../../../app/service/lookup.service';
import { toast } from 'react-toastify';
import { GET_DEPARTMENTS } from '../../../app/service/department.service';
import { GET_TOTAL_SCHOOLS, UPLOAD_LOGO } from '../../../app/service/school.service';
import { GET_ROLES } from '../../../app/service/roles.service';
import moment from 'moment';
import { MultiSelect } from 'react-multi-select-component';
import errorMessages from '../../../components/error-messages';
import "react-datepicker/dist/react-datepicker.css";
import appConfig from '../../../config/constant';
import { DateComponent } from '../../../components/date';

interface Props {
    updatePopup?: (data: PopupData) => void;
}

export const UserFormPage: React.FC<any> = (props: Props) => {
    const [usersData, setUsersData] = useState<any>({});
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [validationErrors, setValidationErrors] = useState<FormControlError[]>([]);
    const [currentUsersData, setCurrentUsersData] = useState<any>({});
    const [statesData, setStatesData] = useState<any>([]);
    const [districtData, setDistrictData] = useState<any>([]);
    const [cityData, setCityData] = useState<any>([]);
    const [departmentData, setDepartmentData] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [rolesData, setRolesData] = useState<any>([]);
    const [email, setEmail] = useState(false);
    const [mobileNumber, setMobileNumber] = useState(false);
    const [dependentMobileNumber, setDependentMobileNumber] = useState(false);

    const [contactNumber, setContactNumber] = useState(false);
    const [whatsappNumber, setWhatsappNumber] = useState(false);
    const [pincodeNumber, setPincodeNumber] = useState(false);
    const [panNumber, setPanNumber] = useState(false);
    const [drivingLicenseNumber, setDrivingLicenseNumber] = useState(false);
    const [drivingLicenseDate, setDrivingLicenseDate] = useState(false);
    const [canShowSchoolsList, setCanShowSchoolsList] = useState(false);
    const [drivingLlicenseDate, setDrivingLlicenseDate] = useState(null);
    const [dependentMinDateofBirth, setDependentMinDateofBirth] = useState<any>(moment().subtract(60, "years").format("YYYY-MM-DD"));
    const [dependentMaxDateofBirth, setDependentMaxDateofBirth] = useState<any>(moment().subtract(0, "years").format("YYYY-MM-DD"));
    const [accountNumber, setAccountNumber] = useState(false);
    const [IFSCCode, setIFSCCode] = useState(false);
    const [bankName, setBankName] = useState(false);
    const [branchName, setBranchName] = useState(false);
    const [userRole, setUserRole] = useState<any>("");
    const [schoolsSelected, setSchoolsSelected] = useState<any>([]);
    const [schoolList, setSchoolList] = useState<any>([]);
    const [canCheckMobileNumber, setCanCheckMobileNumber] = useState(false);
    const [canShowWhatsAppError, setCanShowWhatsAppError] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [todayDate, setTodayDate] = useState(moment().format('YYYY-MM-DD'));
    const [createDate, setCreateDate] = useState(moment().format('YYYY-MM-DD'));
    const [confirmPopup, setConfirmPopup] = useState(false);
    const [documentsLogo, setDocumentsLogo] = useState<any>();
    const [checkedStatus, setCheckedStatus] = useState<any>();
    const [isDateError, setIsDateError] = useState<any>({});
    const [isRegistrationDateError, setIsRegistrationDateError] = useState<any>({});
    const [isDrivingLicenseExpiryDateError, setIsDrivingLicenseExpiryDateError] = useState<any>({});
    const [nameError, setNameError] = useState('');
    const [addressError, setAddressError] = useState('');
    const [blockError, setBlockError] = useState('');
    const [allStateBlock, setAllStateBlock] = useState<any>({});
    const [blockData, setBlockData] = useState<any>([]);
    const [isDependentDateOfBirthError, setIsDependentDateOfBirthErrorError] = useState<any>({});
    const [dependentNameError, setDependentNameError] = useState('');
    const [checkedUserMechanismStatus, setCheckedUserMechanismStatus] = useState<any>();
    let [documentsData, setDocumentsData] = useState<any>([
        { type: "Aadhar Card", url: '', status: 'Pending' },
        { type: "Pan Card", url: '', status: 'Pending' },
        { type: "Residential Proof", url: '', status: 'Pending' },
        { type: "Experience Letter", url: '', status: 'Pending' },
        { type: "Education Certificates", url: '', status: 'Pending' },
        { type: "Resignation Letter", url: '', status: 'Pending' },
        { type: "Bank Statement", url: '', status: 'Pending' },
        { type: "Cancel Cheque", url: '', status: 'Pending' },
        { type: "Resume", url: '', status: 'Pending' }
    ]);

    const [dependencyForm, setDependencyForm] = useState([{
        dependent_type: "",
        dependent_name: "",
        dependent_mobile_number: "",
        dependent_date_of_birth: ""
    }]);

    const history = useHistory();
    let { id } = useParams<{ id: string }>();
    const usersId = parseInt(id);

    const formValidations = [
        new FormField('name', [FormValidators.REQUIRED]),
        new FormField('region', [FormValidators.REQUIRED]),
        new FormField('user_role', [FormValidators.REQUIRED]),
        new FormField('gender', [FormValidators.REQUIRED]),
        new FormField('email_id', [FormValidators.REQUIRED]),
        new FormField('mobile_number', [FormValidators.REQUIRED]),
        new FormField('contact_number', [FormValidators.REQUIRED]),
        new FormField('whatsapp_number', []),
        new FormField('department_name', [FormValidators.REQUIRED]),
        new FormField('pan_number', []),
        new FormField('date_of_birth', []),
        new FormField('blood_group', []),
        new FormField('state', [FormValidators.REQUIRED]),
        new FormField('district', [FormValidators.REQUIRED]),
        new FormField('taluka', []),
        new FormField('city_village', []),
        new FormField('address', [FormValidators.REQUIRED]),
        new FormField('pin_code', [FormValidators.REQUIRED]),
        new FormField('driving_license_number', []),
        new FormField('driving_license_expiry_date', []),
        new FormField('date_of_registration', []),
        new FormField('bank_name', [FormValidators.REQUIRED]),
        new FormField('branch_name', [FormValidators.REQUIRED]),
        new FormField('account_number', [FormValidators.REQUIRED]),
        new FormField('ifsc_code', [FormValidators.REQUIRED])
    ];

    useEffect(() => {
        const userRole = localStorage.getItem("user_role");
        setUserRole(userRole);
        setLoading(true);
        getDepartments();
        getRoles();
        getSchools();
        LOOK_UP_DATA().then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                setStatesData(res.states);
                setLoading(false);
                if (usersId > 0) {
                    GET_MSB_USER_BY_ID(usersId).then((response: any) => {
                        if (response.status === 'fail') {
                            toast.error(response.message);
                        } else {
                            const data = response.rows[0];
                            data.account_number = data.bank_details.account_number;
                            data.ifsc_code = data.bank_details.ifsc_code;
                            data.bank_name = data.bank_details.bank_name;
                            data.branch_name = data.bank_details.branch_name;
                            if (data.date_of_birth) {
                                const date_of_birth: any = new Date(data.date_of_birth);
                                setStartDate(date_of_birth);
                            }
                            if (data.date_of_registration) {
                                const date_of_registration: any = new Date(data.date_of_registration);
                                setTodayDate(date_of_registration);
                            }
                            if (data.created_at) {
                                const created_at: any = new Date(data.created_at);
                                setCreateDate(created_at);
                            }
                            if (data.driving_license_expiry_date) {
                                const driving_license_expiry_date: any = new Date(data.driving_license_expiry_date);
                                setDrivingLlicenseDate(driving_license_expiry_date);
                            }
                            if (data.department_name === "MSB") {
                                setCanShowSchoolsList(true);
                                setSchoolsSelected(data.FTM_schools || []);
                            }
                            if (data.mobile_number === data.whatsapp_number) {
                                data.checked = true;
                            }
                            if (data.active) {
                                setCheckedStatus(data.active);
                            }

                            if (data.access) {
                                setCheckedUserMechanismStatus(data.access);
                            }

                            setCurrentUsersData(data);
                            getStatesData(res.states, data);
                            setDependencyForm(data.dependents);
                            setDocumentsData(data.documents);
                            setDrivingLicenseDate(data.driving_license_number ? true : false);
                        }
                    });
                }
            }
        });

    }, []);



    function getDepartments() {
        GET_DEPARTMENTS().then((response: any) => {
            if (response.status === 'fail') {
                toast.error(response.message);
            } else {
                const data = response.rows;
                setDepartmentData(data);
            }
        });
    }

    function getSchools() {
        GET_TOTAL_SCHOOLS().then(async (res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                } else {
                    const data = res.rows;
                    const preparedData: any = [];
                    for (let i = 0; i < data.length; i++) {
                        preparedData.push({
                            label: data[i].name_of_school,
                            value: data[i].school_code
                        })

                    }
                    setSchoolList(preparedData);
                }
            }
        });
    }

    function getRoles() {
        GET_ROLES().then((response: any) => {
            if (response.status === 'fail') {
                toast.error(response.message);
            } else {
                const data = response.rows;
                setRolesData(data);
            }
        });
    }


    function getStatesData(states: any, data: any) {
        if (states.length > 0) {
            const preparedData = states.filter((el: any) => el.state === data.state);
            setDistrictData(preparedData[0].districts);
            setCityData(preparedData[0].cities);
            onLoadBlocks(data.district, preparedData[0]?.block);
        }
    }

    const getInputValid = (control: string) => {
        const value = GetControlIsValid(validationErrors, control);
        return value;
    }

    const handleSubmit = () => {
        setIsFormSubmitted(true);
        const data = usersData.value ? { ...usersData.value } : { ...currentUsersData };
        const errors: FormControlError[] = FormValidator(formValidations, data);
        setValidationErrors(errors);
        data.profile_img = "";
        data.date_of_birth = startDate ? moment(startDate).format('YYYY-MM-DD') : null;
        data.driving_license_expiry_date = drivingLlicenseDate ? moment(drivingLlicenseDate).format('YYYY-MM-DD') : null;
        data.date_of_registration = todayDate ? moment(todayDate).format('YYYY-MM-DD') : null;
        data.bank_details = {
            account_number: data.account_number,
            ifsc_code: data.ifsc_code,
            bank_name: data.bank_name,
            branch_name: data.branch_name
        };
        delete data.account_number;
        delete data.ifsc_code;
        delete data.bank_name;
        delete data.branch_name;
        data.type = "MSB";
        data.dependents = dependencyForm;
        data.documents = documentsData;
        delete data.sameMobileNumber;
        delete data.file;
        delete data.checked;
        delete data[''];
        delete data.day;
        delete data.month;
        delete data.year;
        delete data.dependent_name;
        delete data.dependent_type;
        delete data.dependent_mobile_number;
        delete data.dependent_date_of_birth;
        let isDependents = false;
        data.dependents.forEach((element: any) => {
            isDependents = false;
            if (element.dependent_date_of_birth && element.dependent_mobile_number && element.dependent_name && element.dependent_type) {
                isDependents = true;
            }
        });
        if (!isDependents) {
            delete data.dependents;
        }
        if (schoolsSelected.length > 0) {
            data.FTM_schools = schoolsSelected
        }
        Object.keys(data).forEach(function (key) {
            if (typeof data[key] === 'string') {
                data[key] = data[key].trim();
            }
        });
        if (errors.length < 1 && !email && !mobileNumber && !contactNumber &&
            !panNumber && !pincodeNumber && !drivingLicenseNumber && !accountNumber && !IFSCCode && !bankName && !branchName
            && onChangeName(data.name) && onChangeAddress(data.address)) {
            data.whatsapp_number = data.whatsapp_number ? data.whatsapp_number : data.mobile_number;
            setLoading(true);
            CREATE_MSB_USER(data).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                    data.account_number = data.bank_details.account_number;
                    data.ifsc_code = data.bank_details.ifsc_code;
                    data.bank_name = data.bank_details.bank_name;
                    data.branch_name = data.bank_details.branch_name;
                    setCurrentUsersData(data);
                } else {
                    toast.success("User created Successfully");
                    setLoading(false);
                    history.push("/dashboard/users");
                }
            });
        }
        else {
            if (!nameError && !onChangeName(data.name)) {
                setNameError('Please enter name');
            }
            if (!addressError && !onChangeAddress(data.address)) {
                setAddressError('Please enter address');
            }
        }

    }

    const handleUpdate = () => {
        const data = usersData.value ? { ...usersData.value } : { ...currentUsersData };
        const errors: FormControlError[] = FormValidator(formValidations, data);
        setValidationErrors(errors);
        data.id = usersId;
        data.profile_img = "";
        data.bank_details = {
            account_number: data.account_number ? data.account_number : (currentUsersData.bank_details.account_number ? currentUsersData.bank_details.account_number : ''),
            ifsc_code: data.ifsc_code ? data.ifsc_code : (currentUsersData.bank_details.ifsc_code ? currentUsersData.bank_details.ifsc_code : ''),
            bank_name: data.bank_name ? data.bank_name : (currentUsersData.bank_details.bank_name ? currentUsersData.bank_details.bank_name : ''),
            branch_name: data.branch_name ? data.branch_name : (currentUsersData.bank_details.branch_name ? currentUsersData.bank_details.branch_name : '')
        };
        data.date_of_birth = startDate ? moment(startDate).format('YYYY-MM-DD') : null;
        delete data.account_number;
        delete data.ifsc_code;
        delete data.bank_name;
        delete data.user_code;
        delete data.post_in;
        delete data.FTM_schools;
        delete data.active;
        delete data.status;
        delete data.created_at;
        delete data.updated_at;
        delete data.user_name;
        delete data.role_name;
        delete data.department_display_name;
        delete data.branch_name;
        delete data.day;
        delete data.month;
        delete data.year;
        data.type = "MSB";
        data.dependents = dependencyForm ? dependencyForm : [];
        data.documents = documentsData;
        data.date_of_registration = todayDate ? moment(todayDate).format('YYYY-MM-DD') : null;
        data.driving_license_expiry_date = drivingLlicenseDate ? moment(drivingLlicenseDate).format('YYYY-MM-DD') : null;
        data.active = checkedStatus;
        delete data[''];
        if (schoolsSelected.length > 0) {
            data.FTM_schools = schoolsSelected
        }
        let isDependents = false;
        data.dependents.forEach((element: any) => {
            isDependents = false;
            if (element.dependent_date_of_birth && element.dependent_mobile_number && element.dependent_name && element.dependent_type) {
                isDependents = true;
            }
        });
        if (!isDependents) {
            delete data.dependents;
        }
        delete data.sameMobileNumber;
        delete data.file;
        delete data.checked;
        delete data.day;
        delete data.month;
        delete data.year;
        delete data.access;
        data.whatsapp_number = currentUsersData.whatsapp_number;
        Object.keys(data).forEach(function (key) {
            if (typeof data[key] === 'string') {
                data[key] = data[key].trim();
            }
        });
        if (errors.length < 1 && !email && !mobileNumber && !contactNumber &&
            !panNumber && !pincodeNumber && !drivingLicenseNumber && !accountNumber && !IFSCCode && !bankName && !branchName
            && onChangeName(data.name) && onChangeAddress(data.address)) {
            data.access = checkedUserMechanismStatus;
            setLoading(true);
            UPDATE_MSB_USER(data).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    toast.success("User Updated Successfully");
                    setLoading(false);
                    history.push("/dashboard/users");
                }
            });
        } else {

            if (!nameError && !onChangeName(data.name)) {
                setNameError('Please enter name');
            }
            if (!addressError && !onChangeAddress(data.address)) {
                setAddressError('Please enter address');
            }
            if (!data.pan_number) {
                handlePanChange(data.pan_number);
            }
        }
    }

    const handleInput = (data: any) => {
        data.value = { ...currentUsersData, ...data.value };
        setUsersData(data);
        const errors: any = FormValidator(formValidations, data.value);
        setValidationErrors(errors);
    }

    const handleStateChange = (e: any) => {
        setBlockData([]);
        const data = { ...usersData.value };
        data.state = e.target.value;
        const preparedData = statesData.filter((el: any) => el.state === e.target.value);
        setDistrictData(preparedData[0].districts);
        setCityData(preparedData[0].cities);
        setAllStateBlock(preparedData[0]?.block);

        if (currentUsersData) {
            currentUsersData.state = e.target.value;
        }
    }

    const handleDistrictChange = (e: any) => {
        setBlockData([]);
        const data = { ...usersData.value };
        data.district = e.target.value;
        if (currentUsersData) {
            currentUsersData.district = e.target.value;
        }
        onLoadBlocks(e.target.value, allStateBlock);
    }

    const onLoadBlocks = (e: any, blocks: any) => {
        Object.keys(blocks).find(key => {
            if (key === e) {
                setBlockData(blocks[key]);
                return;
            }
        });
    }

    const handleCityChange = (e: any) => {
        const data = { ...usersData.value };
        data.city_village = e.target.value;
        if (currentUsersData) {
            currentUsersData.city_village = e.target.value;
        }
    }

    const handleBlockChange = (e: any) => {
        const data = { ...usersData.value };
        data.taluka = e.target.value;
        if (currentUsersData) {
            currentUsersData.taluka = e.target.value;
        }
    }

    const handleBloodGroupChange = (e: any) => {
        const data = { ...usersData.value };
        data.blood_group = e.target.value;
        if (currentUsersData) {
            currentUsersData.blood_group = e.target.value;
        }
    }

    const handleRegionChange = (e: any) => {
        const data = { ...usersData.value };
        data.region = e.target.value;
        if (currentUsersData) {
            currentUsersData.region = e.target.value;
        }
    }

    const handleDepartmentChange = (e: any) => {
        const data = { ...usersData.value };
        data.department_name = e.target.value;
        if (e.target.value === "MSB") {
            setCanShowSchoolsList(true);
        } else {
            setCanShowSchoolsList(false);
        }

        if (currentUsersData) {
            currentUsersData.department_name = e.target.value;
        }
    }

    const handleGender = (e: any) => {
        const data = { ...usersData.value };
        data.gender = e.target.value;
        if (currentUsersData) {
            currentUsersData.gender = e.target.value;
        }
    }

    const handleRoleChange = (e: any) => {
        const data = { ...usersData.value };
        data.user_role = e.target.value;
        if (currentUsersData) {
            currentUsersData.user_role = e.target.value;
        }
    }

    const handleContactChange = (e: any) => {
        const data = { ...usersData.value };
        const re = /(6|7|8|9)\d{9}/;
        if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length === 10) {
            data.contact_number = e.target.value;
            setContactNumber(false);
        } else {
            data.contact_number = e.target.value;
            setContactNumber(true);
        }

        if (currentUsersData) {
            currentUsersData.contact_number = e.target.value.replace(/\D+/g, '');
        }
    }

    const handleMobileChange = (e: any) => {
        const data = { ...usersData.value };
        const re = /(6|7|8|9)\d{9}/;

        if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length === 10) {
            data.mobile_number = e.target.value;
            setMobileNumber(false);
        } else {
            data.mobile_number = e.target.value;
            setMobileNumber(true);
        }

        if (currentUsersData) {
            currentUsersData.mobile_number = e.target.value.replace(/\D+/g, '');
        }
        if (currentUsersData.checked) {
            currentUsersData.whatsapp_number = e.target.value.replace(/\D+/g, '');
        }
    }

    const handleWhatsappChange = (e: any) => {
        const data = { ...usersData.value };
        const re = /(6|7|8|9)\d{9}/;

        if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length === 10) {
            data.whatsapp_number = e.target.value;
            setWhatsappNumber(false);
            setCanShowWhatsAppError(false);
        } else {
            data.whatsapp_number = e.target.value;
            setWhatsappNumber(true);
        }

        if (currentUsersData) {
            currentUsersData.whatsapp_number = e.target.value.replace(/\D+/g, '');
        }
    }

    const handleMobileNumberChange = (e: any) => {
        const data = { ...usersData.value };
        if (e.target.checked) {
            if (data.whatsapp_number) {
                data.whatsapp_number = data.mobile_number;
                currentUsersData.whatsapp_number = data.mobile_number;
            }
            if (data.mobile_number || currentUsersData.mobile_number) {
                currentUsersData.whatsapp_number = currentUsersData.mobile_number;
                setCurrentUsersData(currentUsersData);
            }
            setCanCheckMobileNumber(true);
            setCanShowWhatsAppError(false);
            currentUsersData.checked = true;
        } else {

            setCanCheckMobileNumber(false);
            currentUsersData.checked = false;
        }
    }

    const handleEmailChange = (e: any) => {
        const data = { ...usersData.value };
        const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/;

        if (e.target.value === '' || re.test(e.target.value)) {
            data.email_id = e.target.value;
            setEmail(false);
        } else {
            data.email_id = e.target.value;
            setEmail(true);
        }

        if (currentUsersData) {
            currentUsersData.email_id = e.target.value;
        }
    }

    const handlePanChange = (e: any) => {
        const data = { ...usersData.value };
        if (!(/^([A-Z]){5}([0-9]){4}([A-Z]){1}$/.test(e)) && e) {
            setPanNumber(true);
        } else {
            data.pan_number = e;
            setPanNumber(false);
        }

        if (currentUsersData) {
            currentUsersData.pan_number = e;
        }

    }

    const handlePincodeChange = (e: any) => {
        const data = { ...usersData.value };
        const re = /^[0-9\b]+$/;

        if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length === 6) {
            data.pin_code = e.target.value;
            setPincodeNumber(false);
        } else {
            data.pin_code = e.target.value;
            setPincodeNumber(true);
        }

        if (currentUsersData) {
            currentUsersData.pin_code = e.target.value.replace(/\D+/g, '');
        }
    }

    const handleDrivingLicenseNumberChange = (e: any) => {
        const data = { ...usersData.value };
        if ((/^[a-z0-9]+$/.test(e.target.value) || e.target.value.length < 16 || e.target.value.length > 16)) {
            data.driving_license_number = e.target.value;
            setDrivingLicenseNumber(true);
            setDrivingLicenseDate(false);
        } else {
            setDrivingLicenseDate(true);
            setDrivingLicenseNumber(false);
        }

        if (currentUsersData) {
            currentUsersData.driving_license_number = e.target.value;
        }
    }


    const addDependency = () => {
        setDependencyForm([...dependencyForm, {
            dependent_type: "",
            dependent_name: "",
            dependent_mobile_number: "",
            dependent_date_of_birth: ""
        }]);
    }

    const removeFormFields = (i: number) => {
        let newDependencyForm = [...dependencyForm];
        newDependencyForm.splice(i, 1);
        setDependencyForm(newDependencyForm);
    }

    let handleChange = (i: any, e: any) => {
        var newDependencyForm: any = [...dependencyForm];
        if (e?.target?.name === 'dependent_mobile_number') {
            const re = /(6|7|8|9)\d{9}/;

            if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length === 10) {
                newDependencyForm[i][e?.target?.name] = e.target.value;
                setDependentMobileNumber(false);
            } else {
                newDependencyForm[i][e?.target?.name] = e.target.value;
                setDependentMobileNumber(true);
            }

            if (currentUsersData) {
                newDependencyForm[i][e?.target?.name] = e.target.value.replace(/\D+/g, '');
            }
        } else if (e?.target?.name === 'dependent_name') {
            setDependentNameError('');
            const reg = /^[A-Za-z]+(?:[ _-][A-Za-z]+)*$/;
            if (e?.target?.value) {
                if (!reg.test(e?.target?.value.trim())) {
                    setDependentNameError('Enter only alphabets');
                } else {
                    newDependencyForm[i][e?.target?.name] = e.target.value.trim();
                    return true;
                }
            } else if (e?.target?.value === '') {
                setDependentNameError('Please enter name');
            }
        }
        setIsDependentDateOfBirthErrorError(null);
        if (e.error) {

            if (e.error != 'Please select date') {
                setIsDependentDateOfBirthErrorError(e);
            }
        } else if (e.date) {
            if (!moment(e.date).isAfter(moment())) {
                newDependencyForm[i]['dependent_date_of_birth'] = e?.date ? moment(e?.date).format('YYYY-MM-DD') : null;
                setDependencyForm(newDependencyForm);
            } else {
                setIsDependentDateOfBirthErrorError({ error: 'Can not select future date' });
            }

        } else {
            newDependencyForm[i][e?.target?.name] = e?.target?.value;
            setDependencyForm(newDependencyForm);
            if (e?.target?.value === "child") {
                newDependencyForm[i]['dependent_mobile_number'] = currentUsersData?.mobile_number ? currentUsersData?.mobile_number : e.target.value;
                setDependencyForm(newDependencyForm);
            }
        }
    }

    const handleBankNameChange = (e: any) => {
        const data = { ...usersData.value };
        const re = /^[a-zA-Z]+$/;

        if ((e.target.value === '' || re.test(e.target.value))) {
            setBankName(false);
        } else {
            data.bank_name = e.target.value;
            setBankName(true);
        }

        if (currentUsersData) {
            currentUsersData.bank_name = e.target.value;
        }

    }

    const handleBranchNameChange = (e: any) => {
        const data = { ...usersData.value };
        const re = /^[a-zA-Z]+$/;

        if ((e.target.value === '' || re.test(e.target.value))) {
            setBranchName(false);
        } else {
            data.branch_name = e.target.value;
            setBranchName(true);
        }

        if (currentUsersData) {
            currentUsersData.branch_name = e.target.value;
        }

    }

    const handleAccountChange = (e: any) => {
        const data = { ...usersData.value };
        const re = /^[0-9\b]+$/;

        if ((e.target.value === '' || re.test(e.target.value))) {
            setAccountNumber(false);
        } else {
            data.account_number = e.target.value;
            setAccountNumber(true);
        }

        if (currentUsersData) {
            currentUsersData.account_number = e.target.value;
        }

    }

    const handleIFSChange = (e: any) => {
        const data = { ...usersData.value };
        const re = /^[A-Z]{4}0[A-Z0-9]{6}$/;

        if (e.target.value === '' || re.test(e.target.value)) {
            data.ifsc_code = e.target.value;
            setIFSCCode(false);
        } else {
            data.ifsc_code = e.target.value;
            setIFSCCode(true);
        }
        if (currentUsersData) {
            currentUsersData.ifsc_code = e.target.value;
        }

    }


    const handleUploadDocument = (e: any, item: any, index: any) => {
        if (e.target.files && e.target.files[0]) {
            e.preventDefault();
            const fileType = e.target.files[0].name.split('.').pop()
            if (fileType == "jpeg" || fileType == "jpg" || fileType == "png" || fileType == "pdf") {
                const formData = new FormData();
                const image = new Image();
                image.src = URL.createObjectURL(e.target.files[0]);
                image.onload = function () {
                    const fileSize: any = (Math.floor(e.target.files[0].size / 1024));
                    if ((fileSize > 20 && fileSize < 500) && (image.width <= 200 && image.height <= 230)) {
                        formData.append('file', e.target.files[0], e.target.files[0].name);
                        uploadLogo(formData, item, index);
                    } else {
                        toast.error("Maximum Dimentions : 200px *230 px,Image Size should be between 20 kb to 500 kb");
                    }
                };

            } else {
                toast.error("Valid file type .jpg, .jpeg,png,pdf only");
            }
        }
    }

    function uploadLogo(formdata: any, item: any, i: any) {
        UPLOAD_LOGO(formdata).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
            } else {
                handleUploadUrl(res.url, item, i, 'browse');
            }
        });
    }

    function handleUploadUrl(url: any, item: any, index: any, type: any) {
        const data = documentsData;
        for (let i = 0; i < data.length; i++) {
            if (item.type === "Aadhar Card" && index === i) {
                documentsData[i].url = (type === 'browse' ? url : '');
                documentsData[i].status = (type === 'browse' ? "Complete" : 'Pending');
            } else if (item.type === "Pan Card" && index === i) {
                documentsData[i].url = (type === 'browse' ? url : '');
                documentsData[i].status = (type === 'browse' ? "Complete" : 'Pending');
            } else if (item.type === "Residential Proof" && index === i) {
                documentsData[i].url = (type === 'browse' ? url : '');
                documentsData[i].status = (type === 'browse' ? "Complete" : 'Pending');
            } else if (item.type === "Education Certificates" && index === i) {
                documentsData[i].url = (type === 'browse' ? url : '');
                documentsData[i].status = (type === 'browse' ? "Complete" : 'Pending');
            } else if (item.type === " Experience Letter" && index === i) {
                documentsData[i].url = (type === 'browse' ? url : '');
                documentsData[i].status = (type === 'browse' ? "Complete" : 'Pending');
            } else if (item.type === "Resignation Letter" && index === i) {
                documentsData[i].url = (type === 'browse' ? url : '');
                documentsData[i].status = (type === 'browse' ? "Complete" : 'Pending');
            } else if (item.type === "Bank Statemen" && index === i) {
                documentsData[i].url = (type === 'browse' ? url : '');
                documentsData[i].status = (type === 'browse' ? "Complete" : 'Pending');
            } else if (item.type === "Cancel Cheque" && index === i) {
                documentsData[i].url = (type === 'browse' ? url : '');
                documentsData[i].status = (type === 'browse' ? "Complete" : 'Pending');
            } else if (item.type === "Resume" && index === i) {
                documentsData[i].url = (type === 'browse' ? url : '');
                documentsData[i].status = (type === 'browse' ? "Complete" : 'Pending');
            }
        }
        setDocumentsData([...documentsData]);
    }

    function handleDocumentView(url: any) {
        setDocumentsLogo(url);
        setConfirmPopup(true);
    }

    const handleConfirmclose = () => {
        setConfirmPopup(false);
    }

    const onDateChange = (value: any) => {
        setTodayDate(todayDate);
        if (value.error) {
            setIsRegistrationDateError(value);
        } else {
            setTodayDate(value.date);
        }
    };

    const onDrivingLicenseDateChange = (value: any) => {
        setDrivingLlicenseDate(null);
        if (value.error) {
            setIsDrivingLicenseExpiryDateError(value);
        } else {
            if (moment(value.date).isAfter(moment())) {
                setDrivingLlicenseDate(value.date);
            } else {
                setIsDrivingLicenseExpiryDateError({ error: 'Can not select past date, license expired' });

            }
        }
    };

    const handleUserStatusChange = (e: any) => {
        if (currentUsersData) {
            const status = e.target.checked === true ? 1 : 0;
            currentUsersData.active = e.target.checked === true ? 1 : 0;
            setCheckedStatus(status);
        }
    }

    const onDateSelection = (value: any) => {
        setStartDate(null);
        if (value.error) {
            setIsDateError(value);
        } else {
            setStartDate(value.date);
        }
    }

    const onChangeName = (event: any) => {
        setNameError('');

        const reg = /^[a-zA-Z\s]*$/;
        if (event) {
            if (!reg.test(event)) {
                setNameError('Enter only alphabets');
            } else {
                return true;
            }
        } else {
            setNameError('Please enter name');
        }
    }

    const onChangeAddress = (event: any) => {
        setAddressError('');
        if (event) {
            if (event.trim().length <= 2) {
                setAddressError('Address minimum 3 characters to allowed');
            } else if (event.trim().length >= 256) {
                setAddressError('Address maximum 256 characters to allowed');
            } else {
                return true;
            }
        } else {
            setAddressError('Please enter Address');
        }
    }

    const onChangeBlock = (event: any) => {
        setBlockError('');
        if (event) {
            if (event.trim().length <= 2) {
                setBlockError('Block minimum 3 characters');
            } else if (event.trim().length >= 256) {
                setBlockError('Block maximum 256 characters');

            } else {
                return true;
            }
        } else {
            setBlockError('Please enter Block');
        }
    }

    const handleUserMechanism = (e: any) => {
        if (currentUsersData) {
            const status = e.target.checked === true ? "ENABLED" : "DISABLED";
            currentUsersData.access = status;
            setCheckedUserMechanismStatus(status);
        }
    }

    return (
        <div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            <div className={`modal fade ${confirmPopup ? 'show' : 'false'}`} style={{ display: (confirmPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Document View</h5>
                            <button type="button" className="close popup-close cursor-pointer" data-dismiss="modal" aria-label="Close" onClick={handleConfirmclose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body text-center">
                            <img src={`${appConfig.FILE_UPLOAD_URL}${documentsLogo?.charAt(0) === "/" ? documentsLogo?.substring(1) : documentsLogo}`} style={{ height: '100px', width: '100px', objectFit: 'contain' }} />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleConfirmclose}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
            {!loading && <div>
                <div className="row border-top border-primary py-3">
                    {usersId === 0 && <h2>Create User</h2>}
                    {usersId > 0 && <h2>Update User</h2>}
                </div>
                <FormBuilder onUpdate={handleInput}>
                    <form>
                        <div className="row custom-form">
                            <div style={{ borderBottom: 'solid 1px black' }} className="mb-4">
                                <h5 className="form-label mb-2 d-block">Personal Details</h5>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Mrs./ Mr
                                        <span style={{ paddingLeft: '5px' }}>Name</span></label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '5px' }}>*</span>
                                    <input className="form-control form-control-lg" type="text" name="name" onChange={(e) => onChangeName(e.target.value)} defaultValue={currentUsersData?.name} placeholder="Please enter name" />
                                    {nameError && <p className="text-danger">{nameError}</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-4">Gender</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <br />
                                    <input className="mb-0" type="radio" value="male" name="gender" checked={currentUsersData?.gender === "male"} onChange={(e) => { handleGender(e) }} />
                                    <span style={{ paddingRight: '15px', paddingLeft: '15px' }}>Male</span>
                                    &nbsp;
                                    <input className="mb-0" type="radio" value="female" name="gender" checked={currentUsersData?.gender === "female"} onChange={(e) => { handleGender(e) }} />
                                    <span style={{ paddingRight: '15px', paddingLeft: '15px' }}>Female</span>
                                    <input className="mb-0" type="radio" value="transgender" name="gender" checked={currentUsersData?.gender === "transgender"} onChange={(e) => { handleGender(e) }} />
                                    <span style={{ paddingRight: '15px', paddingLeft: '15px' }}>Other</span>
                                    {isFormSubmitted && !getInputValid('gender') && <p className="text-danger">Please select gender</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Date of Birth</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    {currentUsersData.date_of_birth &&
                                        <DateComponent onDate={onDateSelection} yearDiff={18} defaultData={currentUsersData.date_of_birth}></DateComponent>}
                                    {!currentUsersData.date_of_birth && <DateComponent onDate={onDateSelection} yearDiff={18}></DateComponent>}
                                    {isFormSubmitted && !startDate && !isDateError?.error && <p className="text-danger">Please select date</p>}
                                    {isDateError?.error && !startDate && <p className="text-danger">{isDateError?.error}</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Blood Group</label>
                                    <select name="blood_group" value={currentUsersData.blood_group} onChange={(e) => { handleBloodGroupChange(e) }} className="form-select form-select-lg mb-3">
                                        <option value="">Select blood group</option>
                                        <option value="O+">O+</option>
                                        <option value="O-">O-</option>
                                        <option value="A-">A-</option>
                                        <option value="A+">A+</option>
                                        <option value="B-">B-</option>
                                        <option value="B+">B+</option>
                                        <option value="AB-">AB-</option>
                                        <option value="AB+">AB+</option>
                                    </select>
                                </div>
                            </div>
                            <div style={{ borderBottom: 'solid 1px black' }} className="mb-4">
                                <h5 className="form-label mb-2 d-block">Contact Details</h5>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Email ID</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <input className="form-control form-control-lg" type="text" name="email_id" value={currentUsersData?.email_id} onChange={(e) => { handleEmailChange(e) }} placeholder="Please enter email" />
                                    {email && <p className="text-danger">{errorMessages.email}</p>}
                                    {!email && isFormSubmitted && !getInputValid('email_id') && <p className="text-danger">Please enter email</p>}
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Contact Number( Mobile)</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <input className="form-control form-control-lg" type="text" name="contact_number" maxLength={10} value={currentUsersData?.contact_number} onChange={(e) => { handleContactChange(e) }} placeholder="Please enter mobile number" />
                                    {contactNumber && <p className="text-danger">Length of mobile number is 10 digits, Enter proper value</p>}
                                    {!contactNumber && isFormSubmitted && !getInputValid('contact_number') && <p className="text-danger">Please enter contact number</p>}
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Address</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <input className="form-control form-control-lg" type="text" name="address" defaultValue={currentUsersData?.address} onChange={(e) => onChangeAddress(e.target.value)} placeholder="Please enter address" />
                                    {addressError && <p className="text-danger">{addressError}</p>}

                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">State</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <select name="state" className="form-select form-select-lg mb-3" value={currentUsersData?.state} onChange={(e) => { handleStateChange(e) }}>
                                        <option value="">Select state</option>
                                        {statesData.map((name: any, i: number) => (
                                            <option key={i} value={name.state}>
                                                {name.state}
                                            </option>
                                        ))}
                                    </select>
                                    {isFormSubmitted && !getInputValid('state') && <p className="text-danger">Please select state</p>}
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">District</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <select name="district" className="form-select form-select-lg mb-3" value={currentUsersData?.district} onChange={(e) => { handleDistrictChange(e) }}>
                                        <option value="">Select district</option>
                                        {districtData.map((name: any, i: number) => (
                                            <option key={i} value={name}>
                                                {name}
                                            </option>
                                        ))}
                                    </select>
                                    {isFormSubmitted && !getInputValid('district') && <p className="text-danger">Please select district</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-2">City/Village (Optional)</label>
                                    <select name="city_village" className="form-select form-select-lg mb-3" value={currentUsersData?.city_village} onChange={(e) => { handleCityChange(e) }}>
                                        <option value="">Select city</option>
                                        {cityData.map((name: any, i: number) => (
                                            <option key={i} value={name}>
                                                {name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Block</label>
                                    <select name="taluka" className="form-select form-select-lg mb-3" value={currentUsersData?.taluka} onChange={(e) => { handleBlockChange(e) }}>
                                        <option value="">Select block</option>
                                        {blockData.map((name: any, i: number) => (
                                            <option key={i} value={name}>
                                                {name}
                                            </option>
                                        ))}
                                    </select>
                                    {isFormSubmitted && !getInputValid('taluka') && <p className="text-danger">Please select block</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">PIN Code</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <input className="form-control form-control-lg" type="text" name="pin_code" maxLength={6} value={currentUsersData?.pin_code} onChange={(e) => { handlePincodeChange(e) }} placeholder="Please enter PIN code" />
                                    {pincodeNumber && <p className="text-danger">{errorMessages.pincode}</p>}
                                    {isFormSubmitted && !getInputValid('pin_code') && <p className="text-danger">Please enter pin code</p>}
                                </div>
                            </div>

                            <div style={{ borderBottom: 'solid 1px black' }} className="mb-4">
                                <h5 className="form-label mb-2 d-block">Job Details</h5>
                            </div>

                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Role</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <select className="form-select form-select-lg mb-3" name="user_role" value={currentUsersData?.user_role} onChange={(e) => { handleRoleChange(e) }}>
                                        <option value="">Select role</option>
                                        {rolesData.map((name: any, i: number) => (
                                            <option key={i} value={name.role_code}>
                                                {name.role_name}
                                            </option>
                                        ))}
                                    </select>
                                    {isFormSubmitted && !getInputValid('user_role') && <p className="text-danger">Please select role</p>}
                                </div>
                            </div>
                            {usersId > 0 && <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Date of Registration</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    {currentUsersData.date_of_registration &&
                                        <DateComponent onDate={onDateChange} idDisable={true} yearDiff={0} defaultData={currentUsersData.date_of_registration}></DateComponent>}
                                    {!currentUsersData.date_of_registration && <DateComponent idDisable={true} defaultData={todayDate} onDate={onDateChange} yearDiff={0}></DateComponent>}
                                </div>
                            </div>}

                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Department Name</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <select name="department_name" className="form-select form-select-lg mb-3" value={currentUsersData?.department_name} onChange={(e) => { handleDepartmentChange(e) }}>
                                        <option value="">Select department</option>
                                        {departmentData.map((name: any, i: number) => (
                                            <option key={i} value={name.department_code}>
                                                {name.department_name}
                                            </option>
                                        ))}
                                    </select>
                                    {isFormSubmitted && !getInputValid('department_name') && <p className="text-danger">Please select department</p>}
                                </div>
                            </div>
                            {canShowSchoolsList && <div className="col-md-4">
                                <div className="mb-4 mt-3">
                                    <label className="form-label mb-0">School</label>
                                    <MultiSelect
                                        options={schoolList}
                                        value={schoolsSelected}
                                        onChange={setSchoolsSelected}
                                        labelledBy="Select"
                                    />
                                </div>
                            </div>}
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Region</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <select name="region" value={currentUsersData?.region} onChange={(e) => { handleRegionChange(e) }} className="form-select form-select-lg mb-3">
                                        <option value="">Select region</option>
                                        <option value="North">North</option>
                                        <option value="South">South</option>
                                        <option value="West">West</option>
                                        <option value="East">East</option>
                                    </select>
                                    {isFormSubmitted && !getInputValid('region') && <p className="text-danger">Please select region</p>}
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Official Mobile</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <input className="form-control form-control-lg" type="text" name="mobile_number" maxLength={10} value={currentUsersData?.mobile_number} onChange={(e) => { handleMobileChange(e) }} placeholder="Please enter official mobile" />
                                    {mobileNumber && <p className="text-danger">{errorMessages.mobile_number}</p>}
                                    {isFormSubmitted && !getInputValid('mobile_number') && <p className="text-danger">Please enter official mobile</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">WhatsApp Number</label>
                                    (  <input type="checkbox" name="sameMobileNumber" checked={currentUsersData.checked} onChange={(e) => { handleMobileNumberChange(e) }} style={{ paddingLeft: '10px' }} />
                                    <span style={{ paddingLeft: '10px' }}>Same as Mobile Number </span> )
                                    <input className="form-control form-control-lg" type="text" name="whatsapp_number" disabled={currentUsersData.checked} maxLength={10} value={currentUsersData?.whatsapp_number} onChange={(e) => { handleWhatsappChange(e) }} placeholder="Please enter whatsapp number" />
                                    {(whatsappNumber) && <p className="text-danger">{errorMessages.whatsapp_number}</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">PAN Number</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}></span>
                                    <input className="form-control form-control-lg" type="text" name="pan_number" maxLength={10} value={currentUsersData?.pan_number} onChange={(e) => { handlePanChange(e.target.value) }} placeholder="Please enter PAN number" />
                                    {panNumber && <p className="text-danger">{errorMessages.pancard}</p>}
                                    {/* {isFormSubmitted && !getInputValid('pan_number') && <p className="text-danger">Please enter pan number</p>} */}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-2">Driving License Number</label>
                                    <input className="form-control form-control-lg" type="text" maxLength={16} name="driving_license_number" onChange={(e) => { handleDrivingLicenseNumberChange(e) }} value={currentUsersData?.driving_license_number} placeholder="Please enter driving license number" />
                                    {drivingLicenseNumber && <p className="text-danger">{errorMessages.driving_license_number}</p>}
                                </div>
                            </div>
                            {drivingLicenseDate && <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Driving License Expiry Date</label>

                                    {currentUsersData.driving_license_expiry_date &&
                                        <DateComponent onDate={onDrivingLicenseDateChange} yearDiff={-30} defaultData={currentUsersData.driving_license_expiry_date}></DateComponent>}
                                    {!currentUsersData.driving_license_expiry_date && <DateComponent onDate={onDrivingLicenseDateChange} yearDiff={-30}></DateComponent>}
                                    {isFormSubmitted && !drivingLlicenseDate && !isDrivingLicenseExpiryDateError?.error && <p className="text-danger">Please select date</p>}
                                    {isDrivingLicenseExpiryDateError?.error && !drivingLlicenseDate && <p className="text-danger">{isDrivingLicenseExpiryDateError?.error}</p>}
                                </div>
                            </div>}
                            {usersId > 0 && <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label">User Status</label><br />
                                    <label className="switch">
                                        <input type="checkbox" name="active" value={currentUsersData?.active} checked={currentUsersData?.active === 1} onChange={(e) => { handleUserStatusChange(e) }} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>}

                            {usersId > 0 && <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label">User Access</label><br />
                                    <label className="switch">
                                        <input type="checkbox" name="access" value={currentUsersData?.access} checked={currentUsersData?.access === "ENABLED"} onChange={(e) => { handleUserMechanism(e) }} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>}

                            <div style={{ borderBottom: 'solid 1px black' }} className="mb-4">
                                <h5 className="form-label mb-2 d-block">Dependent List</h5>
                            </div>
                            <div className="row">
                                <div className="col-md-11">
                                    {dependencyForm?.map((name: any, i: number) => (
                                        <div className="row" key={i}>
                                            <div className="col-md-11">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="mb-4">
                                                            <label className="form-label mb-0">Type</label>
                                                            <select name="dependent_type" defaultValue={name.dependent_type || ""} className="form-select form-select-lg mb-3" onChange={e => handleChange(i, e)}>
                                                                <option value="">Select type</option>
                                                                <option value="father">Father</option>
                                                                <option value="mother">Mother</option>
                                                                <option value="wife">Wife</option>
                                                                <option value="child">Child</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="mb-4">
                                                            <label className="form-label mb-0">Name</label>
                                                            <input className="form-control form-control-lg" defaultValue={name.dependent_name || ""} type="text" name="dependent_name" placeholder="Please enter name" onChange={e => handleChange(i, e)} />

                                                            {dependentNameError && <p className="text-danger">{dependentNameError}</p>}

                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="mb-4">
                                                            <label className="form-label mb-2">Date of Birth</label>
                                                            {name.dependent_date_of_birth &&
                                                                <DateComponent onDate={(event: any) => handleChange(i, event)} yearDiff={0} defaultData={name.dependent_date_of_birth}></DateComponent>}
                                                            {!name.dependent_date_of_birth && <DateComponent onDate={(event: any) => handleChange(i, event)} yearDiff={0}></DateComponent>}
                                                            {isDependentDateOfBirthError?.error && <p className="text-danger">{isDependentDateOfBirthError?.error}</p>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <div className="mb-4">
                                                            <label className="form-label mb-0">Mobile  Number</label>
                                                            <input className="form-control form-control-lg dependent-mobile-number" maxLength={10} defaultValue={name.dependent_mobile_number || ""} type="text" name="dependent_mobile_number" placeholder="Please enter mobile number" onChange={e => handleChange(i, e)} />
                                                            {dependentMobileNumber && <p className="text-danger">{errorMessages.mobile_number}</p>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-1" style={{ marginTop: '25px' }}>
                                                {i ?
                                                    <div>
                                                        <i className="bi bi-trash cursor-pointer" onClick={() => removeFormFields(i)} style={{ color: 'black', cursor: 'pointer', padding: "5px", fontSize: '25px' }}></i>
                                                    </div> : null
                                                }
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="col-md-1" style={{ padding: '0px', marginTop: '25px' }}>
                                    <button className="btn btn-primary px-3 cursor-pointer" type="button" onClick={addDependency}>Add New</button>
                                    {dependencyForm?.length > 0 && <div style={{ textAlign: 'center' }}>Count: {dependencyForm?.length}</div>}
                                </div>
                            </div>
                            <div style={{ borderBottom: 'solid 1px black' }} className="mb-4">
                                <h5 className="form-label mb-2 d-block">Bank Details</h5>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Account Number</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <input className="form-control form-control-lg" type="text" name="account_number" value={currentUsersData?.account_number} onChange={(e) => { handleAccountChange(e) }} placeholder="Please enter account number" />
                                    {accountNumber && <p className="text-danger">{errorMessages.branch_account_number}</p>}
                                    {isFormSubmitted && !getInputValid('account_number') && <p className="text-danger">Please enter account number</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">IFSC Code</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <input className="form-control form-control-lg" type="text" name="ifsc_code" maxLength={11} value={currentUsersData?.ifsc_code} onChange={(e) => { handleIFSChange(e) }} placeholder="Please enter IFSC code" />
                                    {IFSCCode && <p className="text-danger">{errorMessages.ifsc_code}</p>}
                                    {isFormSubmitted && !getInputValid('ifsc_code') && <p className="text-danger">Please enter IFSC code</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Bank Name</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <input className="form-control form-control-lg" type="text" name="bank_name" value={currentUsersData?.bank_name} onChange={(e) => { handleBankNameChange(e) }} placeholder="Please enter bank name" />
                                    {bankName && <p className="text-danger">{errorMessages.bank_name}</p>}
                                    {isFormSubmitted && !getInputValid('bank_name') && <p className="text-danger">Please enter bank name</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Branch Name</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <input className="form-control form-control-lg" type="text" name="branch_name" value={currentUsersData?.branch_name} onChange={(e) => { handleBranchNameChange(e) }} placeholder="Please enter branch name" />
                                    {branchName && <p className="text-danger">{errorMessages.branch_name}</p>}
                                    {isFormSubmitted && !getInputValid('branch_name') && <p className="text-danger">Please enter branch name</p>}
                                </div>
                            </div>

                            <div style={{ borderBottom: 'solid 1px black' }} className="row mb-4">
                                <div className="col-md-11">
                                    <h5 className="form-label mb-2 d-block">Upload Soft Copies</h5>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="mar-t-20 custom-scroll table-responsive">
                                    <table className="table fixed-table-header mb-0">
                                        <thead>
                                            <tr>
                                                <th>Document Name</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {documentsData.map((name: any, i: number) => (
                                                <tr key={i}>
                                                    <td>{name.type}</td>
                                                    <td>{name.status}</td>
                                                    <td>
                                                        {(userRole === "SUPER_ADMIN" && name.status === "Pending") &&
                                                            <a className="file btn btn-sm btn-primary px-4 rounded-12 mb-4 cursor-pointer" >
                                                                Browse
                                                                <input type="file" name='file' onChange={(e) => handleUploadDocument(e, name, i)} />
                                                            </a>}&nbsp;
                                                        {(userRole === "SUPER_ADMIN" && name.status === "Complete") &&
                                                            <a className="file btn btn-sm btn-primary px-4 rounded-12 mb-4 cursor-pointer" onClick={() => handleDocumentView(name.url)}>
                                                                Preview
                                                            </a>}&nbsp;
                                                        {(userRole === "SUPER_ADMIN" && name.status === "Complete") &&
                                                            <a className="file btn btn-sm btn-primary px-4 rounded-12 mb-4 cursor-pointer" onClick={() => handleUploadUrl(name.url, name, i, "cancel")}>
                                                                Cancel
                                                            </a>}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </form>
                </FormBuilder>
                <div className="form-footer border-top border-primary py-3 text-end mt-4">
                    {usersId === 0 && <a className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer" onClick={handleSubmit}>Create</a>}
                    {usersId > 0 && <a className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer" onClick={handleUpdate}>Update</a>}
                    <Link className="text-dark text-decoration-none ms-2 cursor-pointer" to="/dashboard/users">Cancel</Link>
                </div>
            </div>}
        </div>
    )
}


const mapStateToProps = (state: any) => {
    return {
        UtilityRed: state.AppUtilityReducer
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        updatePopup: (data: PopupData) => dispatch(AppUtilityActions.updatePopup(data))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UserFormPage);
