import API from "../utility/axios";
const apiUrl = "/api/v1";
const mnsUrl = "/api/v2";

export const GET_MNS_MODULE_DETAILS = (): Promise<any> => {
  return API.get(`${apiUrl}/module?module_code=${"MATHS"}`);
};

export const MNS_QUESTIONS_LIST = (data: any): Promise<any> => {
  return API.post(`${mnsUrl}/module/mns/school/user/questions/list`, data);
};

export const CREATE_MNS_USER_ASSIGN = (data: any): Promise<any> => {
  return API.post(`${mnsUrl}/module/mns/school/user/assign`, data);
};

export const GET_MNS_STUDENT_STATUS = (data: any): Promise<any> => {
  return API.post(`${mnsUrl}/module/mns/student/status`, data);
};

export const GET_MNS_DETAILS = (): Promise<any> => {
  return API.get(`${mnsUrl}/module/mns/details`);
};

export const MNS_SUBMIT_QUESTIONS = (data: any): Promise<any> => {
  return API.post(`${mnsUrl}/module/mns/school/user/answers/update`, data);
};

export const GET_STUDENT_MATH_REPORT = (user_code: any): Promise<any> => {
  return API.get(
    `${mnsUrl}/module/maths/student/report/download?user_code=${user_code}`
  );
};

export const MNS_SUBMIT_QUESTIONS_STATUS = (data: any): Promise<any> => {
  return API.post(`${mnsUrl}/module/mns/school/user/questions/list`, data);
};

export const GET_MATHS_MODULE_REPORT_BY_STUDENT = (
  UserCode: any
): Promise<any> => {
  return API.get(`${mnsUrl}/module/maths/student/report?user_code=${UserCode}`);
};
