import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Link, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { GET_SUBJECT_BY_UUID } from '../../../../app/service/abhyas-subject.service';
import { FETCH_PARIKSHA_USER_QUESTION_INFO } from '../../../../app/service/pariksha-module-questions';
import moment from 'moment';
import { GET_MODULES_BY_USER_CODES } from '../../../../app/service/module.service';
import TESTALLOCATED from "../../../../institute-dashboard-assets/images/test-allocated.png";
import TESTATTEMPTED from "../../../../institute-dashboard-assets/images/test-attempted.png";
import TESTPASSED from "../../../../institute-dashboard-assets/images/test-passed.png";
import TESTFAILED from "../../../../institute-dashboard-assets/images/test-failed.png";
import TESTEXAMTIME from "../../../../institute-dashboard-assets/images/exam-time.gif";
import { CREATE_PAYMENT, CREATE_PAYMENT_DETAILS_SCHOOL_MODULE_CODE } from '../../../../app/service/payment-service';
import { CREATE_MNS_USER_ASSIGN } from '../../../../app/service/mns-service';
import { UI_BASE_URL } from '../../../../config/constant';
import { CREATE_CCAVENUE_PAYMENT } from '../../../../app/service/ccavenue.service';
import { GET_USER_USER_CODE } from '../../../../app/service/users.service';
import { ADD_UPDATE_SUBSCRIPTION_PRICE } from '../../../../app/service/subscription.service';

export const ParikshaProgressView = (props: any) => {
    const [testLoading, setTestLoading] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [canShowPopup, setCanShowPopup] = useState<boolean>(false);
    const [canShowPaymentPopup, setCanShowPaymentPopup] = useState<boolean>(false);
    const [parikshaInfo, setParikshaInfo] = useState<any>({});
    const [subjectData, setSubjectData] = useState<any>({});
    const history = useHistory();
    const [testsList, setTestsList] = useState<any>([]);
    const [totalTestList, setTotalList] = useState<any>([]);
    const [moduleData, setModuleData] = useState<any>({});
    const [selectWorksheetInfo, setSelectWorksheetInfo] = useState<any>({});
    const [selectedTest, setSelectedTest] = useState("");
    const [paymentInfo, setPaymentInfo] = useState<any>({});
    const [studentInfo, setStudentInfo] = useState<any>({});
    const [trialStatus, setTrialStatus] = useState<boolean>(false);
    let { moduleUUID, userCode, schoolCode, BoardUuid, MediumUuid, StandardUuid, SubjectUuid } = useParams<{ moduleUUID: string, userCode: string, schoolCode: string, BoardUuid: string, MediumUuid: string, StandardUuid: string, SubjectUuid: string }>();
    const modules_uuid = moduleUUID;
    const user_code = userCode;
    const school_code = schoolCode;
    const boardUuid = BoardUuid;
    const mediumUuid = MediumUuid;
    const standardUuid = StandardUuid;
    const subjectUuid = SubjectUuid;

    useEffect(() => {
        const splitLocation: any = window.location.href.split("?");
        const queryString = splitLocation[1];
        if (queryString) {
            const match = queryString.match(/status=([^&]*)/);
            if (match) {
                const statusValue = match[1];
                const studentInfo: any = JSON.parse(localStorage.getItem('cc_student_info') || '{}');
                const studentPaymentInfo = JSON.parse(localStorage.getItem('cc_user_payment_info') || '{}');
                const txnId = localStorage.getItem("cc_transaction_id");
                const moduleInfo = JSON.parse(localStorage.getItem('cc_module_info') || '{}');
                if (txnId && statusValue === "success") {
                    updateSubscription(txnId, "", studentPaymentInfo, statusValue, studentInfo, moduleInfo);
                } else {
                    removeItemsFromeLocalStorage();
                }
            } else {
            }
        }
        getUserDetails();
        getModuleData();
        getSubjectInfo();
        getParikshaCompleteInfo();

    }, []);

    function getParikshaCompleteInfo() {
        let payload = {
            module_uuid: modules_uuid,
            school_code: school_code,
            user_code: user_code,
            board_uuid: boardUuid,
            medium_uuid: mediumUuid,
            standard_uuid: standardUuid,
            subject_uuid: subjectUuid
        }
        localStorage.setItem('userSelectedSubjectInfo', JSON.stringify(payload));
        setLoading(true);
        FETCH_PARIKSHA_USER_QUESTION_INFO(payload).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res?.err);
                setLoading(false);
            } else {
                if (res) {
                    const data = res;
                    setParikshaInfo(data);
                    if (data && data?.tests) {
                        // data?.tests?.forEach((element: any) => {
                        //     element.started_dt = moment(element.started_dt.split("T")[0]).format('DD-MM-yyyy');
                        // });
                        setTotalList(data?.tests);
                        data?.tests.slice(0, 10)
                        setTestsList(data?.tests);
                    }
                    setLoading(false);

                }
            }
        });
    }
    function getModuleData() {
        GET_MODULES_BY_USER_CODES(userCode).then((res: any) => {

            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                } else {
                    const data = res.filter((el: any) => el.module_code === 'ABHYAAS');
                    setModuleData(data[0]);
                }
            }
        });
    }
    function getUserDetails() {
        GET_USER_USER_CODE(userCode).then((response: any) => {
            if (response.status === 'fail') {
                toast.error(response.message);
            } else {
                const data = response.rows[0];
                localStorage.setItem("cc_student_info", JSON.stringify(data));
                setStudentInfo(data);
                if (data?.account_type === "TRIAL" && data?.trial_start_dt && data?.trial_end_dt) {
                    const trialStartDate = new Date(data?.trial_start_dt);
                    const trialEndDate = new Date(data?.trial_end_dt);
                    const currentDate = new Date();
                    const endEqualsCurrent = currentDate?.toDateString() === trialEndDate?.toDateString();
                    if (currentDate >= trialStartDate && (endEqualsCurrent || currentDate <= trialEndDate) && data?.modules) {
                        const modulesArray = data?.modules?.split(',').map((module: any) => module.trim());
                        let matchFound = modulesArray.includes("ABHYAAS");
                        setTrialStatus(matchFound);
                    }
                }
            }
        });
    }
    const getSubjectInfo = () => {
        GET_SUBJECT_BY_UUID(subjectUuid).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res?.err);
            } else {
                if (res.rows.length > 0) {
                    setSubjectData(res.rows[0]);
                }
            }
        });
    }

    const showResults = (data: any) => {
        localStorage.setItem('result-test-data', JSON.stringify(data));
        let dataUuid = data?.uuid;
        // return
        // if (data?.status === "pending") {
        localStorage.setItem("testUuid", dataUuid);
        history.push(`/student-dashboard/list/pariksha/test-analysis/${modules_uuid}/${user_code}/${school_code}`);
        // }

    }

    const handleRows = (e: any) => {
        setTestLoading(true);
        let selectValue = parseInt(e)
        let preparedElement = totalTestList;
        let preapreList = preparedElement?.slice(0, selectValue);
        // let preapreList = testsList.slice(0, selectValue);
        setTestsList(preapreList);
        setTimeout(() => {
            setTestLoading(false);

        }, 1000);

    }
    const solveWorksheet = (item: any) => {
        if (moduleData?.amount_status === "COMPLETED" || trialStatus) {
            setSelectWorksheetInfo(item)
            setCanShowPopup(true);
            // setCanShowPaymentPopup(true);
        } else {
            if (moduleData?.uuid) {
                getPaymentDetailsOfUser()
            }
        }

    }
    function getPaymentDetailsOfUser() {
        const payLoad = {
            "institute_school_code": school_code,
            "standard": "",
            "division": "",
            "students": [{ user_code: `${user_code}` }],
            "module_uuid": moduleData?.uuid
        }
        CREATE_PAYMENT_DETAILS_SCHOOL_MODULE_CODE(payLoad).then((res: any) => {
            if (res.status === "fail") {
                toast.error(res?.message);
            } else {
                if (res) {
                    let data = res?.students;
                    if (data?.length > 0) {
                        setPaymentInfo(data[0]);
                        localStorage.setItem("cc_user_payment_info", JSON.stringify(data[0]));
                        setCanShowPaymentPopup(true);
                    }
                }
            }
        });

    }
    const onClickSave = () => {
        localStorage.setItem("backword_key", "false");

        history.push(`/student-dashboard/list/abhyas/progress-view/${moduleData?.uuid}/${user_code}/${school_code}/${boardUuid}/${mediumUuid}/${standardUuid}/${subjectUuid}`)

    }

    const onClickCancel = () => {
        setCanShowPopup(false);
        setCanShowPaymentPopup(false);
    }
    const handlePayment = () => {
        createPayment(moduleData, paymentInfo);
        localStorage.setItem("cc_module_info", JSON.stringify(moduleData));
    }
    function createPayment(moduleInfo: any, paymentInfo: any) {
        const payload = {
            school_code: school_code,
            user_ids: paymentInfo?.user_code,
            sub_amount: paymentInfo?.total_amount
        }
        if (parseInt(paymentInfo?.total_amount) > 0) {
            CREATE_PAYMENT(payload).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res.message);
                    } else {
                        onOpenPaymentInfo(payload.sub_amount, res.txn_id, moduleInfo, paymentInfo);
                    }
                }
            });
        } else {
            getAssignModule(paymentInfo, moduleInfo, studentInfo);
        }
    }
    function onOpenPaymentInfo(amount: any, txn_id: string, moduleInfo: any, paymentInfo: any) {
        localStorage.setItem("cc_user_payment_info", JSON.stringify(paymentInfo));
        localStorage.setItem("cc_transaction_id", txn_id)
        createCcavenuePayment(amount, txn_id);
    }
    const createCcavenuePayment = (amount: any, txn_id: string) => {
        let payLoad = {
            amount: amount,
            currency: "INR",
            transaction_uuid: txn_id,
            redirect_url: `${UI_BASE_URL}/student-dashboard/list/pariksha/progress-view/${modules_uuid}/${user_code}/${school_code}/${boardUuid}/${mediumUuid}/${standardUuid}/${subjectUuid}`,
            cancel_url: `${UI_BASE_URL}/student-dashboard/list/pariksha/progress-view/${modules_uuid}/${user_code}/${school_code}/${boardUuid}/${mediumUuid}/${standardUuid}/${subjectUuid}`,
        }
        CREATE_CCAVENUE_PAYMENT(payLoad).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res?.error);
            } else {
                initiatePayment(res);

            }
        });
    }
    const initiatePayment = async (paymentData: any) => {
        window.location.href = paymentData.url;
    };
    function updateSubscription(transactionId: any, transactionData: any, paymentInfo: any, paymentStatus: any, studentInfo?: any, moduleInfo?: any) {
        if (paymentInfo?.subscription_payment_status === 'pending') {
            const payload = {
                data: [
                    {
                        "uuid": "",
                        "school_code": studentInfo?.institute_school_code,
                        "user_code": paymentInfo?.user_code,
                        "transaction_id": transactionId,
                        "amount": parseInt(paymentInfo?.subscription_price),
                        // "transaction_details": JSON.stringify(transactionData),
                        "transaction_details": "",
                        "payment_status": "completed"
                    }
                ]
            }
            if (paymentStatus === "success") {
                ADD_UPDATE_SUBSCRIPTION_PRICE(payload).then((res: any) => {
                    if (res.status === 'fail') {
                        toast.error(res?.message);
                    } else {
                        getAssignModule(paymentInfo, moduleInfo, studentInfo);
                        toast.success('Subscription Added Successfully');
                    }
                });
            }
        } else {
            getAssignModule(paymentInfo, moduleInfo, studentInfo);
        }
    }

    function getAssignModule(paymentInfo: any, moduleData: any, studentInfo?: any) {
        const user_code = [];
        user_code.push(paymentInfo?.user_code);
        const payload = {
            module_uuid: moduleData.uuid ? moduleData.uuid : '',
            payment_status: "COMPLETED",
            school_code: studentInfo?.institute_school_code,
            target_std: moduleData.target_std,
            total_amount_paid: paymentInfo?.module_price,
            user_codes: user_code
        }
        CREATE_MNS_USER_ASSIGN(payload).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
            } else {
                toast.success("Successfully assigned  module!");
                getModuleData()
                removeItemsFromeLocalStorage();
                setCanShowPopup(false);
            }
        });
    }

    const removeItemsFromeLocalStorage = () => {
        const keysToRemove = ['cc_student_info', 'cc_user_payment_info', 'cc_transaction_id', 'cc_module_info'];
        keysToRemove.forEach((key: any) => {
            localStorage.removeItem(key);
        });
    };
    const handleTestClick = (testType: any) => {
        let preparedTest = testType?.charAt(0).toUpperCase() + testType?.slice(1).toLowerCase();
        localStorage.setItem("select_test", preparedTest)
        setSelectedTest(testType);
        switch (testType) {
            case 'allocated':
                setTestsList(parikshaInfo?.tests_summary?.tests_allocated_data);
                break;
            case 'attempted':
                setTestsList(parikshaInfo?.tests_summary?.tests_attempted_data);
                break;
            case 'passed':
                setTestsList(parikshaInfo?.tests_summary?.tests_passed_data);
                break;
            case 'failed':
                setTestsList(parikshaInfo?.tests_summary?.tests_failed_data);
                break;
            default:
                setTestsList([]);
        }
        setTestLoading(true);
        setTimeout(() => {
            setTestLoading(false);
        }, 1000);
    };

    const calculateTrianglePosition = () => {
        switch (selectedTest) {
            case 'allocated':
                return 'calc(9% - 5px)'; // Adjust this value as needed for your layout
            case 'attempted':
                return 'calc(30.5% - 5px)'; // Adjust this value as needed for your layout
            // Add cases for other test types if needed
            case 'passed':
                return 'calc(51.5% - 5px)';
            case 'failed':
                return 'calc(73.5% - 5px)';
            default:
                return '50%';
        }
    };

    const getTriangleColor = () => {
        // Return the class name for the triangle based on the selected test
        switch (selectedTest) {
            case 'allocated':
                return 'triangle-allocated'; // Use orange color for allocated
            case 'attempted':
                return 'triangle-attempted'; // Use green color for attempted
            // Add cases for other test types if needed
            case 'passed':
                return 'triangle-passed';
            case 'failed':
                return 'triangle-failed';
            default:
                return ''; // Default color or class
        }
    };
    const getBtnColor = () => {
        switch (selectedTest) {
            case 'allocated':
                return '#5233ff';
            case 'attempted':
                return '#66ccff';
            case 'passed':
                return '#52cc99';
            case 'failed':
                return '#ff5033';
            default:
                return '';
        }
    }
    const getDateFormate = (time: any) => {
        if (time) {
            let timeData = moment(time.split("T")[0]).format('DD-MM-yyyy');
            return timeData;
        } else {
            return "";
        }

    }
    return (
        <div>
            <div
                className={`modal fade ${canShowPaymentPopup ? "show" : "false"}`}
                style={{ display: canShowPaymentPopup ? "block" : "none" }}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog pariksha-model-dialog" role="document">
                    <div className="modal-content pariksha-model-content">
                        <div className="modal-body  pariksha-model-info">
                            <div className='pariksha-model-border ba bas-dashed'>
                                <div className=' mt-4 justify-content-center'>
                                    <h6 className='blue_text f16'>You do not have to access to <br />ABHYAAS initiative to solve worksheets </h6>
                                </div>
                                <p className='text-center blue_text fw-normal'>You need to pay  <i className="fa fa-rupee pt-3"></i> {paymentInfo?.total_amount}.00  to access <br /> ABHYAAS initiative </p>
                                <div className='start-test mt-4 justify-content-center'>
                                    <h6>Do you want to continue ? </h6>
                                </div>
                                <div className='mt-4'>
                                    <div className='mt-3 d-flex' style={{ justifyContent: 'center' }}>
                                        <button className='btn test-btn' onClick={handlePayment}>Pay Now</button>
                                        <button className='btn test-btn' onClick={onClickCancel}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={`modal fade ${canShowPopup ? "show" : "false"}`}
                style={{ display: canShowPopup ? "block" : "none" }}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog pariksha-model-dialog" role="document">
                    <div className="modal-content pariksha-model-content">
                        <div className="modal-body  pariksha-model-info">
                            <div className='pariksha-model-border'>
                                <div className='mt-3'>
                                    <h5 className='blue_text f18'>To solve the worksheet of {selectWorksheetInfo?.test_details?.name ? selectWorksheetInfo?.test_details?.name : selectWorksheetInfo?.test_details?.exam_type?.split('_')
                                        .map((word: any) => word?.charAt(0).toUpperCase() + word?.slice(1).toLowerCase())
                                        .join(' ')}</h5>
                                </div>
                                <div className='mt-2 blue_text'>
                                    <h5>You will be redirected to ABHYAAS </h5>
                                </div>

                                <div className='mt-4 mb-3 blue_text '>
                                    <h5>Do you want to continue?</h5>
                                    <div className='mt-3'>
                                        <button className='btn test-btn' onClick={onClickSave}>YES</button>
                                        <button className='btn test-btn' onClick={onClickCancel}>NO</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb pb-2 ps-0 pt-0 border-bottom">
                            <li className="breadcrumb-item "><Link to="/student-dashboard/list"><span className='pe-2'>Dashboard</span></Link></li> /
                            <li className="breadcrumb-item "><Link to={`/student-dashboard/list/pariksha/test-initiative/${moduleUUID}/${userCode}/${schoolCode}`}><span>Pariksha</span></Link></li> /
                            <li className="breadcrumb-item "><span>Result Summary</span></li> /
                            <li className="breadcrumb-item "><span>{subjectData?.name?.charAt(0).toUpperCase() + subjectData?.name?.slice(1).toLowerCase()}</span></li>
                        </ol>
                    </nav>
                    <div className='text-end me-5 mb-1'>
                        <button className="btn btn-primary btn-sm px-5 py-1 rounded-12 cursor-pointer" onClick={() => history.goBack()}>Back</button>
                    </div>
                    <h5 className='text-center mb-5'>Result Summary - {subjectData?.name?.toUpperCase()}</h5>
                    {/* //// */}
                    {/* <div className='row  ms-4 ms-md-0 justify-content-center position-relative'>
                        <div className='co-12 col-md-10'>
                            <div className='prx_sm ms-5 ms-md-0'>
                                {testDataList?.map((item: any, i: number) => (
                                    <div className={`position-relative   ${selectedTest === `${item?.nameCode}` ? 'selected' : ''}`} onClick={() => handleTestClick(item?.nameCode)} key={i}>
                                        <div className='d-flex  cursor-pointer position-relative'>
                                            <div className='position-absolute' style={{ left: "-41px", bottom: "-17px" }}>
                                                <img src={item?.testImg} className="test_summary_mg " alt="average area" ></img>
                                            </div>
                                            <div className='all_bx'>
                                                <p className='mb-1'> {parikshaInfo?.tests_summary?.tests_allocated}</p>
                                                <p className='mb-0'>{item?.name}</p>
                                            </div>
                                        </div>
                                        {selectedTest === item?.nameCode &&
                                            <div className='position-absolute z_100' style={{ top: "66px" }}>
                                                <div className={` triangle ${getTriangleColor()}`} style={{ left: "2px" }}>
                                                </div>
                                                <div className='row '>
                                                    <div className='col-12'>
                                                        <div className='border border-2 mt-2 px-3 me-2 bg-white w-100'>
                                                            <div className='text-center mt-5'><button className='test_sm_btn' style={{ backgroundColor: getBtnColor() }}>{selectedTest === 'allocated' && " Allocated Tests"} {selectedTest === 'attempted' && " Attempted Tests"} {selectedTest === 'passed' && "Passed Tests"} {selectedTest === 'failed' && " Failed Tests"}</button></div>
                                                            <div className='d-flex justify-content-between mt-1'>
                                                                <h5></h5>
                                                                <div className='mb-1 d-flex me-3'>
                                                                    <h6 className='me-3 mt-md-2 fw-normal'>Row(s) per Page</h6>
                                                                    <div>
                                                                        <select className="my-2 f16 cursor-pointer" onChange={(e) => handleRows(e.target.value)}>
                                                                            <option value="10">10</option>
                                                                            <option value="20">20</option>
                                                                            <option value="50">50</option>
                                                                            <option value="70">70</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {testLoading &&
                                                                <div className="text-center p-5">
                                                                    <div className="spinner-border" role="status">
                                                                        <span className="sr-only">Loading...</span>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {!testLoading && <div className="mb-4">
                                                                <div className='table-responsive mar-t-20 custom-scroll  f14 '>
                                                                    <table className='table test_fixed-table-header mb-0'>
                                                                        <thead>
                                                                            <tr>
                                                                                <th style={{ width: "50px" }}>#</th>
                                                                                <th >Test Name</th>
                                                                                <th style={{ width: '100px' }} >Date</th>
                                                                                {selectedTest === "attempted" && <th style={{ width: '70px' }}>Allocated By</th>}
                                                                                <th style={{ width: '80px' }} className='text-center'> Questions</th>
                                                                                <th style={{ width: '80px' }} className='text-center'> Attempted</th>
                                                                                <th style={{ width: '90px' }} className='text-center'>Correct</th>
                                                                                <th style={{ width: '90px' }} className='text-center'>Wrong</th>
                                                                                <th style={{ width: '90px' }} className='text-center'>Score (%)</th>
                                                                                <th style={{ width: '150px' }}>Recommendation</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {testsList?.map((item: any, i: number) => {
                                                                                return (<>
                                                                                    <tr key={i} className='border-bottom align-middle'>
                                                                                        <td>{i + 1}</td>
                                                                                        <td><span className='active cursor-pointer f16' onClick={() => showResults(item)}>{item?.test_details?.exam_type?.split('_')
                                                                                            .map((word: any) => word?.charAt(0).toUpperCase() + word?.slice(1).toLowerCase())
                                                                                            .join(' ')}  {item?.test_details?.exam_type === "CHAPTER_WISE_TEST" && <span className='mb-0 cursor-pointer f14 ms-1' >({item?.topic_wise_result[0]?.chapter_name})</span>}</span> </td>
                                                                                        <td>{getDateFormate(item?.started_dt)}</td>
                                                                                        {selectedTest === "attempted" && <td>Self</td>}
                                                                                        <td className='text-center'>{item?.result?.total_questions}</td>
                                                                                        <td className='text-center'>{item?.result?.attempted}</td>
                                                                                        <td className='text-center'>{item?.result?.correct}</td>
                                                                                        <td className='text-center'>{item?.result?.wrong}</td>
                                                                                        <td className='text-center'>{item?.result?.percentage?.toFixed(0)}</td>
                                                                                        <td>{item?.status === "pending" ? <span className='active cursor-pointer' onClick={() => solveWorksheet(item)}> Solve the Worksheet </span> : <span>Complete</span>}</td>
                                                                                    </tr>
                                                                                </>
                                                                                )
                                                                            })}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                {testsList?.length == 0 && <p className='text-center f16 mt-2'>No Data Available</p>}
                                                            </div>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div> */}



                    {/* //// */}
                    <div className='row  ms-2 justify-content-center'>
                        <div className='co-8  col-md-10 '>
                            <div className='prx_sm ms-5 ms-lg-0'>
                                <div className={` test-summary ${selectedTest === 'allocated' ? 'selected' : ''}`} onClick={() => handleTestClick('allocated')}>
                                    <div className='d-flex position-relative cursor-pointer'>
                                        <div className='position-absolute' style={{ left: "-41px" }}>
                                            <img src={TESTALLOCATED} className="test_summary_mg " alt="average area" ></img>
                                        </div>
                                        <div className='all_bx'>
                                            <p className='mb-1'> {parikshaInfo?.tests_summary?.tests_allocated}</p>
                                            <p className='mb-0'>Allocated Tests</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={` test-summary ${selectedTest === 'attempted' ? 'selected' : ''}`} onClick={() => handleTestClick('attempted')}>
                                    <div className='d-flex position-relative cursor-pointer'>
                                        <div className='position-absolute' style={{ left: "-41px" }}>
                                            <img src={TESTATTEMPTED} className="test_summary_mg " alt="average area" ></img>
                                        </div>
                                        <div className='all_bx'>
                                            <p className='mb-1'> {parikshaInfo?.tests_summary?.tests_attempted}</p>
                                            <p className='mb-0'>Attempted Tests</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={` test-summary ${selectedTest === 'passed' ? 'selected' : ''}`} onClick={() => handleTestClick('passed')}>
                                    <div className='d-flex position-relative cursor-pointer'>
                                        <div className='position-absolute' style={{ left: "-41px" }}>
                                            <img src={TESTPASSED} className="test_summary_mg " alt="average area" ></img>
                                        </div>
                                        <div className='all_bx'>
                                            <p className='mb-1'> {parikshaInfo?.tests_summary?.tests_passed}</p>
                                            <p className='mb-0'>Passed Tests</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={` test-summary ${selectedTest === 'failed' ? 'selected' : ''}`} onClick={() => handleTestClick('failed')}>
                                    <div className='d-flex position-relative cursor-pointer'>
                                        <div className='position-absolute' style={{ left: "-41px" }}>
                                            <img src={TESTFAILED} className="test_summary_mg " alt="average area" ></img>
                                        </div>
                                        <div className='all_bx'>
                                            <p className='mb-1'> {parikshaInfo?.tests_summary?.tests_failed}</p>
                                            <p className='mb-0'>Failed Tests</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {selectedTest && (<div className='col-12'>
                            <div className={`d-none d-lg-block triangle ${getTriangleColor()}`} style={{ left: calculateTrianglePosition() }}></div>
                            <div className='border border-2 mt-lg-2 mt-3 px-3 me-2'>
                                <div className='text-center mt-5'><button className='test_sm_btn' style={{ backgroundColor: getBtnColor() }}>{selectedTest === 'allocated' && " Allocated Tests"} {selectedTest === 'attempted' && " Attempted Tests"} {selectedTest === 'passed' && "Passed Tests"} {selectedTest === 'failed' && " Failed Tests"}</button></div>
                                <div className='d-flex justify-content-between mt-1'>
                                    <h5></h5>
                                    <div className='mb-1 d-flex me-3'>
                                        <h6 className='me-3 mt-md-2 fw-normal'>Row(s) per Page</h6>
                                        <div>
                                            <select className="my-2 f16 cursor-pointer" onChange={(e) => handleRows(e.target.value)}>
                                                <option value="10">10</option>
                                                <option value="20">20</option>
                                                <option value="50">50</option>
                                                <option value="70">70</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                {testLoading &&
                                    <div className="text-center p-5">
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                }
                                {!testLoading && <div className="mb-4">
                                    <div className='table-responsive mar-t-20 custom-scroll  f14 '>
                                        <table className='table test_fixed-table-header mb-0'>
                                            <thead>
                                                <tr>
                                                    <th style={{ width: "50px" }}>#</th>
                                                    <th >Test Name</th>
                                                    <th style={{ width: '100px' }} >Date</th>
                                                    {selectedTest === "attempted" && <th style={{ width: '70px' }}>Allocated By</th>}
                                                    <th style={{ width: '80px' }} className='text-center'> Questions</th>
                                                    <th style={{ width: '80px' }} className='text-center'> Attempted</th>
                                                    <th style={{ width: '90px' }} className='text-center'>Correct</th>
                                                    <th style={{ width: '90px' }} className='text-center'>Wrong</th>
                                                    <th style={{ width: '90px' }} className='text-center'>Score (%)</th>
                                                    <th style={{ width: '150px' }}>Recommendation</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {testsList?.map((item: any, i: number) => {
                                                    return (<>
                                                        <tr key={i} className='border-bottom align-middle'>
                                                            <td>{i + 1}</td>
                                                            <td><span className='active cursor-pointer f16' onClick={() => showResults(item)}>{item?.test_details?.exam_type?.split('_')
                                                                .map((word: any) => word?.charAt(0).toUpperCase() + word?.slice(1).toLowerCase())
                                                                .join(' ')}  {item?.test_details?.exam_type === "CHAPTER_WISE_TEST" && <span className='mb-0 cursor-pointer f14 ms-1' >({item?.topic_wise_result[0]?.chapter_name})</span>}</span> </td>
                                                            <td>{getDateFormate(item?.started_dt)}</td>
                                                            {selectedTest === "attempted" && <td>Self</td>}
                                                            <td className='text-center'>{item?.result?.total_questions}</td>
                                                            <td className='text-center'>{item?.result?.attempted}</td>
                                                            <td className='text-center'>{item?.result?.correct}</td>
                                                            <td className='text-center'>{item?.result?.wrong}</td>
                                                            <td className='text-center'>{item?.result?.percentage?.toFixed(0)}</td>
                                                            <td>{item?.status === "pending" ? <span className='active cursor-pointer' onClick={() => solveWorksheet(item)}> Solve the Worksheet </span> : <span>Complete</span>}</td>
                                                        </tr>
                                                    </>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    {testsList?.length == 0 && <p className='text-center f16 mt-2'>No Data Available</p>}
                                </div>}
                            </div>
                        </div>)}
                    </div>
                    {!selectedTest && <div className='text-center mt-5'>
                        <img src={TESTEXAMTIME} className="timer_gf " alt="average area" ></img>
                    </div>}

                    <div className='row '>
                        <div className=' col-12 align-self-end '>

                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

