import React, { useEffect, useState } from "react";
import FreeTrialSchoolWise from "../../../../components/free-trial/school-wise";
import { useHistory, useParams } from "react-router-dom";

const SchooldashboardFreeTrial = () => {
    const [loading, setLoading] = useState(false);

    let { id, schoolCode } = useParams<{ id: string, schoolCode: string, }>();
    const schoolId = Number(id);
    const schoolsCode = schoolCode;
    const history = useHistory();


    useEffect(() => {

    }, []);
    const assignTrial = () => {
        history.push(`/school-dashboard/profile/students/lis/${schoolId}/${schoolsCode}`)
    }
    return (
        <div>{loading &&
            <div className="text-center p-5">
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        }
            {!loading &&
                <FreeTrialSchoolWise setLoading={setLoading} schoolId={schoolId} schoolsCode={schoolsCode}
                    assignTrial={assignTrial} />}
        </div>
    )

}
export default SchooldashboardFreeTrial;