import { DataTableCol } from "../../../../components/data-table/types";

export const StudentsDataGridCols: DataTableCol[] = [
  {
    title: "Student Name",
    // control: 'name',
    control: "school_student",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "User Name",
    control: "user_name",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Email",
    control: "email_id",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Mobile Number",
    control: "mobile_number",
    sortable: true,
    canShowColumn: true,
  },

  {
    title: "Standard",
    control: "standard",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Division",
    control: "division",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Account Type",
    control: "account_type",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Modules",
    control: "show_cards",
    canShowColumn: true,
  },
  {
    title: "Actions",
    control: "both",
    isEdit: false,
    isDelete: false,
    canShowColumn: false,
    resetProgress: false,
    setPassword: false,
    // control: 'one'
  },
];
