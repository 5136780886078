import API from "../utility/axios";
const apiUrl = "/api/v1";
const mlaUrl = "/api/v2";

export const GET_MNS_MODULE_DETAILS = (): Promise<any> => {
  return API.get(`${apiUrl}/module?module_code=${"MNS"}`);
};

export const GET_MLA_DETAILS = (): Promise<any> => {
  return API.get(`${mlaUrl}/module/mla/one/details`);
};

export const GET_MLA_INFO = (data: any): Promise<any> => {
  return API.post(`${mlaUrl}/module/mla/one/school/user/questions/list`, data);
};

export const GET_MLA_QUESTIONS = (data: any): Promise<any> => {
  return API.post(`${mlaUrl}/module/mla/one/fetch/paper`, data);
};

export const MLA_ANSWERS_UPDATE = (data: any): Promise<any> => {
  return API.post(`${mlaUrl}/module/mla/one/school/user/answers/update`, data);
};

export const MLA_TIME_UPDATE = (data: any): Promise<any> => {
  return API.post(
    `${mlaUrl}/module/mla/one/school/user/tests/start/time/update`,
    data
  );
};

export const ABILITY_UPDATE = (data: any): Promise<any> => {
  return API.post(`${mlaUrl}/module/mla/one/upload/config`, data);
};

export const GET_MLA_ONE_TEST_DETAILS = (): Promise<any> => {
  return API.get(`${mlaUrl}/module/mla/one/details?tests=yes`);
};

export const GET_MLA_ONE_WORKSHEETS = (code: any): Promise<any> => {
  return API.get(`${mlaUrl}/module/mla/one/details?ability=${code}`);
};

export const GET_MLA_ONE_QUESTIONS_BY_TEST_NUMBER = (
  number: any
): Promise<any> => {
  return API.get(`${mlaUrl}/module/mla/one/details?test_no=${number}`);
};

export const MLA_ONE_UPDATE_ABILITY_BY_ID = (
  Id: number,
  data: any
): Promise<any> => {
  return API.put(`${mlaUrl}/module/mla/one/update/ability/${Id}`, data);
};
