import React, { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FormControlError, FormField, FormValidators } from '../../../../components/form-builder/model/form-field';
import { FormValidator, GetControlIsValid, GetEmailControlIsValid, GetMobileControlIsValid } from '../../../../components/form-builder/validations';
import { CREATE_INDIVIDUAL_STUDENT, GET_USER_BY_ID, UPDATE_USER } from '../../../../app/service/users.service';
import { CREATE_INDIVIDUAL_STUDENT_PARENT, CREATE_SCHOOL_USER, GET_SCHOOL_BY_SCHOOL_CODE, UPLOAD_LOGO } from '../../../../app/service/school.service';
import FormBuilder from '../../../../components/form-builder';
import errorMessages from '../../../../components/error-messages';
import appConfig from '../../../../config/constant';
import { DateComponent } from '../../../../components/date';
import ProfileImageCrop from '../../../../components/image-crop/crop';

export const ParentForm = () => {
  const [parentsData, setParentsData] = useState<any>({});
  const [formData, setFormData] = useState<any>({});
  const [validationErrors, setValidationErrors] = useState<FormControlError[]>([]);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [currentParentsData, setCurrentParentsData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [parentLogo, setParentLogo] = useState<any>();
  const [imageLoader, setImageLoader] = useState(false);
  const [mobileNumber, setMobileNumber] = useState(false);
  const [whatsappNumber, setWhatsappNumber] = useState(false);
  const [parentInfo, setParentInfo] = useState<any>({});
  const [canShowPopup, setCanShowPopup] = useState(false);
  const [min, setMin] = useState<any>(moment().subtract(60, "years").format("YYYY-MM-DD"));
  const [max, setMax] = useState<any>(moment().subtract(18, "years").format("YYYY-MM-DD"));
  const [checkedStatus, setCheckedStatus] = useState<any>();
  const [email, setEmail] = useState(false);
  const [canCheckMobileNumber, setCanCheckMobileNumber] = useState(false);
  const [canShowWhatsAppError, setCanShowWhatsAppError] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [isDateError, setIsDateError] = useState<any>({});
  const [checkedUserMechanismStatus, setCheckedUserMechanismStatus] = useState<any>();
  let { id, userCode, schoolName, parentId } = useParams<{ id: string, userCode: string, schoolName: string, parentId: string }>();
  const studentId = parseInt(id);
  const usersCode = userCode;
  const parentsId = parseInt(parentId);
  const schoolsName = schoolName;
  const history = useHistory();
  const [nameError, setNameError] = useState('');
  const [schoolInfo, setSchoolInfo] = useState<any>({});

  const formValidations = [
    new FormField('name', [FormValidators.REQUIRED]),
    new FormField('gender', [FormValidators.REQUIRED]),
    new FormField('date_of_birth', []),
    new FormField('blood_group', []),
    new FormField('city_village', []),
    new FormField('email_id', [FormValidators.REQUIRED]),
    new FormField('mobile_number', [FormValidators.REQUIRED]),
    new FormField('whatsapp_number', []),
  ];

  useEffect(() => {
    if (parentsId > 0) {
      setLoading(true);
      GET_USER_BY_ID(parentsId).then((res: any) => {
        if (res.status === 'fail') {
          toast.error(res.message);
          setLoading(false);
        } else {
          setLoading(false);
          const data = res.rows[0];
          if (data.date_of_birth) {
            // data.date_of_birth = data.date_of_birth.split(" ")[0];
            const date_of_birth: any = new Date(data.date_of_birth);
            setStartDate(date_of_birth);
          }
          if (data.mobile_number === data.whatsapp_number) {
            data.checked = true;
          }
          if (data.access) {
            setCheckedUserMechanismStatus(data.access);
          }
          setParentLogo(data.profile_img);
          setCurrentParentsData(data);
        }
      });
    } else {
      let schoolCode = schoolName
      GET_SCHOOL_BY_SCHOOL_CODE(schoolCode).then((res: any) => {
        if (res.status === "fail") {
          toast.error(res.message);
          setLoading(false);
        } else {
          if (res) {
            const data = res.rows[0];
            setSchoolInfo(data);

          }
        }
      });
    }

  }, []);

  const handleInput = (data: any) => {
    data.value = { ...currentParentsData, ...data.value };
    setParentsData(data);
    const errors: any = FormValidator(formValidations, data.value);
    setValidationErrors(errors);
  };

  const handleSubmit = () => {
    setIsFormSubmitted(true);
    const data = { ...parentsData.value };
    const errors: FormControlError[] = FormValidator(formValidations, data);
    setValidationErrors(errors);
    data.user_role = "PARENT";
    data.institute_school_code = schoolsName;
    data.student_code = usersCode;
    data.profile_img = parentLogo;
    // data.cas_report_view = checkedStatus;
    data.cas_report_view = 1;
    data.date_of_birth = startDate ? moment(startDate).format('YYYY-MM-DD') : null;
    data.type = "individual";
    data.school_name = schoolInfo?.name_of_school;
    delete data.sameMobileNumber;
    delete data.file;
    delete data.checked;
    delete data.day;
    delete data.month;
    delete data.year;
    delete data[''];
    // if ((canShowWhatsAppError && canCheckMobileNumber) || (!canShowWhatsAppError && canCheckMobileNumber) || data.whatsapp_number) {
    if (errors.length < 1 && !email && !mobileNumber && onChangeName(data.name)) {
      data.whatsapp_number = data.whatsapp_number ? data.whatsapp_number : data.mobile_number;
      if (data.date_of_birth !== null) {
        // CREATE_SCHOOL_USER(data).then((res: any) => {
        CREATE_INDIVIDUAL_STUDENT_PARENT(data).then((res: any) => {
          if (res.status === 'fail') {
            toast.error(res.message);
            setLoading(false);
            setCurrentParentsData(data);
          } else {
            toast.success("Parent Added Successfully");
            history.push(`/dashboard/individual-student/info/${studentId}/parentlist/${usersCode}/${schoolsName}`);
            setLoading(false);
            setParentInfo(res);
            setCanShowPopup(true);
          }
        });
      }
    }
    // }
    else {
      // if (!data.whatsapp_number) {
      //   setCanShowWhatsAppError(true);
      // }
      if (!nameError && !onChangeName(data.name)) {
        setNameError('Please enter name');
      }
    }
  }

  const getInputValid = (control: string) => {
    const value = GetControlIsValid(validationErrors, control);
    return value;
  }

  const getEmailValid = (control: string) => {
    const data = { ...parentsData.value };
    const value = GetEmailControlIsValid(validationErrors, control, data);
    return value;
  }


  const getMobileValid = (control: string) => {
    const data = { ...parentsData.value };
    const value = GetMobileControlIsValid(validationErrors, control, data);
    return value;
  }

  function updateParent() {
    setIsFormSubmitted(true);

    const data = parentsData.value ? { ...parentsData.value } : { ...currentParentsData };
    data.date_of_birth = startDate ? moment(startDate).format('YYYY-MM-DD') : null;
    const errors: FormControlError[] = FormValidator(formValidations, data);
    setValidationErrors(errors);
    data.user_role = "PARENT";
    data.institute_school_code = schoolsName;
    data.id = parentsId;
    data.student_code = usersCode;
    data.profile_img = parentLogo;
    // data.cas_report_view = checkedStatus;
    data.cas_report_view = 1;
    delete data.sameMobileNumber;
    delete data.file;
    delete data.checked;
    delete data.user_code;
    delete data.teaching_standards;
    delete data.teaching_subjects;
    delete data.address2;
    delete data.status;
    delete data.guardian;
    delete data.occupation;
    delete data.post_in;
    delete data.active;
    delete data.cas_report_view;
    delete data.indv_school_details;
    delete data.created_at;
    delete data.updated_at;
    delete data.role_name;
    delete data.user_name;
    delete data.short_name;
    delete data.name_of_school;
    delete data.name_of_organization;
    delete data.display_name;
    delete data.address;
    delete data.state;
    delete data.taluka;
    delete data.pin_code;
    delete data.district;
    delete data.standard;
    delete data.division;
    delete data.date_of_registration;
    delete data.day;
    delete data.month;
    delete data.year;
    delete data[''];
    delete data.access;
    data.whatsapp_number = currentParentsData.whatsapp_number;
    if (errors.length < 1 && !email && !mobileNumber && onChangeName(data.name)) {        // if (errors.length < 1) {
      data.access = checkedUserMechanismStatus;
      UPDATE_USER(data).then((res: any) => {
        if (res.status === 'fail') {
          toast.error(res.message);
          setLoading(false);
        } else {
          setIsFormSubmitted(false);
          toast.success("Parent Updated Successfully");
          setLoading(false);
          history.push(`/dashboard/individual-student/info/${studentId}/parentlist/${usersCode}/${schoolsName}`);
        }
      });
    } else {

      if (!nameError && !onChangeName(data.name)) {
        setNameError('Please enter name');
      }
    }
  }

  const handleGender = (e: any) => {
    const data = { ...parentsData.value };
    data.gender = e.target.value;
    if (currentParentsData) {
      currentParentsData.gender = e.target.value;
    }
  }

  function handleUploadLogo(e: any, type: string) {
    if (e.target.files && e.target.files[0]) {
      const fileType = e.target.files[0].name.split('.').pop()
      if (fileType == "jpeg" || fileType == "jpg") {
        const formData = new FormData();
        formData.append('file', e.target.files[0], e.target.files[0].name);
        uploadLogo(formData, type);
      } else {
        toast.error("Please select image file only");
      }
    }
  }

  function uploadLogo(formdata: any, type: string) {
    setImageLoader(true);
    UPLOAD_LOGO(formdata).then((res: any) => {
      if (res.status === 'fail') {
        toast.error(res.message);
        setImageLoader(false);
      } else {
        setParentLogo(res.url);
        setImageLoader(false);
      }
    });
  }

  const handleBloodGroupChange = (e: any) => {
    const data = { ...parentsData.value };
    data.blood_group = e.target.value;
    if (currentParentsData) {
      currentParentsData.blood_group = e.target.value;
    }
  }

  const handleMobileChange = (e: any) => {
    const data = { ...parentsData.value };
    const re = /(6|7|8|9)\d{9}/;

    if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length === 10) {
      data.mobile_number = e.target.value;
      setMobileNumber(false);
    } else {
      data.mobile_number = e.target.value;
      setMobileNumber(true);
    }

    if (currentParentsData) {
      currentParentsData.mobile_number = e.target.value.replace(/\D+/g, '');
    }

    if (currentParentsData.checked) {
      currentParentsData.whatsapp_number = e.target.value.replace(/\D+/g, '');
    }
  }

  const handleWhatsappChange = (e: any) => {
    const data = { ...parentsData.value };
    const re = /(6|7|8|9)\d{9}/;

    if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length === 10) {
      data.whatsapp_number = e.target.value;
      setWhatsappNumber(false);
      setCanShowWhatsAppError(false);
    } else {
      data.whatsapp_number = e.target.value;
      setWhatsappNumber(true);
    }

    if (currentParentsData) {
      currentParentsData.whatsapp_number = e.target.value.replace(/\D+/g, '');
    }
  }

  const handleclose = () => {
    setCanShowPopup(false);
    // history.push(`/dashboard/individual-student/info/${studentId}/parentlist/${usersCode}/${schoolsName}`);
  }

  const handleSave = () => {
    setCanShowPopup(false);
    history.push(`/dashboard/individual-student/info/${studentId}/parentlist/${usersCode}/${schoolsName}`);
  }

  const handleReportStatusChange = (e: any) => {
    const status = e.target.checked === true ? 1 : 0;
    setCheckedStatus(status);
    if (currentParentsData) {
      const status = e.target.checked === true ? 1 : 0;
      currentParentsData.cas_report_view = e.target.checked === true ? 1 : 0;
      setCheckedStatus(status);
    }
  }

  const handleEmailChange = (e: any) => {
    const data = { ...parentsData.value };
    const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      data.email_id = e.target.value;
      setEmail(false);
    } else {
      data.email_id = e.target.value;
      setEmail(true);
    }
    if (currentParentsData) {
      currentParentsData.email_id = e.target.value;
    }
  }

  const handleMobileNumberChange = (e: any) => {
    const data = { ...parentsData.value };
    if (e.target.checked) {
      if (data.whatsapp_number) {
        data.whatsapp_number = data.mobile_number;
        currentParentsData.whatsapp_number = data.mobile_number;
        setCanCheckMobileNumber(true);
        setCanShowWhatsAppError(false);
      }
      if (data.mobile_number || currentParentsData.mobile_number) {
        setCanCheckMobileNumber(true);
        setCanShowWhatsAppError(false);
        currentParentsData.whatsapp_number = currentParentsData.mobile_number;
        setCurrentParentsData(currentParentsData);
      }
      currentParentsData.checked = true;
    } else {

      setCanCheckMobileNumber(false);
      currentParentsData.checked = false;
    }
  }

  const onChange = (dates: any) => {
    setStartDate(null);
    if (dates.error) {
      setIsDateError(dates);
    } else {
      setStartDate(dates.date);
    }
  }

  const onChangeName = (event: any) => {
    setNameError('');
    const reg = /^[A-Za-z]+(?:[ _-][A-Za-z]+)*$/;
    if (event) {
      if (!reg.test(event.trim())) {
        setNameError('Enter only alphabets');
      } else {
        return true;
      }
    } else {
      setNameError('Please enter name');
    }
  }

  const handleUserMechanism = (e: any) => {
    if (currentParentsData) {
      const status = e.target.checked === true ? "ENABLED" : "DISABLED";
      currentParentsData.access = status;
      setCheckedUserMechanismStatus(status);
    }
  }

  return (
    <div>
      <div className={`modal fade ${canShowPopup ? 'show' : 'false'}`} style={{ display: (canShowPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Parent Login Details</h5>
              <button type="button" className="close popup-close cursor-pointer" data-dismiss="modal" aria-label="Close" onClick={handleclose}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div>Login ID: {parentInfo.user_name}</div>
              <div>Password: {parentInfo.password}</div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleclose}>Close</button>
            </div>
          </div>
        </div>
      </div>
      {loading &&
        <div className="text-center p-5">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      }
      {!loading && <div>
        <FormBuilder onUpdate={handleInput}>
          <form>
            <div style={{ borderBottom: 'solid 1px black', marginTop: '15px', paddingLeft: '10px' }} className="mb-4">
              <h5 className="form-label mb-2 d-block">Personal Details</h5>
            </div>
            <div className="row custom-form">
              <div className="col-md-4">
                <div className="mb-4">
                  <label className="form-label mb-0"> Mrs./ Mr
                    <span style={{ paddingLeft: '5px' }}>Name</span></label>
                  <span style={{ color: 'red', fontSize: '22px', paddingLeft: '5px' }}>*</span>
                  <input className="form-control form-control-lg" type="text" name="name" onChange={(e) => onChangeName(e.target.value)} defaultValue={currentParentsData.name} placeholder="Please enter name" />
                  {nameError && <p className="text-danger">{nameError}</p>}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-4">
                  <label className="form-label mb-4">Gender</label>
                  <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                  <br />
                  <input type="radio" name="gender" value="male" checked={currentParentsData?.gender === "male"} onChange={(e) => { handleGender(e) }} />
                  <span style={{ margin: '0px 12px' }}>Male</span>
                  <input type="radio" name="gender" value="female" checked={currentParentsData?.gender === "female"} onChange={(e) => { handleGender(e) }} />
                  <span style={{ margin: '0px 12px' }}>Female</span>
                  <input className="mb-0" type="radio" value="transgender" name="gender" checked={currentParentsData?.gender === "transgender"} onChange={(e) => { handleGender(e) }} />
                  <span style={{ paddingRight: '15px', paddingLeft: '15px' }}>Other</span>
                  {isFormSubmitted && !getInputValid('gender') && <p className="text-danger">Please select gender</p>}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-4">
                  <label className="form-label mb-0">Date of Birth</label>
                  <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                  {currentParentsData.date_of_birth &&
                    <DateComponent onDate={onChange} yearDiff={18} defaultData={currentParentsData.date_of_birth}></DateComponent>}
                  {!currentParentsData.date_of_birth && <DateComponent onDate={onChange} yearDiff={18}></DateComponent>}
                  {isFormSubmitted && !startDate && !isDateError?.error && <p className="text-danger">Please select date</p>}
                  {isDateError?.error && !startDate && <p className="text-danger">{isDateError?.error}</p>}
                </div>
              </div>

              <div className="col-md-4">
                <div className="mb-4">
                  <label className="form-label mb-0">Blood Group</label>
                  {/* <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span> */}
                  <select name="blood_group" value={currentParentsData.blood_group} onChange={(e) => { handleBloodGroupChange(e) }} className="form-select form-select-lg mb-3">
                    <option value="">Select blood group</option>
                    <option value="O+">O+</option>
                    <option value="O-">O-</option>
                    <option value="A-">A-</option>
                    <option value="A+">A+</option>
                    <option value="B-">B-</option>
                    <option value="B+">B+</option>
                    <option value="AB-">AB-</option>
                    <option value="AB+">AB+</option>
                  </select>
                  {/* {isFormSubmitted && !getInputValid('blood_group') && <p className="text-danger">Please select blood group</p>} */}
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="mb-4 d-flex justify-content-center">
                  <ProfileImageCrop uploadLogo={uploadLogo} profileImg={parentLogo} imageLoader={imageLoader} profileImageDelete={setParentLogo} />
                </div>
              </div>
              {parentsId > 0 && <div className="col-md-4">
                <div className="mb-4">
                  <label className="form-label">User Access</label><br />
                  <label className="switch">
                    <input type="checkbox" name="access" value={currentParentsData?.access} checked={currentParentsData?.access === "ENABLED"} onChange={(e) => { handleUserMechanism(e) }} />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>}

              <div style={{ borderBottom: 'solid 1px black' }} className="mb-4">
                <h5 className="form-label mb-2 d-block">Contact Details</h5>
              </div>
              <div className="col-md-4">
                <div className="mb-4">
                  <label className="form-label mb-0">Email ID</label>
                  <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                  <input className="form-control form-control-lg" type="text" name="email_id" value={currentParentsData.email_id} onChange={(e) => { handleEmailChange(e) }} placeholder="Please enter email" />
                  {email && <p className="text-danger">{errorMessages.email}</p>}
                  {isFormSubmitted && !getInputValid('email_id') && <p className="text-danger">Please enter email</p>}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-4">
                  <label className="form-label mb-0">Mobile Number</label>
                  <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                  <input className="form-control form-control-lg" type="text" name="mobile_number" maxLength={10} onChange={(e) => { handleMobileChange(e) }} value={currentParentsData.mobile_number} placeholder="Please enter mobile number" />
                  {mobileNumber && <p className="text-danger">{errorMessages.mobile_number}</p>}
                  {isFormSubmitted && !getInputValid('mobile_number') && <p className="text-danger">Please enter mobile number</p>}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-4">
                  <label className="form-label mb-0">WhatsApp Number</label>
                  {/* <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span> */}
                  (  <input type="checkbox" name="sameMobileNumber" checked={currentParentsData.checked} onChange={(e) => { handleMobileNumberChange(e) }} style={{ paddingLeft: '10px' }} />
                  <span style={{ paddingLeft: '10px' }}>Same as Mobile Number</span> )
                  <input className="form-control form-control-lg" disabled={currentParentsData.checked} type="text" name="whatsapp_number" maxLength={10} onChange={(e) => { handleWhatsappChange(e) }} value={currentParentsData.whatsapp_number} placeholder="Please enter whatsapp number" />
                  {(whatsappNumber) && <p className="text-danger">{errorMessages.whatsapp_number}</p>}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-4">
                  <label className="form-label mb-0">City/Village (Optional)</label>
                  <input className="form-control form-control-lg" type="text" name="city_village" defaultValue={currentParentsData.city_village} placeholder="Please enter city/village" />
                </div>
              </div>

            </div>
          </form>
        </FormBuilder>
        <div className="form-footer border-top border-primary py-3 text-end mt-4">
          {parentsId === 0 && <a className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer" onClick={handleSubmit}>Create</a>}
          {parentsId > 0 && <a className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer" onClick={() => updateParent()}>Update</a>}
          <Link className="text-dark text-decoration-none ms-2 cursor-pointer" to={`/dashboard/individual-student/info/${studentId}/parentlist/${usersCode}/${schoolsName}`}>Cancel</Link>
        </div>
      </div>}
    </div>
  )
}