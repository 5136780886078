import React, { useEffect, useState } from 'react'
import useRazorpay, { RazorpayOptions } from 'react-razorpay';
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify';
import { CREATE_MNS_USER_ASSIGN } from '../../../../app/service/mns-service';
import { GET_MODULES_BY_SCHOOL_ID, GET_MODULES_BY_USER_CODES } from '../../../../app/service/module.service';
import { CREATE_PAYMENT, CREATE_PAYMENT_DETAILS_SCHOOL_MODULE_CODE, UPDATE_PAYMENT } from '../../../../app/service/payment-service';
import { ADD_UPDATE_SUBSCRIPTION_PRICE, GET_SUBSCRIPTION_PRICE_STATUS_BY_STUDENT } from '../../../../app/service/subscription.service';
import { GET_USER_USER_CODE } from '../../../../app/service/users.service';
import appConfig, { UI_BASE_URL } from '../../../../config/constant';
import { CREATE_CCAVENUE_PAYMENT } from '../../../../app/service/ccavenue.service';

export const IndividualStudentModulesList = () => {
    const Razorpay = useRazorpay();
    const [modulesData, setModulesData] = useState<any>([]);
    const [transactionId, SettransactionId] = useState<any>("");
    const [moduleName, setModuleName] = useState<any>("");
    const [amount, setAmount] = useState<any>("");
    const [loading, setLoading] = useState(false);
    const [paymentDetailsLoading, setPaymentDetailsLoading] = useState(false);
    const [userType, setUserType] = useState<any>();
    const [subscriptionInfo, setSubscriptionInfo] = useState<any>({});
    const [moduleInfo, setModuleInfo] = useState<any>({});
    const [canShowPopup, setCanShowPopup] = useState(false);
    const [studentInfo, setStudentInfo] = useState<any>({});
    const [studentsModules, setStudentsModuless] = useState(false);
    const [paymentInfo, setPaymentInfo] = useState<any>({});
    const history = useHistory();
    let { id, userCode, schoolName } = useParams<{ id: string, userCode: string, schoolName: string }>();
    const schoolId = parseInt(id);
    const usersCode = userCode;
    const schoolCode = schoolName;
    const RAZORPAY_OPTIONS: RazorpayOptions = {
        key: appConfig.paymentKey,
        amount: (amount + "000"),
        currency: "INR",
        name: "IAMSAMARTH",
        description: "Subscription Fee",
        image: "",//MSB LOGO
        order_id: "",

        prefill: {
            name: moduleName,//SCHOOL CODE OR MODULE NAME
            email: "",
            contact: "9999999999",
        },
        notes: {
            address: "Razorpay Corporate Office",
        },
        theme: {
            color: "#3399cc",
        },
    };


    useEffect(() => {
        const userType = localStorage.getItem("user_type");
        setUserType(userType);
        getUserDetails();
        const accessObjectsData: any = localStorage.getItem("access_objects_data");
        onHandleTable(JSON.parse(accessObjectsData));
        // ccavenue //
        const splitLocation: any = window.location.href.split("?");
        const queryString = splitLocation[1];
        if (queryString) {
            const match = queryString.match(/status=([^&]*)/);
            if (match) {
                const statusValue = match[1];
                const studentPaymentInfo = JSON.parse(localStorage.getItem('cc_user_payment_info') || '{}');
                const txnId = localStorage.getItem("cc_transaction_id");
                const moduleInfo = JSON.parse(localStorage.getItem('cc_payment_module_info') || '{}');
                const studentInfo: any = JSON.parse(localStorage.getItem('cc_student_info') || '{}');
                if (txnId && statusValue === "success") {
                    updateSubscription(txnId, "", studentPaymentInfo, studentInfo, moduleInfo);
                } else {
                    removeItemsFromeLocalStorage();
                }
            } else {
            }
        }
    }, []);

    function getUserDetails() {
        setLoading(true);
        GET_USER_USER_CODE(usersCode).then((response: any) => {
            if (response.status === 'fail') {
                toast.error(response?.message);
            } else {
                const data = response?.rows[0];
                getModules();
                setStudentInfo(data);
            }
        });
    }

    function getModules() {
        let schoolData: string | any[] = [];
        GET_MODULES_BY_SCHOOL_ID(schoolCode).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    let preData = res?.rows?.filter((el: any) => el.module_code !== 'CAS-PARENT');
                    schoolData = preData
                }
            }
        });
        GET_MODULES_BY_USER_CODES(userCode).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    const data = res?.filter((el: any) => el.module_code !== 'CAS-PARENT');
                    for (let x = 0; x < schoolData.length; x++) {
                        for (let z = 0; z < data.length; z++) {
                            if (data[z].module_code === schoolData[x].module_code) {
                                data[z].amount = schoolData[x].amount
                            }
                        }
                    }
                    setModulesData(data);
                    setLoading(false);
                }
            }
        });
    }

    function onClickPay(data: any) {
        setPaymentInfo({});
        setModuleInfo(data);
        // setAmount(data.amount);
        setModuleName(data.module_name);
        getPaymentDetailsOfUser(data);
        setCanShowPopup(true);
    }

    function getPaymentDetailsOfUser(data: any) {
        const payLoad = {
            "institute_school_code": schoolCode,
            "standard": "",
            "division": "",
            "students": [{ user_code: `${studentInfo?.user_code}` }],
            "module_uuid": data?.uuid
        }
        setPaymentDetailsLoading(true);
        CREATE_PAYMENT_DETAILS_SCHOOL_MODULE_CODE(payLoad).then((res: any) => {
            if (res.status === "fail") {
                toast.error(res?.message);
                setPaymentDetailsLoading(false);
            } else {
                if (res) {
                    let data = res?.students;
                    if (data?.length > 0) {
                        setPaymentInfo(data[0]);
                        localStorage.setItem("cc_user_payment_info", JSON.stringify(data[0]));
                        setAmount(data[0]?.total_amount);
                    }
                    setPaymentDetailsLoading(false);
                }
            }
        });
    }

    function getSubscriptionStatusByUser(data: any) {
        setPaymentDetailsLoading(true);
        GET_SUBSCRIPTION_PRICE_STATUS_BY_STUDENT(usersCode).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setPaymentDetailsLoading(false);
                } else {
                    const usersData = res;

                    if (usersData['guardian'] === null) {
                        toast.error("Please Add Parents Details");

                        setAmount(usersData.subscription_price);

                    }

                    else if (usersData['students'].length > 0) {
                        setSubscriptionInfo(usersData);
                        if (usersData['students'][0].payment_status === 'not-completed') {
                            setAmount(data.amount + usersData.subscription_price_1);
                        } else {
                            setAmount(data.amount);
                        }
                    }
                    setPaymentDetailsLoading(false);
                }
            }
        });
    }

    function createPayment(moduleInfo: any, paymentInfo: any) {
        const payload = {
            school_code: studentInfo?.institute_school_code,
            user_ids: paymentInfo?.user_code,
            sub_amount: paymentInfo?.total_amount
        }
        if (parseInt(paymentInfo?.total_amount) > 0) {
            CREATE_PAYMENT(payload).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res.message);
                    } else {
                        SettransactionId(res.txn_id);
                        onOpenPaymentInfo(payload.sub_amount, res.txn_id, moduleInfo, paymentInfo);
                    }
                }
            });
        } else {
            getAssignModule(paymentInfo, moduleInfo, studentInfo);
        }
    }

    function onOpenPaymentInfo(amount: any, txn_id: string, moduleInfo: any, paymentInfo: any) {
        localStorage.setItem("cc_user_payment_info", JSON.stringify(paymentInfo));
        localStorage.setItem("cc_transaction_id", txn_id);
        if (amount > 0) {
            createCcavenuePayment(amount, txn_id);
            // RAZORPAY_OPTIONS.amount = amount + "00";
            // RAZORPAY_OPTIONS.handler = (response) => {
            //     onUpdatePayment(txn_id, response, "success", data);
            //     if (amount > 0) {
            //         updateSubscription(txn_id, response, subscriptionData);
            //     }
            // }
            // let razorpay = new Razorpay(RAZORPAY_OPTIONS);

            // razorpay.open();

            // razorpay.on("payment.failed", function (response: any) {
            //     onUpdatePayment(txn_id, response, "failed", data);
            //     if (amount > 0) {
            //         updateSubscription(txn_id, response, subscriptionData);
            //     }
            // });

        } else {
            onUpdatePayment(txn_id, { "message": "SUM AMOUNT ZERO" }, "success", moduleInfo);
            // if (subscriptionData.subscription_price_1 > 0) {
            if (amount > 0) {
                updateSubscription(txn_id, { "message": "SUM AMOUNT ZERO" }, paymentInfo);
            }
        }
    }


    function onUpdatePayment(transactionId: string, data: any, status: string, moduleData: any) {

        const payload = {
            txn_id: transactionId,
            "module_code": moduleData.module_code ? moduleData.module_code : '',
            "module_uuid": '',
            "txn_details": data,
            "status": status
        }

        UPDATE_PAYMENT(payload).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                } else {
                    SettransactionId(res.txn_id);
                    toast.success('Payment Updated Successfully');
                    // setCanShowPopup(false);
                    getAssignModule(paymentInfo, moduleData, studentInfo);
                }
            }
        });
    }

    function updateSubscription(transactionId: any, transactionData: any, paymentInfo: any, studentInfo?: any, moduleInfo?: any) {
        const payload = {
            data: [
                {
                    "uuid": "",
                    "school_code": studentInfo?.institute_school_code,
                    "user_code": paymentInfo?.user_code,
                    "transaction_id": transactionId,
                    "amount": parseInt(paymentInfo?.subscription_price),
                    // "transaction_details": JSON.stringify(transactionData),
                    "transaction_details": "",
                    "payment_status": "completed"
                }
            ]
        }
        ADD_UPDATE_SUBSCRIPTION_PRICE(payload).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
            } else {
                // setCanShowPopup(false);
                getAssignModule(paymentInfo, moduleInfo, studentInfo);
                toast.success('Subscription Added Successfully');
            }
        });
    }

    function getAssignModule(paymentInfo: any, moduleData: any, studentInfo?: any) {
        const user_code = [];
        user_code.push(paymentInfo?.user_code);
        const payload = {
            module_uuid: moduleData.uuid ? moduleData.uuid : '',
            payment_status: "COMPLETED",
            school_code: studentInfo?.institute_school_code,
            target_std: moduleData.target_std,
            total_amount_paid: paymentInfo?.module_price,
            user_codes: user_code
        }
        CREATE_MNS_USER_ASSIGN(payload).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
            } else {
                toast.success(res.message);
                setCanShowPopup(false);
                removeItemsFromeLocalStorage();
                getModules();
                getUserDetails();
            }
        });
    }

    function onClickModuleGridView(item: any) {
        if (item.module_code === 'MATHS') {
            history.push(`/dashboard/mns/test-level-status/${item.uuid}/${usersCode}/${schoolCode}`);
        } else {
            history.push(`/dashboard/questions/${usersCode}/${item.module_code}`);
        }
    }

    const handleclose = () => {
        setCanShowPopup(false);
    }

    const handlePayment = () => {
        createPayment(moduleInfo, paymentInfo);
        localStorage.setItem("cc_payment_module_info", JSON.stringify(moduleInfo));
        localStorage.setItem("cc_student_info", JSON.stringify(studentInfo));
    }
    function onHandleTable(accessObjectsList: any) {
        const preparedData = accessObjectsList?.filter((el: any) => el.object_code === "INDIVIDUAL");
        if (preparedData?.length > 0) {
            for (let i = 0; i < preparedData[0].child.length; i++) {
                if (preparedData[0].child[i].object_code === "INDIVIDUAL-STUDENTS-MODULES" && preparedData[0].child[i].p_read === 1) {
                    setStudentsModuless(true);
                }
            }
        }
    }
    const createCcavenuePayment = (amount: any, txn_id: string) => {
        let payLoad = {
            amount: amount,
            currency: "INR",
            transaction_uuid: txn_id,
            redirect_url: `${UI_BASE_URL}/dashboard/individual-student/info/${schoolId}/modulelist/${userCode}/${schoolCode}`,
            cancel_url: `${UI_BASE_URL}/dashboard/individual-student/info/${schoolId}/modulelist/${userCode}/${schoolCode}`,
        }
        CREATE_CCAVENUE_PAYMENT(payLoad).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res?.error);
            } else {
                initiatePayment(res);
            }
        });
    }
    const initiatePayment = async (paymentData: any) => {
        window.location.href = paymentData.url;
    };
    const removeItemsFromeLocalStorage = () => {
        const keysToRemove = ['cc_subscription_data', 'cc_transaction_id', 'cc_payment_module_info', "cc_user_payment_info", "cc_student_info"];
        keysToRemove.forEach((key: any) => {
            localStorage.removeItem(key);
        });
    };
    return (
        <div>
            <div className={`modal fade ${canShowPopup ? 'show' : 'false'}`} style={{ display: (canShowPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog subscription-payment-dialog" role="document">
                    <div className="modal-content subscription-payment-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Payment Details</h5>
                            <button type="button" className="close popup-close" data-dismiss="modal" aria-label="Close" onClick={handleclose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {paymentDetailsLoading &&
                                <div className="text-center p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            }
                            {!paymentDetailsLoading && <div className="mar-t-20 custom-scroll table-responsive">
                                <table className="table fixed-table-header mb-0">
                                    <thead>
                                        <tr>
                                            <th>Student Name</th>
                                            <th>Module Name</th>
                                            <th className='text-center'>Subscription Price</th>
                                            <th className='text-center'>Module Price</th>
                                            <th className='text-center'>Total</th>
                                        </tr>
                                    </thead>
                                    {Object.keys(paymentInfo).length > 0 && <tbody>
                                        <tr>
                                            <td>{paymentInfo?.name}</td>
                                            <td>{(moduleInfo.module_code === "CAS" || moduleInfo.module_code === "MATHS") ? moduleInfo?.module_code : moduleInfo?.module_code?.charAt(0).toUpperCase() + moduleInfo?.module_code?.slice(1).toLowerCase()}</td>
                                            <td className='text-center'>{(paymentInfo?.subscription_payment_status === "completed") ? <span>-</span> : <span>{parseInt(paymentInfo?.subscription_price)}</span>}</td>
                                            <td className='text-center'>{paymentInfo?.module_price}</td>
                                            <td className='text-center'>{paymentInfo?.total_amount}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>Total Amount to Pay</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td style={{ fontWeight: 'bold' }} className='text-center'>{paymentInfo?.total_amount}</td>
                                        </tr>
                                    </tbody>}
                                </table>
                                {Object.keys(paymentInfo).length == 0 && <p className="text-center f12 mt-3">No Data Found</p>}
                            </div>}
                        </div>
                        <div className="modal-footer">
                            {(Object.keys(paymentInfo).length > 0) && <button type="button" className="btn btn-primary cursor-pointer" onClick={handlePayment}>{(parseInt(paymentInfo?.total_amount)) > 0 ? 'Pay Now' : 'Assign'}</button>}
                            <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleclose}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <div className="mar-t-20 custom-scroll table-responsive">
                <table className="table fixed-table-header mb-0">
                    <thead>
                        <tr>
                            <th>Module Name</th>
                            <th>Version</th>
                            <th>Status</th>
                            {userType === 'individual' && <th>Report Info</th>}
                            <th>Amount</th>
                            <th>Payment Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {modulesData.map((name: any, i: number) => (
                            <tr key={i}>
                                <td>{name.module_code}</td>
                                <td>{name.module_ver}</td>
                                <td>{name.module_status}</td>
                                {userType === 'individual' && <td>
                                    {
                                        name.amount_status === 'COMPLETED' && <div>
                                            {name.module_status !== 'completed' &&
                                                <button className="px-3 mb-2 cursor-pointer" style={{ border: 'none', backgroundColor: '#0f70cd', borderRadius: '5px', color: 'white', marginRight: '5px' }} onClick={() => onClickModuleGridView(name)}>
                                                    <span>Take Survey</span>
                                                </button>
                                            }
                                        </div>
                                    }
                                </td>}
                                <td>{name.amount}</td>
                                <td>
                                    <span>{(name.amount_status === 'COMPLETED') ? 'COMPLETED' : 'PENDING'}</span>&nbsp;
                                    {name.amount_status !== 'COMPLETED' && <button className="file btn btn-sm btn-primary px-4 mx-4 rounded-12 d-inline-block cursor-pointer" onClick={() => onClickPay(name)}>Pay Now</button>}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {modulesData.length === 0 && <div style={{ textAlign: 'center' }}>
                    No records Found
                </div>}
            </div>}
        </div >
    )
}
