import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { FETCH_ABHYAS_USER_QUESTION_INFO } from "../../../../app/service/abhyas-module-questions.service";
import { toast } from "react-toastify";
import { GET_MODULES_BY_USER_CODES } from "../../../../app/service/module.service";
import { GET_SUBJECT_BY_UUID } from "../../../../app/service/abhyas-subject.service";
import { FETCH_PARIKSHA_USER_QUESTION_INFO } from "../../../../app/service/pariksha-module-questions";
import { ADD_UPDATE_SUBSCRIPTION_PRICE } from "../../../../app/service/subscription.service";
import SUBJECTCOMPLETE from '../../../../institute-dashboard-assets/images/subj-completed-logo.svg'
import { CREATE_MNS_USER_ASSIGN } from "../../../../app/service/mns-service";
import { GET_USER_USER_CODE } from "../../../../app/service/users.service";

const ParentViewStudentAbhyaasSummary = () => {
    const [loading, setLoading] = useState(false);
    const [priorityItems, setPriorityItems] = useState<any>([]);
    const [studentData, setStudentData] = useState<any>({});
    const [parikshaItems, setParikshaItems] = useState<any>(null);
    const [canShowSubItems, setShowSubItems] = useState(false);
    const [currentCycleInfo, setCurrentCycleInfo] = useState<any>(null);
    const [previousCycleInfo, setPreviousCycleInfo] = useState<any>(null);
    const [subjectData, setSubjectData] = useState<any>({});
    const [MNSInfo, setMNSInfo] = useState(false);
    const history = useHistory();
    const [moduleData, setModuleData] = useState<any>({});
    let { moduleUUID, userCode, schoolCode, BoardUuid, MediumUuid, StandardUuid, SubjectUuid } = useParams<{ moduleUUID: string, userCode: string, schoolCode: string, BoardUuid: string, MediumUuid: string, StandardUuid: string, SubjectUuid: string }>();
    const modules_uuid = moduleUUID;
    const user_code = userCode;
    const school_code = schoolCode;
    const boardUuid = BoardUuid;
    const mediumUuid = MediumUuid;
    const standardUuid = StandardUuid;
    const subjectUuid = SubjectUuid;

    useEffect(() => {
        getUserDetails();
        getModuleData();
        getSubjectInfo()
        getAbhyasCompleteInfo();

        const splitLocation: any = window.location.href.split("?");
        const queryString = splitLocation[1];
        if (queryString) {
            const match = queryString.match(/status=([^&]*)/);
            if (match) {
                const statusValue = match[1];
                const studentInfo: any = JSON.parse(localStorage.getItem('cc_student_info') || '{}');
                const studentPaymentInfo = JSON.parse(localStorage.getItem('cc_user_payment_info') || '{}');
                const txnId = localStorage.getItem("cc_transaction_id");
                const moduleInfo = JSON.parse(localStorage.getItem('cc_module_info') || '{}');
                if (txnId && statusValue === "success") {
                    updateSubscription(txnId, "", studentPaymentInfo, statusValue, studentInfo, moduleInfo);
                } else {
                    removeItemsFromeLocalStorage();
                }
            } else {
            }
        }

    }, []);
    function getUserDetails() {
        setLoading(true);
        GET_USER_USER_CODE(user_code).then((response: any) => {
            if (response.status === 'fail') {
                const errorValue = Array.isArray(response?.err) ? response?.err[0] : response?.err;
                toast.error(errorValue);
                setLoading(false);
            } else {
                const data = response.rows[0];
                setStudentData(data);

            }
        });
    }

    function getAbhyasCompleteInfo() {
        let payload = {
            module_uuid: modules_uuid,
            school_code: school_code,
            user_code: user_code,
            cycle_no: '',
            board_uuid: boardUuid,
            medium_uuid: mediumUuid,
            standard_uuid: standardUuid,
            subject_uuid: subjectUuid
        }
        localStorage.setItem('userSelectedSubjectInfo', JSON.stringify(payload));
        setLoading(true);
        FETCH_ABHYAS_USER_QUESTION_INFO(payload).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res?.err);
                setLoading(false);
            } else {
                if (res) {
                    const data = res;
                    setCurrentCycleInfo(data);
                    getParikshaCompleteInfo();
                    if (data?.status === 'completed') {
                        setShowSubItems(false);
                    } else {
                        setShowSubItems(true);
                    }
                    let status = data?.tests?.find((x: any) => x.status === 'completed');
                    if (data.cycle_no > 1 && !status) {
                        getRequestedCycleData(data.cycle_no - 1);
                    }
                    if (data && data?.priorities) {
                        data.priorities = data?.priorities?.map((item: any, index: number) => {
                            item.isSelected = false;
                            return item;
                        })
                        setPriorityItems(data?.priorities);
                    }


                }
            }
        });
    }

    function getRequestedCycleData(cycle_no: any) {
        let payload = {
            "module_uuid": modules_uuid,
            "school_code": school_code,
            "user_code": user_code,
            "cycle_no": cycle_no ? cycle_no : '',
            "board_uuid": boardUuid,
            "medium_uuid": mediumUuid,
            "standard_uuid": standardUuid,
            "subject_uuid": subjectUuid
        }

        FETCH_ABHYAS_USER_QUESTION_INFO(payload).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
            } else {
                if (res) {
                    const data = res;
                    setPreviousCycleInfo(data);
                    if (data && data?.priorities) {
                        data.priorities = data?.priorities?.map((item: any, index: number) => {
                            item.isSelected = false;
                            return item;
                        })
                        setPriorityItems(data?.priorities);
                    }
                }
            }
        });
    }
    function getModuleData() {
        GET_MODULES_BY_USER_CODES(userCode).then((res: any) => {

            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                } else {
                    const data = res.filter((el: any) => el.module_code === 'PARIKSHA');
                    setModuleData(data[0]);
                }
            }
        });
    }
    const getSubjectInfo = () => {
        GET_SUBJECT_BY_UUID(subjectUuid).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res?.err);
            } else {
                if (res?.rows?.length > 0) {
                    setSubjectData(res?.rows[0]);
                }
            }
        });
    }
    function getParikshaCompleteInfo() {
        let payload = {
            module_uuid: modules_uuid,
            school_code: school_code,
            user_code: user_code,
            board_uuid: boardUuid,
            medium_uuid: mediumUuid,
            standard_uuid: standardUuid,
            subject_uuid: subjectUuid
        }
        FETCH_PARIKSHA_USER_QUESTION_INFO(payload).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res?.err);
                setLoading(false);

            } else {
                if (res) {
                    const data = res;
                    if (data && data?.tests) {
                        data.tests = data?.tests?.map((item: any, index: number) => {
                            item.isSelected = false;
                            return item;
                        })
                        setParikshaItems(data.tests);
                    }
                    setLoading(false);

                }
            }
        });
    }


    const getAbhyasTestCompletedStatus = (cycle_info: any) => {
        if (cycle_info && cycle_info.tests) {
            let isCompleted = true;
            for (let i = 0; i < cycle_info.tests.length; i++) {
                if (cycle_info.tests[i].status === 'pending') {
                    isCompleted = false;
                }
            }
            return isCompleted
        } else {
            return false;
        }

    }

    const getCompletedCycleInfoToShow = () => {
        if (currentCycleInfo && previousCycleInfo && currentCycleInfo.tests_status === 'pending'
            && previousCycleInfo.tests_status === 'completed') {
            return previousCycleInfo;
        } else {
            return currentCycleInfo;
        }
    }


    function onClickViewResult(cycleInfo: any) {

        // return
        if (getAbhyasTestCompletedStatus(cycleInfo)) {
            let testInfo = {
                subject_name: subjectData?.name?.toUpperCase()
            }
            localStorage.setItem("abhyas_test_info", JSON.stringify(testInfo))
            history.push(`/parent-dashboard/view/abhyaas/result/${modules_uuid}/${user_code}/${school_code}`);
        }
    }



    const onClickPracticeInfo = (item: any, index: number) => {
        let items = [...priorityItems];
        items[index].isSelected = !item.isSelected;
        setPriorityItems([...items]);
    }
    const onClickParikshaPracticeInfo = (item: any, index: number) => {
        let items = [...parikshaItems];
        items[index].isSelected = !item.isSelected;
        setParikshaItems([...items]);
    }





    function updateSubscription(transactionId: any, transactionData: any, paymentInfo: any, paymentStatus: any, studentInfo?: any, moduleInfo?: any) {
        if (paymentInfo?.subscription_payment_status === 'pending') {
            const payload = {
                data: [
                    {
                        "uuid": "",
                        "school_code": studentInfo?.institute_school_code,
                        "user_code": paymentInfo?.user_code,
                        "transaction_id": transactionId,
                        "amount": parseInt(paymentInfo?.subscription_price),
                        // "transaction_details": JSON.stringify(transactionData),
                        "transaction_details": "",
                        "payment_status": "completed"
                    }
                ]
            }
            if (paymentStatus === "success") {
                ADD_UPDATE_SUBSCRIPTION_PRICE(payload).then((res: any) => {
                    if (res.status === 'fail') {
                        toast.error(res?.message);
                    } else {
                        getAssignModule(paymentInfo, moduleInfo);
                        toast.success('Subscription Added Successfully');
                    }
                });
            }
        } else {
            getAssignModule(paymentInfo, moduleInfo);
        }
    }


    const renderAbhyasTests = (cycleInfo: any) => {
        return (
            <div>
                <div className='mb-2 ' >
                    <a className='status-cards d-flex justify-content-between align-items-center mb-2 position-relative'>
                        <div>
                            <div className="d-md-flex d-block">
                                <div>
                                    <label className='f20 m-0 text-black fw-medium d-block'>Assessment Test</label>
                                    <span className='text-black fw-light line-hieight-normal'>{getAbhyasTestCompletedStatus(cycleInfo) ? 'Completed' : 'Number of Tests'} </span>
                                    <ul className='list-inline m-0 attempts ' >
                                        {cycleInfo && cycleInfo?.tests?.map((item: any, i: number) => (
                                            <li className='list-inline-item' key={i} style={{ backgroundColor: item.status === 'completed' ? '#8CC63F ' : '#FFFFFF' }}>{item?.test_no}</li>
                                        ))}
                                    </ul>
                                </div>
                                {getAbhyasTestCompletedStatus(cycleInfo) && <div className="mt-2">
                                    <button className="btn btn-success f12 ms-md-3 px-2 py-1 mt-md-4 cursor-pointer" onClick={() => { onClickViewResult(cycleInfo) }}>View Result</button>
                                </div>}
                            </div>

                        </div>
                        <div className='ms-2 '>
                            {getAbhyasTestCompletedStatus(cycleInfo) &&
                                <div>
                                    {
                                        cycleInfo?.tests?.map((item: any, index: number) => (

                                            <div className='text-center d-inline-block ms-4' key={index}>
                                                <img src={SUBJECTCOMPLETE} />
                                            </div>
                                        ))
                                    }
                                </div>
                            }
                        </div>

                    </a>
                </div>
            </div>
        )
    }


    const getChapterName = (name: any) => {
        return `(${name})`

    }
    const handleBack = () => {
        history.push(`/parent-dashboard/view/abhyaas/initiative/${moduleUUID}/${userCode}/${schoolCode}`)
    }
    function getAssignModule(paymentInfo: any, moduleData: any) {
        const user_code = [];
        user_code.push(paymentInfo?.user_code);
        const payload = {
            module_uuid: moduleData.uuid ? moduleData.uuid : '',
            payment_status: "COMPLETED",
            school_code: schoolCode,
            target_std: moduleData.target_std,
            total_amount_paid: paymentInfo?.module_price,
            user_codes: user_code
        }
        CREATE_MNS_USER_ASSIGN(payload).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
            } else {
                toast.success("Successfully assigned  module!");
                getModuleData()
                removeItemsFromeLocalStorage();
            }
        });
    }
    const removeItemsFromeLocalStorage = () => {
        const keysToRemove = ['cc_student_info', 'cc_user_payment_info', 'cc_transaction_id', 'cc_module_info'];
        keysToRemove.forEach((key: any) => {
            localStorage.removeItem(key);
        });
    };
    const statusCount = (data: any, status: string) => {
        if (status === "pending") {
            let count = data?.filter((item: any) => item?.status === "pending");
            return count?.length ? count?.length : 0;
        } else {
            let count = data?.filter((item: any) => item?.status === "completed");
            return count?.length ? count?.length : 0;
        }

    }

    return (
        <div>


            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb pb-2 ps-0 pt-0 border-bottom">
                        <li className="breadcrumb-item "><Link to="/parent-dashboard/view"><span className='pe-2'>Dashboard</span></Link></li> /
                        <li className="breadcrumb-item "><Link to={`/parent-dashboard/view/abhyaas/initiative/${modules_uuid}/${user_code}/${school_code}`}><span>Abhyaas</span></Link></li> /
                        <li className="breadcrumb-item "><span>{subjectData?.name?.toUpperCase()} </span></li>
                    </ol>
                </nav>
                <div className=' ps-lg-2 pe-lg-5'>
                    <div className="text-center">
                        <h5 className="mb-0 f16 md_dashboard_txt"><span className="text-inprogess ">{studentData?.name?.toUpperCase()}'S</span> ABHYAAS DASHBOARD <span className="text-dark fw-bold">({subjectData?.name?.toUpperCase()} SECTION)</span></h5>
                    </div>
                    <div className='mb-3 mb-md-4 mt-1' style={{ textAlign: 'right' }}>
                        <button className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer" onClick={handleBack}>Back</button>
                    </div>

                    {/* <h5 className='text-center f20 mb-5'> ABHYAAS : {subjectData?.name?.toUpperCase()} DASHBOARD </h5> */}
                    <div className={`mb-2 ${moduleData && " border border-dark p-2 rounded border-1"} `}>
                        {renderAbhyasTests(getCompletedCycleInfoToShow())}
                        <div className={`collapse show `} id="collapseExample3">

                            {currentCycleInfo && canShowSubItems && priorityItems && priorityItems.length > 0
                                &&
                                <div>
                                    {priorityItems?.map((item: any, index: number) => (
                                        <React.Fragment key={index}>

                                            {item?.topics?.length > 0 && <span
                                                className={`status-cards d-flex justify-content-between align-items-center mb-2 position-relative ${item.isSelected ? "" : "collapsed"} `}
                                                data-bs-toggle="collapse"

                                                role="button"
                                                aria-expanded="false"
                                                aria-controls="collapseExample2" onClick={() => { onClickPracticeInfo(item, index) }}
                                            >
                                                <div className="d-md-flex d-block">
                                                    <div>
                                                        <label className="f20 m-0 text-black fw-medium d-block"
                                                        >Practice {item?.priority}</label>
                                                        <h6 className='f14 mt-2'>{item?.topics?.length}{item?.topics?.length < 2 ? ' Topic is in ' : ' Topics are in '} practice</h6>
                                                    </div>
                                                    <div className="ms-md-5 text-inprogess fw-bold text-md-center">
                                                        <p className="m-0 f14">Pending</p>
                                                        <p className="m-0 f12">({statusCount(item?.topics, "pending")})</p>
                                                    </div>
                                                    <div className="ms-md-5 text-completed fw-bold text-md-center">
                                                        <p className="m-0 f14">Completed</p>
                                                        <p className="m-0 f12">({statusCount(item?.topics, "completed")})</p>
                                                    </div>
                                                </div>
                                                {item?.topics?.length > 0 &&
                                                    <i className="status-cards-arrow" >
                                                    </i>}
                                            </span>}

                                            {item.isSelected &&
                                                <div>
                                                    {item?.topics && item?.topics.map((topic: any, j: number) => (
                                                        <span
                                                            className={`status-cards d-flex justify-content-between align-items-center mb-2 position-relative ${MNSInfo ? "" : "collapsed"} `}
                                                            data-bs-toggle="collapse"
                                                            aria-expanded="false"
                                                            aria-controls="collapseExample2"
                                                            key={j}>
                                                            <div className='pb-2'>
                                                                <div className="d-md-flex d-block mt-2 ">
                                                                    <h3 className='f20 text-black fw-medium d-block text-mblue'>{topic?.topic_name}</h3>
                                                                    <p className={`mb-0 ms-md-3 fw-bold f14 ${topic?.status === "pending" ? "text-inprogess" : "text-completed"}`}>({topic?.status?.charAt(0)?.toUpperCase() + topic?.status?.slice(1)?.toLowerCase()})</p>
                                                                </div>
                                                                <div className='f14 '>Worksheets</div>
                                                                {topic?.worksheets?.map((sheet: any, k: number) => (
                                                                    <div key={k} className="circle  position-relative" style={{ backgroundColor: sheet.status === 'completed' ? (sheet.result === "fail" ? '#f15a24' : '#8CC63F') : '#FFFFFF' }}
                                                                    >
                                                                        <p>{k + 1}</p>

                                                                    </div>
                                                                ))}
                                                            </div>
                                                            {item.status === 'completed' &&
                                                                <img src={SUBJECTCOMPLETE} />
                                                            }
                                                            <i className='status-cards-arrow small mt-1'>   </i>
                                                        </span>
                                                    ))}

                                                </div>

                                            }
                                        </React.Fragment>
                                    ))}
                                </div>
                            }
                        </div>
                    </div>

                    <h2 className="mt-3 mb-2 f18 mt-4 text-black fw-medium">Practice assigned by PARIKSHA</h2>

                    <div className="mb-2 border border-dark p-2 rounded  border-1">

                        {(moduleData?.amount_status === "PENDING" || parikshaItems?.length == 0) &&
                            <span
                                className={`status-cards d-flex justify-content-between align-items-center mb-2 position-relative collapsed`}
                                data-bs-toggle="collapse"
                                aria-expanded="false"
                                role="button"
                                aria-controls="collapseExample2"
                            >
                                <div>
                                    <h6 className='text-danger m-0 f12 fw-bold'>No Worksheets </h6>
                                </div>

                            </span>

                        }
                        <div>
                            {parikshaItems?.map((item: any, index: number) => (
                                <React.Fragment key={index}>
                                    <span
                                        className={`status-cards d-flex justify-content-between align-items-center mb-2 position-relative ${item.isSelected ? "" : "collapsed"} `}
                                        data-bs-toggle="collapse"
                                        aria-expanded="false"
                                        role="button"
                                        aria-controls="collapseExample2"
                                        onClick={() => { onClickParikshaPracticeInfo(item, index) }} >
                                        <div className="d-md-flex d-block">
                                            <div>{
                                                item?.test_details?.exam_type === "UNIT_TEST_1" && <label className="f18 m-0  fw-medium d-block "
                                                >Unit Test - 1 </label>
                                            }
                                                {
                                                    item?.test_details?.exam_type === "UNIT_TEST_2" && <label className="f18 m-0  fw-medium d-block "
                                                    >Unit Test - 2 </label>
                                                }
                                                {
                                                    (item?.test_details?.exam_type !== "UNIT_TEST_1" && item?.test_details?.exam_type !== "UNIT_TEST_2") && <label className="f18 m-0  fw-medium d-block "
                                                    >{item?.test_details?.exam_type?.split('_')
                                                        .map((word: any) => word?.charAt(0).toUpperCase() + word?.slice(1).toLowerCase())
                                                        .join(' ')}

                                                    </label>}
                                                {item?.test_details?.exam_type === "CHAPTER_WISE_TEST" && <h5 className='f16 text-black'> {getChapterName(item?.topic_wise_result[0]?.chapter_name)}</h5>}

                                                <h6 className='f14 mt-2'>{item?.topics?.length}{item?.topics?.length < 2 ? ' Topic is in ' : ' Topics are in '} practice</h6>

                                            </div>
                                            <div className="ms-md-5 text-inprogess fw-bold text-md-center">
                                                <p className="m-0 f14">Pending</p>
                                                <p className="m-0 f12">({statusCount(item?.topics, "pending")})</p>
                                            </div>
                                            <div className="ms-md-5 text-completed fw-bold text-md-center">
                                                <p className="m-0 f14">Completed</p>
                                                <p className="m-0 f12">({statusCount(item?.topics, "completed")})</p>
                                            </div>
                                        </div>
                                        {item?.topics?.length > 0 &&
                                            <i className="status-cards-arrow" ></i>
                                        }
                                    </span>

                                    {item.isSelected &&
                                        <div>
                                            {item?.topics && item?.topics?.map((topic: any, j: number) => (
                                                <span
                                                    className={`status-cards d-flex justify-content-between align-items-center mb-2 position-relative ${MNSInfo ? "" : "collapsed"} `}
                                                    data-bs-toggle="collapse"
                                                    aria-expanded="false"
                                                    aria-controls="collapseExample2"
                                                    key={j}>
                                                    <div className='pb-2'>
                                                        <div className="d-md-flex d-block mt-2 ">
                                                            <h3 className='f20 text-black fw-medium d-block text-mblue'>{topic?.topic_name}
                                                            </h3>
                                                            <p className={`mb-0 ms-md-3 fw-bold f14 ${topic?.status === "pending" ? "text-inprogess" : "text-completed"}`}>({topic?.status?.charAt(0)?.toUpperCase() + topic?.status?.slice(1)?.toLowerCase()})</p>
                                                        </div>
                                                        <div className='f14 '>{topic?.worksheets?.length > 0 ? "Worksheets" : "No Worksheets"}</div>
                                                        {topic?.worksheets?.map((sheet: any, k: number) => (
                                                            <div key={k} className="circle  position-relative" style={{ backgroundColor: sheet.status === 'completed' ? (sheet.result === "fail" ? '#f15a24' : '#8CC63F') : '#FFFFFF' }}
                                                            >
                                                                <p>{k + 1}</p>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    {(topic.status === 'completed' && topic?.worksheets?.length > 0) &&
                                                        <img src={SUBJECTCOMPLETE} />
                                                    }
                                                    {(topic.status === 'pending') && <i className='status-cards-arrow small mt-1'></i>}
                                                </span>
                                            ))}
                                        </div>
                                    }
                                </React.Fragment>
                            ))}
                        </div>

                    </div>
                </div>
            </div>}
        </div>
    )

}
export default ParentViewStudentAbhyaasSummary;