import React from 'react'
import { Switch, Route } from 'react-router-dom';
import { ParentDashboardFamilyReport } from '../../pages/parent-dashboard/dashboard/reports/family';
import { ParentDashboardParentReport } from '../../pages/parent-dashboard/dashboard/reports/parent';
import { ParentDashboardStudentReport } from '../../pages/parent-dashboard/dashboard/reports/student';
import { ParentServeyInstructions } from '../../pages/parent-dashboard/dashboard/servey-instructions';
import { ParentServeyQuestions } from '../../pages/parent-dashboard/dashboard/servey-questions';
import { ParentServeySuccessPage } from '../../pages/parent-dashboard/dashboard/servey-success';
import { ParentInformationDashboard } from '../../pages/parent-dashboard/dashboard/view';
import ParentViewAbhyaasInitiative from '../../pages/parent-dashboard/dashboard/abhyaas-report/initiative';
import ParentViewStudentAbhyaasSummary from '../../pages/parent-dashboard/dashboard/abhyaas-report/summary';
import ParentViewStudentAbhyaasResult from '../../pages/parent-dashboard/dashboard/abhyaas-report/result';
import ParentViewStudentMathsReport from '../../pages/parent-dashboard/dashboard/maths/report';
import ParentViewStudentParikshaTestAnalysis from '../../pages/parent-dashboard/dashboard/pariksha-report/test-analysis';
import ParentVieStudentParikshaExplanation from '../../pages/parent-dashboard/dashboard/pariksha-report/explanation';
import ParentViewStudentParikshaInitiative from '../../pages/parent-dashboard/dashboard/pariksha-report/initiative';
import ParentViewStudentParikshaSummary from '../../pages/parent-dashboard/dashboard/pariksha-report/summary';
import ParentViewChildrenProfile from '../../pages/parent-dashboard/profile/children-profile';
import { ParentStudentMathsReport } from '../../pages/parent-dashboard/dashboard/reports/maths/student';

export const ParentDashBoardRoutes = () => {
    return (
        <Switch>
            <Route exact path="/parent-dashboard/view" component={ParentInformationDashboard}></Route>
            <Route exact path="/parent-dashboard/view/servey-instructions/:userCode/:moduleCode" component={ParentServeyInstructions}></Route>
            <Route exact path="/parent-dashboard/view/servey-questions/:userCode/:moduleCode/:language" component={ParentServeyQuestions}></Route>

            {/* // Reports Routing */}

            <Route exact path="/parent-dashboard/view/servy-success/:id/:userCode/:studentCode" component={ParentServeySuccessPage}></Route>
            <Route exact path="/parent-dashboard/view/reports/student" component={ParentDashboardStudentReport}></Route>
            <Route exact path="/parent-dashboard/view/reports/student/:id/:userCode/:studentCode" component={ParentDashboardStudentReport}></Route>
            <Route exact path="/parent-dashboard/view/reports/family" component={ParentDashboardFamilyReport}></Route>
            <Route exact path="/parent-dashboard/view/reports/family/:id/:userCode/:studentCode" component={ParentDashboardFamilyReport}></Route>
            <Route exact path="/parent-dashboard/view/reports/parent" component={ParentDashboardParentReport}></Route>
            <Route exact path="/parent-dashboard/view/reports/parent/:id/:userCode/:studentCode" component={ParentDashboardParentReport}></Route>
            <Route exact path="/parent-dashboard/view/maths/reports/student/:userCode" component={ParentViewStudentMathsReport}></Route>
            {/* <Route exact path="/parent-dashboard/view/maths/reports/student/:userCode" component={ParentStudentMathsReport}></Route> */}


            {/* children rounting */}
            <Route exact path="/parent-dashboard/profile/children/:userCode" component={ParentViewChildrenProfile}></Route>

            {/* Abhyaas Routing */}
            <Route exact path="/parent-dashboard/view/abhyaas/initiative/:moduleUUID/:userCode/:schoolCode" component={ParentViewAbhyaasInitiative}></Route>
            <Route exact path="/parent-dashboard/view/abhyaas/summary/:moduleUUID/:userCode/:schoolCode/:BoardUuid/:MediumUuid/:StandardUuid/:SubjectUuid" component={ParentViewStudentAbhyaasSummary}></Route>
            <Route exact path="/parent-dashboard/view/abhyaas/result/:moduleUUID/:userCode/:schoolCode" component={ParentViewStudentAbhyaasResult}></Route>
            {/* Pariksha Routing */}
            <Route exact path="/parent-dashboard/view/pariksha/test-analysis/:moduleUUID/:userCode/:schoolCode" component={ParentViewStudentParikshaTestAnalysis}></Route>
            <Route exact path="/parent-dashboard/view/pariksha/explanation/:moduleUUID/:userCode/:schoolCode" component={ParentVieStudentParikshaExplanation}></Route>
            <Route exact path="/parent-dashboard/view/pariksha/initiative/:moduleUUID/:userCode/:schoolCode" component={ParentViewStudentParikshaInitiative}></Route>
            <Route exact path="/parent-dashboard/view/pariksha/summary/:moduleUUID/:userCode/:schoolCode/:BoardUuid/:MediumUuid/:StandardUuid/:SubjectUuid" component={ParentViewStudentParikshaSummary}></Route>
        </Switch>
    )
}