import React, { useEffect, useRef, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-classic-with-mathtype';
import { MathJaxContext, MathJax } from 'better-react-mathjax';
import { Breadcum } from '../../../../../components/breadcum';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CREATE_ABHYAS_QUESTION, GET_ABHYAS_QUESTION_LIST, UPDATE_ABHYAS_QUESTION } from '../../../../../app/service/abhyas-question.service';
import moment from 'moment';
import { UPLOAD_LOGO } from '../../../../../app/service/school.service';
import { GET_CHAPTER_FILTERS_LIST, GET_TOPIC_DATA_LIST, GET_TOPIC_FITERS_LIST } from '../../../../../app/service/abhyas-worksheet-filter.service';
import { GET_STANDARD_LIST_BY_MEDIUM } from '../../../../../app/service/abhyas-standard.service';
import { GET_SUBJECT_LIST_BY_STANDARD } from '../../../../../app/service/abhyas-subject.service';
// import { Image, ImageResizeEditing, ImageResizeHandles } from '@ckeditor/ckeditor5-image';
// import { LinkImage } from '@ckeditor/ckeditor5-link';
interface Props {
    topicName?: string;
    worksheetNumber?: number;
    isLinked: string;
    display: string;
    chapterUuid: string;
    topicUuid: string;
    sheetUuid: string;
    onSelectTopic?: (e: string) => void;
    selectList?: (e: string, name: string | undefined) => void;

}
const AbhyasQuestionsList: React.FC<Props> = (props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [activePage, setActivePage] = useState(0);
    const [questionsList, setQuestionsList] = useState<any>([]);
    const [pageArray, setPageNumbers] = useState([]);
    const [canShowPopup, setCanShowPopup] = useState<boolean>(false);
    const [questionTypeError, setQuestionTypeError] = useState<string>('');
    const [selectedOption, setSelectedOption] = useState<string>('multiple_choice_questions');
    const [questionImg, setQuestionImg] = useState<any[]>([]);
    const [imageLoader, setImageLoader] = useState(false);
    const [questionUuid, setQuestionUuid] = useState<string>('');
    const [questionsData, setQuestionsData] = useState<any>([]);
    const [updateQuestionsData, setUpdateQuestionsData] = useState<any>([]);
    const [canShowCkeditor, setCanShowCkeditor] = useState(false);
    const [inputData, setInputData] = useState<any>();
    const [questionData, setQuestionData] = useState<any>();
    const [canShowCKeditorPopup, setCanShowCKeditorPopup] = useState(false);
    const [canShowCKeditorAddPopup, setCanShowCKeditorAddPopup] = useState(false);
    const [loadingCkeditor, setLoadingCkeditor] = useState(false);
    const [currentQuestionData, setCurrentQuestionData] = useState<any>([]);
    const [questionIndex, setQuestionIndex] = useState<any>(null);
    const [optionIndex, setOptionIndex] = useState<any>(null);
    const [correctAnswer, setCorrectAnswer] = useState<any>('');
    const [editor, setEditor] = useState<any>();
    const [questionNumber, setQuestionNumber] = useState<any>('');
    const [alphabets, setAlphabets] = useState<any>(["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"]);
    const history = useHistory();
    const listRef: any = useRef(null);
    const editorRef = useRef(null);
    const [pTagContent, setPTagContent] = useState('');
    const [figureElements, setFigureElements] = useState([]);
    const [chapterList, setChapterList] = useState<any>([]);
    const [topicList, setTopicList] = useState<any>([]);
    const [standardList, setStandardList] = useState<any>([]);
    const [subjectList, setSubjectList] = useState<any>([]);
    const [selectStandardUuid, setSelectStandardUuid] = useState<string>("");
    const [selectSubjectUuid, setSelectSubjectUuid] = useState<string>("");
    // const [selectChapterUuid, setSelectChapterUuid] = useState<string>("");
    const [selectTopicUuid, setSelectTopicUuid] = useState<string>("");
    // const [standardError, setStandardError] = useState<boolean>(false);
    // const [subjectError, setSubjectError] = useState<boolean>(false);
    const [chapterError, setChapterError] = useState<string>("");
    const [topicError, setTopicError] = useState<string>("");
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    let { code, id, BoardUuid, BoardName, MediumUuid, MediumName, StandardUuid, StandardName, SubjectUuid, SubjectName, ChapterUuid, ChapterName, TopicUuid, TopicName, SheetUuid, SheetNo, Type } = useParams<{ code: string, id: string, BoardUuid: string, BoardName: string, MediumUuid: string, MediumName: string, StandardUuid: string, StandardName: string, SubjectUuid: string, SubjectName: string, ChapterUuid: string, ChapterName: string, TopicUuid: string, TopicName: string, SheetUuid: string, SheetNo: string, Type: string }>();
    const moduleCode = code;
    const moduleId = Number(id);
    const boardUuid = BoardUuid;
    const mediumUuid = MediumUuid;
    const standardUuid = StandardUuid;
    const subjectUuid = SubjectUuid;
    const chapterUuid = ChapterUuid;
    const topicUuid = TopicUuid;
    const sheetUuid = SheetUuid ? SheetUuid : props.sheetUuid;
    const sheetNo = SheetNo;
    const sheetType = Type;
    const [standardNo, setStanadrdNo] = useState<number>(0);
    const parser = new DOMParser();

    const [breadcumInfo, setBreadcumInfo] = useState<any>([
        {
            label: 'Board',
            value: decodeURIComponent(BoardName),
            routerLink: `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/worksheet`,
            subName: "(board)",
            isActive: false
        },
        {
            label: 'Medium',
            value: decodeURIComponent(MediumName),
            routerLink: `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/${boardUuid}/${BoardName}/medium`,
            subName: "(Medium)",
            isActive: false
        },
        {
            label: 'standard',
            value: decodeURIComponent(StandardName),
            routerLink: `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/${boardUuid}/${BoardName}/${MediumUuid}/${MediumName}/standard`,
            subName: "(standard)",
            isActive: false
        },
        {
            label: 'subject',
            value: decodeURIComponent(SubjectName),
            routerLink: `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/${boardUuid}/${BoardName}/${MediumUuid}/${MediumName}/${standardUuid}/${StandardName}/subject`,
            subName: "(subject)",
            isActive: false
        },
        {
            label: 'chapter',
            value: decodeURIComponent(ChapterName),
            routerLink: `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/${boardUuid}/${BoardName}/${MediumUuid}/${MediumName}/${standardUuid}/${StandardName}/${subjectUuid}/${SubjectName}/chapter`,
            subName: "(chapter)",
            isActive: false
        },
        {
            label: 'topic',
            value: decodeURIComponent(TopicName),
            routerLink: `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/${boardUuid}/${BoardName}/${MediumUuid}/${MediumName}/${standardUuid}/${StandardName}/${subjectUuid}/${SubjectName}/${chapterUuid}/${ChapterName}/topic`,
            subName: "(topic)",
            isActive: false
        },

        {
            label: 'SHEET',
            value: `${sheetType?.slice(0, -1)} ${sheetNo}`,
            routerLink: `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/${boardUuid}/${BoardName}/${MediumUuid}/${MediumName}/${standardUuid}/${StandardName}/${subjectUuid}/${SubjectName}/${chapterUuid}/${ChapterName}/${topicUuid}/${TopicName}/${sheetType === "TESTS" ? "test" : "worksheet-list"}`,
            subName: `(${sheetType?.slice(0, - 1)})`,
            isActive: false
        },
        {
            label: 'Questions',
            value: 'Questions',
            nameHighlight: true,
            isActive: true
        },
    ]);


    useEffect(() => {
        if (sheetType === "TESTS") {
            // getChapterList();
            getStandardList();
            const updatedBreadcumInfo = breadcumInfo.filter(
                (item: any) => item.label !== 'topic' && item.label !== 'chapter'
            );

            let preparedData = updatedBreadcumInfo?.map((item: any) => {
                if (item.label === 'SHEET') {
                    return {
                        ...item,
                        routerLink: `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/${boardUuid}/${BoardName}/${MediumUuid}/${MediumName}/${standardUuid}/${StandardName}/${subjectUuid}/${SubjectName}/test`,
                    };
                }
                return item;
            });
            setBreadcumInfo(preparedData);
        }
        // setBreadcumInfo(breadcumInfo);
        getQuestionsList();

    }, []);

    const getDangerouslySetInnerHTML = (element: any) => {
        return <div dangerouslySetInnerHTML={onViewQuestion(element?.question?.question)}></div>
    }

    const getQuestionsList = () => {
        setLoading(true);
        GET_ABHYAS_QUESTION_LIST(sheetUuid, {}).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                    setLoading(false);
                } else {
                    const data = res.rows;
                    data?.forEach((element: any, i: number) => {
                        element.created_dt = moment(element?.created_dt?.split("T")[0]).format('DD-MM-yyyy');
                        if (element?.modified_dt) {
                            element.modified_dt = moment(element?.modified_dt?.split("T")[0]).format('DD-MM-yyyy');
                        }
                        element.data = JSON.parse(element.data)
                    });
                    const UpdatedElement = [...data[0]?.data]
                    UpdatedElement?.map((element: any, i: number) => {
                        const doc = parser.parseFromString(element?.question, 'text/html');
                        const pElement = doc.querySelector('p');
                        if (pElement) {
                            element.question_name = pElement.textContent;
                        } else {
                            element.question_name = element.question;
                        }

                    });
                    setQuestionsList(UpdatedElement);
                    if (UpdatedElement && UpdatedElement?.length > 0) {
                        setUpdateQuestionsData(UpdatedElement);
                    } else {
                        setUpdateQuestionsData([]);
                    }
                    const pageNumbers = Math.ceil(res.count / 10);
                    const pageArray: any = [];
                    for (let i = 1; i <= pageNumbers; i++) {
                        pageArray.push(i);
                    }
                    setPageNumbers(pageArray);
                    setLoading(false);
                }
            }
        });
    }
    const getStandardList = () => {
        const payLoad = {
            board_uuid: boardUuid,
            medium_uuid: mediumUuid
        }
        GET_STANDARD_LIST_BY_MEDIUM(payLoad).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                } else {
                    const data = res['rows'];
                    let preparedData = data?.filter((data: any) => data.uuid === standardUuid);
                    setStanadrdNo(parseInt(preparedData[0].standard));
                    data?.sort((a: any, b: any) => b.standard - a.standard);
                    let preparedElement = data?.filter((item: any) => {

                        return item.standard <= preparedData[0].standard
                    })
                    const slicedData = preparedElement.slice(0, 3);
                    // setStandardList(preparedElement);
                    setStandardList(slicedData);


                }
            }
        });
    }
    // const getChapterList = () => {
    //     GET_CHAPTER_FILTERS_LIST(boardUuid, mediumUuid, standardUuid, subjectUuid).then((res: any) => {
    //         if (res) {
    //             if (res.status === 'fail') {
    //                 toast.error(res?.err);


    //             } else {
    //                 const data = res['rows'];
    //                 setChapterList(data);

    //             }
    //         }
    //     });
    // };

    const onSaveAddQuestion = () => {
        const payLoad =
            [{
                "mode": "add",
                "id": questionNumber,
                "uuid": "",
                "question_type": selectedOption,
                "question": getCkeditorValue('question'),
                "options": getCkeditorValue('options'),
                // "answer": correctAnswer ? correctAnswer : '',
                "correct_answer": correctAnswer ? correctAnswer : '',
                "standard_uuid": sheetType === "TESTS" ? selectStandardUuid : "",
                "subject_uuid": sheetType === "TESTS" ? selectSubjectUuid : "",
                "topic_uuid": sheetType === "TESTS" ? selectTopicUuid : ""
            }]

        if (correctAnswer && ((sheetType === "TESTS" && selectTopicUuid)) || sheetType === "WORKSHEETS" || (!sheetType && props?.sheetUuid)) {
            // return

            CREATE_ABHYAS_QUESTION(payLoad, sheetUuid).then((res: any) => {
                if (res.status === 'fail') {
                    const errorMessage = Array.isArray(res?.err) ? res?.err[0] : res?.err
                    toast.error(errorMessage);
                } else {
                    toast.success(res.message);
                    setCanShowCKeditorAddPopup(false);
                    getQuestionsList();
                }
            });
        }
        else {
            // if (!selectChapterUuid) {
            //     setChapterError("Please select chapter name");
            // }
            if (!selectTopicUuid) {
                setTopicError("Please select topic")
            }
        }


    }
    const onUpdate = () => {
        currentQuestionData[0].mode = "update";
        currentQuestionData[0].standard_uuid = sheetType === "TESTS" ? selectStandardUuid : "";
        currentQuestionData[0].subject_uuid = sheetType === "TESTS" ? selectSubjectUuid : "";
        currentQuestionData[0].topic_uuid = sheetType === "TESTS" ? selectTopicUuid : "";
        if (((sheetType === "TESTS" && selectTopicUuid)) || sheetType === "WORKSHEETS" || (!sheetType && props?.sheetUuid)) {

            UPDATE_ABHYAS_QUESTION([currentQuestionData[0]], sheetUuid).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.err);
                } else {
                    toast.success(res.message);
                    setCurrentQuestionData([]);
                    setCanShowCKeditorPopup(false);
                    getQuestionsList();
                }
            });
        } else {
            // if (!selectChapterUuid) {
            //     setChapterError("Please select chapter name");
            // }
            if (!selectTopicUuid) {
                setTopicError("Please select topic")
            }
        }

    }


    function onViewQuestion(name: any) {
        return { __html: name };
    }

    function onChange(e: any, editor: any) {
        setInputData(editor.getData());
    };

    function onClickEdit(questions: any, i: number, backNext?: string) {
        setCurrentIndex(i);
        setCurrentQuestionData([]);;
        setQuestionImg([]);
        // onChangeChapter(questions?.chapter_uuid);
        if (sheetType === "TESTS") {
            onChangeStandard(questions?.standard_uuid);
            onChangeSubject(questions?.subject_uuid)
            setSelectTopicUuid(questions?.topic_uuid);
            setQuestionUuid(questions?.uuid)
        }

        // setQuestionImg(questions?.question?.images);
        setSelectedOption(questions?.question_type)
        setCanShowCKeditorPopup(true);
        setQuestionData(questions.question);
        const data = currentQuestionData;
        if (backNext) {
            setCurrentQuestionData([questions]);
        } else {
            data.push(questions);
            setCurrentQuestionData(data);
        }
        questionImg.push(questions)
        setTopicError("");
        setChapterError("");
        setLoadingCkeditor(true);
        setCanShowCkeditor(false);
        setTimeout(() => {
            setLoadingCkeditor(false);
        }, 1000);

    }

    function onEditOptions(question: any, i: number, option: any, j: number) {
        setInputData("");
        setQuestionIndex(i);
        setOptionIndex(j);
        setQuestionData(option.value);
        setCanShowCkeditor(true);
    }

    function getCorrectAnswer(option: any) {
        for (let i = 0; i < alphabets.length; i++) {
            if (option === alphabets[i]) {
                return alphabets[i - 1];
            }
        }
    }

    function onDeleteOptions(question: any, i: number, option: any, optionIndex: number) {
        setLoadingCkeditor(true);
        const data = currentQuestionData;
        for (let i = 0; i < data.length; i++) {
            for (let j = 0; j < data[i].options.length; j++) {
                if (option.options === data[i].correct_answer) {
                    data[i].correct_answer = "";
                } else {
                    if (data[i].correct_answer === data[i].options[j].option) {

                        if (j > optionIndex) {
                            data[i].correct_answer = getCorrectAnswer(data[i].options[j].option);
                        } else {
                            data[i].correct_answer = data[i].correct_answer;
                        }
                    }
                }
            }
        }
        data[0].options.splice(optionIndex, 1);

        for (let i = 0; i < data[0].options.length; i++) {
            for (let j = 0; j < alphabets.length; j++) {
                if (i === j) {
                    data[0].options[i].option = alphabets[j];
                    data[0].options[i].value = data[0].options[i].value;
                }
            }
        }

        setTimeout(() => {
            setCurrentQuestionData(data);
            setLoadingCkeditor(false);
            setCanShowCkeditor(false);
        }, 1000);

    }

    function onEditQuestion(question: any, i: number) {
        setInputData("");
        setQuestionData(question?.question);
        setQuestionIndex(i);
        setOptionIndex(null);
        setCanShowCkeditor(true);
    }

    const handleClose = () => {
        setCanShowCKeditorPopup(false);
        getQuestionsList();
        // getWorksheetQuestions(worksheetNumber);
        setCurrentQuestionData([]);
        setQuestionIndex(null);
        setOptionIndex(null);
        setCanShowPopup(false);
    }

    function getOptionLists(item: any, optionData: any) {
        const answerOptions = item;
        answerOptions.push({
            option: '',
            value: ''
        });
        for (let i = 0; i < answerOptions.length; i++) {
            for (let j = 0; j < alphabets.length; j++) {
                if (i === j && (answerOptions[i].value === '' || answerOptions[i].option === '')) {
                    answerOptions[i].option = alphabets[j];
                    answerOptions[i].value = optionData;
                }
            }
        }

        return answerOptions;
    }

    function onSave() {
        setLoadingCkeditor(true);
        const data = currentQuestionData;
        if (questionIndex !== null && optionIndex !== null) {
            data[questionIndex].options[optionIndex].value = inputData ? inputData : data[questionIndex].options[optionIndex].value;
            // data[questionIndex].options[optionIndex].value = inputData;
            setTimeout(() => {
                setCurrentQuestionData(data);
                setLoadingCkeditor(false);
                setCanShowCkeditor(false);
            }, 1000);
        } else if (questionIndex !== null) {
            data[questionIndex].question = inputData ? inputData : data[questionIndex].question;
            // data[0].question = inputData;
            setTimeout(() => {
                setCurrentQuestionData(data);
                setLoadingCkeditor(false);
                setCanShowCkeditor(false);
            }, 1000);
        } else {
            data[0].options = getOptionLists(data[0].options, inputData);
            setTimeout(() => {
                setCurrentQuestionData(data);
                setLoadingCkeditor(false);
                setCanShowCkeditor(false);
            }, 1000);
        }
        setQuestionData(inputData);
    }

    function handleQuestionOptions(item: any, index: number) {
        const data = currentQuestionData;
        for (let i = 0; i < alphabets.length; i++) {
            if (index === i) {
                data[0].correct_answer = alphabets[i];
            }
        }
        setCurrentQuestionData(data);
    }

    function getUpdateQuestionsData() {
        const result = Object.values(
            [].concat(updateQuestionsData, currentQuestionData)
                .reduce((r: any, c: any) => (r[c.question_number] = Object.assign((r[c.question_number] || {}), c), r), {})
        );
        const updatedOptions = currentQuestionData?.map((element: any) => ({
            ...element,
            uuid: questionUuid
        }));
        return updatedOptions;
    }

    function onAddAnswerOptions() {
        setCanShowCkeditor(true);
        setQuestionData('');
        setOptionIndex(null);
        setQuestionIndex(null);
    }


    function capitalizeFirstLetter(string: any) {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }

    function onAddQuestion() {
        setInputData("");
        setQuestionImg([]);
        setSelectedOption('multiple_choice_questions')
        setQuestionTypeError('');
        setCurrentQuestionData([]);
        setLoadingCkeditor(false);
        if (updateQuestionsData.length > 0) {
            setQuestionNumber(updateQuestionsData.length + 1);
        } else {
            setQuestionNumber("1");
        }
        setQuestionsData([]);
        setCanShowCKeditorAddPopup(true);
        setCanShowCkeditor(false);
        setTopicError("");
        setChapterError("");
        getStatusSaveBtn();
        setTopicList([]);
        setSubjectList([]);
        setSelectStandardUuid("");
        setSelectSubjectUuid("");
        // setSelectChapterUuid("");
        setSelectTopicUuid("");
        setTimeout(() => {
            setLoadingCkeditor(false);
        }, 2000);
    }

    function getOptions(index: number) {
        for (let i = 0; i < alphabets.length; i++) {
            if (index === i) {
                return alphabets[i];
            }
        }
    }

    function createMarkup(name: any) {

        return { __html: name };
    }

    function handleAddQuestionOptions(item: any, index: number, e: any) {
        for (let i = 0; i < alphabets.length; i++) {
            if ((index - 1) === i) {
                setCorrectAnswer(alphabets[i]);
            }
        }
    }

    function getStatusSaveBtn(optionType?: string) {
        if (questionsData.length > 1) {
            return false;
        } else {
            return true;
        }
    }
    function getCkeditorValue(type: any) {
        if (type === 'question') {
            if (questionsData.length > 0) {
                return questionsData[0];
            }
        } else {
            const preparedOptions = [];
            const data = questionsData.slice(1);
            for (let i = 0; i < data.length; i++) {
                preparedOptions.push({
                    option: getOptions(i),
                    value: data[i]
                })
            }
            return preparedOptions;
        }
    }

    function getQuestionsData() {
        const data = [];
        const payloads = {
            "id": "",
            "uuid": "",
            "question_type": selectedOption,
            "question": getCkeditorValue('question'),
            // "images": questionImg,
            "options": getCkeditorValue('options'),
            "correct_answer": correctAnswer ? correctAnswer : ''
        }
        data.push(payloads);
        return data;


    }

    const handleAddClose = () => {
        setCanShowCKeditorAddPopup(false);
        getQuestionsList();
        setCurrentQuestionData([]);
        setQuestionIndex(null);
        setOptionIndex(null);
        setQuestionImg([]);

    }

    function onAddSave() {
        setCanShowCkeditor(true);

        if (editor.getData()) {
            questionsData.push(inputData);
        }
    }
    function addMatchedOption(optionType: string) {
        setCanShowCkeditor(false);
        getStatusSaveBtn(optionType);
    }

    function addAnswerOptions() {
        setCanShowCkeditor(false);
        getStatusSaveBtn();
    }
    const onPageChange = (data: any) => {
        const pageNumber = data - 1;
        setActivePage(pageNumber);
        setLoading(true);
        // GET_ABHYAS_QUESTION_LIST_TEST(activePage, {}).then((res: any) => {

        GET_ABHYAS_QUESTION_LIST(sheetUuid, {}).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                    setLoading(false);
                } else {
                    const data = res['rows'];
                    data?.forEach((element: any, i: number) => {
                        element.created_dt = moment(element?.created_dt?.split("T")[0]).format('DD-MM-yyyy');
                        if (element?.modified_dt) {
                            element.modified_dt = moment(element?.modified_dt?.split("T")[0]).format('DD-MM-yyyy');
                        }
                        element.data = JSON.parse(element.data)
                    });
                    const UpdatedElement = [...data[0]?.data]
                    UpdatedElement?.map((element: any, i: number) => {
                        const doc = parser.parseFromString(element?.question, 'text/html');
                        const pElement = doc.querySelector('p');
                        if (pElement) {
                            element.question_name = pElement.textContent;
                        }
                        else {
                            element.question_name = element.question;
                        }
                    });
                    setQuestionsList(UpdatedElement);
                    if (UpdatedElement && UpdatedElement?.length > 0) {
                        setUpdateQuestionsData(UpdatedElement);
                    } else {
                        setUpdateQuestionsData([]);
                    }
                    setPageNumbers(pageArray);
                    setLoading(false);
                }
            }
        });

    }

    const onDelete = (data: any) => {
        data.mode = "delete";
        setLoading(true);
        UPDATE_ABHYAS_QUESTION([data], sheetUuid).then((res: any) => {
            if (res.status === 'fail') {
                const errorMessage = Array.isArray(res?.err) ? res?.err[0] : res?.err
                toast.error(errorMessage);
                setLoading(false);
            } else {
                setLoading(false);
                toast.success(res.message);
                getQuestionsList();
            }
        });

    }
    const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = event.target.value;
        setSelectedOption(selectedValue);
        setQuestionTypeError("");

    }
    function handleUploadLogo(e: any, type: string) {
        if (e.target.files && e.target.files[0]) {
            const fileType = e.target.files[0].name.split('.').pop()
            if (fileType == "jpeg" || fileType == "jpg") {
                const formData = new FormData();
                formData.append('file', e.target.files[0], e.target.files[0].name);
                uploadLogo(formData, type);
            } else {
                toast.error("Valid file type .jpg, .jpeg only");
            }
        }
    }
    function uploadLogo(formdata: any, type: string) {
        setImageLoader(true);
        UPLOAD_LOGO(formdata).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res?.err);
                setImageLoader(false);
            } else {
                let imgUrl = res.url
                questionImg.push(imgUrl)
                setImageLoader(false);
            }
        });
    }
    const handleRemoveItem = (index: number) => {
        const updatedItems = [...questionImg];
        updatedItems.splice(index, 1);
        setQuestionImg(updatedItems);
    };

    const uploadAdapter = (loader: any) => {
        return {
            upload: () => {
                return new Promise((resolve, reject) => {
                    const body = new FormData();
                    loader.file.then((file: any) => {
                        body.append("file", file);
                        UPLOAD_LOGO(body).then((res: any) => {
                            if (res.status === 'fail') {
                                toast.error(res?.err);
                            } else {
                                let imgUrl = res.url
                                resolve({ default: `${res?.base_url}${imgUrl}` })
                            }
                        });
                    })
                })
            }
        }
    }
    const uploadPlugin = (editor: any) => {

        if (editor) {
            editor.pulgin.get('FileRepository').createUploadAdapter = (loader: any) => {
                return uploadAdapter(loader);
            };
        }
    }
    const handleRemoveImage = () => {
        // const editor = editorRef.current.editor;
        if (editor) {
            editor.execute('imageRemove');
        }
    };

    function extractPTags(htmlString: string) {
        const parsedHtml = JSON.parse(htmlString);
        for (const element of parsedHtml) {
            if (element.type === 'tag' && element.name === 'p' && element.children) {
                const pContent = element.children.map((child: { text: any; }) => child.text || '').join('');
                setPTagContent(pContent);
            }
            if (element.type === 'tag' && element.name === 'figure' && element.children) {
                const figureContent = element.children.map((child: { text: any; }) => child.text || '').join('');
                setFigureElements(figureContent);
            }
        }

    }
    function extractFigureTags(htmlString: string): string[] {
        const parsedHtml = JSON.parse(htmlString);
        const figureTagsContent: string[] = [];
        for (const element of parsedHtml) {
            if (element.type === 'tag' && element.name === 'p' && element.children) {
                const pContent = element.children.map((child: { text: any; }) => child.text || '').join('');
                figureTagsContent.push(pContent);
            }
        }

        return figureTagsContent;
    }
    const handleTopicClick = () => {
        if (props.onSelectTopic) {
            props.onSelectTopic(props.chapterUuid);
        }
    };
    const handleSelectClick = () => {
        if (props.selectList) {
            props.selectList(props.topicUuid, props.topicName);
        }
    };
    const onChangeStandard = (e?: any) => {
        setSubjectList([]);
        setTopicList([]);
        setSelectSubjectUuid("");
        setSelectTopicUuid("");
        setSelectStandardUuid("");
        if (e) {
            setSelectStandardUuid(e);
            const payLoad = {
                board_uuid: boardUuid,
                medium_uuid: mediumUuid,
                standard_uuid: e
            }
            GET_SUBJECT_LIST_BY_STANDARD(payLoad).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res?.err);

                    } else {
                        const data = res['rows'];
                        setSubjectList(data);
                    }
                }
            });
        }


    }
    const onChangeSubject = (e: any) => {
        setTopicList([]);
        setSelectTopicUuid("");
        setSelectSubjectUuid("");
        if (e) {
            setSelectSubjectUuid(e);
            const payLoad = {
                board_uuid: boardUuid,
                medium_uuid: mediumUuid,
                standard_uuid: selectStandardUuid,
                subject_uuid: e
            }
            GET_TOPIC_DATA_LIST(payLoad).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res?.err);
                    } else {
                        const data = res['rows'];
                        setTopicList(data);
                    }
                }
            })
        }
    }

    const onChangeTopic = (e: any) => {
        setSelectTopicUuid("");
        if (e) {
            setSelectTopicUuid(e);
            setTopicError("");
        } else {
            setTopicError("Please select topic")
        }
    }
    const handleNext = () => {
        if (currentIndex < updateQuestionsData.length - 1) {
            const newIndex = currentIndex + 1;
            setCurrentIndex(newIndex);
            onClickEdit(updateQuestionsData[newIndex], newIndex, "backNext");
        }
    };

    const handleBack = () => {
        if (currentIndex > 0) {
            const newIndex = currentIndex - 1;
            setCurrentIndex(newIndex);
            onClickEdit(updateQuestionsData[newIndex], newIndex, "backNext");
        }
    };
    return (
        <div>
            <div
                className={`modal fade ${canShowPopup ? "show" : "false"}`}
                style={{ display: canShowPopup ? "block" : "none" }}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Question Information
                            </h5>
                            <button
                                type="button"
                                className="close popup-close cursor-pointer"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={handleClose}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body parent-info ">

                        </div>
                        <div className="modal-footer">

                            <button
                                type="button"
                                className="btn btn-secondary cursor-pointer btn-sm px-4 rounded-12 cursor-pointer py-2"
                                data-dismiss="modal"
                                onClick={handleClose}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`modal fade parent-modal-fade ${canShowCKeditorPopup ? 'show' : 'false'}`} style={{ display: (canShowCKeditorPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog worksheet-quesions-dialog" role="document">
                    <div className="modal-content worksheet-questions-model">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Update Question</h5>
                            <button type="button" className="close popup-close cursor-pointer" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body" style={{ overflowX: 'hidden' }}>
                            {sheetType === "TESTS" && <div className='row mt-1 mb-3'>
                                <div className='col-md-4'>
                                    <div className="form-group ">
                                        <label className='control-label w-50 fw-bold mb-1'> Standard</label>
                                        <select name="standard_uuid" id="standard_uuid" className="form-select ft-14 " value={selectStandardUuid} onChange={(e) => { onChangeStandard(e.target.value) }}>
                                            <option value="">Select Standard</option>
                                            {standardList?.map((name: any, i: number) => (
                                                <>
                                                    {(name?.uuid !== standardUuid && (standardNo - 1 == name?.standard || standardNo - 2 == name?.standard)) && <option key={i} value={name?.uuid}>
                                                        {name?.standard}

                                                    </option>}
                                                </>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className="form-group ">
                                        <label className='control-label w-50 fw-bold mb-1'> Subject</label>
                                        <select name="standard_uuid" id="standard_uuid" className="form-select ft-14 " value={selectSubjectUuid} onChange={(e) => { onChangeSubject(e.target.value) }}>
                                            <option value="">Select Subject</option>
                                            {subjectList?.map((name: any, i: number) => (
                                                <option key={i} value={name?.uuid}>
                                                    {name?.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className='col-md-4'>
                                    <div className="form-group ">
                                        <label className="control-label  fw-bold">Topic</label>
                                        <select name="topic_uuid" id="topic_uuid" className="form-select  ft-14 mt-1" value={selectTopicUuid} onChange={(e) => { onChangeTopic(e.target.value) }}>
                                            <option value="">Select Topic</option>
                                            {topicList?.map((name: any, i: number) => (
                                                <option key={i} value={name?.uuid}>
                                                    {name?.name}
                                                </option>
                                            ))}
                                        </select>
                                        {topicError && <p className='text-danger mb-0'>{topicError}</p>}
                                    </div>
                                </div>
                            </div>}

                            {loadingCkeditor &&
                                <div className="text-center p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            }

                            {!loadingCkeditor && <div>

                                <div className='cdk-question-view'>
                                    {currentQuestionData?.map((question: any, i: number) => (

                                        <div className="mt-1" key={i}>
                                            <div className='row'>
                                                <div className='row'>
                                                    <div className='col-md-1 pt-4' >
                                                        <span className='fw-bold' style={{ fontSize: '22px' }}>Q{question?.id}:</span>
                                                    </div>
                                                    <div className='col-md-7 mb-3 ' ref={listRef} style={{ paddingTop: '25px' }}>
                                                        <span className='fw-bold' style={{ fontSize: '22px' }}>
                                                            <MathJaxContext>
                                                                <MathJax>
                                                                    <div className='img_ck' dangerouslySetInnerHTML={onViewQuestion(question?.question)}></div>
                                                                </MathJax>
                                                            </MathJaxContext>
                                                        </span>
                                                    </div>

                                                    <div className='col-md-2  px-0 pb-0 pt-4' >
                                                        <i className="bi bi-pencil-square cursor-pointer edit-icon p-2" onClick={() => onEditQuestion(question, i)}></i>
                                                    </div>

                                                </div>
                                                <div className='row pt-5'>
                                                    <div className='col-md-9'>
                                                        {question?.options?.map((options: any, j: number) => (
                                                            <div className='row' key={j}>
                                                                <div className='col-md-1'>
                                                                    <span>{options.option}.</span>
                                                                </div>
                                                                <div className='col-md-9'>
                                                                    <div className='row' style={{ position: 'relative' }}>
                                                                        <div className='col-md-8'>
                                                                            <MathJaxContext>
                                                                                <MathJax>
                                                                                    <span className='text-break img_ck' dangerouslySetInnerHTML={onViewQuestion(options.value)} ></span>
                                                                                </MathJax>
                                                                            </MathJaxContext>
                                                                        </div>
                                                                        <div className='col-md-4'>
                                                                            {j === 0 && <div style={{ position: 'absolute', top: '-30px', left: '390px' }}>Choose Correct Answer</div>}
                                                                            <input type='radio' name="option" defaultChecked={question.correct_answer === options.option} onChange={(e) => { handleQuestionOptions(options, j) }} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-2'>
                                                                    <i className="bi bi-pencil-square cursor-pointer edit-icon p-2" onClick={() => onEditOptions(question, i, options, j)}></i>
                                                                    <i className="bi bi-trash cursor-pointer edit-icon p-2" onClick={() => onDeleteOptions(question, i, options, j)}></i>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div className='col-md-3 mb-2'>
                                                        <button className='btn btn-primary' onClick={() => onAddAnswerOptions()}>Add Answer Options</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                {/* </MathJax>
                                </MathJaxContext> */}
                                {canShowCkeditor && <div className='mt-3'>
                                    <MathJaxContext>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            id="editorId"
                                            data={questionData}
                                            config={{
                                                toolbar: {
                                                    items: [
                                                        'heading', 'MathType', 'ChemType',
                                                        '|',
                                                        'bold',
                                                        'italic',
                                                        'link',
                                                        'bulletedList',
                                                        'numberedList',
                                                        'imageUpload',
                                                        'mediaEmbed',
                                                        'insertTable',
                                                        'blockQuote',
                                                        'undo',
                                                        'redo',
                                                        '|',
                                                        'imageStyle:full', 'imageStyle:side',
                                                        'imageTextAlternative',
                                                        'imageResize',
                                                        'imageUploadMultiple',
                                                        // 'imageInsert',
                                                        // 'imageUploadMultiple',
                                                        // 'removeImage',
                                                    ]
                                                },
                                                language: 'en',
                                            }}

                                            onChange={(event: any, editor: any) => {
                                                return onChange(event, editor);
                                            }}
                                            onReady={(editor: any) => {
                                                // You can store the "editor" and use when it is needed.
                                                editorRef.current = editor;
                                                editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
                                                    return uploadAdapter(loader);
                                                };
                                            }}
                                        />
                                        <MathJax>
                                        </MathJax>
                                    </MathJaxContext>
                                    <div className='mt-2'>
                                        <button className='btn btn-primary' onClick={() => onSave()}>Ok</button>&nbsp;
                                    </div>
                                </div>}
                            </div>}
                        </div>
                        <div className="modal-footer d-flex justify-content-between">
                            <div className=''>
                                {updateQuestionsData?.length > 1 && <div>
                                    <button className={`q_nxt_btn ms-5 me-4  f16 ${currentIndex === 0 && "btn_disabled"}`} onClick={handleBack} disabled={currentIndex === 0}>
                                        Back
                                    </button>
                                    <button className={`q_nxt_btn  f16 ${currentIndex === updateQuestionsData.length - 1 && "btn_disabled"}`} onClick={handleNext} disabled={currentIndex === updateQuestionsData.length - 1}>
                                        Next
                                    </button>
                                </div>}
                            </div>
                            <div className='questions-save-btn'>
                                <button className='btn btn-primary me-3' onClick={() => onUpdate()}>Update</button>
                                <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleClose}>Close</button>&nbsp;
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`modal fade parent-modal-fade ${canShowCKeditorAddPopup ? 'show' : 'false'}`} style={{ display: (canShowCKeditorAddPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog worksheet-quesions-dialog" role="document">
                    <div className="modal-content worksheet-questions-model">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Add Question </h5>
                            <button type="button" className="close popup-close cursor-pointer" data-dismiss="modal" aria-label="Close" onClick={handleAddClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body" style={{ overflowX: 'hidden' }}>
                            {sheetType === "TESTS" && <div className='row mt-1 mb-3'>
                                <div className='col-md-4'>
                                    <div className="form-group ">
                                        <label className='control-label w-50 fw-bold mb-1'> Standard</label>
                                        <select name="standard_uuid" id="standard_uuid" className="form-select ft-14 " value={selectStandardUuid} onChange={(e) => { onChangeStandard(e.target.value) }}>
                                            <option value="">Select Standard</option>
                                            {standardList?.map((name: any, i: number) => (
                                                <>
                                                    {(name?.uuid !== standardUuid && (standardNo - 1 == name?.standard || standardNo - 2 == name?.standard)) && <option key={i} value={name?.uuid}>
                                                        {name?.standard}

                                                    </option>}
                                                </>
                                            ))}
                                        </select>
                                        {/* {standardError && <p className='text-danger'>Please select standard</p>} */}
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className="form-group ">
                                        <label className='control-label w-50 fw-bold mb-1'> Subject</label>
                                        <select name="standard_uuid" id="standard_uuid" className="form-select ft-14 " value={selectSubjectUuid} onChange={(e) => { onChangeSubject(e.target.value) }}>
                                            <option value="">Select Subject</option>
                                            {subjectList?.map((name: any, i: number) => (
                                                <option key={i} value={name?.uuid}>
                                                    {name?.name}
                                                </option>
                                            ))}
                                        </select>
                                        {/* {subjectError && <p className='text-danger'>Please select subject</p>} */}
                                    </div>
                                </div>
                                {/* <div className='col-md-4'>
                                    <div className="form-group ">
                                        <label className='control-label w-50 fw-bold mb-1'> Chapter Name</label>
                                        <select name="chapter_uuid" id="chapter_uuid" className="form-select ft-14 " value={selectChapterUuid} onChange={(e) => { onChangeChapter(e.target.value) }}>
                                            <option value="">Select Chapter</option>
                                            {chapterList?.map((name: any, i: number) => (
                                                <option key={i} value={name?.uuid}>
                                                    {name?.name}
                                                </option>
                                            ))}
                                        </select>
                                        {chapterError && <p className='text-danger'>{chapterError}</p>}
                                    </div>
                                </div> */}
                                <div className='col-md-4'>
                                    <div className="form-group ">
                                        <label className="control-label  fw-bold">Topic</label>
                                        <select name="topic_uuid" id="topic_uuid" className="form-select  ft-14 mt-1" value={selectTopicUuid} onChange={(e) => { onChangeTopic(e.target.value) }}>
                                            <option value="">Select Topic</option>
                                            {topicList?.map((name: any, i: number) => (
                                                <option key={i} value={name?.uuid}>
                                                    {name?.name}
                                                </option>
                                            ))}
                                        </select>
                                        {topicError && <p className='text-danger'>{topicError}</p>}
                                    </div>

                                </div>
                            </div>}

                            {/* <div className='mb-4 d-md-flex'>
                                <h6 className='me-3 mt-md-2'>Question Type:</h6>
                                <div>
                                    <select className="form-select form-select-lg mb-2 w-100 f16" name="question_type" value={selectedOption} onChange={handleOptionChange}>
                                        <option value="multiple_choice_questions">Multiple Choice</option>
                                        <option value="fill_in_the_blanks">Fill in the blanks</option>
                                        <option value="match_the_following">Match the following</option>
                                        <option value="true_or_false">True or False</option>
                                    </select>
                                    {questionTypeError && <p className='text-inprogess f14 mb-1'>{questionTypeError}</p>}
                                </div>
                            </div> */}
                            {/* <div className="mb-4">
                                <div className="file btn btn-sm btn-primary px-4 rounded-12 mt-2 d-inline-block">Upload Images
                                    <input type="file" accept="image/*" name="logo_url" onChange={(e) => handleUploadLogo(e, "school")} />
                                </div>
                            </div>
                            <div className='row'>

                                {imageLoader &&
                                    <div className="text-center col-md-1 p-5">
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                }
                               
                                {questionImg?.map((img: any, i: number) => (
                                    <div className="col-md-2" key={i}>

                                        <div className="mb-4">
                                            <img src={`${appConfig.FILE_UPLOAD_URL}${img?.charAt(0) === "/" ? img?.substring(1) : img}`} style={{ width: '100px', height: '100px', objectFit: "contain" }} /> <span onClick={() => handleRemoveItem(i)}><i className='bi bi-trash cursor-pointer p-2'></i></span>
                                        </div>
                                    </div>
                                ))}
                            </div> */}
                            {loadingCkeditor &&
                                <div className="text-center p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            }
                            {!loadingCkeditor && <div>
                                <div className='mt-2'>
                                    <h6>Question: {questionNumber}</h6>
                                </div>
                                {(questionsData && questionsData.length > 0) && <div className='mt-2'>
                                    <div className='cdk-question-view'>
                                        {questionsData && <div className='row' style={{ position: 'relative' }}>
                                            {questionsData?.map((name: any, i: number) => (
                                                <div key={i} className=' col-md-8 row' style={{
                                                    marginTop: i !== 0 ? '15px' : '2px',
                                                    marginBottom: i !== 0 ? '5px' : '25px',
                                                    position: i !== 0 ? 'relative' : 'static'
                                                }}>
                                                    {i !== 0 && <div className='col-md-1'>
                                                        <span>{getOptions(i - 1)}.</span>
                                                    </div>}
                                                    <div className={i !== 0 ? 'col-md-4' : 'col-md-11'}>
                                                        <MathJaxContext>
                                                            <MathJax>
                                                                <div style={{ fontSize: i !== 0 ? '14px' : '25px' }} dangerouslySetInnerHTML={createMarkup(name)} >
                                                                </div>
                                                            </MathJax>
                                                        </MathJaxContext>
                                                    </div>

                                                    {(i !== 0 && questionsData.length >= 3) && <div className='col-md-4'>
                                                        {(i !== 0 && i === 1) && <div className='mt-2' style={{ position: 'absolute', top: '-40px', left: '240px' }}>
                                                            <div>Choose Correct Answer</div>
                                                        </div>}
                                                        <div className='col-md-1'>
                                                            <input type='radio' name="option" onChange={(e) => { handleAddQuestionOptions(name, i, e) }} />
                                                        </div>
                                                    </div>}
                                                </div>
                                            ))}
                                            <div className='col-md-3 mb-2'>
                                                <button className='btn btn-primary' onClick={() => addAnswerOptions()}>Add Answer Options</button>
                                            </div>
                                        </div>}
                                    </div>
                                    {/* {selectedOption === "match_the_following" && <div className='cdk-question-view'>
                                        {questionsData && <div className='row' style={{ position: 'relative' }}>

                                            <div>
                                                {questionsData.map((name: any, i: number) => (
                                                    <>
                                                        {i == 0 && <div className={i !== 0 ? 'col-md-4' : 'col-md-11'}>
                                                            <MathJaxContext>
                                                                <MathJax>
                                                                    <div style={{ fontSize: i !== 0 ? '14px' : '25px' }} dangerouslySetInnerHTML={createMarkup(name)}>
                                                                    </div>
                                                                </MathJax>
                                                            </MathJaxContext>
                                                        </div>}
                                                    </>
                                                ))}
                                            </div>
                                           
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <button className='btn btn-primary' onClick={() => addMatchedOption("left")}>Add Left Options</button>

                                                    {matchLeftOptions?.map((name: any, i: number) => (
                                                        <div key={i} className=' col-md-8 row' style={{
                                                            marginTop: i !== 0 ? '15px' : '2px',
                                                            marginBottom: i !== 0 ? '5px' : '25px',
                                                            position: i !== 0 ? 'relative' : 'static'
                                                        }}>
                                                            {i !== 0 && <div className='col-md-1'>
                                                                <span>{getOptions(i - 1)}.</span>
                                                            </div>}
                                                            {i !== 0 && <div className={i !== 0 ? 'col-md-4' : 'col-md-11'}>
                                                                <MathJaxContext>
                                                                    <MathJax>
                                                                        <div style={{ fontSize: i !== 0 ? '14px' : '25px' }} dangerouslySetInnerHTML={createMarkup(name)}>
                                                                        </div>
                                                                    </MathJax>
                                                                </MathJaxContext>
                                                            </div>}


                                                        </div>
                                                    ))}

                                                </div>
                                                <div className='col-md-4'>
                                                    <h6 className='mb-5'>Select Answers</h6>
                                                    <div >
                                                        {matchLeftOptions?.map((name: any, i: number) => (
                                                            <>
                                                                {(i !== 0 && questionsData.length >= 3) && <div className='mt-4'>

                                                                    <div >
                                                                        <input type='select' name="option" onChange={(e) => { handleAddQuestionOptions(name, i, e) }} />
                                                                    </div>
                                                                </div>}
                                                            </>
                                                        ))}
                                                    </div>

                                                </div>
                                                <div className='col-md-4'>
                                                    <button className='btn btn-primary' onClick={() => addMatchedOption("right")}>Add Right Options</button>
                                                    {matchRightOptions?.map((name: any, i: number) => (
                                                        <div key={i} className=' col-md-8 row' style={{
                                                            marginTop: i !== 0 ? '15px' : '2px',
                                                            marginBottom: i !== 0 ? '5px' : '25px',
                                                            position: i !== 0 ? 'relative' : 'static'
                                                        }}>
                                                            {i !== 0 && <div className='col-md-1'>
                                                                <span>{getOptions(i - 1)}.</span>
                                                            </div>}
                                                            {i !== 0 && <div className={i !== 0 ? 'col-md-4' : 'col-md-11'}>
                                                                <MathJaxContext>
                                                                    <MathJax>
                                                                        <div style={{ fontSize: i !== 0 ? '14px' : '25px' }} dangerouslySetInnerHTML={createMarkup(name)}>
                                                                        </div>
                                                                    </MathJax>
                                                                </MathJaxContext>
                                                            </div>}


                                                        </div>
                                                    ))}
                                                </div>

                                            </div>
                                        </div>}
                                    </div>} */}
                                </div>}
                                {!canShowCkeditor && <div className='mt-3'>
                                    <MathJaxContext>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            id="editorId"
                                            data=''
                                            config={{
                                                // plugins: [Image, ImageResizeEditing, ImageResizeHandles],
                                                toolbar: {
                                                    items: [
                                                        'heading', 'MathType', 'ChemType',
                                                        '|',
                                                        'bold',
                                                        'italic',
                                                        'link',
                                                        'bulletedList',
                                                        'numberedList',
                                                        'imageUpload',
                                                        'mediaEmbed',
                                                        'insertTable',
                                                        'blockQuote',
                                                        'undo',
                                                        'redo',
                                                        '|',
                                                        'imageStyle:full', 'imageStyle:side',
                                                        'imageTextAlternative',
                                                        'imageResize',
                                                        'imageUploadMultiple',
                                                        // 'imageInsert',
                                                        // 'imageUploadMultiple',
                                                        // 'removeImage',
                                                        // 'resizeImage:50',
                                                        // 'resizeImage:75',
                                                        // 'resizeImage:original',

                                                    ]
                                                },
                                                // image: {
                                                //     toolbar: [
                                                //         'imageStyle:block',
                                                //         'imageStyle:side',
                                                //         '|',
                                                //         'toggleImageCaption',
                                                //         'imageTextAlternative',
                                                //         '|',
                                                //         'linkImage'
                                                //     ]
                                                // },
                                                image: {
                                                    resizeOptions: [{
                                                        name: 'resizeImage:original',
                                                        value: null,
                                                        label: 'Original Size',
                                                        icon: 'original'
                                                    },
                                                    {
                                                        name: 'resizeImage:25',
                                                        value: '25',
                                                        label: '25%',
                                                        icon: 'small'
                                                    },
                                                    {
                                                        name: 'resizeImage:50',
                                                        value: '50',
                                                        label: '50%',
                                                        icon: 'medium'
                                                    },
                                                    {
                                                        name: 'resizeImage:75',
                                                        value: '75',
                                                        label: '75%',
                                                        icon: 'large'
                                                    }
                                                    ],
                                                    toolbar: [
                                                        'imageStyle:inline',
                                                        'imageStyle:block',
                                                        'imageStyle:side',
                                                        '|',
                                                        'toggleImageCaption',
                                                        'imageTextAlternative',
                                                        // 'resizeImage',
                                                        'resizeImage:25',
                                                        'resizeImage:50',
                                                        'resizeImage:75',
                                                        'resizeImage:original',
                                                    ],
                                                },
                                                language: 'en',

                                            }}

                                            onChange={(event: any, editor: any) => {
                                                return onChange(event, editor);
                                            }}
                                            onReady={(editor: any) => {
                                                // You can store the "editor" and use when it is needed.
                                                editorRef.current = editor;
                                                editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
                                                    return uploadAdapter(loader);
                                                };
                                                setEditor(editor);
                                            }}

                                        />
                                        <MathJax>
                                        </MathJax>
                                    </MathJaxContext>
                                    <div className='mt-2'>
                                        <button className='btn btn-primary' disabled={editor && editor.getData() ? false : true} onClick={() => onAddSave()}>Ok</button>&nbsp;
                                    </div>
                                </div>}
                            </div>}
                        </div>
                        <div className="modal-footer">
                            <div className='questions-save-btn'>
                                <button className='btn btn-primary' disabled={getStatusSaveBtn()} onClick={() => onSaveAddQuestion()}>Save</button>
                            </div>
                            <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleAddClose}>Close</button>&nbsp;
                        </div>
                    </div>
                </div>
            </div>
            <div className={`mt-3 ${props.display === "none" && "d-none"}`}>
                <Breadcum breadcumList={breadcumInfo} margin={"classic"} />
            </div>
            {/* <div>
                <h5 className="modal-title" id="exampleModalLabel">
                    Test No: {testUuid === "0" ?
                        (<span>{testList.length + 1}</span>)
                        : (<span> {currentFormData && currentFormData?.number}</span>)
                    }
                </h5>
            </div> */}
            {props.isLinked === "false" && <nav aria-label="breadcrumb" className="first">
                <ol className={`breadcrumb indigo lighten-6 first mb-3 bg-dark navbar-light  px-md-4`}>
                    <li className="breadcrumb-item font-weight-bold font-italic">
                        <span className='cursor-pointer' onClick={handleTopicClick}><span className='breadcum-label mr-md-3 mr-2 black-text text-uppercase active'>{props?.topicName} </span> <span className='font-weight-bold f10 text-black ms-1 fw_bold '>( Topic )</span></span>
                        <i className="bi bi-chevron-right breadcum-arrow"></i>
                    </li>
                    <li className="breadcrumb-item font-weight-bold font-italic">
                        <span className='cursor-pointer' onClick={handleSelectClick}><span className='breadcum-label mr-md-3 mr-2 black-text text-uppercase active'>{props.worksheetNumber} </span> <span className='font-weight-bold f10 text-black ms-1 fw_bold '>( Worksheet )</span></span>
                        <i className="bi bi-chevron-right breadcum-arrow"></i>
                    </li>
                    <li className="breadcum-active-label ">
                        <span className='black-text text-uppercase active-1 disabled-link active'>Questions</span>
                    </li>
                </ol>
            </nav>}
            <div className="d-flex  ms-2 justify-content-between mt-1">
                <h5 className="">Questions</h5>
                <p className="mb-0"><button className="btn btn-primary " onClick={() => onAddQuestion()}>Add Question</button></p>
            </div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                // <div className="mt-3">
                //     <MathJaxContext>
                //         <MathJax>
                //             <DataTable TableCols={AbhyasQuestionDataGridCols} tableData={questionsList}
                //                 editInfo={onClickEdit}
                //                 deleteInfo={onDelete}
                //                 pageNumber={onPageChange} activePageNumber={activePage} pageNumbers={pageArray}></DataTable>
                //         </MathJax>
                //     </MathJaxContext>
                // </div>

                <div>
                    <MathJaxContext>
                        <MathJax>
                            {questionsList?.map((question: any, i: number) => (
                                <div className="mt-4" key={i}>
                                    <div className='row'>
                                        <div className='col-md-11'>
                                            <div className='row'>
                                                {/* <div className='col-md-1' style={{ verticalAlign: 'middle', margin: 'auto' }}>
                                                    <span className='fw-bold' style={{ fontSize: '22px' }}>Q{question.id}:</span>
                                                </div> */}
                                                <div className='col-md-12' style={{ paddingTop: '15px' }}>
                                                    <div className='d-flex'>
                                                        <p className='fw-bold mb-0' style={{ fontSize: '22px' }}>
                                                            Q{question.id}:
                                                        </p>
                                                        <div className='ms-4 ps-2'>
                                                            <span className='fw-bold' style={{ fontSize: '22px' }}>
                                                                {/* <MathJaxContext>
                                                        <MathJax> */}
                                                                <div dangerouslySetInnerHTML={onViewQuestion(question.question)}></div>
                                                                {/* </MathJax>
                                                    </MathJaxContext> */}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {question.options.map((options: any, j: number) => (
                                                <div className='row' key={j}>
                                                    <div className='col-md-1'>
                                                        <span>{options.option}.</span>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        {/* <MathJaxContext>
                                                        <MathJax> */}
                                                        <span dangerouslySetInnerHTML={onViewQuestion(options.value)}></span>
                                                        {/* </MathJax>
                                                    </MathJaxContext> */}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div className='col-md-1' style={{ paddingTop: '15px' }}>
                                            <i className="bi bi-pencil-square cursor-pointer edit-icon p-2" onClick={() => onClickEdit(question, i)}></i>
                                            <i className="bi bi-trash cursor-pointer p-2" onClick={() => onDelete(question)}></i>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </MathJax>
                    </MathJaxContext>
                </div>
            }

        </div >
    );
};

export default AbhyasQuestionsList;
