import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { CREATE_SCHOOL_USER, UPLOAD_LOGO } from '../../../../app/service/school.service';
import { GET_USER_BY_ID, UPDATE_USER } from '../../../../app/service/users.service';
import FormBuilder from '../../../../components/form-builder';
import { FormControlError, FormField, FormValidators } from '../../../../components/form-builder/model/form-field';
import { FormValidator, GetControlIsValid } from '../../../../components/form-builder/validations';
import { toast } from 'react-toastify';
import moment from 'moment';
import errorMessages from '../../../../components/error-messages';
import "react-datepicker/dist/react-datepicker.css";
import { DateComponent } from '../../../../components/date';
import ProfileImageCrop from '../../../../components/image-crop/crop';

interface Props {
    userId: any;
    instituteCode: any;
    handleUpdateCancel: () => void;
    setChairmanView: any
}

export const InstituteProfileChairmanForm = (props: Props) => {
    const [chairmanErrors, setChairmanErrors] = useState<FormControlError[]>([]);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [chairmanData, setChairmanData] = useState<any>({});
    const [chairmanLogo, setChairmanLogo] = useState<any>();
    const [currentChairmanData, setCurrentChairmanData] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const [imageLoader, setImageLoader] = useState(false);
    const [mobileNumber, setMobileNumber] = useState(false);
    const [whatsappNumber, setWhatsappNumber] = useState(false);
    const [email, setEmail] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const institutesCode = props.instituteCode;
    const usersId: number = props.userId ? parseInt(props.userId) : 0;
    const [nameError, setNameError] = useState('');
    const [isDateError, setIsDateError] = useState<any>({});
    const [checkedUserMechanismStatus, setCheckedUserMechanismStatus] = useState<any>();
    const chairmanFormValidations = [
        new FormField('name', [FormValidators.REQUIRED]),
        new FormField('gender', [FormValidators.REQUIRED]),
        new FormField('date_of_birth', []),
        new FormField('blood_group', []),
        new FormField('city_village', []),
        new FormField('email_id', [FormValidators.REQUIRED]),
        new FormField('mobile_number', [FormValidators.REQUIRED]),
        new FormField('whatsapp_number', []),
    ];

    useEffect(() => {
        if (usersId > 0) {
            setLoading(true);
            GET_USER_BY_ID(usersId).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    setLoading(false);
                    const data = res.rows[0];
                    if (data.date_of_birth) {
                        const date_of_birth: any = new Date(data.date_of_birth);
                        setStartDate(date_of_birth);
                    }
                    if (data.mobile_number === data.whatsapp_number) {
                        data.checked = true;
                    }

                    if (data.access) {
                        setCheckedUserMechanismStatus(data.access);
                    }

                    setCurrentChairmanData(data);
                    setChairmanLogo(data.profile_img);
                }
            });
        }
    }, []);

    const getChairmanInputValid = (control: string) => {
        const value = GetControlIsValid(chairmanErrors, control);
        return value;
    }

    const handleChairmanInput = (data: any) => {
        data.value = { ...currentChairmanData, ...data.value };
        setChairmanData(data);
        const errors: any = FormValidator(chairmanFormValidations, data.value);
        setChairmanErrors(errors);
    };

    function handleUploadLogo(e: any, type: string) {
        if (e.target.files && e.target.files[0]) {
            const fileType = e.target.files[0].name.split('.').pop()
            if (fileType == "jpeg" || fileType == "jpg") {
                const formData = new FormData();
                formData.append('file', e.target.files[0], e.target.files[0].name);
                uploadLogo(formData, type);
            } else {
                toast.error("Valid file type .jpg, .jpeg only");
            }
        }
    }

    function uploadLogo(formdata: any, type: string) {
        setImageLoader(true);
        UPLOAD_LOGO(formdata).then((res: any) => {
            setChairmanLogo(res.url);
            if (res.status === 'fail') {
                toast.error(res.message);
                setImageLoader(false);
            } else {
                setChairmanLogo(res.url);
                setImageLoader(false);
            }
        });
    }

    function createChairman() {
        const chairmansData = chairmanData.value ? { ...chairmanData.value } : { ...currentChairmanData };
        setIsFormSubmitted(true);
        const errors: FormControlError[] = FormValidator(chairmanFormValidations, chairmansData);
        setChairmanErrors(errors);
        chairmansData.type = "institute";
        chairmansData.user_role = "CHAIRMAN";
        chairmansData.institute_school_code = institutesCode;
        chairmansData.profile_img = chairmanLogo;
        chairmansData.date_of_birth = startDate ? moment(startDate).format('YYYY-MM-DD') : null;
        delete chairmansData.sameMobileNumber;
        delete chairmansData.file;
        delete chairmansData.checked;
        delete chairmansData[""];
        delete chairmansData.day;
        delete chairmansData.month;
        delete chairmansData.year;
        Object.keys(chairmansData).forEach(function (key) {
            if (typeof chairmansData[key] === 'string') {
                chairmansData[key] = chairmansData[key].trim();
            }
        });
        if (errors.length < 1 && !email && !mobileNumber && onChangeName(chairmansData.name)) {
            chairmansData.whatsapp_number = chairmansData.whatsapp_number ? chairmansData.whatsapp_number : chairmansData.mobile_number;
            setLoading(true);
            CREATE_SCHOOL_USER(chairmansData).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                    setCurrentChairmanData(chairmansData);
                } else {
                    toast.success("Chairman created Successfully");
                    props.handleUpdateCancel();
                    setLoading(false);

                }
            });
        } else {
            if (!nameError && !onChangeName(chairmansData.name)) {
                setNameError('Please enter name');
            }
        }
    }

    function updateChairman() {
        const chairmansData = chairmanData.value ? { ...chairmanData.value } : { ...currentChairmanData };
        setIsFormSubmitted(true);
        const errors: FormControlError[] = FormValidator(chairmanFormValidations, chairmansData);
        setChairmanErrors(errors);
        chairmansData.type = "institute";
        chairmansData.user_role = "CHAIRMAN";
        chairmansData.institute_school_code = institutesCode;
        chairmansData.profile_img = chairmanLogo;
        chairmansData.id = usersId;
        chairmansData.date_of_birth = startDate ? moment(startDate).format('YYYY-MM-DD') : null;
        delete chairmansData.sameMobileNumber;
        delete chairmansData.file;
        delete chairmansData.checked;
        delete chairmansData.date_of_registration;
        delete chairmansData.user_code;
        delete chairmansData.address;
        delete chairmansData.address2;
        delete chairmansData.status;
        delete chairmansData.guardian;
        delete chairmansData.state;
        delete chairmansData.taluka;
        delete chairmansData.pin_code;
        delete chairmansData.district;
        delete chairmansData.standard;
        delete chairmansData.division;
        delete chairmansData.occupation;
        delete chairmansData.active;
        delete chairmansData.cas_report_view;
        delete chairmansData.indv_school_details;
        delete chairmansData.created_at;
        delete chairmansData.updated_at;
        delete chairmansData.user_name;
        delete chairmansData.role_name;
        delete chairmansData.short_name;
        delete chairmansData.name_of_school;
        delete chairmansData.name_of_organization;
        delete chairmansData.display_name;
        delete chairmansData.day;
        delete chairmansData.month;
        delete chairmansData.year;
        delete chairmansData[""];
        delete chairmansData.access;
        chairmansData.whatsapp_number = currentChairmanData.whatsapp_number;
        Object.keys(chairmansData).forEach(function (key) {
            if (typeof chairmansData[key] === 'string') {
                chairmansData[key] = chairmansData[key].trim();
            }
        });
        if (errors.length < 1 && !email && !mobileNumber && onChangeName(chairmansData.name)) {
            chairmansData.access = checkedUserMechanismStatus;
            setLoading(true);
            UPDATE_USER(chairmansData).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    toast.success("Chairman Updated Successfully");
                    props.handleUpdateCancel()
                    setLoading(false);

                }
            });
        } else {
            if (!nameError && !onChangeName(chairmansData.name)) {
                setNameError('Please enter name');
            }
        }
    }

    const handleGender = (e: any) => {
        const data = { ...chairmanData.value };
        data.gender = e.target.value;
        if (currentChairmanData) {
            currentChairmanData.gender = e.target.value;
        }
    }
    const handleBloodGroupChange = (e: any) => {
        const data = { ...chairmanData.value };
        data.blood_group = e.target.value;
        if (currentChairmanData) {
            currentChairmanData.blood_group = e.target.value;
        }
    }

    const handleMobileChange = (e: any) => {
        const data = { ...chairmanData.value };
        const re = /(6|7|8|9)\d{9}/;

        if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length === 10) {
            data.mobile_number = e.target.value;
            setMobileNumber(false);
        } else {
            data.mobile_number = e.target.value;
            setMobileNumber(true);
        }

        if (currentChairmanData) {
            currentChairmanData.mobile_number = e.target.value.replace(/\D+/g, '');
        }

        if (currentChairmanData.checked) {
            currentChairmanData.whatsapp_number = e.target.value.replace(/\D+/g, '');
        }
    }

    const handleWhatsappChange = (e: any) => {
        const data = { ...chairmanData.value };
        const re = /(6|7|8|9)\d{9}/;

        if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length === 10) {
            data.whatsapp_number = e.target.value;
            setWhatsappNumber(false);
        } else {
            data.whatsapp_number = e.target.value;
            setWhatsappNumber(true);
        }

        if (currentChairmanData) {
            currentChairmanData.whatsapp_number = e.target.value.replace(/\D+/g, '');
        }
    }

    const handleEmailChange = (e: any) => {
        const data = { ...chairmanData.value };
        const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/;

        if (e.target.value === '' || re.test(e.target.value)) {
            data.email_id = e.target.value;
            setEmail(false);
        } else {
            data.email_id = e.target.value;
            setEmail(true);
        }

        if (currentChairmanData) {
            currentChairmanData.email_id = e.target.value;
        }
    }

    const handleMobileNumberChange = (e: any) => {
        const data = { ...chairmanData.value };
        if (e.target.checked) {
            if (data.whatsapp_number) {
                data.whatsapp_number = data.mobile_number;
                currentChairmanData.whatsapp_number = data.mobile_number;
            }
            if (data.mobile_number || currentChairmanData.mobile_number) {
                currentChairmanData.whatsapp_number = currentChairmanData.mobile_number;
                setCurrentChairmanData(currentChairmanData);
            }
            currentChairmanData.checked = true;
        } else {
            currentChairmanData.checked = false;
        }
    }

    const onChange = (dates: any) => {
        setStartDate(null);
        if (dates.error) {
            setIsDateError(dates);
        } else {
            setStartDate(dates.date);
        }
    };

    function addMonths(theDate: any, years: any) {
        return new Date(theDate.getTime() - years * 24 * 60 * 60 * 1000);
    }

    function subMonths(theDate: any, years: any) {
        return new Date(theDate.getTime() - years * 24 * 60 * 60 * 1000);
    }

    const onChangeName = (event: any) => {
        setNameError('');
        const reg = /^[A-Za-z]+(?:[ _-][A-Za-z]+)*$/;
        if (event) {
            if (!reg.test(event.trim())) {
                setNameError('Enter only alphabets');
            } else {
                return true;
            }
        } else {
            setNameError('Please enter name');
        }
    }

    const handleUserMechanism = (e: any) => {
        if (currentChairmanData) {
            const status = e.target.checked === true ? "ENABLED" : "DISABLED";
            currentChairmanData.access = status;
            setCheckedUserMechanismStatus(status);
        }
    }
    const handleFormClose = () => {
        props.setChairmanView(false);
    };

    return (
        <div>
            <div
                className={`modal fade ${true ? "show" : "false"}`}
                style={{ display: true ? "block" : "none" }}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog parent-model-dialog" role="document">
                    <div className="modal-content parent-model">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Institute Chairman
                            </h5>
                            <button
                                type="button"
                                className="close popup-close cursor-pointer"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={handleFormClose}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body py-2 mx-2 parent-model">
                            {loading &&
                                <div className="text-center p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            }
                            {!loading && <div>
                                <FormBuilder onUpdate={handleChairmanInput}>
                                    <form>
                                        <div className="row custom-form">
                                            <div style={{ borderBottom: 'solid 1px black', marginTop: '15px' }} className="mb-4">
                                                <h5 className="form-label mb-2 d-block f16">Personal Details</h5>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-4">
                                                    <label className="form-label mb-0 f14">Mrs./ Mr
                                                        <span style={{ paddingLeft: '5px' }}>Name</span></label>
                                                    <span style={{ color: 'red', fontSize: '18px', paddingLeft: '5px' }}>*</span>
                                                    <input className="form-control form-control-lg" type="text" onChange={(e) => onChangeName(e.target.value)} name="name" defaultValue={currentChairmanData.name} placeholder="Please enter name" />
                                                    {nameError && <p className="text-danger">{nameError}</p>}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-4">
                                                    <label className="form-label mb-4 f14">Gender</label>
                                                    <span style={{ color: 'red', fontSize: '18px', paddingLeft: '10px' }}>*</span>
                                                    <br />
                                                    <input className="mb-0" type="radio" value="male" name="gender" checked={currentChairmanData?.gender === "male"} onChange={(e) => { handleGender(e) }} />
                                                    <span style={{ paddingRight: '15px', paddingLeft: '15px' }}>Male</span>
                                                    &nbsp;
                                                    <input className="mb-0" type="radio" value="female" name="gender" checked={currentChairmanData?.gender === "female"} onChange={(e) => { handleGender(e) }} />
                                                    <span style={{ paddingRight: '15px', paddingLeft: '15px' }}>Female</span>

                                                    <input className="mb-0" type="radio" value="transgender" name="gender" checked={currentChairmanData?.gender === "transgender"} onChange={(e) => { handleGender(e) }} />
                                                    <span style={{ paddingRight: '15px', paddingLeft: '15px' }}>Other</span>

                                                    {isFormSubmitted && !getChairmanInputValid('gender') && <p className="text-danger">Please select gender</p>}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-4">
                                                    <label className="form-label mb-0 f14">Date of Birth</label>
                                                    <span style={{ color: 'red', fontSize: '18px', paddingLeft: '10px' }}>*</span>
                                                    {currentChairmanData.date_of_birth &&
                                                        <DateComponent onDate={onChange} yearDiff={18} defaultData={currentChairmanData.date_of_birth}></DateComponent>}
                                                    {!currentChairmanData.date_of_birth && <DateComponent onDate={onChange} yearDiff={18}></DateComponent>}
                                                    {isFormSubmitted && !startDate && !isDateError?.error && <p className="text-danger">Please select date</p>}
                                                    {isDateError?.error && !startDate && <p className="text-danger">{isDateError?.error}</p>}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-4">
                                                    <label className="form-label mb-0 f14">Blood Group</label>
                                                    <select name="blood_group" value={currentChairmanData.blood_group} onChange={(e) => { handleBloodGroupChange(e) }} className="form-select form-select-lg mb-3">
                                                        <option value="">Select blood group</option>
                                                        <option value="O+">O+</option>
                                                        <option value="O-">O-</option>
                                                        <option value="A-">A-</option>
                                                        <option value="A+">A+</option>
                                                        <option value="B-">B-</option>
                                                        <option value="B+">B+</option>
                                                        <option value="AB-">AB-</option>
                                                        <option value="AB+">AB+</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-12">
                                                <div className="mb-4 d-flex justify-content-center">
                                                    <ProfileImageCrop uploadLogo={uploadLogo} profileImg={chairmanLogo} imageLoader={imageLoader} profileImageDelete={setChairmanLogo} />
                                                </div>
                                            </div>
                                            {usersId > 0 && <div className="col-md-4">
                                                <div className="mb-4">
                                                    <label className="form-label f14">User Access</label><br />
                                                    <label className="switch">
                                                        <input type="checkbox" name="access" value={currentChairmanData?.access} checked={currentChairmanData?.access === "ENABLED"} onChange={(e) => { handleUserMechanism(e) }} />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                            </div>}

                                            <div style={{ borderBottom: 'solid 1px black' }} className="mb-4">
                                                <h5 className="form-label mb-2 d-block f16">Contact Details</h5>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="mb-4">
                                                    <label className="form-label mb-0 f14">Email</label>
                                                    <span style={{ color: 'red', fontSize: '18px', paddingLeft: '10px' }}>*</span>
                                                    <input className="form-control form-control-lg" type="text" name="email_id" value={currentChairmanData.email_id} onChange={(e) => { handleEmailChange(e) }} placeholder="Please enter email" />
                                                    {email && <p className="text-danger">{errorMessages.email}</p>}
                                                    {!email && isFormSubmitted && !getChairmanInputValid('email_id') && <p className="text-danger">Please enter email</p>}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-4">
                                                    <label className="form-label mb-0 f14">Mobile Number</label>
                                                    <span style={{ color: 'red', fontSize: '18px', paddingLeft: '10px' }}>*</span>
                                                    <input className="form-control form-control-lg" type="text" name="mobile_number" maxLength={10} value={currentChairmanData.mobile_number} onChange={(e) => { handleMobileChange(e) }} placeholder="Please enter mobile number" />
                                                    {mobileNumber && <p className="text-danger">{errorMessages.mobile_number}</p>}
                                                    {!mobileNumber && isFormSubmitted && !getChairmanInputValid('mobile_number') && <p className="text-danger">Please enter mobile number</p>}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-4">
                                                    <label className="form-label mb-0 f14">WhatsApp Number</label>
                                                    (  <input type="checkbox" name="sameMobileNumber" checked={currentChairmanData.checked} onChange={(e) => { handleMobileNumberChange(e) }} style={{ paddingLeft: '10px' }} />
                                                    <span style={{ paddingLeft: '10px' }}>Same as Mobile Number</span> )
                                                    <input className="form-control form-control-lg" type="text" disabled={currentChairmanData.checked} name="whatsapp_number" maxLength={10} value={currentChairmanData.whatsapp_number} onChange={(e) => { handleWhatsappChange(e) }} placeholder="Please enter whatsapp number" />
                                                    {(whatsappNumber) && <p className="text-danger">{errorMessages.whatsapp_number}</p>}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-4">
                                                    <label className="form-label mb-0 f14">City/Village (Optional)</label>
                                                    <input className="form-control form-control-lg" type="text" name="city_village" defaultValue={currentChairmanData.city_village} placeholder="Please enter city" />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </FormBuilder>

                            </div>}
                        </div>
                        <div className="modal-footer">
                            {usersId === 0 && <a className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer" onClick={() => createChairman()}>Create</a>}
                            {usersId > 0 && <a className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer" onClick={() => updateChairman()}>Update</a>}
                            <span className='mb-0 mx-3 cursor-pointer' onClick={handleFormClose}>Cancel</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
