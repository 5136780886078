import React from 'react';
import { format } from 'date-fns';

interface Props {
    priority: any[];
    priorityNumber: string;
}


const MlaPriorityReportTable: React.FC<Props> = ({ priority, priorityNumber }) => {
    const formatDate = (dateString: string): any => {
        const date = new Date(dateString);
        const formattedDate = format(date, "dd-MMM-yyyy"); // Format date component
        const formattedTime = format(date, "HH:mm:ss"); // Format time component
        return (
            <>
                <span>{formattedDate},</span><br />
                <span>{formattedTime}</span>
            </>
        );
    }
    const decimalToMinutes = (decimal: number): number => {
        const hours = Math.floor(decimal);
        const minutes = Math.round((decimal - hours) * 60);
        return hours * 60 + minutes;
    };
    const getPriorityTableClass = (level: string): string => {
        switch (level) {
            case "1":
                return "priority_1";
            case "2":
                return "priority_2";
            case "3":
                return "priority_3";
            default:
                return "priority_1";
        }

    }
    return (
        <>
            {priority?.map((priority: any, i: number) => (
                <div key={i}>
                    {i === 0 && <h6 className="mt-5 mb-4 f18 priority_ul">Priority - {priorityNumber}</h6>}
                    <h5 className="f16 mb-4">{priority?.topic} - {priority?.ability_name} ( Total Worksheets: {priority?.total_worksheets}, Total utilised Time: {decimalToMinutes(priority?.total_utilized_time)} mins )</h5>
                    <div className="report_table_container mb-4 pb-3 table-responsive">
                        <table className={`report_priority_table ${getPriorityTableClass(priorityNumber)}`}>
                            <thead>
                                <tr className="text-center">
                                    <th className="text-start ps-2">Worksheet<br /> Name</th>
                                    <th>Worksheet<br /> Number</th>
                                    <th>Total <br />Questions</th>
                                    <th>Correct <br />Answers</th>
                                    <th>Wrong <br />Answers</th>
                                    <th>Accuracy</th>
                                    <th>Utilised <br />Time</th>
                                    <th>Status</th>
                                    <th>Date & Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                {priority?.statistics?.map((worksheet: any, x: number) => (
                                    <tr className="text-center" key={x}>
                                        <td className='col_one text-start'>{priority?.ability_name}</td>
                                        <td>{worksheet?.worksheet_no}</td>
                                        <td>{worksheet?.total_questions}</td>
                                        <td>{worksheet?.correct_answers}</td>
                                        <td>{worksheet?.wrong_answers}</td>
                                        <td>{worksheet?.percentage?.toFixed(2)}%</td>
                                        <td>{worksheet?.time_taken} mins</td>
                                        <td>{worksheet?.status === "completed" ? "Closed" : "Open"}</td>
                                        <td>{formatDate(worksheet?.submitted_time)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            ))}
        </>
    );
};

export default MlaPriorityReportTable;
