import { BrowserRouter, Switch } from 'react-router-dom';
import './App.css';
import Routes from './routes';
import './assets/css/custom.css';
import './assets/scss/style.scss';
import './institute-dashboard-assets/css/custom-institute.css';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import Popup from './components/popup';
import { ToastContainer } from 'react-toastify';
import CacheBuster from 'react-cache-buster';
import { version } from '../package.json';
import ScrollToTop from './components/scrollTop';




function App() {

  return (
    <>
      <CacheBuster
        currentVersion={version}
        isEnabled={true} //If false, the library is disabled.
        isVerboseMode={false} //If true, the library writes verbose logs to console.
        metaFileDirectory={'.'}
      >
        <Provider store={store}>
          <ToastContainer autoClose={10000} closeButton={true} hideProgressBar={true} theme={'colored'} />
          {/* <Popup /> */}
          <BrowserRouter>
            {/* <ScrollToTop /> */}
            <Switch>
              <Routes />
            </Switch>
          </BrowserRouter>
        </Provider>
      </CacheBuster>
    </>
  );
}

export default App;
