import { DataTableCol } from "../../../../components/data-table/types";

export const ManageSchoolStudentsDataGridCols: DataTableCol[] = [
  {
    title: "Name",
    control: "name",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "User Name",
    control: "user_name",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Email",
    control: "email_id",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Mobile Number",
    control: "mobile_number",
    sortable: true,
    canShowColumn: true,
  },

  {
    title: "Standard",
    control: "standard",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Division",
    control: "division",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Account Type",
    control: "account_type",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Actions",
    control: "both",
    isEdit: true,
    isDelete: true,
    canShowColumn: true,
  },
];
