import { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';

const useUnsavedChangesWarnMessage = (message: string): [boolean, () => void, () => void] => {
    const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);

    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            if (unsavedChanges) {
                event.preventDefault();
                event.returnValue = message;
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [unsavedChanges, message]);

    // const setPristine = () => setUnsavedChanges(false);
    // const setDirtyFlag = () => setUnsavedChanges(true);
    const routerPrompt: any = <Prompt when={unsavedChanges} message={message} />

    return [routerPrompt, () => setUnsavedChanges(true), () => setUnsavedChanges(false)];
    // return [unsavedChanges, setDirtyFlag, setPristine];
};

export default useUnsavedChangesWarnMessage;