import API from "../utility/axios";
const apiUrl = "/api/v1";
const mlaUrl = "/api/v2";

export const GET_MNS_MODULE_DETAILS = (): Promise<any> => {
  return API.get(`${apiUrl}/module?module_code=${"MNS"}`);
};

export const GET_MLA_TWO_DETAILS = (): Promise<any> => {
  return API.get(`${mlaUrl}/module/mla/two/details`);
};

export const GET_MLA_TWO_COMPLETE_INFO = (data: any): Promise<any> => {
  return API.post(`${mlaUrl}/module/mla/two/school/user/questions/list`, data);
};

export const GET_MLA_TWO_QUESTIONS = (data: any): Promise<any> => {
  return API.post(`${mlaUrl}/module/mla/two/fetch/paper`, data);
};

export const MLA_TWO_ANSWERS_UPDATE = (data: any): Promise<any> => {
  return API.post(`${mlaUrl}/module/mla/two/school/user/answers/update`, data);
};

export const MLA_TWO_TIME_UPDATE = (data: any): Promise<any> => {
  return API.post(
    `${mlaUrl}/module/mla/two/school/user/tests/start/time/update`,
    data
  );
};

export const MLA_TWO_ABILITY_UPDATE = (data: any): Promise<any> => {
  return API.post(`${mlaUrl}/module/mla/two/upload/config`, data);
};

export const GET_MLA_TWO_TEST_DETAILS = (): Promise<any> => {
  return API.get(`${mlaUrl}/module/mla/two/details?tests=yes`);
};

export const GET_MLA_TWO_WORKSHEETS = (code: any): Promise<any> => {
  return API.get(`${mlaUrl}/module/mla/two/details?ability=${code}`);
};

export const GET_MLA_TWO_QUESTIONS_BY_TEST_NUMBER = (
  number: any
): Promise<any> => {
  return API.get(`${mlaUrl}/module/mla/two/details?test_no=${number}`);
};
export const DELETE_MLA_TWO_QUESTIONS_ID_BY_TEST_NUMBER = (
  id: any,
  data: any
): Promise<any> => {
  return API.delete(`${mlaUrl}/module/mla/two/delete/question/${id}`, {
    data,
  });
};
