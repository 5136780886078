import React, { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom';
import { CREATE_SCHOOL_USER, UPLOAD_LOGO } from '../../../../app/service/school.service';
import { GET_USER_BY_ID, UPDATE_USER } from '../../../../app/service/users.service';
import FormBuilder from '../../../../components/form-builder';
import { FormControlError, FormField, FormValidators } from '../../../../components/form-builder/model/form-field';
import { FormValidator, GetControlIsValid } from '../../../../components/form-builder/validations';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import appConfig from '../../../../config/constant';
import errorMessages from '../../../../components/error-messages';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DateComponent } from '../../../../components/date';
import { log } from 'console';
import ProfileImageCrop from '../../../../components/image-crop/crop';

interface Props {
    userId: any;
    schoolsCode: any;
    updateCancel: () => void;
    setPrincipalView: any
}

export const ManagePrincipalForm = (props: Props) => {
    const [principalValidationErrors, setPrincipalValidationErrors] = useState<FormControlError[]>([]);
    const [principalData, setPrincipalData] = useState<any>({});
    const [currentPrincipalData, setCurrentPrincipalData] = useState<any>({});
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [principalLogo, setPrincipalLogo] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [imageLoader, setImageLoader] = useState(false);
    const [mobileNumber, setMobileNumber] = useState(false);
    const [whatsappNumber, setWhatsappNumber] = useState(false);
    const [min, setMin] = useState<any>(moment().subtract(60, "years").format("YYYY-MM-DD"));
    const [max, setMax] = useState<any>(moment().subtract(18, "years").format("YYYY-MM-DD"));
    const [email, setEmail] = useState(false);
    const [canCheckMobileNumber, setCanCheckMobileNumber] = useState(false);
    const [canShowWhatsAppError, setCanShowWhatsAppError] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [checkedUserMechanismStatus, setCheckedUserMechanismStatus] = useState<any>();
    let { id, schoolCode, userId } = useParams<{ id: string, schoolCode: string, userId: string }>();
    const schoolId = parseInt(id);
    const schoolsCode = props.schoolsCode;
    const usersId: number = props.userId ? props.userId : 0;
    const [isDateError, setIsDateError] = useState<any>({});
    const history = useHistory();
    const [nameError, setNameError] = useState('');
    const userRole = localStorage.getItem("user_role");
    const [canShowFormPopup, setCanFormPopup] = useState(true);

    const principalFormValidations = [
        new FormField('name', [FormValidators.REQUIRED]),
        new FormField('gender', [FormValidators.REQUIRED]),
        new FormField('date_of_birth', []),
        new FormField('blood_group', []),
        new FormField('city_village', []),
        new FormField('email_id', [FormValidators.REQUIRED]),
        new FormField('mobile_number', [FormValidators.REQUIRED]),
        new FormField('whatsapp_number', []),
    ];

    useEffect(() => {
        if (usersId > 0) {
            setLoading(true);
            GET_USER_BY_ID(usersId).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    setLoading(false);
                    const data = res.rows[0];
                    if (data?.date_of_birth) {
                        const date_of_birth: any = new Date(data.date_of_birth);
                        setStartDate(date_of_birth);
                    }

                    if (data?.access) {
                        setCheckedUserMechanismStatus(data?.access);
                    }
                    setPrincipalLogo(data?.profile_img);
                    setCurrentPrincipalData(data);
                }
            });
        }
    }, []);

    const handlePrincipalInput = (data: any) => {
        data.value = { ...currentPrincipalData, ...data.value };
        setPrincipalData(data);
        const errors: any = FormValidator(principalFormValidations, data.value);
        setPrincipalValidationErrors(errors);
    };

    function createPrincipal() {
        const principalsData = principalData.value ? { ...principalData.value } : { ...currentPrincipalData };
        setIsFormSubmitted(true);
        const errors: FormControlError[] = FormValidator(principalFormValidations, principalsData);
        setPrincipalValidationErrors(errors);
        principalsData.type = "school";
        principalsData.user_role = "PRINCIPAL";
        principalsData.institute_school_code = schoolsCode;
        principalsData.profile_img = principalLogo;
        principalsData.date_of_birth = startDate ? moment(startDate).format('YYYY-MM-DD') : null;
        delete principalsData.sameMobileNumber;
        delete principalsData.file;
        delete principalsData.checked;
        delete principalsData[''];
        delete principalsData.day;
        delete principalsData.month;
        delete principalsData.year;
        if (errors.length < 1 && !email && !mobileNumber && onChangeName(principalsData.name)) {
            principalsData.whatsapp_number = principalsData.whatsapp_number ? principalsData.whatsapp_number : principalsData.mobile_number;
            CREATE_SCHOOL_USER(principalsData).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                    setCurrentPrincipalData(principalsData);
                } else {
                    toast.success("Principal  Added Successfully");
                    props.updateCancel();
                    setCanFormPopup(!canShowFormPopup);
                    setLoading(false);
                }
            });
        }
        else {

            if (!nameError && !onChangeName(principalsData.name)) {
                setNameError('Please enter name');
            }
        }
    }

    function updatePrincipal() {
        const principalsData = principalData.value ? { ...principalData.value } : { ...currentPrincipalData };
        setIsFormSubmitted(true);
        const errors: FormControlError[] = FormValidator(principalFormValidations, principalsData);
        setPrincipalValidationErrors(errors);
        principalsData.type = "school";
        principalsData.user_role = "PRINCIPAL";
        principalsData.institute_school_code = schoolsCode;
        principalsData.id = usersId;
        principalsData.profile_img = principalLogo;
        principalsData.date_of_birth = startDate ? moment(startDate).format('YYYY-MM-DD') : null;
        delete principalsData.sameMobileNumber;
        delete principalsData.file;
        delete principalsData.checked;
        delete principalsData[''];
        delete principalsData.date_of_registration;
        delete principalsData.user_code;
        delete principalsData.address;
        delete principalsData.address2;
        delete principalsData.status;
        delete principalsData.guardian;
        delete principalsData.state;
        delete principalsData.taluka;
        delete principalsData.pin_code;
        delete principalsData.district;
        delete principalsData.standard;
        delete principalsData.division;
        delete principalsData.occupation;
        delete principalsData.active;
        delete principalsData.cas_report_view;
        delete principalsData.indv_school_details;
        delete principalsData.created_at;
        delete principalsData.updated_at;
        delete principalsData.user_name;
        delete principalsData.role_name;
        delete principalsData.short_name;
        delete principalsData.name_of_school;
        delete principalsData.name_of_organization;
        delete principalsData.display_name;
        delete principalsData.day;
        delete principalsData.month;
        delete principalsData.year;
        delete principalsData.access;
        principalsData.whatsapp_number = currentPrincipalData.whatsapp_number;
        if (errors.length < 1 && !email && !mobileNumber && onChangeName(principalsData.name)) {
            principalsData.access = checkedUserMechanismStatus;
            UPDATE_USER(principalsData).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    toast.success("Principal  Updated Successfully");
                    props.updateCancel();
                    setCanFormPopup(!canShowFormPopup);
                    setLoading(false);


                }
            });
        }
        else {

            if (!nameError && !onChangeName(principalsData.name)) {
                setNameError('Please enter name');
            }
        }
    }

    const getPrincipalInputValid = (control: string) => {
        const value = GetControlIsValid(principalValidationErrors, control);
        return value;
    }

    function handleUploadLogo(e: any, type: string) {
        if (e.target.files && e.target.files[0]) {
            const fileType = e.target.files[0].name.split('.').pop()
            if (fileType == "jpeg" || fileType == "jpg") {
                const formData = new FormData();
                formData.append('file', e.target.files[0], e.target.files[0].name);
                uploadLogo(formData, type);
            } else {
                toast.error("Valid file type .jpg, .jpeg only");
            }
        }
    }

    function uploadLogo(formdata: any, type: string) {
        setImageLoader(true);
        UPLOAD_LOGO(formdata).then((res: any) => {
            setPrincipalLogo(res.url);
            if (res.status === 'fail') {
                toast.error(res.message);
                setImageLoader(false);
            } else {
                setPrincipalLogo(res.url);
                setImageLoader(false);
            }
        });

    }

    const handleGender = (e: any) => {
        const data = { ...principalData.value };
        data.gender = e.target.value;
        if (currentPrincipalData) {
            currentPrincipalData.gender = e.target.value;
        }
    }

    const handleBloodGroupChange = (e: any) => {
        const data = { ...principalData.value };
        data.blood_group = e.target.value;
        if (currentPrincipalData) {
            currentPrincipalData.blood_group = e.target.value;
        }
    }

    const handleMobileChange = (e: any) => {
        const data = { ...principalData.value };
        const re = /(6|7|8|9)\d{9}/;

        if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length === 10) {
            data.mobile_number = e.target.value;
            setMobileNumber(false);
        } else {
            data.mobile_number = e.target.value;
            setMobileNumber(true);
        }

        if (currentPrincipalData) {
            currentPrincipalData.mobile_number = e.target.value.replace(/\D+/g, '');
        }

        if (currentPrincipalData.checked) {
            currentPrincipalData.whatsapp_number = e.target.value.replace(/\D+/g, '');
        }
    }

    const handleWhatsappChange = (e: any) => {
        const data = { ...principalData.value };
        const re = /(6|7|8|9)\d{9}/;

        if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length === 10) {
            data.whatsapp_number = e.target.value;
            setWhatsappNumber(false);
            setCanShowWhatsAppError(false);
        } else {
            data.whatsapp_number = e.target.value;
            setWhatsappNumber(true);
        }

        if (currentPrincipalData) {
            currentPrincipalData.whatsapp_number = e.target.value.replace(/\D+/g, '');
        }
    }

    const handleEmailChange = (e: any) => {
        const data = { ...principalData.value };
        const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/;

        if (e.target.value === '' || re.test(e.target.value)) {
            data.email_id = e.target.value;
            setEmail(false);
        } else {
            data.email_id = e.target.value;
            setEmail(true);
        }

        if (currentPrincipalData) {
            currentPrincipalData.email_id = e.target.value;
        }
    }

    const handleMobileNumberChange = (e: any) => {
        const data = { ...principalData.value };
        if (e.target.checked) {
            if (data.whatsapp_number) {
                data.whatsapp_number = data.mobile_number;
                currentPrincipalData.whatsapp_number = data.mobile_number;
                setCanCheckMobileNumber(true);
                setCanShowWhatsAppError(false);
            }
            if (data.mobile_number || currentPrincipalData.mobile_number) {
                setCanCheckMobileNumber(true);
                setCanShowWhatsAppError(false);
                currentPrincipalData.whatsapp_number = currentPrincipalData.mobile_number;
                setCurrentPrincipalData(currentPrincipalData);
            }
            currentPrincipalData.checked = true;
        } else {

            setCanCheckMobileNumber(false);
            currentPrincipalData.checked = false;
        }
    }

    const onChange = (dates: any) => {
        setStartDate(null);
        if (dates.error) {
            setIsDateError(dates);
        } else {
            setStartDate(dates.date);
        }
    };

    function addMonths(theDate: any, years: any) {
        return new Date(theDate.getTime() - years * 24 * 60 * 60 * 1000);
    }

    function subMonths(theDate: any, years: any) {
        return new Date(theDate.getTime() - years * 24 * 60 * 60 * 1000);
    }

    const onChangeName = (event: any) => {
        setNameError('');
        const reg = /^[A-Za-z]+(?:[ _-][A-Za-z]+)*$/;
        if (event) {
            if (!reg.test(event.trim())) {
                setNameError('Enter only alphabets');
            } else {
                return true;
            }
        } else {
            setNameError('Please enter name');
        }
    }

    const handleUserMechanism = (e: any) => {
        if (currentPrincipalData) {
            const status = e.target.checked === true ? "ENABLED" : "DISABLED";
            currentPrincipalData.access = status;
            setCheckedUserMechanismStatus(status);
        }
    }

    const handleFormClose = () => {
        setCanFormPopup(false);
        props.setPrincipalView(false);
    };

    return (
        <div>
            <div
                className={`modal fade ${canShowFormPopup ? "show" : "false"}`}
                style={{ display: canShowFormPopup ? "block" : "none" }}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog parent-model-dialog" role="document">
                    <div className="modal-content parent-model">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Principal
                            </h5>

                            <button
                                type="button"
                                className="close popup-close cursor-pointer"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={handleFormClose}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body py-2  mx-2 parent-model">


                            {loading &&
                                <div className="text-center p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            }
                            {!loading && <div>
                                <FormBuilder onUpdate={handlePrincipalInput}>
                                    <form>
                                        <div style={{ borderBottom: 'solid 1px black', marginTop: '15px', paddingLeft: '10px' }} className="mb-4">
                                            <h5 className="f18 mb-2 d-block">Personal Details</h5>
                                        </div>
                                        <div className="row custom-form">
                                            <div className="col-md-4">
                                                <div className="mb-4">
                                                    <label className="f12 text-mgray mb-0">Mrs./ Mr
                                                        <span style={{ paddingLeft: '5px' }}>Name</span></label>
                                                    <span style={{ color: 'red', fontSize: '17px', paddingLeft: '5px' }}>*</span>
                                                    <input className="form-control form-control-lg f18 mb-0" type="text" name="name" onChange={(e) => onChangeName(e.target.value)} defaultValue={currentPrincipalData?.name} placeholder="Please enter name" />
                                                    {nameError && <p className="text-danger">{nameError}</p>}

                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-4">
                                                    <label className="f12 text-mgray mb-4">Gender</label>
                                                    <span style={{ color: 'red', fontSize: '17px', paddingLeft: '10px' }}>*</span>
                                                    <br />
                                                    <input className="mb-0" type="radio" value="male" name="gender" checked={currentPrincipalData?.gender === "male"} onChange={(e) => { handleGender(e) }} />
                                                    <span style={{ paddingRight: '15px', paddingLeft: '15px' }} className='f18'>Male</span>
                                                    &nbsp;
                                                    <input className="mb-0" type="radio" value="female" name="gender" checked={currentPrincipalData?.gender === "female"} onChange={(e) => { handleGender(e) }} />
                                                    <span style={{ paddingRight: '15px', paddingLeft: '15px' }} className='f18'>Female</span>

                                                    <input className="mb-0" type="radio" value="transgender" name="gender" checked={currentPrincipalData?.gender === "transgender"} onChange={(e) => { handleGender(e) }} />
                                                    <span style={{ paddingRight: '15px', paddingLeft: '15px' }} className='f18'>Other</span>

                                                    {isFormSubmitted && !getPrincipalInputValid('gender') && <p className="text-danger">Please select gender</p>}
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="mb-4">
                                                    <label className="f12 text-mgray mb-0">Date of Birth</label>
                                                    <span style={{ color: 'red', fontSize: '17px', paddingLeft: '10px' }}>*</span>
                                                    {currentPrincipalData?.date_of_birth &&
                                                        <DateComponent onDate={onChange} yearDiff={18} defaultData={currentPrincipalData?.date_of_birth}></DateComponent>}
                                                    {!currentPrincipalData.date_of_birth && <DateComponent onDate={onChange} yearDiff={18}></DateComponent>}
                                                    {isFormSubmitted && !startDate && !isDateError?.error && <p className="text-danger">Please select date</p>}
                                                    {isDateError?.error && !startDate && <p className="text-danger">{isDateError?.error}</p>}

                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-4">
                                                    <label className="f12 text-mgray mb-0">Blood Group</label>
                                                    <select name="blood_group f18" value={currentPrincipalData?.blood_group} onChange={(e) => { handleBloodGroupChange(e) }} className="form-select form-select-lg mb-3">
                                                        <option value="">Select blood group</option>
                                                        <option value="O+">O+</option>
                                                        <option value="O-">O-</option>
                                                        <option value="A-">A-</option>
                                                        <option value="A+">A+</option>
                                                        <option value="B-">B-</option>
                                                        <option value="B+">B+</option>
                                                        <option value="AB-">AB-</option>
                                                        <option value="AB+">AB+</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-12">
                                                <div className="mb-4 d-flex justify-content-center">
                                                    <ProfileImageCrop uploadLogo={uploadLogo} profileImg={principalLogo} imageLoader={imageLoader} profileImageDelete={setPrincipalLogo} />
                                                </div>
                                            </div>

                                            {usersId > 0 && <div className="col-md-4">
                                                <div className="mb-4">
                                                    <label className="form-label">User Access</label><br />
                                                    <label className="switch">
                                                        <input type="checkbox" name="access" value={currentPrincipalData?.access} checked={currentPrincipalData?.access === "ENABLED"} onChange={(e) => { handleUserMechanism(e) }} />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                            </div>}
                                            <div style={{ borderBottom: 'solid 1px black', paddingLeft: '10px' }} className="mb-4">
                                                <h5 className="f18 mb-2 d-block">Contact Details</h5>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="mb-4">
                                                    <label className="f12 text-mgray mb-0">Email</label>
                                                    <span style={{ color: 'red', fontSize: '17px', paddingLeft: '10px' }}>*</span>
                                                    <input className="form-control form-control-lg f18" type="text" name="email_id" value={currentPrincipalData?.email_id} onChange={(e) => { handleEmailChange(e) }} placeholder="Please enter email" />
                                                    {email && <p className="text-danger">{errorMessages.email}</p>}
                                                    {isFormSubmitted && !getPrincipalInputValid('email_id') && <p className="text-danger">Please enter email</p>}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-4">
                                                    <label className="f12 text-mgray mb-0">Mobile Number</label>
                                                    <span style={{ color: 'red', fontSize: '17px', paddingLeft: '10px' }}>*</span>
                                                    <input className="form-control form-control-lg f18" type="text" name="mobile_number" maxLength={10} onChange={(e) => { handleMobileChange(e) }} value={currentPrincipalData?.mobile_number} placeholder="Please enter mobile number" />
                                                    {mobileNumber && <p className="text-danger">{errorMessages.mobile_number}</p>}
                                                    {isFormSubmitted && !getPrincipalInputValid('mobile_number') && <p className="text-danger">Please enter mobile number</p>}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-4">
                                                    <label className="f12 text-mgray mb-0 me-2">WhatsApp Number</label>
                                                    (  <input type="checkbox" name="sameMobileNumber" className='f12 ' checked={currentPrincipalData?.checked} onChange={(e) => { handleMobileNumberChange(e) }} style={{ paddingLeft: '10px' }} />
                                                    <span style={{ paddingLeft: '10px' }} className='f12 text-mgray'>Same as Mobile Number</span> )
                                                    <input className="form-control form-control-lg f18" disabled={currentPrincipalData?.checked} type="text" name="whatsapp_number" maxLength={10} onChange={(e) => { handleWhatsappChange(e) }} value={currentPrincipalData?.whatsapp_number} placeholder="Please enter whatsapp number" />
                                                    {(whatsappNumber) && <p className="text-danger">{errorMessages.whatsapp_number}</p>}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-4">
                                                    <label className="f12 text-mgray mb-0">City/Village (Optional)</label>
                                                    <input className="form-control form-control-lg f18" type="text" name="city_village" defaultValue={currentPrincipalData?.city_village} placeholder="Please enter city" />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </FormBuilder>
                            </div>}
                        </div>
                        <div className="modal-footer">
                            {usersId == 0 &&
                                <a className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer" onClick={() => createPrincipal()}>Create</a>
                            }
                            {usersId > 0 && <a className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer" onClick={() => updatePrincipal()}>Update</a>}

                            <span className='mb-0 mx-3 cursor-pointer' onClick={handleFormClose}>Cancel</span>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}