// import React from "react";
import React, { useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import FormBuilder from "../../../components/form-builder";
import { CREATE_PROMOTIONS, GET_PROMOTIONS_BY_UUID, UPDATE_PROMOTIONS } from "../../../app/service/promotions";
import { toast } from "react-toastify";
import moment from "moment";


export const PromotionForm = () => {
    const [loading, setLoading] = useState<any>(false);
    const [nameError, setNameError] = useState('');
    const [promotionData, setPromotionData] = useState<any>({});
    const [currentPromotionData, setCurrentPromotionData] = useState<any>({});
    const [startDate, setStartDate] = useState<any>();
    const [promotionTypeError, setPromotionTypeError] = useState(false);
    const [discountTypeError, setDiscountTypeError] = useState(false);
    const [disabledPromotionType, setDisabledPromotionType] = useState(false);
    const [endDate, setEndDate] = useState<any>();
    let { id } = useParams<{ id: string }>();
    const promotionId = id;
    const history = useHistory();

    useEffect(() => {
        if (promotionId !== "0") {
            setLoading(true);
            GET_PROMOTIONS_BY_UUID(promotionId).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    const data = res.rows[0];
                    setCurrentPromotionData(data);
                    if (data.start_at) {
                        data.start_at = moment(data.start_at.split("T")[0]).format('yyyy-MM-DD');
                    }
                    setStartDate(data.start_at);
                    if (data.end_at) {
                        data.end_at = moment(data.end_at.split("T")[0]).format('yyyy-MM-DD');
                    }
                    setEndDate(data.end_at);

                    setLoading(false);

                }
            });
            setDisabledPromotionType(true);
        }
    }, []);


    const handlePromotionInput = (data: any) => {
        data.value = { ...currentPromotionData, ...data.value };
        setPromotionData(data);

    };

    const onChangeName = (event: any) => {
        setNameError('');
        const reg = /^[A-Za-z]+(?:[ _-][A-Za-z]+)*$/;
        if (event) {
            if (!reg.test(event.trim())) {
                setNameError('Enter only alphabets');
            } else {
                return true;
            }
        } else {
            setNameError('Please enter name');
        }
    }
    const handlePromotionTypeChange = (e: any) => {
        const data = { ...promotionData.value };
        data.promotion_type = e.target.value;
        if (e.target.value) {
            setPromotionTypeError(false);

        }
        if (currentPromotionData) {
            currentPromotionData.promotion_type = e.target.value;
        }
    }
    const handleDiscountTypeChange = (e: any) => {
        const data = { ...promotionData.value };
        data.discount_type = e.target.value;
        if (e.target.value) {
            setDiscountTypeError(false);
        }
        if (currentPromotionData) {
            currentPromotionData.discount_type = e.target.value;
        }
    }



    const onChangeNote = (e: any) => {
        const data = { ...promotionData.value };
        data.notes = e.target.value;
        if (currentPromotionData) {
            currentPromotionData.notes = e.target.value;
        }
    }

    function createPromotion() {
        const promotionsData = promotionData.value ? { ...promotionData.value } : { ...currentPromotionData };
        const payload = {
            name: promotionsData.name,
            notes: promotionsData.notes ? promotionsData.notes : '',
            promotion_type: promotionsData.promotion_type,
            discount_type: promotionsData.discount_type,
            start_at: startDate ? `${startDate} 00:00:00` : null,
            end_at: endDate ? `${endDate} 23:59:59` : null,

        }

        if (promotionsData.discount_type && onChangeName(promotionsData.name) && promotionsData.promotion_type) {
            setLoading(true);
            CREATE_PROMOTIONS(payload).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.message);
                } else {
                    toast.success("Promotion Created Successfully");
                    history.push(`/dashboard/promotion/list`);
                    setLoading(false);
                }
            });
        } else {
            if (!nameError && !onChangeName(promotionsData.name)) {
                setNameError('Please enter name');
            }
            if (!promotionsData.promotion_type) {
                setPromotionTypeError(true);
            }
            if (!promotionsData.discount_type) {
                setDiscountTypeError(true);
            }
        }
    }
    function updatePromotion() {
        const promotionsData = promotionData.value ? { ...promotionData.value } : { ...currentPromotionData };
        const payload = {
            name: promotionsData.name,
            notes: promotionsData.notes ? promotionsData.notes : "",
            promotion_type: promotionsData.promotion_type,
            discount_type: promotionsData.discount_type,
            start_at: startDate ? `${startDate} 00:00:00` : null,
            end_at: endDate ? `${endDate} 23:59:59` : null,
        }
        if (promotionsData.discount_type && onChangeName(promotionsData.name) && promotionsData.promotion_type) {
            setLoading(true);
            UPDATE_PROMOTIONS(promotionId, payload).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.message);
                } else {
                    toast.success("Promotion Updated Successfully");
                    history.push(`/dashboard/promotion/list`);
                    setLoading(false);
                }
            });
        }
        else {
            if (!nameError && !onChangeName(promotionsData.name)) {
                setNameError('Please enter name');
            }
            if (!promotionsData.promotion_type) {
                setPromotionTypeError(true);
            }
            if (!promotionsData.discount_type) {
                setDiscountTypeError(true);
            }
        }

    }


    function disabledFutureDatesExpire() {
        // var dtToday = new Date();
        // var month: any = dtToday.getMonth() + 1;
        // var day: any = dtToday.getDate();
        // var year = dtToday.getFullYear();
        // return year + '-' + month + '-' + day;

        var dtToday = startDate ? new Date(startDate) : new Date();
        var month: any = dtToday.getMonth() + 1;
        var day: any = dtToday.getDate();
        var year = dtToday.getFullYear();
        if (month < 10)
            month = '0' + month.toString();
        if (day < 10)
            day = day.toString();
        return year + '-' + month + '-' + day;
    }

    function onSelectStartDate(e: any) {
        setStartDate(e);
        // let end = new Date(e);
        // end.setMonth(end.getMonth() + 1);
        disabledFutureDatesExpire();
        setEndDate(moment(e).add(1, 'month').format("YYYY-MM-DD"));

    }
    const getCurrentDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    const getEndDate = () => {
        const today = new Date(startDate);
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    return (
        <div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <div>
                <div style={{ borderBottom: 'solid 1px black', marginTop: '15px', paddingLeft: '10px' }} className="mb-4">
                    <h5 className="form-label mb-2 d-block">Promotion Details</h5>
                </div>
                <FormBuilder onUpdate={handlePromotionInput}>
                    <form>
                        <div className="row ">
                            <div className="col-md-4 custom-form">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Promotion Name
                                    </label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '5px' }}>*</span>
                                    <input className="form-control form-control-lg" type="text" name="name" onChange={(e) => onChangeName(e.target.value)} defaultValue={currentPromotionData.name} placeholder="Please enter name" />
                                    {/* {isFormSubmitted && !getAdminInputValid('name') && <p className="text-danger">Please fill the field</p>} */}
                                    {nameError && <p className="text-danger">{nameError}</p>}

                                </div>
                            </div>
                            <div className="col-md-4 custom-form">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Promotion Type</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '5px' }}>*</span>
                                    <select name="promotion_type" className="form-select form-select-lg" value={currentPromotionData?.promotion_type} onChange={(e) => { handlePromotionTypeChange(e) }} disabled={disabledPromotionType} style={{ backgroundColor: disabledPromotionType ? 'hsl(0,0%,96.5%)' : 'none' }}>
                                        <option value="">Select the promotion type</option>
                                        <option value="SCHOOL">SCHOOL</option>
                                        <option value="INSTITUTIONAL">INSTITUTIONAL</option>
                                        <option value="GEOGRAPHICAL">GEOGRAPHICAL</option>
                                        <option value="EVENT">EVENT</option>
                                        <option value="SEASONAL">SEASONAL</option>
                                        <option value="MILESTONE">MILESTONE</option>
                                    </select>
                                    {promotionTypeError && <p className="text-danger">Please select promotion type</p>}
                                </div>
                            </div>
                            <div className="col-md-4 custom-form">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Discount Type</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '5px' }}>*</span>
                                    <select className="form-select form-select-lg" name="discount_type" onChange={(e) => handleDiscountTypeChange(e)} value={currentPromotionData?.discount_type} placeholder="Please select discount type" >
                                        <option value="">Select the discount type</option>
                                        <option value="PERCENTAGE_BASED">PERCENTAGE BASED</option>
                                        <option value="FLAT_VALUE_DISCOUNT">FLAT VALUE DISCOUNT</option>
                                        <option value="VOLUME_BASED">VOLUME BASED</option>
                                    </select>
                                    {discountTypeError && <p className="text-danger">Please select discount type</p>}
                                </div>
                            </div>

                            <div className="col-md-4 custom-form">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Start date
                                    </label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '5px' }}></span>
                                    <input type="date" className='form-control' name="expiry_dt" value={startDate} min={getCurrentDate()} onChange={(e: any) => onSelectStartDate(e.target.value)} onKeyDown={(event) => {
                                        event.preventDefault();
                                    }} />
                                </div>
                            </div>
                            <div className="col-md-4 custom-form">
                                <div className="mb-4">
                                    <label className="form-label mb-0">End date
                                    </label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '5px' }}></span>
                                    <input type="date" className='form-control' name="expiry_dt" disabled={!startDate} value={endDate} min={getEndDate()} onChange={(e: any) => setEndDate(e.target.value)} onKeyDown={(event) => {
                                        event.preventDefault();
                                    }} />

                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label  mb-0 mt-2">Note </label>
                                    <textarea className="form-control form-control-lg f16" id="notes" name="notes" rows={3} cols={50} onChange={(e) => onChangeNote(e)} defaultValue={currentPromotionData.notes} placeholder="Please enter note"></textarea>
                                    {/* <input className="form-control form-control-lg" type="textarea" name="notes" onChange={(e) => onChangeNote(e)} defaultValue={currentPromotionData.notes} placeholder="Please enter note" style={{ height: "50px" }} /> */}
                                </div>
                            </div>
                        </div>

                    </form>
                </FormBuilder>
                <div className="form-footer border-top border-primary py-3 text-end mt-4">
                    {promotionId === "0" && <a className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer" onClick={() => createPromotion()}>Create</a>}
                    {promotionId !== "0" && <a className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer" onClick={() => updatePromotion()}>Update</a>}
                    <Link className="text-dark text-decoration-none ms-2 cursor-pointer" to={`/dashboard/promotion/list`}>Cancel</Link>

                </div>
            </div>}

        </div>
    )
}