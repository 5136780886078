import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import TESTALLOCATED from "../../../../institute-dashboard-assets/images/test-allocated.png";
import TESTATTEMPTED from "../../../../institute-dashboard-assets/images/test-attempted.png";
import TESTPASSED from "../../../../institute-dashboard-assets/images/test-passed.png";
import TESTFAILED from "../../../../institute-dashboard-assets/images/test-failed.png";
import TESTEXAMTIME from "../../../../institute-dashboard-assets/images/exam-time.gif";
import { FETCH_PARIKSHA_USER_QUESTION_INFO } from "../../../../app/service/pariksha-module-questions";
import { toast } from "react-toastify";
import moment from "moment";
import { GET_USER_USER_CODE } from "../../../../app/service/users.service";
import { GET_SUBJECT_BY_UUID } from "../../../../app/service/abhyas-subject.service";

const ParentViewStudentParikshaSummary = () => {
    const [testLoading, setTestLoading] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [parikshaInfo, setParikshaInfo] = useState<any>({});
    const [subjectData, setSubjectData] = useState<any>({});
    const history = useHistory();
    const [testsList, setTestsList] = useState<any>([]);
    const [totalTestList, setTotalList] = useState<any>([]);
    const [selectedTest, setSelectedTest] = useState("");
    const [studentInfo, setStudentInfo] = useState<any>({});
    let { moduleUUID, userCode, schoolCode, BoardUuid, MediumUuid, StandardUuid, SubjectUuid } = useParams<{ moduleUUID: string, userCode: string, schoolCode: string, BoardUuid: string, MediumUuid: string, StandardUuid: string, SubjectUuid: string }>();
    const modules_uuid = moduleUUID;
    const user_code = userCode;
    const school_code = schoolCode;
    const boardUuid = BoardUuid;
    const mediumUuid = MediumUuid;
    const standardUuid = StandardUuid;
    const subjectUuid = SubjectUuid;

    useEffect(() => {

        getUserDetails();
        getSubjectInfo();
        getParikshaCompleteInfo();

    }, []);

    function getParikshaCompleteInfo() {
        let payload = {
            module_uuid: modules_uuid,
            school_code: school_code,
            user_code: user_code,
            board_uuid: boardUuid,
            medium_uuid: mediumUuid,
            standard_uuid: standardUuid,
            subject_uuid: subjectUuid
        }
        localStorage.setItem('userSelectedSubjectInfo', JSON.stringify(payload));
        setLoading(true);
        FETCH_PARIKSHA_USER_QUESTION_INFO(payload).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res?.err);
                setLoading(false);
            } else {
                if (res) {
                    const data = res;
                    setParikshaInfo(data);
                    if (data && data?.tests) {
                        // data?.tests?.forEach((element: any) => {
                        //     element.started_dt = moment(element.started_dt.split("T")[0]).format('DD-MM-yyyy');
                        // });
                        setTotalList(data?.tests);
                        data?.tests.slice(0, 10)
                        setTestsList(data?.tests);
                    }
                    setLoading(false);

                }
            }
        });
    }

    function getUserDetails() {
        GET_USER_USER_CODE(userCode).then((response: any) => {
            if (response.status === 'fail') {
                toast.error(response.message);
            } else {
                const data = response.rows[0];
                localStorage.setItem("cc_student_info", JSON.stringify(data));
                setStudentInfo(data);
            }
        });
    }
    const getSubjectInfo = () => {
        GET_SUBJECT_BY_UUID(subjectUuid).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res?.err);
            } else {
                if (res.rows.length > 0) {
                    setSubjectData(res.rows[0]);
                }
            }
        });
    }

    const showResults = (data: any) => {
        localStorage.setItem('result-test-data', JSON.stringify(data));
        history.push(`/parent-dashboard/view/pariksha/test-analysis/${modules_uuid}/${user_code}/${school_code}`);
        // }

    }

    const handleRows = (e: any) => {
        setTestLoading(true);
        let selectValue = parseInt(e)
        let preparedElement = totalTestList;
        let preapreList = preparedElement?.slice(0, selectValue);
        // let preapreList = testsList.slice(0, selectValue);
        setTestsList(preapreList);
        setTimeout(() => {
            setTestLoading(false);

        }, 1000);

    }
    const viewResultExplanation = (item: any) => {
        const testInfo = {
            exam_type: item?.test_details?.exam_type,
            test_name: item?.test_details?.name,
            board_uuid: boardUuid,
            medium_uuid: mediumUuid,
            standard_uuid: standardUuid,
            subject_uuid: subjectUuid
        }
        localStorage.setItem("pariksha_test_info", JSON.stringify(testInfo));
        localStorage.setItem("result-test-data", JSON.stringify(item));
        history.push(`/parent-dashboard/view/pariksha/explanation/${modules_uuid}/${user_code}/${school_code}`);
    }


    const handleTestClick = (testType: any) => {
        let preparedTest = testType?.charAt(0).toUpperCase() + testType?.slice(1).toLowerCase();
        localStorage.setItem("select_test", preparedTest)
        setSelectedTest(testType);
        switch (testType) {
            case 'allocated':
                setTestsList(parikshaInfo?.tests_summary?.tests_allocated_data);
                break;
            case 'attempted':
                setTestsList(parikshaInfo?.tests_summary?.tests_attempted_data);
                break;
            case 'passed':
                setTestsList(parikshaInfo?.tests_summary?.tests_passed_data);
                break;
            case 'failed':
                setTestsList(parikshaInfo?.tests_summary?.tests_failed_data);
                break;
            default:
                setTestsList([]);
        }
        setTestLoading(true);
        setTimeout(() => {
            setTestLoading(false);
        }, 1000);
    };

    const calculateTrianglePosition = () => {
        switch (selectedTest) {
            case 'allocated':
                return 'calc(9% - 5px)'; // Adjust this value as needed for your layout
            case 'attempted':
                return 'calc(30.5% - 5px)'; // Adjust this value as needed for your layout
            // Add cases for other test types if needed
            case 'passed':
                return 'calc(51.5% - 5px)';
            case 'failed':
                return 'calc(73.5% - 5px)';
            default:
                return '50%';
        }
    };

    const getTriangleColor = () => {
        // Return the class name for the triangle based on the selected test
        switch (selectedTest) {
            case 'allocated':
                return 'triangle-allocated'; // Use orange color for allocated
            case 'attempted':
                return 'triangle-attempted'; // Use green color for attempted
            // Add cases for other test types if needed
            case 'passed':
                return 'triangle-passed';
            case 'failed':
                return 'triangle-failed';
            default:
                return ''; // Default color or class
        }
    };
    const getBtnColor = () => {
        switch (selectedTest) {
            case 'allocated':
                return '#5233ff';
            case 'attempted':
                return '#66ccff';
            case 'passed':
                return '#52cc99';
            case 'failed':
                return '#ff5033';
            default:
                return '';
        }
    }
    const getDateFormate = (time: any) => {
        if (time) {
            let timeData = moment(time.split("T")[0]).format('DD-MM-yyyy');
            return timeData;
        } else {
            return "";
        }

    }
    const handleGoBack = () => {
        history.push(`/parent-dashboard/view/pariksha/initiative/${moduleUUID}/${userCode}/${schoolCode}`);
    }
    return (
        <div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb pb-2 ps-0 pt-0 border-bottom">
                            <li className="breadcrumb-item "><Link to="/parent-dashboard/view"><span className='pe-2'>Dashboard</span></Link></li> /
                            <li className="breadcrumb-item "><Link to={`/parent-dashboard/view/pariksha/initiative/${moduleUUID}/${userCode}/${schoolCode}`}><span>Pariksha</span></Link></li> /
                            <li className="breadcrumb-item "><span>Result Summary</span></li> /
                            <li className="breadcrumb-item "><span>{subjectData?.name?.charAt(0).toUpperCase() + subjectData?.name?.slice(1).toLowerCase()}</span></li>
                        </ol>
                    </nav>
                    <div className="text-center">
                        <h5 className="mb-0 f16 md_dashboard_txt"><span className="text-inprogess ">{studentInfo?.name?.toUpperCase()}'S</span> PARIKSHA DASHBOARD</h5>
                    </div>
                    <div className='text-end me-5 mb-1'>
                        <button className="btn btn-primary btn-sm px-5 py-1 rounded-12 cursor-pointer" onClick={handleGoBack}>Back</button>
                    </div>
                    <h5 className='text-center mb-5'>Result Summary - {subjectData?.name?.toUpperCase()}</h5>
                    <div className='row  ms-2 justify-content-center'>
                        <div className='co-8  col-md-10 '>
                            <div className='prx_sm ms-5 ms-lg-0'>
                                <div className={` test-summary ${selectedTest === 'allocated' ? 'selected' : ''}`} onClick={() => handleTestClick('allocated')}>
                                    <div className='d-flex position-relative cursor-pointer'>
                                        <div className='position-absolute' style={{ left: "-41px" }}>
                                            <img src={TESTALLOCATED} className="test_summary_mg " alt="average area" ></img>
                                        </div>
                                        <div className='all_bx'>
                                            <p className='mb-1'> {parikshaInfo?.tests_summary?.tests_allocated}</p>
                                            <p className='mb-0'>Allocated Tests</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={` test-summary ${selectedTest === 'attempted' ? 'selected' : ''}`} onClick={() => handleTestClick('attempted')}>
                                    <div className='d-flex position-relative cursor-pointer'>
                                        <div className='position-absolute' style={{ left: "-41px" }}>
                                            <img src={TESTATTEMPTED} className="test_summary_mg " alt="average area" ></img>
                                        </div>
                                        <div className='all_bx'>
                                            <p className='mb-1'> {parikshaInfo?.tests_summary?.tests_attempted}</p>
                                            <p className='mb-0'>Attempted Tests</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={` test-summary ${selectedTest === 'passed' ? 'selected' : ''}`} onClick={() => handleTestClick('passed')}>
                                    <div className='d-flex position-relative cursor-pointer'>
                                        <div className='position-absolute' style={{ left: "-41px" }}>
                                            <img src={TESTPASSED} className="test_summary_mg " alt="average area" ></img>
                                        </div>
                                        <div className='all_bx'>
                                            <p className='mb-1'> {parikshaInfo?.tests_summary?.tests_passed}</p>
                                            <p className='mb-0'>Passed Tests</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={` test-summary ${selectedTest === 'failed' ? 'selected' : ''}`} onClick={() => handleTestClick('failed')}>
                                    <div className='d-flex position-relative cursor-pointer'>
                                        <div className='position-absolute' style={{ left: "-41px" }}>
                                            <img src={TESTFAILED} className="test_summary_mg " alt="average area" ></img>
                                        </div>
                                        <div className='all_bx'>
                                            <p className='mb-1'> {parikshaInfo?.tests_summary?.tests_failed}</p>
                                            <p className='mb-0'>Failed Tests</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {selectedTest && (<div className='col-12'>
                            <div className={`d-none d-lg-block triangle ${getTriangleColor()}`} style={{ left: calculateTrianglePosition() }}></div>
                            <div className='border border-2 mt-lg-2 mt-3 px-3 me-2'>
                                <div className='text-center mt-5'><button className='test_sm_btn' style={{ backgroundColor: getBtnColor() }}>{selectedTest === 'allocated' && " Allocated Tests"} {selectedTest === 'attempted' && " Attempted Tests"} {selectedTest === 'passed' && "Passed Tests"} {selectedTest === 'failed' && " Failed Tests"}</button></div>
                                <div className='d-flex justify-content-between mt-1'>
                                    <h5></h5>
                                    <div className='mb-1 d-flex me-3'>
                                        <h6 className='me-3 mt-md-2 fw-normal'>Row(s) per Page</h6>
                                        <div>
                                            <select className="my-2 f16 cursor-pointer" onChange={(e) => handleRows(e.target.value)}>
                                                <option value="10">10</option>
                                                <option value="20">20</option>
                                                <option value="50">50</option>
                                                <option value="70">70</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                {testLoading &&
                                    <div className="text-center p-5">
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                }
                                {!testLoading && <div className="mb-4">
                                    <div className='table-responsive mar-t-20 custom-scroll  f14 '>
                                        <table className='table test_fixed-table-header mb-0'>
                                            <thead>
                                                <tr>
                                                    <th style={{ width: "50px" }}>#</th>
                                                    <th >Test Name</th>
                                                    <th style={{ width: '100px' }} >Date</th>
                                                    <th style={{ width: '80px' }} className='text-center'> Questions</th>
                                                    <th style={{ width: '80px' }} className='text-center'> Attempted</th>
                                                    <th style={{ width: '90px' }} className='text-center'>Correct</th>
                                                    <th style={{ width: '90px' }} className='text-center'>Wrong</th>
                                                    <th style={{ width: '90px' }} className='text-center'>Score (%)</th>
                                                    <th style={{ width: '200px' }}>Recommendation</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {testsList?.map((item: any, i: number) => {
                                                    return (<>
                                                        <tr key={i} className='border-bottom align-middle'>
                                                            <td>{i + 1}</td>
                                                            <td><span className='active cursor-pointer f16' onClick={() => showResults(item)}>{item?.test_details?.exam_type?.split('_')
                                                                .map((word: any) => word?.charAt(0).toUpperCase() + word?.slice(1).toLowerCase())
                                                                .join(' ')}  {item?.test_details?.exam_type === "CHAPTER_WISE_TEST" && <span className='mb-0 cursor-pointer f14 ms-1' >({item?.topic_wise_result[0]?.chapter_name})</span>}</span> </td>
                                                            <td>{getDateFormate(item?.started_dt)}</td>
                                                            <td className='text-center'>{item?.result?.total_questions}</td>
                                                            <td className='text-center'>{item?.result?.attempted}</td>
                                                            <td className='text-center'>{item?.result?.correct}</td>
                                                            <td className='text-center'>{item?.result?.wrong}</td>
                                                            <td className='text-center'>{item?.result?.percentage?.toFixed(0)}</td>
                                                            <td><span className='active cursor-pointer un_line fw-bold' onClick={() => viewResultExplanation(item)}> Q & A with Explanation</span></td>
                                                        </tr>
                                                    </>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    {testsList?.length == 0 && <p className='text-center f16 mt-2'>No Data Available</p>}
                                </div>}
                            </div>
                        </div>)}
                    </div>


                    <div className='row '>
                        <div className=' col-12 align-self-end '>

                        </div>
                    </div>
                </div>
            }
        </div>
    )

}
export default ParentViewStudentParikshaSummary;