import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { MathJaxContext, MathJax } from 'better-react-mathjax';
import { GET_MLA_TWO_QUESTIONS, MLA_TWO_ANSWERS_UPDATE, MLA_TWO_TIME_UPDATE } from '../../app/service/mla-two.service';
import LogoImg from '../../assets/images/iamsamarth-Logo-Final.svg';
import BackIcon from '../../assets/images/mobile_back_icon.png';
import closeIcon from '../../assets/images/close_icon.png';
import Countdown from 'react-countdown';
declare const window: any;

export const MLATwoMobileQuestionsForm = () => {
    const [questionsData, setQuestionsData] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [startTimer, setStartTimer] = useState(false);
    const [canShowHeader, setShowHeader] = useState(false);
    const [timeRemain, setTimeRemain] = useState('');
    const [startTime, setStartTime] = useState(new Date());
    const [alphabets, setAlphabets] = useState<any>(["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"]);
    const [canShowPopup, setCanShowPopup] = useState(false);
    const [appData, setAppData] = useState<any>({});
    const [test_start_date, setTestStartDate] = useState<any>('');
    const [timeRemainForApp, setTimeRemainForApp] = useState<string>('');
    const [canShowAlertPopup, setCanShowAlertPopup] = useState<boolean>(false);


    // const window:any=Window;


    useEffect(() => {

        document.addEventListener("message", (message: any) => {
            let data = JSON.parse(message.data);
            if (data?.key && data.key === 'init') {
                setAppData(data);
                // getTimeDetails(data);
                if (data?.type === 'TESTS') {
                    checkTestStartDate(data);
                }
                localStorage.setItem("token", data.token);
                getMLATwoInfo(data);
            }
        });

    }, []);
    useEffect(() => {
        if (startTimer) {
            const timer = setInterval(() => {
                getTimeDetails(appData);
            }, 1000);

            return () => {
                clearInterval(timer);
            };
        }
    }, [startTimer]);
    function getTimeDetails(data: any) {
        const testStartDate: any = data.test_start_date;
        setTestStartDate(testStartDate ? testStartDate : null);
        let time = getTime(testStartDate);
        let timeMillis = moment(time, 'YYYY-MM-DD hh:mm:ss').toDate().getTime();
        let reqTimeMillis = 2 * 24 * 60 * 60 * 1000;
        let actualTimeToComplete = timeMillis + reqTimeMillis;
        let x: any = new Date(actualTimeToComplete)
        // setTimeRemain(x);

        let currentTime = new Date().getTime();

        let timerResult = x.getTime() - currentTime;
        let tSeconds = Math.floor(timerResult / 1000);
        let hours = Math.floor(tSeconds / 3600); // get hours
        let minutes = Math.floor((tSeconds - (hours * 3600)) / 60); // get minutes
        let seconds = tSeconds - (hours * 3600) - (minutes * 60); //  get seconds
        setTimeRemain((hours < 10 ? "0" + hours : hours) + " Hr : " + (minutes < 10 ? "0" + minutes : minutes) + " Min : " + (seconds < 10 ? "0" + seconds : seconds) + ' Sec');
        setTimeRemainForApp((hours < 10 ? "0" + hours : hours) + ":" + (minutes < 10 ? "0" + minutes : minutes) + ":" + (seconds < 10 ? "0" + seconds : seconds) + ' hrs');
        if (canShowAlertPopup && data?.type === 'TESTS') {
            setTimeRemain("00 Hr : 00 Min : 00 Sec");
        }
        if (hours == 0 && minutes == 0 && seconds == 0 && data?.type === 'TESTS') {
            setCanShowAlertPopup(true);
            setTimeRemain("00 Hr : 00 Min : 00 Sec");
        }
    }
    const checkTestStartDate = (data: any) => {
        const twoDaysAfterTestStartDate = new Date(data.test_start_date);
        twoDaysAfterTestStartDate.setDate(twoDaysAfterTestStartDate.getDate() + 2);
        // Get the current date and time
        const currentDate = new Date();
        // Compare current date with two days after test start date
        if (currentDate.getTime() >= twoDaysAfterTestStartDate.getTime()) {
            setCanShowAlertPopup(true);
            setStartTimer(false);
            setTimeRemain("00 Hr : 00 Min : 00 Sec");
        }
    }

    function getTime(time: any) {
        if (time !== "null") {
            return time;
        } else {
            return time ? time : startTime;
        }
    }


    function getMLATwoInfo(data: any) {
        getMLAQuestionsList(data);
    }


    function getMLAQuestionsList(data: any) {
        localStorage.setItem("token", data.token);
        let payload = {
            "mode": data.type,
            "test_no": data.question_paper_id ? data?.question_paper_id : '',
            "ability": data.ability ? data.ability : '',
            "worksheet_no": data.question_paper_id ? data.question_paper_id : ''
        }

        setShowHeader(false);
        setLoading(true);
        GET_MLA_TWO_QUESTIONS(payload).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    if (res) {
                        if (res.questions && res.questions.length > 0) {
                            setQuestionsData(res.questions);
                            ///hack///
                            const data = res.questions;
                            for (let i = 0; i < data.length; i++) {
                                data[i].answer = data[i].correct_answer;
                            }
                            setQuestionsData(data);
                            ///hack///
                            setShowHeader(true);
                            setStartTimer(true);
                            setLoading(false);

                            if (data.type === 'TESTS' && (!data.test_start_date
                                || data.test_start_date == '' || data.test_start_date === 'null')) {
                                // setTimeRemain("48:00:00 hrs");
                                setTestStartDate(new Date());
                                storeInitialTimeForTest();
                            }
                        } else {
                            setQuestionsData([]);
                            setShowHeader(true);
                            setLoading(false);
                        }

                    }

                }
            }
        });
    }

    function storeInitialTimeForTest() {
        let payload = {
            "user_code": appData.userCode,
            "school_code": appData.schoolCode,
            "cycle_no": appData.cycle_number ? appData.cycle_number : 1,
            "test_start_date": moment(startTime).format('YYYY-MM-DD HH:mm:ss')
        }

        MLA_TWO_TIME_UPDATE(payload).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                } else {
                    if (res) {
                        let preData = { ...appData }
                        preData.test_start_date = payload.test_start_date;
                        setAppData({ ...preData });
                    }
                }
            }
        });
    }

    function onViewQuestion(name: any) {
        return { __html: name };
    }


    function handleQuestionOptions(item: any, index: number, j: number) {
        const data = questionsData;
        for (let i = 0; i < alphabets.length; i++) {
            if (j === i) {
                data[index].answer = alphabets[i];
            }
        }
        setQuestionsData(data);
    }


    const handleCompleteSubmit = () => {
        let payload = {
            "user_code": appData.userCode,
            "school_code": appData.schoolCode,
            "cycle_no": appData.cycle_number ? parseInt(appData.cycle_number) : 1,
            "mode": appData?.type,
            "test_no": appData && appData.test_no ? appData.test_no : 1,
            // "question_paper_id": appData?.question_paper_id ? appData?.question_paper_id : '',
            // "question_paper_id": appData?.id ? appData?.id : '',
            "question_paper_id": appData?.type === 'TESTS' ? (appData?.question_paper_id ? appData?.question_paper_id : '') : (appData?.id ? appData?.id : ''),
            "question_paper_level": appData.level ? appData.level : '',
            "ability": appData.ability ? appData.ability : '',
            "priority_no": appData?.priority ? appData?.priority : '',
            "time_taken": 10,
            "test_start_date": moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            "questions": questionsData
        }

        setLoading(true);
        MLA_TWO_ANSWERS_UPDATE(payload).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    setLoading(false);
                    const data = res;
                    if (appData.type === 'WORKSHEETS') {
                        sendMessageToApp({ action: 'show_popup', type: 'worksheet_submitted', data: '' });
                        return;
                    }
                    sendMessageToApp({ action: 'show_popup', type: 'test_submitted', timeRemain: timeRemainForApp });
                }

            }
        });
    }

    const getCircleColor = (item: any) => {
        if (item.question_paper_id === appData.question_paper_id) {
            return '#FCEE21';
        } else if (item.status === 'completed') {
            return '#8CC63F';
        } else {
            return '#FFFFFF';
        }
    }

    const handleBack = () => {
        sendMessageToApp({ action: 'back_click' });
    }

    const sendMessageToApp = (data: any) => {
        if (window?.ReactNativeWebView) {
            try {
                window?.ReactNativeWebView.postMessage(JSON.stringify(data));
            } catch (error) {

            }
        }
    }

    const handleclose = () => {
        setCanShowPopup(false);
        handleBack();
    }

    const handleSubmit = () => {
        setCanShowPopup(false);
        handleBack();
    }

    // Renderer callback with condition
    const renderer = ({
        days,
        hours,
        minutes,
        seconds,
        completed,
    }: {
        days: any;
        hours: any;
        minutes: any;
        seconds: any;
        completed: any;
    }) => {
        if (completed) {
            return <div></div>
            // Render a completed state
        } else {
            // Render a countdown
            return (
                <span style={{ fontSize: "1rem", paddingTop: "10px", fontStyle: 'italic' }}>
                    {hours} Hr : {minutes} Min : {seconds} Sec
                </span>
            );
        }
    };

    return (
        <div className="row p-3" style={{ backgroundColor: '#F2F2F2' }}>
            <div
                className={`modal fade ${canShowAlertPopup ? "show" : "false"}`}
                style={{ display: canShowAlertPopup ? "block" : "none" }}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Times up!!!
                            </h5>
                            <button
                                type="button"
                                className="close popup-close cursor-pointer"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={handleCompleteSubmit}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className='modal-body'>
                            <p>Attention!</p>
                            <p className='mb-0'> You have exceeded the allotted time to take the MLA test.</p>
                            <p>Please note that you can only access the test within the designated <span className='fw-bold'>48-hours</span> window from the time of initiation   <br /><span className='fw-bold'>  {appData?.test_start_date}</span>.
                                If you believe this is an error or if you have any questions, please contact support for assistance.</p>
                            <p>Thank you for your understanding.</p>
                            {/* Your inputs submitted successfully */}
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-primary cursor-pointer"
                                onClick={handleCompleteSubmit}
                            >
                                Ok
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`modal fade ${canShowPopup ? 'show' : 'false'}`} style={{ display: (canShowPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content mobile-modal-content">
                        <div className="modal-header mobile-modal-header">
                            <div className="modal-title" id="exampleModalLabel" style={{ fontSize: '20px', fontWeight: 'bold' }}>Alert</div>
                            <div className="close popup-close cursor-pointer" data-dismiss="modal" aria-label="Close" onClick={handleclose}>
                                <img src={closeIcon} style={{ width: '20px', height: '20px' }} />
                            </div>
                        </div>
                        <div className='modal-body'>
                            <span style={{ fontSize: '15px' }}>You have successfully completed worksheet</span>
                        </div>
                        <div className="modal-footer mobile-modal-footer">
                            <span style={{ color: '#0F70CD', fontSize: 14, paddingRight: '15px' }} className="cursor-pointer" data-dismiss="modal" onClick={handleclose}>CANCEL</span>
                            <span style={{ color: '#333333', fontWeight: 'bold', fontSize: 14 }} className="cursor-pointer" onClick={handleSubmit}>OK</span>
                        </div>
                    </div>
                </div>
            </div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <div>
                {canShowHeader && <div>
                    <div className='row'>
                        <div className='col-10 col-md-11'>
                            <img src={LogoImg} alt="" className="mobile-login-logo" />
                        </div>
                        <div className='col-2 col-md-1 mobile-back-icon-header'>
                            <img src={BackIcon} alt="" className='mobile-back-icon' onClick={handleBack} />
                        </div>
                    </div>
                    <div className="row mt-1">
                        {appData && appData.type === 'TESTS' && < div className='col-md-8'>
                            <div style={{ fontSize: '14px', color: '#333333' }}>MLA</div>
                            <div style={{ fontSize: '25px', fontStyle: 'italic', fontWeight: 'bold', color: '#333333' }}>Test -0{appData && appData.test_no ? appData.test_no : ''}</div>
                        </div>}
                        {appData && appData.type === 'WORKSHEETS' &&
                            <div className='col-12 col-md-10'>
                                <div style={{ fontSize: '14px', color: '#333333' }}>Priority - {appData ? appData.priority : ''}</div>
                                <div style={{ fontSize: '25px', fontWeight: 'bold', color: '#333333' }}>{appData ? appData.name : ''}</div>
                                <div style={{ fontSize: '12px', color: '#333333' }}>Worksheets</div>
                                <div className='col-md-12'>
                                    {appData && appData.worksheets && appData.worksheets.map((item: any, i: number) => (
                                        <div key={i} className="circle" style={{ backgroundColor: getCircleColor(item) }}>
                                            <p>{i + 1}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        }
                        {appData && appData?.type === 'TESTS' && <div className='col-md-2'>
                            <div>Time Limit</div>
                            <div >
                                <p className='mb-0' style={{ fontSize: "1rem", paddingTop: "10px", fontStyle: 'italic' }}>{timeRemain}</p>

                                {/* {timeRemain && <Countdown
                                    date={timeRemain}
                                    renderer={renderer}
                                />} */}
                            </div>
                        </div>}
                    </div>
                </div>}
                <div>
                    <MathJaxContext>
                        <MathJax>
                            {questionsData.map((question: any, i: number) => (
                                <div className="mt-4" key={i}>
                                    <div className='row'>
                                        <div className='col-2 col-md-1' style={{ alignSelf: 'center' }}>
                                            <span className='fw-bold' style={{ fontSize: '16px', color: '#333333' }}>
                                                Q{question.question_number}:
                                            </span>
                                        </div>
                                        <div className='col-10 col-md-11' style={{ paddingTop: '15px' }}>
                                            <span className='fw-bold' style={{ fontSize: '16px', color: '#333333' }}>
                                                <div dangerouslySetInnerHTML={onViewQuestion(question.question)}></div>
                                            </span>
                                        </div>
                                    </div>
                                    <form>
                                        {question.options.map((options: any, j: number) => (
                                            <div className='row pt-3' key={j}>
                                                <div className='col-1 col-md-1'>
                                                    <span>
                                                        <input className="mb-0" type="radio" name="option" onChange={(e) => { handleQuestionOptions(options, i, j) }} />
                                                    </span>
                                                </div>
                                                <div className='col-11 col-md-6 fw-bold' style={{ fontSize: '16px', color: '#333333' }}>
                                                    <span dangerouslySetInnerHTML={onViewQuestion(options.value)}></span>
                                                </div>
                                            </div>
                                        ))}
                                    </form>
                                </div>
                            ))}
                        </MathJax>
                    </MathJaxContext>
                </div>
                {questionsData.length > 0 && <div className="text-center mt-4">
                    <button className="col-12 col-md-2 btn btn-primary px-5 rounded-5 cursor-pointer" onClick={handleCompleteSubmit}>Submit</button>
                </div>}
            </div>}
        </div >
    )
}
