import React, { useState } from "react";
import { format } from 'date-fns';
import ReportTestPieChart from "./test-result-pie-chart";
import ReportColoumnChart from "./column-chart";
import ReportAccuracyPieChart from "./accuracy-pie-chart";


interface Props {
    mnsReportInfo: any;
}
export const MNSReport: React.FC<Props> = (props: Props) => {


    const formatDate = (dateString: string): any => {
        const date = new Date(dateString);
        const formattedDate = format(date, "dd-MMM-yyyy"); // Format date component
        const formattedTime = format(date, "HH:mm:ss"); // Format time component
        return (
            <>
                <span>{formattedDate},</span><br />
                <span>{formattedTime}</span>
            </>
        );
    }
    const getTestTableClass = (level: string, subLevel: string): string => {
        switch (level) {
            case "A":
                return "mns_table_case-1";
            case "B":
                return "mns_table_case-2";
            case "C":
                if (subLevel === "C1") {
                    return "mns_table_case-3";
                } else {
                    return "mns_table_case-4";
                }
            default:
                return "mns_table_case-1";
        }

    }
    const getMnsLevelTableClass = (level: string, subLevel: string): string => {
        switch (level) {
            case "A":
                return "mns_practice_case-1";
            case "B":
                return "mns_practice_case-2";
            case "C":
                // if (subLevel === "level_C1") {
                if (subLevel === "C1") {
                    return "mns_practice_case-3";
                } else {
                    return "mns_practice_case-4";
                }
            default:
                return "mns_practice_case-1";
        }

    }
    const convertDecimalToTime = (value: any): string => {
        const totalSeconds = Math.round(value * 60); // Convert decimal to total seconds
        const minutes = Math.floor(totalSeconds / 60); // Extract minutes
        const seconds = totalSeconds % 60;
        return `${minutes}${seconds > 0 ? `.${seconds < 10 ? `0${seconds}` : seconds}` : ""}`;
        // return `${minutes}${seconds > 0 ? `.${seconds}` : ""}`;
    };

    return (
        <div className="mx-4">
            <h4 className="text-center cl_ble un_line mt-5"><span className="un_line">Detailed Report</span></h4>
            <div className="ms-lg-4">
                {(props?.mnsReportInfo?.tests?.length > 0 || props?.mnsReportInfo?.levels?.length > 0) && <h4 className="txt_mns_org mt-5">Minimum Numerical Skills (MNS)</h4>}
                {props?.mnsReportInfo?.tests?.length > 0 && <h6 className="under_line_skip_ink entry_ul my-4 pb-3">Result of Entry Level Test (Assessment Test)</h6>}
            </div>
            {/* <p className="f14 mns_case_cl mt-4"><span className="under_line_skip_ink mns_cases">Case: 1</span> (0-8)</p> */}

            {props?.mnsReportInfo?.tests?.length > 0 && props?.mnsReportInfo?.tests?.map((data: any, i: number) =>
                <div key={i}>
                    <div className="row justify-content-md-center mb-3">
                        <div className="col-12 col-lg-11 ">
                            <div className="math_charts_responsive ">
                                <div className="">
                                    <ReportTestPieChart
                                        title="RESULT OF ASSESSMENT TEST"
                                        correctAnswers={data?.correct_answers}
                                        wrongAnswers={data?.wrong_answers}
                                    />
                                </div>
                                <div className="">
                                    <ReportColoumnChart
                                        chartTitle="Allocated time vs Utilised time"
                                        subTitle="Time in minutes"
                                        dataValues={[
                                            {
                                                name: "Allocated time",
                                                data: [Number(data?.allocated_time) ? Number(convertDecimalToTime(data?.allocated_time)) : 0],
                                                color: '#156082'
                                            },
                                            {
                                                name: "Utilised time",
                                                data: [Number(data?.time_taken) ? Number(convertDecimalToTime(data?.time_taken)) : 0],
                                                color: '#E97132'
                                            },
                                        ]}
                                    />
                                </div>
                                <div className="">
                                    <ReportAccuracyPieChart
                                        title="Assessment Test Accuracy"
                                        data={[
                                            {
                                                name: 'Accuracy', y: data?.percentage, color: '#0A89B9'
                                            },
                                            { name: '', y: (100 - data?.percentage) == 0 ? "" : (100 - data?.percentage), color: '#83B7DD' },
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-12 col-md-11 ">

                            <div className="row ">
                                <div className="col-12 col-lg-4">
                                    <div>
                                        <ReportTestPieChart
                                            title="RESULT OF ASSESSMENT TEST"
                                            correctAnswers={data?.correct_answers}
                                            wrongAnswers={data?.wrong_answers}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4">
                                    <ReportColoumnChart
                                        chartTitle="Allocated time vs Utilised time"
                                        subTitle="Time in minutes"
                                        dataValues={[
                                            {
                                                name: "Allocated time",
                                                data: [Number(data?.allocated_time) ? Number(convertDecimalToTime(data?.allocated_time)) : 0],
                                                color: '#156082'
                                            },
                                            {
                                                name: "Utilised time",
                                                data: [Number(data?.time_taken) ? Number(convertDecimalToTime(data?.time_taken)) : 0],
                                                color: '#E97132'
                                            },
                                        ]}
                                    />
                                </div>
                                <div className="col-12  col-lg-4">
                                    <div>
                                        <ReportAccuracyPieChart
                                            title="Assessment Test Accuracy"
                                            data={[
                                                {
                                                    name: 'Accuracy', y: data?.percentage, color: '#0A89B9'
                                                },
                                                { name: '', y: (100 - data?.percentage) == 0 ? "" : (100 - data?.percentage), color: '#83B7DD' },
                                            ]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className="mns_table_container mt-3 table-responsive" >
                        <table className={`${getTestTableClass(data?.assigned_level, data?.assigned_sub_level)}`}>
                            <thead>
                                <tr className="text-center">
                                    <th>Total <br />Questions</th>
                                    <th>Allocated <br /> Time</th>
                                    <th>Utilised<br /> Time</th>
                                    <th>Correct <br />Answers</th>
                                    <th>Wrong <br />Answers</th>
                                    <th>Accuracy</th>
                                    <th>Level <br />Assigned</th>
                                    <th>Date & Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="text-center">
                                    <td className="fw-bold">{data?.questions} ({parseInt(data?.questions) * 3})</td>
                                    <td>{data?.allocated_time && convertDecimalToTime(data?.allocated_time)}min</td>
                                    <td>{data?.time_taken && convertDecimalToTime(data?.time_taken)}min</td>
                                    <td>{data?.correct_answers}</td>
                                    <td>{data?.wrong_answers}</td>
                                    <td>{data?.percentage?.toFixed(2)}%</td>
                                    <td>{data?.assigned_sub_level}</td>
                                    <td>{data?.attempted && formatDate(data?.attempted)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>)}

            {props?.mnsReportInfo?.levels?.length > 0 && <div>
                <h6 className="ms-lg-4 mt-5 mb-4 pb-3"><span className="under_line_skip_ink entry_ul">Practice assigned as per the assessment result</span> </h6>
                <div className="  row justify-content-md-center mb-3">
                    <div className="col-12 col-lg-11">
                        <div className="math_charts_responsive">
                            <div className="">
                                <ReportColoumnChart
                                    chartTitle="Correct Answers vs Wrong Answers"
                                    subTitle="Number of questions"
                                    dataValues={[
                                        {
                                            name: "Correct",
                                            data: [Number(props?.mnsReportInfo?.levels_overall_data?.correct_answers) ? Number(props?.mnsReportInfo?.levels_overall_data?.correct_answers) : 0],
                                            color: '#156082'
                                        },
                                        {
                                            name: "Wrong",
                                            data: [Number(props?.mnsReportInfo?.levels_overall_data?.wrong_answers) ? Number(props?.mnsReportInfo?.levels_overall_data?.wrong_answers) : 0],
                                            color: '#E97132'
                                        },
                                    ]}
                                />
                            </div>
                            <div className="">
                                <ReportAccuracyPieChart
                                    title="Overall Accuracy in MNS"
                                    data={[
                                        {
                                            name: 'Accuracy', y: props?.mnsReportInfo?.levels_overall_data?.accuracy, color: '#0A89B9'
                                        },
                                        { name: '', y: (100 - props?.mnsReportInfo?.levels_overall_data?.accuracy) == 0 ? "" : (100 - props?.mnsReportInfo?.levels_overall_data?.accuracy), color: '#83B7DD' },
                                    ]}
                                />
                            </div>
                            <div className="">
                                <div>
                                    <ReportColoumnChart
                                        chartTitle="Recommended time vs Utilised time"
                                        subTitle="Time in minutes"
                                        dataValues={[
                                            {
                                                name: "Recommended time",
                                                data: [Number(props?.mnsReportInfo?.levels_overall_data?.allocated_time) ? Number(convertDecimalToTime(props?.mnsReportInfo?.levels_overall_data?.allocated_time)) : 0],
                                                color: '#156082'
                                            },
                                            {
                                                name: "Utilised time",
                                                data: [Number(props?.mnsReportInfo?.levels_overall_data?.utilized_time) ? Number(convertDecimalToTime(props?.mnsReportInfo?.levels_overall_data?.utilized_time)) : 0],
                                                color: '#E97132'
                                            },
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="report_table_container table-responsive">
                    <table className={` mns_practice_level ${getMnsLevelTableClass(props?.mnsReportInfo?.tests[0]?.assigned_level, props?.mnsReportInfo?.tests[0]?.assigned_sub_level)}`}>
                        <thead>
                            <tr className="text-center">
                                <th>Test</th>
                                <th>Total <br />Questions</th>
                                <th>Allocated <br />Time</th>
                                <th>Utilised <br />Time</th>
                                <th>Correct <br />Answers</th>
                                <th>Wrong <br />Answers</th>
                                <th>Number of<br /> Attempt</th>
                                <th>Accuracy</th>
                                <th>Status</th>
                                <th>Level<br /> Assigned</th>
                                <th>Date & Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props?.mnsReportInfo?.levels?.map((item: any, i: number) => <>
                                {(item?.level !== props?.mnsReportInfo?.levels[i - 1]?.level) && <tr className="text-center " key={i}>
                                    <td className='bg-level fw-bold border-0' colSpan={11}> {item?.level} - Level</td>
                                </tr>}
                                {item?.status === "skipped" && <tr className="text-center ">
                                    <td className='bg-level text-white'>{item?.sub_level}</td>
                                    <td colSpan={10}>Level Skipped as per the assessment result</td>
                                </tr>}
                                {item?.data?.map((level: any, index: number) => <tr className="text-center" key={index}>

                                    {level?.status !== "skipped" && <>
                                        <td className='bg-level text-white'>{level?.sub_level} - T{level?.test_attempt}</td>
                                        <td>{level?.questions}</td>
                                        <td>{level?.allocated_time ? convertDecimalToTime(level?.allocated_time) : "-"}min</td>
                                        <td>{level?.time_taken ? convertDecimalToTime(level?.time_taken) : "-"}{level?.time_taken && "min"}</td>
                                        <td>{(level?.correct_answers || level?.correct_answers === 0) ? level?.correct_answers : "-"}</td>
                                        <td>{(level?.wrong_answers || level?.wrong_answers === 0) ? level?.wrong_answers : "-"}</td>
                                        <td>{(level?.test_attempts || level?.test_attempts === 0) ? level?.test_attempts : "-"}</td>
                                        <td>{(level?.percentage || level?.percentage === 0) ? level?.percentage?.toFixed(1) : "-"}{(level?.percentage || level?.percentage === 0) && "%"}</td>
                                        <td>{level?.test_result ? level?.test_result === "pass" ? "Pass" : "Fail" : "-"}</td>
                                        <td>{level?.level_assigned ? level?.level_assigned : "-"}</td>
                                        <td>{level?.submitted_time ? formatDate(level?.submitted_time) : "-"}</td>
                                    </>}
                                </tr>)}
                            </>)}
                        </tbody>
                    </table>
                </div>
            </div>}
        </div>
    )
}