import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { GET_USER_USER_CODE } from "../../../../app/service/users.service";
import { toast } from "react-toastify";
import { GET_SCHOOL_BY_SCHOOL_CODE } from "../../../../app/service/school.service";
import { GET_BOARD_FILTER } from "../../../../app/service/abhyas-board.service";
import { GET_MEDIUM_FILTER } from "../../../../app/service/abhyas-medium.service";
import { GET_SUBJECT_FILTER_LIST } from "../../../../app/service/common-module.service";
import { GET_STANDARD_FILTER } from "../../../../app/service/abhyas-standard.service";
import COMPLETETASKLIST from "../../../../institute-dashboard-assets/images/Picture3.gif"
import { FETCH_PARIKSHA_USER_QUESTION_INFO } from "../../../../app/service/pariksha-module-questions";

const ParentViewStudentParikshaInitiative = () => {
    let { moduleUUID, userCode, schoolCode } = useParams<{ moduleUUID: string, userCode: string, schoolCode: string }>();
    const [canShowArrow, setCanShowArrow] = useState<Boolean>(false);
    const [studentInfo, setStudentInfo] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const [subjectList, setSubjectList] = useState<any>([]);
    const modules_uuid = moduleUUID;
    const user_code = userCode;
    const school_code = schoolCode;
    const history = useHistory();
    let standardNo: any = "";
    let medium: string = "";
    const [selectUuid, setSelectUuid] = useState<any>({
        school_code: school_code,
        user_code: user_code
    });
    useEffect(() => {
        getUserDetails();

    }, []);
    function getUserDetails() {
        setLoading(true);
        // window.scrollTo(0, 0);
        GET_USER_USER_CODE(user_code).then((response: any) => {
            if (response.status === 'fail') {
                const errorValue = Array.isArray(response?.err) ? response?.err[0] : response?.err;
                toast.error(errorValue);
                setLoading(false);
            } else {
                const data = response.rows[0];
                setStudentInfo(data);
                standardNo = data?.standard;
                medium = data?.medium
                if (data?.indv_school_details?.school_board) {
                    getBoardUuid(data?.indv_school_details?.school_board);
                } else {
                    getSchoolData();
                }
                // getStandardData(data);
            }
        });
    }
    const getSchoolData = () => {
        GET_SCHOOL_BY_SCHOOL_CODE(school_code).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                } else {
                    const data = res?.rows[0];
                    getBoardUuid(data?.curriculum);
                }
            }
        });
    }
    const getBoardUuid = (data: string) => {
        let payLoad = {
            search: data
        }
        GET_BOARD_FILTER(payLoad).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                } else {
                    const data = res?.rows[0];
                    selectUuid.board_uuid = data?.uuid;
                    getMediumUuid(data?.uuid);

                }
            }
        });
    }
    const getMediumUuid = (bordUuid: any) => {
        let payLoad = {
            search: medium
        }
        GET_MEDIUM_FILTER(payLoad, bordUuid).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                } else {
                    const data = res?.rows[0];
                    selectUuid.medium_uuid = data?.uuid;
                    getStandardUuid(bordUuid, data?.uuid);
                }
            }
        });
    }
    const getStandardUuid = (bordUuid: any, mediumUuid: any) => {

        let payLoad = {
            search: parseInt(standardNo)
        }
        GET_STANDARD_FILTER(payLoad, bordUuid, mediumUuid).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                } else {
                    const data = res?.rows[0];
                    selectUuid.standard_uuid = data?.uuid;
                    getSubjectList(data?.uuid);
                }
            }
        });
    }
    const getSubjectList = (standardUuid: any) => {

        GET_SUBJECT_FILTER_LIST(standardUuid).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                } else {
                    const data = res['rows'];
                    data?.map((item: any) => {
                        getSubjectStatus(item);

                    })
                    setSubjectList(data);
                    setLoading(false);

                }
            }
        });
    }
    const getSubjectStatus = (subject: any) => {
        let payload = {
            module_uuid: modules_uuid,
            school_code: school_code,
            user_code: user_code,
            board_uuid: subject?.board_uuid,
            medium_uuid: subject?.medium_uuid,
            standard_uuid: subject?.standard_uuid,
            subject_uuid: subject?.uuid
        }
        setLoading(true);
        FETCH_PARIKSHA_USER_QUESTION_INFO(payload).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res?.err);
                setLoading(false);
            } else {
                if (res) {
                    const data = res;
                    if (data?.tests?.length > 0) {
                        subject.ui_status = "completed"
                    }

                }
            }
        });
    }
    const onClickResultSummary = () => {
        setCanShowArrow(!canShowArrow);
    }
    const onChangeSubject = (subjectUuid: any, subjectData: any) => {
        selectUuid.subject_uuid = subjectUuid;
        localStorage.setItem('user_uuids_Info', JSON.stringify(selectUuid));
        if (subjectData?.ui_status === "completed") {
            history.push(`/parent-dashboard/view/pariksha/summary/${modules_uuid}/${user_code}/${school_code}/${selectUuid?.board_uuid}/${selectUuid?.medium_uuid}/${selectUuid?.standard_uuid}/${subjectUuid}`);
        }
    }
    return (
        <div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb pb-2 ps-0 pt-0 border-bottom">
                            <li className="breadcrumb-item "><Link to="/parent-dashboard/view"><span className='pe-2'>Dashboard</span></Link></li> /
                            <li className="breadcrumb-item "><span>Pariksha</span></li>
                        </ol>
                    </nav>
                    <div className="text-center">
                        <h5 className="mb-0 f16 md_dashboard_txt"><span className="text-inprogess ">{studentInfo?.name?.toUpperCase()}'S</span> PARIKSHA DASHBOARD</h5>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-7 col-12 text-center ">
                            <div className='mt-5 pt-md-5'>
                                <img src={COMPLETETASKLIST} className="ps_report_gf" alt="task list" ></img>
                            </div>
                        </div>
                        <div className="col-lg-4 col-12 text-center text-lg-start">
                            <div className="mt-5 pt-4  ab_test_select">
                                <button className="ab_select mt-5" onClick={onClickResultSummary}> Results Summary
                                    {!canShowArrow && <i className="bi bi-chevron-right ps-3"></i>}
                                    {canShowArrow && <i className="bi bi-chevron-down ps-3"></i>}
                                </button>
                                {(canShowArrow && subjectList.length > 0) && <ul className='ab_select_drop_list z_100'>
                                    {subjectList?.map((item: any, i: number) => (
                                        <li className={`subject-list-view ${item?.ui_status === "completed" ? " cursor-pointer" : "text-secondary"}`} key={i} onClick={() => onChangeSubject(item?.uuid, item)}>{item?.name?.toUpperCase()}</li>
                                    ))}
                                </ul>}
                            </div>
                            <div className="mt-5 pt-3">
                                <button className="ab_select mt-5 pe-4" > Assign Pariksha </button>
                            </div>
                        </div>
                    </div>
                </div>}
        </div>
    )

}
export default ParentViewStudentParikshaInitiative;