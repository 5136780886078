import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FETCH_ABHYAS_USER_QUESTION_INFO } from '../../../../app/service/abhyas-module-questions.service';

export const AbhyasTopicResultData = () => {
    const [sectionData, setSectionData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [resultData, setResultData] = useState<any>([])
    const history = useHistory();
    let { moduleUUID, userCode, schoolCode } = useParams<{ moduleUUID: string, userCode: string, schoolCode: string }>();
    const modules_uuid = moduleUUID;
    const user_code = userCode;
    const school_code = schoolCode;
    let selectUuids = JSON.parse(localStorage.getItem('userSelectedSubjectInfo') || '{}');
    let testInfo: any = JSON.parse(localStorage.getItem("abhyas_test_info") || '{}');

    useEffect(() => {
        getAbhyasResults();

    }, []);
    function getAbhyasResults() {
        let payload = {
            module_uuid: modules_uuid,
            school_code: school_code,
            user_code: user_code,
            cycle_no: '',
            board_uuid: selectUuids?.board_uuid,
            medium_uuid: selectUuids?.medium_uuid,
            standard_uuid: selectUuids?.standard_uuid,
            subject_uuid: selectUuids?.subject_uuid
        }
        setLoading(true);
        FETCH_ABHYAS_USER_QUESTION_INFO(payload).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                if (res) {
                    const data = res;
                    const replacedData = data?.test_results?.map((item: any) => ({
                        ...item,
                        levels: item?.levels?.map(replaceValues)
                    }));
                    setResultData(replacedData);
                    loadResults(data.test_results);
                    setLoading(false);

                }
            }
        });
    }



    const loadResults = (test_results: any) => {
        let topic_data: any = [];
        test_results.forEach((item: any) => {
            let topic = topic_data.find((a: any) => a === item.topic);
            if (!topic) {
                topic_data.push(item.topic);
            }
        });

        let sectionList: any = [];
        topic_data.forEach((item: any) => {
            let data = test_results.filter((topic: any) => topic.topic === item);
            sectionList.push({
                title: item,
                data: data
            });
        });

        setSectionData(sectionList);
    }

    const getFieldColor = (value: any) => {
        if (value === 0) {
            return 'red';
        } else if (value === 1 || value === 2) {
            return '#ED7D31';
        } else if (value === 3) {
            return '#8cc63f';
        } else {
            return '#333333';
        }
    }

    const handleBack = () => {
        history.push(`/student-dashboard/list/abhyas/test-level-status/${moduleUUID}/${userCode}/${schoolCode}`)
    }

    function getSolvedWorkSheetNumbers(count: any) {
        if (count.length > 0) {
            return count[0].worksheets;
        } else {
            return '';
        }
    }
    const replaceValues = (value: any) => {
        switch (value) {
            case "LOW":
                return "Easy";
            case "MEDIUM":
                return "Medium";
            case "HIGH":
                return "Hard";
            default:
                return value;
        }
    };
    const navigateWorksheets = () => {
        history.push(`/student-dashboard/list/abhyas/progress-view/${moduleUUID}/${userCode}/${selectUuids?.school_code}/${selectUuids?.board_uuid}/${selectUuids?.medium_uuid}/${selectUuids?.standard_uuid}/${selectUuids?.subject_uuid}`)
    }
    return (
        <div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb pb-2 ps-0 pt-0 border-bottom">
                        <li className="breadcrumb-item "><Link to="/student-dashboard/list"><span className='pe-2'>Dashboard</span></Link></li> /
                        <li className="breadcrumb-item "><Link to={`/student-dashboard/list/abhyas/test-level-status/${moduleUUID}/${userCode}/${schoolCode}`}><span>Abhyaas</span></Link></li> /
                        <li className="breadcrumb-item "><Link to={`/student-dashboard/list/abhyas/progress-view/${moduleUUID}/${userCode}/${selectUuids?.school_code}/${selectUuids?.board_uuid}/${selectUuids?.medium_uuid}/${selectUuids?.standard_uuid}/${selectUuids?.subject_uuid}`}><span>{testInfo?.subject_name}</span></Link></li> /
                        <li className="breadcrumb-item "><span>Combined result</span></li>
                    </ol>
                </nav>
                <div className='' style={{ textAlign: 'right' }}>
                    <button className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer" onClick={handleBack}>Back</button>
                </div>
                <section className="mb-4 mt-1 px-2 px-md-0">
                    <div className="bg-white rounded-16 p-3 p-lg-5">
                        <div className="row align-items-center">
                            <h5 className='fw-bold f18 ps-lg-5'>Assessment test result in details.</h5>
                            <div className="col-md-12 ps-lg-5">
                                <div className="mt-3 custom-scroll table-responsive">
                                    <table className="table ability-table mb-0">
                                        <thead>
                                            <tr className='ability-topic-header'>
                                                {/* <th>Chapter Name</th> */}
                                                <th>Concept</th>
                                                <th className='text-center'>Questions</th>
                                                <th className='text-center'>Correct</th>
                                                <th className='text-center'>Wrong</th>
                                                <th className='text-center'>Score (%)</th>
                                                <th className='text-center'>Worksheets</th>
                                                {/* <th>Levels</th> */}

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {resultData.map((detail: any, i: number) => (
                                                <React.Fragment key={i}>
                                                    <tr >
                                                        {/* <td>{detail?.chapter_name}</td> */}

                                                        <td>{detail?.topic_name}</td>

                                                        <td className='text-center'>{detail?.questions}</td>

                                                        <td className='text-center'>{detail?.correct}</td>

                                                        <td className='text-center'>{detail?.wrong}</td>

                                                        <td className='text-center'>{detail?.percentage?.toFixed()}</td>

                                                        <td className='text-center'>
                                                            {parseInt(detail?.worksheets) == 0 && <span className='text-primary'><u>{detail?.worksheets}</u></span>}
                                                            {parseInt(detail?.worksheets) > 0 && <span className='text-primary cursor-pointer' onClick={navigateWorksheets}><u>{detail?.worksheets}</u></span>}
                                                        </td>
                                                        {/* <td >
                                                            {
                                                                detail?.levels?.length > 0 && detail?.levels?.map((item: any, j: number) => (
                                                                    <span key={j} className='ms-1'>{item}{j !== detail.levels.length - 1 && <span>,</span>}</span>
                                                                ))
                                                            }
                                                        </td> */}

                                                    </tr>
                                                    {/* <tr style={{ textAlign: 'initial' }}>
                                                    <td className='ability-topic-header' colSpan={6}>{detail?.title}</td>
                                                </tr> */}

                                                </React.Fragment>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            }
        </div>
    )
}