import React, { useState } from "react";
import ReportColoumnChart from "./column-chart";
import ReportAccuracyPieChart from "./accuracy-pie-chart";
import MlaPriorityReportTable from './priority-table';
interface Props {
    mlaTwoReportInfo: any;
}
export const MLATwoReport: React.FC<Props> = (props: Props) => {

    const mlaPreData = props?.mlaTwoReportInfo?.map((item: any) => {
        const testResults: any = {};
        item?.test_results?.forEach((result: any) => {
            if (!testResults[result.topic]) {
                testResults[result.topic] = [];
            }
            testResults[result.topic].push({
                ...result,
            });
        });

        const formattedTestResults = Object.keys(testResults).map(topic => ({
            topic: topic,
            data: testResults[topic]
        }));

        return {
            ...item,
            test_results: formattedTestResults
        };
    })
    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        const options: any = { month: 'long', day: 'numeric', year: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    };
    const getShowElements = (data: any) => {
        return data?.every((item: any) =>
            item?.test_results?.length > 0 ||
            item?.priority_1?.length > 0 ||
            item?.priority_2?.length > 0 ||
            item?.priority_3?.length > 0
        );

    }
    return (
        <div className="mx-4">
            {mlaPreData?.length > 0 && <h4 className="txt_mns_org mt-5 pb-3">Minimum Learning Abilities Part - 2 (MLA-2)</h4>}
            {mlaPreData?.length > 0 && mlaPreData?.map((data: any, index: number) => (
                <>
                    {data?.test_results?.length > 0 && <div>
                        <h6 className="ms-4   my-4" key={index}> <span className='under_line_skip_ink entry_ul me-1'>Result of Entry Level Test (Assessment Test {data?.cycle_no}) </span>( {formatDate(data?.test_start_dt)} )</h6>
                        <div className="row justify-content-md-center mb-4 mt-2">
                            <div className="col-12 col-lg-11">
                                <div className="math_charts_responsive">
                                    <div className="">
                                        <ReportColoumnChart
                                            chartTitle="Worksheet assigned as per Assessment Test"
                                            subTitle="Number of worksheets are assigned"
                                            dataValues={[
                                                {
                                                    name: "Priority 1",
                                                    data: [Number(data?.worksheets?.priority_1) ? Number(data?.worksheets?.priority_1) : 0],
                                                    color: '#E97132'
                                                },
                                                {
                                                    name: "Priority 2",
                                                    data: [Number(data?.worksheets?.priority_2) ? Number(data?.worksheets?.priority_2) : 0],
                                                    color: '#0F9ED5'
                                                },
                                                {
                                                    name: " Priority 3",
                                                    data: [Number(data?.worksheets?.priority_3) ? Number(data?.worksheets?.priority_3) : 0],
                                                    color: "#4EA72E"
                                                }
                                            ]}
                                        />
                                    </div>
                                    <div className="">
                                        <div>
                                            <ReportAccuracyPieChart
                                                title="Overall Accuracy in MLA-2"
                                                data={[
                                                    {
                                                        name: 'Accuracy', y: data?.percentage, color: '#0A89B9'
                                                    },
                                                    { name: '', y: (100 - data?.percentage) == 0 ? "" : (100 - data?.percentage), color: '#83B7DD' },
                                                ]}
                                            />
                                        </div>
                                    </div>

                                    <div className="">
                                        <ReportColoumnChart
                                            chartTitle="Priority assigned as per Assessment Test"
                                            subTitle="Number of topics are assigned"
                                            dataValues={[
                                                {
                                                    name: "Priority 1",
                                                    data: [Number(data?.topics?.priority_1) ? Number(data?.topics?.priority_1) : 0],
                                                    color: '#E97132'
                                                },
                                                {
                                                    name: "Priority 2",
                                                    data: [Number(data?.topics?.priority_2) ? Number(data?.topics?.priority_2) : 0],
                                                    color: '#0F9ED5'
                                                },
                                                {
                                                    name: " Priority 3",
                                                    data: [Number(data?.topics?.priority_3) ? Number(data?.topics?.priority_3) : 0],
                                                    color: "#4EA72E"
                                                }
                                            ]} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="report_table_container table-responsive">
                            <table className="mla_two_table_report">
                                <thead>
                                    <tr className="text-center">
                                        <th className="text-start report_col_wd">Abilities</th>
                                        <th>Test - 1 <br /> Marks</th>
                                        <th>Test - 2<br />  Marks</th>
                                        <th>Test - 3 <br /> Marks</th>
                                        <th>Combine <br /> Score</th>
                                        <th>Worksheet to <br /> be solved</th>
                                        <th>Priority <br /> Assigned</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.test_results?.map((test: any, z: number) => (
                                        <>
                                            <tr>
                                                <td className='col_one  fw-bold border-0' colSpan={7} key={z}>{test?.topic}</td>
                                            </tr>
                                            {test?.data?.map((item: any, x: number) => (
                                                <tr className="text-center" key={x}>
                                                    <td className='col_one text-start'>{item?.name}</td>
                                                    <td>{item?.test_1}</td>
                                                    <td>{item?.test_2}</td>
                                                    <td>{item?.test_3}</td>
                                                    <td>{item?.average}</td>
                                                    <td>{item?.worksheets}</td>
                                                    <td>Priority - {item?.priority}</td>
                                                </tr>))} </>))}
                                </tbody>
                            </table>
                        </div>
                    </div>}
                    {data?.priority_1?.length > 0 && <MlaPriorityReportTable priority={data?.priority_1} priorityNumber={"1"} />}
                    {data?.priority_2?.length > 0 && <MlaPriorityReportTable priority={data?.priority_2} priorityNumber={"2"} />}
                    {data?.priority_3?.length > 0 && <MlaPriorityReportTable priority={data?.priority_3} priorityNumber={"3"} />}

                </>))}

        </div>
    )
}